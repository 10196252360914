import _ from 'lodash';
import React from 'react';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { WppButton, WppIconPlus, WppInput, WppIconSearch, WppIconClose, WppIconEdit } from 'buildingBlocks';
import { attachedFlightsTable } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/style';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { useWatch } from 'react-hook-form';

type AttachedFlightsActionsProps = {
  searchValue: string
  setSearchValue: (x: string) => void
  setModalIsOpen: (x: boolean) => void
};

const AttachedFlightsActions = (props: AttachedFlightsActionsProps) => {
  const { searchValue, setSearchValue, setModalIsOpen } = props;
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const { wizardFormValues: { budgetAllocationState } } = useBulkCreateStrategyWizardContext();
  const loadingBudgetAllocationState = budgetAllocationState.kind === Status.loading;
  const hasFlightsAttached = _.size(attachedFlights) > 0;

  return (
    <div style={attachedFlightsTable.container}>
      <div style={attachedFlightsTable.searchAndEditContainer}>
        {hasFlightsAttached && (
          <div style={attachedFlightsTable.searchInput}>
            <WppInput
              type="text"
              placeholder="Search Objects by name or ID"
              value={searchValue}
              onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => setSearchValue(event.detail.value)}
            >
              <WppIconSearch slot="icon-start" aria-label="Search icon" />
              {!_.isEmpty(searchValue) && <WppIconClose slot="icon-end" onClick={() => setSearchValue('')} />}
            </WppInput>
          </div>
        )}
        <WppButton
          variant="secondary"
          style={hasFlightsAttached ? attachedFlightsTable.editButton : attachedFlightsTable.attachButton}
          onClick={() => setModalIsOpen(true)}
          disabled={loadingBudgetAllocationState}
        >
          {hasFlightsAttached ? (
            <>
              <WppIconEdit slot="icon-start" />Edit Objects
            </>
          ) : (
            <>
              <WppIconPlus slot="icon-start" />Attach Platform Object
            </>
          )}
        </WppButton>
      </div>
    </div>
  );
};

export default AttachedFlightsActions;
