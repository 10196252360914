import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const preStyle = {
  fontFamily: 'monospace',
  fontSize: '8pt',
  whitespace: 'pre',
};

export const modalActionStyle = {
  display: 'flex',
  justifyContent: 'right',
};

export const taskListHeader = {
  marginBottom: SPACING[12],
};

export const resultTitleStyle = {
  color: 'black',
  display: 'block',
  paddingLeft: '2em',
};

export const resultListContainer:CSSProperties = {
  borderLeft: '1px solid var(--wpp-grey-color-300)',
  marginTop: SPACING[40],
  paddingLeft: SPACING[16],
};

export const s3DirNameStyle = {
  fontSize: '8pt',
  paddingLeft: '2em',
  wordWrap: 'break-word',
};

export const accordionTitleStyle = {
  display: 'inline-flex',
  verticalAlign: 'middle',
  width: '100%',
};

export const resultAccordianStyle = {
  width: '100%',
  marginBottom: SPACING[12],
};

export const resultAccordianContentStyle:CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',
};

export const accordianLoaderStyle:CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: SPACING[12],
  marginBottom: SPACING[12],
};

export const accordianTextareaStyle:CSSProperties = {
  width: '100%',
};

export const breadCrumContainer = {
  display: 'flex',
  marginBottom: SPACING[8],
};

export const breadCrumBackLink = {
  marginLeft: SPACING[4],
  lineHeight: '15px',
};

export const taskContainerStyle = {
  marginTop: SPACING[24],
};

export const runDetailContainer = {
  width: '100%',
  boxShadow: 'none',
};

export const runDetailTable = {
  width: '100%',
};

export const runDetailTableCell = {
  paddingTop: SPACING[4],
  paddingBottom: SPACING[4],
};

export const successIconStyle = {
  color: 'var(--wpp-success-color-400)',
};

export const errorIconStyle = {
  color: 'var(--wpp-danger-color-500)',
};

export const listItemSelected = {
  backgroundColor: 'var(--wpp-primary-color-100)',
};

export const listItemSelectedLabel = {
  color: 'var(--wpp-brand-color-active)',
};

export const showLogButtonSytle = {
  width: '100px',
};

export const showLogButtonHide = {
  display: 'none',
};
