import { SelectedCategoryFilter } from 'components/Notification/constants';
import { NotificationMsgObject } from 'components/Notification/types';
import { Brand } from 'utils/types';

export enum ViewFilters {
  my = 'my',
  all = 'all',
}

export enum DateSortOrder {
  desc = 'desc',
  asc = 'asc',
}

export enum ActionTypes {
  RESET_STATE,
  SELECT_BRANDS,
  SELECT_CATEGORY_FILTER,
  SELECT_VIEW_FILTER,
  SELECT_DATE_RANGE,
  SELECT_UNREADY_ONLY,
  CHANGE_ELEMENTS_PER_PAGE_OR_PAGE_NUM,
  CHANGE_NUM_OF_ELEMENTS,
  INIT_FROM_QS,
  CHANGE_SORT_ORDER,
  RESET_PAGINATION,
}

export type Action = {
  type: ActionTypes
  payload?: Partial<State>
};

export type State = {
  selectedBrands: Array<Brand>
  selectedCategoryFilter: SelectedCategoryFilter
  viewFilter: ViewFilters
  dateRange: string
  unreadOnly: boolean
  elementsPerPage: number
  pageNumber: number
  numberOfElements: number
  skip: number
  sortOrder: DateSortOrder
};

export type QSParams = {
  selectedCategoryFilter: SelectedCategoryFilter
  viewFilter: ViewFilters
  startDate: string
  endDate: string
  unreadOnly: boolean
  elementsPerPage: string
  pageNumber: string
  numberOfElements: string
  sortOrder: DateSortOrder
  skip: string
  brands?: Array<string>
};

export type StrategyInfo = {
  strategyName: string
  member: string
  advertiser: string
  createdByName: string
  strategyActive: boolean
  strategyId: number
};

export type MoreDetailsInfo = {
  title: string
  displayValue: string
  link: string
  strategyActive: boolean
};

export type InfoToDisplay = StrategyInfo | MoreDetailsInfo;

export enum NotificationStates {
  initFromQs = 'initFromQs',
  hasData = 'hasData',
  error = 'error',
  initial = 'initial',
  loading = 'loading',
}

export type NotificationData = {
  notifications: Array<NotificationMsgObject>
  totalCount: number
};

export type NotificationFetcherState =
| { kind: NotificationStates.initial }
| { kind: NotificationStates.loading }
| { kind: NotificationStates.error, errorObject: any }
| { kind: NotificationStates.hasData, data: NotificationData }
| { kind: NotificationStates.initFromQs, data: NotificationData };
