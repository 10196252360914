import React, { createContext, Dispatch, ReactNode, Reducer, useContext, useMemo, useReducer } from 'react';
import strategyWizardReducer from 'containers/StrategyWizard/contexts/reducer';
import { StrategyWizardAction } from 'containers/StrategyWizard/types';
import { Router } from 'utils/withRouter';
import { BulkCreateWizardFormValues } from '../types';
import { BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI } from '../constants';

type BulkCreateStrategyWizardContextValue = {
  wizardFormValues: BulkCreateWizardFormValues
  dispatch: Dispatch<StrategyWizardAction>
  router: Router
};

type BulkCreateStrategyWizardProviderProps = {
  children: ReactNode
  router: Router
};

const BulkCreateStrategyWizardContext = createContext<BulkCreateStrategyWizardContextValue | null>(null);

const BulkCreateStrategyWizardProvider = ({ children, router }: BulkCreateStrategyWizardProviderProps) => {
  // @ts-ignore expecting strategyTypeSelectionStep
  const [wizardFormValues, dispatch] = useReducer<Reducer<BulkCreateWizardFormValues, StrategyWizardAction>>(strategyWizardReducer, BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI);
  // memoize the context value
  const providerValues = useMemo(() => ({
    wizardFormValues,
    dispatch,
    router,
  }), [wizardFormValues, dispatch, router]);

  return (
    <BulkCreateStrategyWizardContext.Provider value={providerValues}>
      {children}
    </BulkCreateStrategyWizardContext.Provider>
  );
};

export const useBulkCreateStrategyWizardContext = () => {
  const context = useContext(BulkCreateStrategyWizardContext);
  if (!context) {
    throw new Error('useBulkCreateStrategyWizardContext must be used within a BulkCreateStrategyWizardProvider. '
      + 'make sure your component tree is wrapped with <BulkCreateStrategyWizardProvider>');
  }
  return context;
};

export default BulkCreateStrategyWizardProvider;
