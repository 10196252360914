import React, { createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { RESULTS_LIMIT } from 'constantsBase';
import { CandidateTableDisplay, initialFlightCandidateInfo } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { ExternalTypeOptimizationLevel, OPTIMIZATION_LEVELS } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FlightCandidate } from 'utils/copilotAPI';
import { useAsyncEffect } from 'utils/functionHelpers';
import { Flight } from 'utils/types';
import { FlightCandidateInfoType } from '../types';

type BulkCreateAttachModalContextValue = {
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: Dispatch<React.SetStateAction<Array<Flight>>>
  flightCandidateInfo: FlightCandidateInfoType
  setFlightCandidateInfo: Dispatch<React.SetStateAction<FlightCandidateInfoType>>
  candidateDisplay: CandidateTableDisplay
  setCandidateDisplay: Dispatch<React.SetStateAction<CandidateTableDisplay>>
};

const BulkCreateAttachModalContext = createContext<BulkCreateAttachModalContextValue | null>(null);

type Props = {
  children: ReactNode
};

const BulkCreateAttachModalProvider = ({ children }: Props) => {
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const member = useWatch({ name: 'member' });
  const [sessionAttachFlights, setSessionAttachFlights] = useState<Array<Flight>>(attachedFlights);
  const [flightCandidateInfo, setFlightCandidateInfo] = useState<FlightCandidateInfoType>(initialFlightCandidateInfo);
  const [candidateDisplay, setCandidateDisplay] = useState<CandidateTableDisplay>(CandidateTableDisplay.all);

  useAsyncEffect(async () => {
    if (member) {
      await setFlightCandidateInfo({ ...initialFlightCandidateInfo, loadingFlightCandidates: true });
      try {
        const candidates = await FlightCandidate.get({
          advertiserId: 'ALL',
          memberId: member.id,
          limit: RESULTS_LIMIT,
          sort: 'externalId DESC',
          isCrossPlatform: false,
          externalTypeIds: OPTIMIZATION_LEVELS[member.dsp][ExternalTypeOptimizationLevel.HIGHER_ORDER].externalTypeIds,
        });
        setFlightCandidateInfo({
          ...initialFlightCandidateInfo,
          flightCandidates: candidates.data,
          allMemberFlights: candidates.data,
        });
      } catch (e) {
        setFlightCandidateInfo(initialFlightCandidateInfo);
        console.error('Failed to fetch flight candidates', e);
      }
    }
  }, [member?.id]);

  // reset session attached flights every time new flights are selected
  useEffect(() => {
    setSessionAttachFlights(attachedFlights);
  }, [attachedFlights]);

  const providerValues = useMemo(() => ({
    sessionAttachFlights,
    setSessionAttachFlights,
    flightCandidateInfo,
    setFlightCandidateInfo,
    candidateDisplay,
    setCandidateDisplay,
  }), [candidateDisplay, sessionAttachFlights, flightCandidateInfo]);

  return (
    <BulkCreateAttachModalContext.Provider value={providerValues}>
      {children}
    </BulkCreateAttachModalContext.Provider>
  );
};

export const useBulkCreateAttachModalContext = () => {
  const context = useContext(BulkCreateAttachModalContext);
  if (!context) {
    throw new Error('useBulkCreateAttachModalContext must be used within a BulkCreateAttachModalProvider');
  }
  return context;
};

export default BulkCreateAttachModalProvider;
