import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Label } from 'buildingBlocks';
import { dateTimeFormatter } from 'utils/dateTime';
import { Job, JobProps, TimeZoneValue } from 'containers/Jobs/types';
import { jobStatusText, jobStatusColor } from 'containers/Jobs/utils';
import { GlobalState } from 'reducers';
import { UserSettings } from 'utils/types';
import { jobTypeLabel, jobStatusLabel } from './style';

const Body = (props: JobProps) => {
  const { jobs, selectedTimezone } = props;
  const userSettings = useSelector<GlobalState>((state) => state.login.settings) as UserSettings;
  const userTimeZone = selectedTimezone === TimeZoneValue.utc ? null : _.get(userSettings, 'config.timeZone');
  return (
    <Table.Body>
      {_.map(jobs, (job: Job) => (
        <Table.Row key={job.id}>
          <Table.Cell>
            <Link to={`/jobs/${job.id}`}>{job.id}</Link>
            <Label style={jobTypeLabel}>{job.type}</Label>
          </Table.Cell>
          {!_.has(job, 'member')
            ? <Table.Cell />
            : (
              <Table.Cell>
                {job.member.name}&nbsp;
                <small>({job.member.externalId})</small>
              </Table.Cell>
            )}
          <Table.Cell>{dateTimeFormatter.timeZoneBasedDateTime(selectedTimezone, job.createdAt, userTimeZone)}</Table.Cell>
          <Table.Cell>{dateTimeFormatter.timeZoneBasedDateTime(selectedTimezone, job.updatedAt, userTimeZone)}</Table.Cell>
          <Table.Cell>
            <Label color={jobStatusColor(job)} style={jobStatusLabel}>
              {jobStatusText(job)}
            </Label>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default Body;
