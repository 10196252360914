import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Container, Form, Grid, Segment, Button } from 'buildingBlocks';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import PageLoading from 'components/PageLoading';
import PageLoadError from 'components/PageLoadError';
import { Permission } from 'utils/featureFlags';
import { User } from 'utils/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import validator from './validate';
import { submit, fetchMessage, clearFormState } from './actions';
import { PAGE_NAME } from './constants';
import MessageForm from './components/MessageForm';
import MessageFooter from './components/MessageFooter';
import { TriggerButtonProps } from './types';

const REQUIRED_PERMISSIONS = [Permission.manageMessages];

export const NavCancelButton = ({ onClick }: TriggerButtonProps) => (
  <Button style={{ background: 'white' }} onClick={onClick}>
    Cancel
  </Button>
);

type LoadingProps = { id: string };

export const Loading = ({ id } : LoadingProps) => (
  <PageLoading pageName={PAGE_NAME}>{`Loading data for message [${id}]`}</PageLoading>
);

type MessagePageProps = {
  messageId: number
  navigate: NavigateFunction
};

export const MessagePage = (props: MessagePageProps) => {
  const { messageId, navigate } = props;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { login, messageCreator } = useSelector<GlobalState>((state) => state) as any;
  const {
    loadedMessageData,
    formSubmitting,
    messageContextId,
  } = messageCreator;
  const user = _.get(login, 'session.data.user') as User;
  const initialFormValues = { ...loadedMessageData };

  const formMethods = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
    resolver: validator,
  });
  const { getValues, reset, control } = formMethods;
  const content = useWatch({ name: 'content', control });

  useMount(() => {
    if (messageId) {
      fetchMessage(messageId, dispatch);
    }
    return () => {
      dispatch(clearFormState());
    };
  });

  useEffect(() => {
    reset(initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMessageData]);

  const handleReset = () => {
    reset(initialFormValues);
  };

  const handleSubmit = () => {
    const prevAlgoTypes = loadedMessageData.algoTypes;
    const formValues = getValues();
    submit(formValues, messageId, messageContextId, user.id, prevAlgoTypes, navigate, dispatch);
  };

  const handleTriggerClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Container fluid>
      <Segment>
        <FormProvider {...formMethods}>
          <Form>
            <Grid.Column width={12}>
              <Grid>
                <MessageForm />
                <MessageFooter
                  user={user}
                  open={modalOpen}
                  onCancel={handleTriggerClick}
                  content={content}
                  onConfirm={handleSubmit}
                  loading={formSubmitting}
                  handleReset={handleReset}
                />
              </Grid>
            </Grid.Column>
          </Form>
        </FormProvider>
      </Segment>
    </Container>
  );
};

const PermissionMessagePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const messageId = _.get(params, 'id');
  const pageName = `${_.isUndefined(messageId) ? 'Create New' : 'Edit'} ${PAGE_NAME}`;
  const { loadingMessage, fetchError } = useSelector<GlobalState>((state) => state.messageCreator) as any;
  return (
    <PermissionPageTemplate
      permissions={REQUIRED_PERMISSIONS}
      title={pageName}
      name={pageName}
      subNavElements={<NavCancelButton onClick={() => { navigate('/messages'); }} />}
    >
      {loadingMessage && <Loading id={messageId} />}
      {fetchError && <PageLoadError error={`Error loading message (${messageId})`} />}
      <MessagePage messageId={_.toNumber(messageId)} navigate={navigate} />
    </PermissionPageTemplate>
  );
};

export default PermissionMessagePage;
