import { Brand } from 'utils/types';
import { ActionTypes, DateSortOrder, State as FilterState } from './types';

const {
  CHANGE_ELEMENTS_PER_PAGE_OR_PAGE_NUM,
  CHANGE_NUM_OF_ELEMENTS,
  SELECT_BRANDS,
  SELECT_UNREADY_ONLY,
  SELECT_DATE_RANGE,
  SELECT_CATEGORY_FILTER,
  INIT_FROM_QS,
  CHANGE_SORT_ORDER,
  RESET_PAGINATION,
} = ActionTypes;

export const resetPagination = () => ({
  type: RESET_PAGINATION,
});

export const updatePagination = (limit: number, start: number) => ({
  type: CHANGE_ELEMENTS_PER_PAGE_OR_PAGE_NUM,
  payload: {
    pageNumber: (start / limit) + 1,
    elementsPerPage: limit,
    skip: start,
  },
});

export const updateNumOfElements = (totalCount: number) => ({
  type: CHANGE_NUM_OF_ELEMENTS,
  payload: {
    numberOfElements: totalCount,
  },
});

export const selectBrands = (brands: Array<Brand>) => ({
  type: SELECT_BRANDS,
  payload: {
    selectedBrands: brands,
  },
});

export const toggleUnread = (unreadOnly: boolean) => ({
  type: SELECT_UNREADY_ONLY,
  payload: {
    unreadOnly: !unreadOnly,
  },
});

export const updateDateRange = (startDate: Date, endDate: Date) => ({
  type: SELECT_DATE_RANGE,
  payload: {
    dateRange: `${startDate} - ${endDate}`,
  },
});

export const updateFilter = (type: ActionTypes, filterName: string) => {
  const payloadKey = type === SELECT_CATEGORY_FILTER
    ? 'selectedCategoryFilter'
    : 'viewFilter';
  return {
    type,
    payload: {
      [payloadKey]: filterName,
    },
  };
};

export const initFiltersFromQS = (filters: FilterState) => ({
  type: INIT_FROM_QS,
  payload: filters,
});

export const updateSortOrder = (sortOrder: DateSortOrder) => ({
  type: CHANGE_SORT_ORDER,
  payload: {
    sortOrder,
  },
});
