import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';
import {
  WppButton,
  WppCard,
  WppDivider,
  WppEmptyTable,
  WppGrid,
  WppIconPlus,
  WppTag,
  WppTypography,
  WppInlineMessage,
  WppSpinner,
  Dimmer,
} from 'buildingBlocks';
import { DemositeStrategy } from '../types';
import { DEMO_SITES_STYLE } from '../styles';
import { ActionsPopover } from './StrategyActionsPopover';

const {
  homeAndAnalyticsContainer,
  homeAndAnalyticsHeader,
  strategyCard,
  noPadding,
  flexColumnStyle,
  currencyTag,
  paddingLeft20,
  paddingBottom8,
  strategyLabelStyle,
} = DEMO_SITES_STYLE;

type Props = {
  handleStrategyModalOpen: () => void
  setStratIndex: Dispatch<SetStateAction<number>>
  noStrategiesErr: boolean
  isStrategyModalOpen: boolean
};

export const HomeAndAnalytics = ({
  handleStrategyModalOpen,
  setStratIndex,
  noStrategiesErr,
  isStrategyModalOpen,
}: Props) => {
  const formStrategies: Array<DemositeStrategy> = useWatch({ name: 'strategies' });
  const stratCount = _.size(formStrategies);

  return (
    <>
      <WppGrid container fullWidth class="homeAndAnalytics">
        <WppGrid item all={24} style={homeAndAnalyticsHeader}>
          <WppTypography tag="h2" type="xl-heading">
            Strategy
          </WppTypography>
          {stratCount > 0 && (
            <WppButton
              variant="secondary"
              size="m"
              onClick={handleStrategyModalOpen}
              class="addStrategy"
              disabled={stratCount >= 3}
            >
              <WppIconPlus slot="icon-start" /> Add Strategy
            </WppButton>
          )}
          {stratCount === 3 && <WppInlineMessage size="m" message="Maximum allowed strategies are 3." type="warning" />}
        </WppGrid>
        <Dimmer active={isStrategyModalOpen} page={false}>
          <WppSpinner size="s" />
        </Dimmer>
        {stratCount > 0 ? (
          _.map(formStrategies, (strategy, index) => (
            <WppCard size="2xl" style={strategyCard} key={index}>
              <div slot="header">
                <WppTypography tag="span" type="s-strong">
                  {strategy.strategyName}
                </WppTypography>
              </div>
              <ActionsPopover
                stratIndex={_.toNumber(index)}
                setStratIndex={setStratIndex}
                handleStrategyModalOpen={handleStrategyModalOpen}
              />
              <WppGrid container fullWidth>
                <WppGrid container all={24}>
                  <WppGrid item all={23} style={{ ...noPadding, ...flexColumnStyle }}>
                    <WppTypography tag="span" type="s-body" style={strategyLabelStyle}>
                      Brand
                    </WppTypography>
                    <WppTypography tag="span" type="m-strong" style={paddingBottom8}>
                      {strategy.brand}
                    </WppTypography>
                  </WppGrid>
                  <WppGrid item all={1} style={noPadding}>
                    <WppTag label={strategy.currency.code} style={currencyTag} />
                  </WppGrid>
                </WppGrid>
                <WppGrid item all={24}>
                  <WppDivider />
                </WppGrid>
                <WppGrid item all={24} style={flexColumnStyle}>
                  <WppTypography tag="span" type="s-body">
                    Member
                  </WppTypography>
                  <WppTypography tag="h4" type="s-strong" style={paddingBottom8}>
                    {strategy.member}
                  </WppTypography>
                  <WppTypography tag="span" type="s-body">
                    Advertiser
                  </WppTypography>
                  <WppTypography tag="h4" type="s-strong" style={paddingBottom8}>
                    {strategy.advertiser}
                  </WppTypography>
                  <WppTypography tag="span" type="s-body">
                    Attached Flight
                  </WppTypography>
                  <WppTypography tag="h4" type="s-strong">
                    {strategy.attachedFlight}
                  </WppTypography>
                </WppGrid>
              </WppGrid>
            </WppCard>
          ))
        ) : (
          <WppGrid style={homeAndAnalyticsContainer}>
            <WppEmptyTable />
            <WppButton variant="secondary" size="m" onClick={handleStrategyModalOpen}>
              <WppIconPlus slot="icon-start" /> Add Strategy
            </WppButton>
            <WppTypography tag="h4">Add strategy to get started</WppTypography>
          </WppGrid>
        )}
        {noStrategiesErr && stratCount === 0 && (
          <WppInlineMessage
            size="s"
            message="Please add at least 1 strategy."
            type="error"
            style={paddingLeft20}
          />
        )}
      </WppGrid>
    </>
  );
};
