// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-cell {
  display: flex;
  height: 100% !important;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 7px;
}

.ag-center-cols-container {
  width: 100% !important;
}

.dark {
  background-color: var(--wpp-grey-color-800) !important;
  -webkit-filter: unset !important;
  filter: unset !important;
}

a {
  color: var(--wpp-primary-color-500);
}

.toolTipContent a {
  color: var(--wpp-gray-color-000);
  text-decoration: underline;
}
.toolTipContent a:hover {
  text-decoration: none;
  color: var(--wpp-gray-color-000) !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/NotificationCenter/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,sDAAA;EACA,gCAAA;EACA,wBAAA;AAGF;;AADA;EACE,mCAAA;AAIF;;AAFA;EACE,gCAAA;EACA,0BAAA;AAKF;AAJE;EACE,qBAAA;EACA,2CAAA;AAMJ","sourcesContent":[".ag-cell {\n  display: flex;\n  height: 100% !important;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 7px;\n}\n.ag-center-cols-container {\n  width: 100% !important;\n}\n.dark {\n  background-color: var(--wpp-grey-color-800) !important;\n  -webkit-filter: unset !important;\n  filter: unset !important;\n}\na {\n  color: var(--wpp-primary-color-500);\n}\n.toolTipContent a {\n  color: var(--wpp-gray-color-000);\n  text-decoration: underline;\n  &:hover {\n    text-decoration: none;\n    color: var(--wpp-gray-color-000) !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
