import _ from 'lodash';
import qs from 'qs';
import { NavigateFunction } from 'react-router';
import { DSP, FLIGHT_EXTERNAL_TYPE, STRATEGY_TYPE } from 'constantsBase';
import { INITIAL_VALUES as BASE_CAMPAIGN_BUDGET_OPTIMIZATION_INITIAL_VALUES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { getGoalTypeDB } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { StrategyWizardResponse, StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { QSParams } from 'containers/StrategyWizard/utils';
import { StrategyWizardStates } from 'containers/StrategyWizard/constants';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { convertPixelsForQS } from 'containers/StrategyWizard/components/PixelPicker/utils';
import { BulkCreateWizardFormValues } from './types';
import { BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI, BULK_CREATE_WIZARD_BASE } from './constants';

export const getInitialBulkCreateWizardFormFieldsUIValues = (strategyState: StrategyWizardResponse) => {
  switch (strategyState.kind) {
    case PossibleStates.hasData:
      // eslint-disable-next-line no-case-declarations
      const initialFormValues: Partial<BulkCreateWizardFormValues> = {
        attachFlightsStep: {
          ...BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI.attachFlightsStep,
          member: strategyState.data.member,
          optimizationLevel: strategyState.data.optimizationLevel,
          attachedFlights: strategyState.data.attachedFlights,
        },
        goalSelectionStep: {
          goal: {
            target: _.get(_.head(strategyState.data.strategyGoals), 'target'),
            type: getGoalTypeDB(strategyState.data),
            impValueFilters: _.get(strategyState.data.config, 'impValueFilters'),
          },
        },
        strategyConfigurationStep: { ..._.omit(strategyState.data.config, ['revTypeConfig', 'hasCustomRevenueType', 'strategyGoals']) } as StrategyConfigurationStep,
        strategyConfirmationStep: {
          strategies: strategyState.data.strategies,
        },
      };
      return initialFormValues;
    case StrategyWizardStates.initFromQs:
      return strategyState.data;
    case StrategyWizardStates.newStrategy:
      return BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI;
    default:
      return null;
  }
};

export const getInitialValuesByExternalType = async (externalType: string | number) => {
  const validExternalTypes = [
    FLIGHT_EXTERNAL_TYPE.amznCampaign.id,
    FLIGHT_EXTERNAL_TYPE.apnCampaign.id,
    FLIGHT_EXTERNAL_TYPE.ttdCampaign.id,
    FLIGHT_EXTERNAL_TYPE.wmtCampaign.id,
    FLIGHT_EXTERNAL_TYPE.wmtCampaignFlight.id,
    FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id,
    FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id,
  ];
  return (_.includes(validExternalTypes, externalType)) ? BASE_CAMPAIGN_BUDGET_OPTIMIZATION_INITIAL_VALUES : null;
};

export const getStrategyTypeByDsp = (dsp: string | number) => {
  switch (dsp) {
    case DSP.AMZN.id:
      return STRATEGY_TYPE.amznBudgetOptimization;
    case DSP.APN.id:
      return STRATEGY_TYPE.apnBudgetOptimization;
    case DSP.TTD.id:
      return STRATEGY_TYPE.ttdBudgetOptimization;
    default:
      return STRATEGY_TYPE.wmtBudgetOptimization;
  }
};

const convertFormToQueryString = (values: BulkCreateWizardFormValues, populateSegments) => {
  const { member, attachedFlights, optimizationLevel } = values.attachFlightsStep;
  const { goal, budgetSettings } = values.goalSelectionStep;
  const strategyType = values.strategyType;
  const impValueFilters = _.get(goal, 'impValueFilters');
  return {
    wizardForm: {
      member: _.get(member, 'externalId'),
      name,
      strategyType: _.get(strategyType, 'id'),
      flightExtIds: _.map(attachedFlights, 'externalId'),
      optimizationLevel: _.head(optimizationLevel.externalTypeIds),
      goalType: _.get(goal, 'type'),
      goalTarget: _.get(goal, 'target'),
      ...({ budgetSettings }),
      // covert dspId pixel keys to dspCode keys to prevent qs.stringify turning object to array
      ...(impValueFilters && { impValueFilters: convertPixelsForQS(impValueFilters) }),
      selectedOptType: OptimizationType.campaign,
    },
    populateSegments,
  };
};

export const updateQueryString = (values: BulkCreateWizardFormValues, qsParams: QSParams, navigate: NavigateFunction, prev: BulkCreateWizardFormValues, pathname: string) => {
  const queryStringVals = convertFormToQueryString(values, qsParams.populateSegments);
  const stringified = qs.stringify(queryStringVals);
  if (!_.isEmpty(prev)) {
    navigate({ pathname, search: `?${stringified}` });
  }
};

export const getStepUrl = (step: number) => `${BULK_CREATE_WIZARD_BASE}${step}`;
