import _ from 'lodash';
import React from 'react';
import { useFormContext, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { WppMultiSelect } from 'buildingBlocks';
import { ArrayDataProvider } from 'buildingBlocks/TwoListMultiSelect/DataProvider';
import { pluralizer } from 'utils/formattingUtils';
import { Advertiser } from 'utils/types';
import { searchByIdExtIdOrName } from 'utils/functionHelpers';
import SelectedAvertisersHeader from './SelectedAvertisersHeader';

const advertiserOptionLabel = (adv: Advertiser) => `[${adv.externalId}] - ${adv.name}`;

type BrandAdvertisersFieldProps = {
  defaultValue: Array<Advertiser>
  validAdvertisers: Array<Advertiser>
  isAdminOrQAGlobal: boolean
  formDisabled: boolean
  selectionOptionsListHeader?: JSX.Element
};

const BrandAdvertisersField: React.FC<BrandAdvertisersFieldProps> = (props: BrandAdvertisersFieldProps): React.ReactElement => {
  const { defaultValue, validAdvertisers, isAdminOrQAGlobal, formDisabled, selectionOptionsListHeader } = props;
  const { control } = useFormContext();

  const onMultiSelectChange = (
    field: ControllerRenderProps<FieldValues, 'advertisers'>,
    advertisers: Array<Advertiser>,
  ) => {
    field.onChange(advertisers);
  };

  return (
    <Controller
      name="advertisers"
      control={control}
      render={({ field }) => (
        <WppMultiSelect
          dataProvider={new ArrayDataProvider(validAdvertisers, searchByIdExtIdOrName)}
          keyFn={(adv: Advertiser) => _.toString(adv.id)}
          displayFn={(adv: Advertiser) => advertiserOptionLabel(adv)}
          sortFn={(adv: Advertiser) => advertiserOptionLabel(adv)}
          pluralize={pluralizer('Advertiser', 'Advertisers')}
          onChange={(advs: Array<Advertiser>) => onMultiSelectChange(field, advs)}
          defaultValue={defaultValue}
          value={field.value}
          disableOptions={formDisabled}
          disableSelected={!isAdminOrQAGlobal}
          selectionOptionsListHeader={selectionOptionsListHeader}
          selectedGridHeaderData={SelectedAvertisersHeader}
        />
      )}
    />
  );
};

export default BrandAdvertisersField;
