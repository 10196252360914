import { delay } from 'redux-saga';
import { put, call, takeLatest } from 'redux-saga/effects';
import { Admin } from 'utils/copilotAPI';
import { updateAdblockStatus } from './actions';
import { CHECK_FOR_AD_BLOCKER } from './constants';

/**
 * Creates a hidden div on the page, with a classname (adsbox) that would be detected by adblockers. In the case that
 * the element is blocked (removed from the page) it will have an offsetHeight of 0. The delay(1000) is so that the
 * pushdown animation occurs and draws attention, and the page doesn't just load with the message already there.
 * @return {Generator} [description]
 */
export function* checkForAdBlocker() {
  let adBlockEnabled = false;
  const testAd = yield call([document, document.createElement], 'div');
  testAd.innerHTML = '&nbsp;';
  testAd.className = 'adsbox ad ads moat-trackable pb-ad-container';
  yield delay(1000);
  yield call([document.body, document.body.appendChild], testAd);
  if (testAd.offsetHeight === 0) {
    adBlockEnabled = true;
  }
  yield call([document.body, document.body.removeChild], testAd);
  // the above doesn't always work for uBlock, so make a request that would typically get blocked and see if it fails.
  if (!adBlockEnabled) {
    try {
      yield call(Admin.checkAdblock);
    } catch (err) {
      if (err.message === 'Network Error') {
        adBlockEnabled = true;
      }
    }
  }
  yield put(updateAdblockStatus(adBlockEnabled));
}
export function* appSaga() {
  yield takeLatest(CHECK_FOR_AD_BLOCKER, checkForAdBlocker);
}

export default appSaga;
