import React, { Dispatch } from 'react';
import { WppSegmentedControl, WppSegmentedControlItem } from 'buildingBlocks';
import { SelectedCategoryFilter } from 'components/Notification/constants';

type CategorySwitcherProps = {
  selectedCategoryFilter: SelectedCategoryFilter
  updateSelectedCategoryFilter: Dispatch<SelectedCategoryFilter>
};

const CategorySwitcher = ({ selectedCategoryFilter, updateSelectedCategoryFilter }: CategorySwitcherProps) => (
  <WppSegmentedControl value={selectedCategoryFilter} size="s" width="300px" hugContentOff>
    <WppSegmentedControlItem
      value={SelectedCategoryFilter.actions}
      onClick={() => updateSelectedCategoryFilter(SelectedCategoryFilter.actions)}
    >
      Actions
    </WppSegmentedControlItem>
    <WppSegmentedControlItem
      value={SelectedCategoryFilter.updates}
      onClick={() => updateSelectedCategoryFilter(SelectedCategoryFilter.updates)}
    >
      Updates
    </WppSegmentedControlItem>
  </WppSegmentedControl>
);

export default CategorySwitcher;
