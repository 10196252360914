/*
 *
 * CopilotHealth actions
 *
 */

import { GET_SUMMARY_DATA, GET_SUMMARY_DATA_COMPLETED } from './constants';

export function getSummaryData(startDate, endDate) {
  return {
    type: GET_SUMMARY_DATA,
    payload: { startDate, endDate },
  };
}

export function getSummaryDataCompleted(payload) {
  return {
    type: GET_SUMMARY_DATA_COMPLETED,
    payload,
  };
}
