import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { WppDatepicker } from 'buildingBlocks';
import { WppDatepickerCustomEvent, DatePickerEventDetail } from 'utils/types';
import { datePickerStyles } from 'containers/NotificationCenter/style';
import { updateDateRange } from 'containers/NotificationCenter/actions';
import { Action } from 'containers/NotificationCenter/types';

const { calendarWrapper, calendarHeight } = datePickerStyles;

type DatePickerProps = {
  dateRange: string
  dispatch: Dispatch<Action>
  isLoading: boolean
};

const DatePicker = ({
  dateRange,
  dispatch,
  isLoading,
}: DatePickerProps) => {
  const [reducerStartDate, reducerEndDate] = dateRange.split(' - ');
  const [startDate, setStartDate] = useState<Date>(moment(reducerStartDate).toDate());
  const [endDate, setEndDate] = useState<Date>(moment(reducerEndDate).toDate());

  useEffect(() => {
    setStartDate(moment(reducerStartDate).toDate());
    setEndDate(moment(reducerEndDate).toDate());
  }, [reducerStartDate, reducerEndDate]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      dispatch(updateDateRange(start, end));
    }
  };

  const onDatepickerChange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const dates = event.detail.date;
    if (dates) {
      handleDateChange(dates);
    }
  };

  return (
    <div style={calendarWrapper}>
      <WppDatepicker
        size="s"
        value={[
          moment(startDate).format('MM/DD/YYYY'),
          moment(endDate).format('MM/DD/YYYY'),
        ]}
        range
        onWppChange={onDatepickerChange}
        minDate={moment().subtract(30, 'd').format('MM/DD/YYYY').toString()}
        maxDate={moment().format('MM/DD/YYYY').toString()}
        locale={{
          dateFormat: 'MM/dd/yyyy',
        }}
        style={calendarHeight}
        disabled={isLoading}
      />
    </div>
  );
};

export default DatePicker;
