import React, { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { StepChangeEventDetail } from '@wppopen/components-library';
import { WppButton, WppIconInfo, WppSideModal, WppStep, WppStepper, WppSpinner, ValidatedCustomWppInput } from 'buildingBlocks';
import { isValidDomain } from '../utils';
import { DEMO_SITE_INFO_RIBBON, DemoSiteSteps, FIRST_PAGE_COUNT, LAST_PAGE_COUNT, SECOND_PAGE_COUNT } from '../constants';
import CreateStrategy from './CreateStrategy';
import { HomeAndAnalytics } from './HomeAndAnalytics';
import './styles.scss';
import { DemoSiteFormType } from '../types';

type Props = {
  isOpen: boolean
  handleOpenModal: () => void
  handleCloseModal: () => void
  handleStrategyModalOpen: () => void
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  setStratIndex: Dispatch<SetStateAction<number>>
  editedDemoSiteId: number
  editLoading: boolean
  isStrategyModalOpen: boolean
};

type StepperProps = {
  currentStep: number,
  handleStepClick: (event) => void
};

const Stepper = ({ currentStep, handleStepClick }: StepperProps) => (
  <div className="demoSiteStepper">
    <WppStepper orientation="horizontal" activeStep={currentStep} onWppChange={handleStepClick} useResizeObserver={false as any} className="demositeStepper">
      <WppStep>
        <p slot="label" className="demoSiteText">
          {DemoSiteSteps.domain}
        </p>
      </WppStep>
      <WppStep>
        <p slot="label" className="demoSiteText">
          {DemoSiteSteps.strategyCreation}
        </p>
      </WppStep>
      <WppStep>
        <p slot="label" className="demoSiteText">
          {DemoSiteSteps.homeAndAnalytics}
        </p>
        <span slot="description">Last Step</span>
      </WppStep>
    </WppStepper>
  </div>
);

const DemoSiteForm = React.memo((props: Props) => {
  const { isOpen, handleOpenModal, handleCloseModal, currentStep, setCurrentStep, handleStrategyModalOpen, setStratIndex,
    editedDemoSiteId, editLoading, isStrategyModalOpen } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { control, setError, getValues, formState: { errors } } = useFormContext<DemoSiteFormType>();
  const [noStrategiesErr, setNoStrategiesErr] = useState<boolean>(false);

  const validateDomainName = async (domainVal: string) => {
    if (editedDemoSiteId) return true;
    const validDomain = await isValidDomain(domainVal);
    setLoading(false);
    if (!validDomain) {
      setError('domain', {
        type: 'invalid',
        message: 'Domain name is already in use. Update the name or edit the existing site.',
      });
      return false;
    }
    return true;
  };

  const getButtonLabel = () => {
    if (currentStep === LAST_PAGE_COUNT) {
      return editedDemoSiteId ? 'Update' : 'Create';
    }
    return 'Next';
  };

  const handleNextStep = async () => {
    if (currentStep === FIRST_PAGE_COUNT) {
      const domainVal = getValues('domain');
      if (_.isEmpty(domainVal)) return false;
      // Skip domain validation when editing
      if (!editedDemoSiteId) {
        setLoading(true);
        const isDomainValid = await validateDomainName(domainVal);
        if (!isDomainValid) return false; // Stop if domain is invalid
      }
    }
    if (currentStep === SECOND_PAGE_COUNT) {
      const createStrategyErr = _.omit(errors, 'strategies');
      if (!_.isEmpty(createStrategyErr)) return false;
    }
    if (currentStep === LAST_PAGE_COUNT) {
      const strategies = getValues('strategies');
      setNoStrategiesErr(_.isEqual(_.size(strategies), 0));
      return !!_.size(strategies);
    }
    setCurrentStep(currentStep + 1);
    return true;
  };

  const handleStepClick = (event: CustomEvent<StepChangeEventDetail>) => {
    if (!event.detail.index) return;
    setCurrentStep(event.detail.index);
  };

  const handlePreviousStep = () => {
    if (currentStep === FIRST_PAGE_COUNT) return;
    setCurrentStep(currentStep - 1);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case FIRST_PAGE_COUNT:
        return (
          <div className="demoSitePage" data-testid="first-page">
            <Controller
              name="domain"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  type="text"
                  title="Domain"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  className="domainInput"
                  skipIsDirtyCheck
                  disabled={!!editedDemoSiteId}
                />
              )}
            />
            {loading && <WppSpinner size="s" />}
          </div>
        );
      case SECOND_PAGE_COUNT:
        return <div className="demoSitePage demoStrategyCreation" data-testid="second-page"><CreateStrategy /></div>;
      case LAST_PAGE_COUNT:
        return (
          <div className="demoSitePage demoStrategyCreation" data-testid="third-page">
            <HomeAndAnalytics
              handleStrategyModalOpen={handleStrategyModalOpen}
              setStratIndex={setStratIndex}
              noStrategiesErr={noStrategiesErr}
              isStrategyModalOpen={isStrategyModalOpen}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <WppSideModal
      open={isOpen}
      onWppSideModalClose={handleCloseModal}
      onWppSideModalOpen={handleOpenModal}
      disableOutsideClick
      size="l"
    >
      <h2 slot="header" className="demoSiteHeaderStyles">{editedDemoSiteId ? 'Edit' : 'New'} Demo Site</h2>
      <div className="demoSiteMain" slot="body">
        <div className="demoSiteWrapper">
          <Stepper currentStep={currentStep} handleStepClick={handleStepClick} />
          {DEMO_SITE_INFO_RIBBON[currentStep - 1]
            && (
              <div className="demoSiteInfoStyle">
                <WppIconInfo />
                <div className="demoSiteRibbonText" dangerouslySetInnerHTML={{ __html: DEMO_SITE_INFO_RIBBON[currentStep - 1] }} />
              </div>
            )}
          <div className="demoSitePages">
            <div className={`demoSiteInner demoSitePage${currentStep}`}>
              {renderStepContent()}
            </div>
          </div>
        </div>
      </div>
      <div className="demoSiteButtons" slot="actions">
        <WppButton onClick={currentStep === 1 ? handleCloseModal : handlePreviousStep} variant="secondary">
          {currentStep === 1 ? 'Cancel' : 'Back'}
        </WppButton>
        <WppButton onClick={handleNextStep} className="demoSiteButton" data-testid="next-button" variant="primary" type="submit" loading={editLoading}>
          {getButtonLabel()}
        </WppButton>
      </div>
    </WppSideModal>
  );
});

export default DemoSiteForm;
