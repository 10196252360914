import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const actionContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: SPACING[12],
};

export const infoContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: SPACING[16],
};

export const infoLabel: CSSProperties = {
  width: 100,
};
