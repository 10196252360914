import { BulkCreateWizardFormAttachFlights, FilterState, FlightCandidateInfoType, FlightsStatusType } from './types';

export const BULK_CREATE_MAX_FLIGHTS_ALLOWED = 500;

export const BULK_CREATE_ATTACH_FLIGHTS_STEP_INITIAL_VALUES = {
  member: null,
  optimizationLevel: null,
  attachedFlights: [],
} as BulkCreateWizardFormAttachFlights;

export const INITIAL_FILTER_STATE: FilterState = {
  advertiser: [],
  flightDate: null,
  search: '',
};

export enum FilterOptionsActions {
  SET_SEARCH = 'SET_SEARCH',
  SET_ADVERTISER = 'SET_ADVERTISER',
  SET_FLIGHT_DATE = 'SET_FLIGHT_DATE',
  RESET_FILTERS = 'RESET_FILTERS',
}

export const initialFlightCandidateInfo: FlightCandidateInfoType = {
  loadingFlightCandidates: false,
  allMemberFlights: [],
  flightCandidates: [],
};

export const initialFlightsStatus: FlightsStatusType = {
  eligibleFlights: [],
  attachedToAnotherStrategy: [],
};

export enum CandidateTableDisplay {
  all = 'All',
  selected = 'Selected',
}

export enum FlightAlreadyAttachedSelection {
  include = 'include',
  exclude = 'exclude',
}

export const alreadyAttachedHeaderMessages = {
  default: 'Some objects are attached to existing strategies. You need to include them in selection if you wish to attach them to new strategies. Current strategies will be paused.',
  objectsIncludes: 'Some objects included in the selection are attached to existing strategies. Proceeding will pause active objects. Exclude from selection to avoid disrupting ongoing campaigns.',
};
