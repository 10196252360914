import React from 'react';
import { WppGrid, WppProgressIndicator, WppTypography } from 'buildingBlocks';
import { validationProgressStyles } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';

const FlightCandidatesValidationProgress = () => (
  <WppGrid item all={12}>
    <div style={validationProgressStyles.container}>
      <WppTypography type="s-body" style={validationProgressStyles.text}>Validating objects. Please wait</WppTypography>
      <div style={validationProgressStyles.progressBar}>
        <WppProgressIndicator variant="bar" width={30} className="candidateValidationProgressBar" />
      </div>
    </div>
  </WppGrid>
);

export default FlightCandidatesValidationProgress;
