import _ from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Form } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { updateQueryString } from 'containers/BulkCreateStrategyWizard/utils';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { SELECT_GOALS } from 'containers/StrategyWizard/constants';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { User } from 'utils/types';
import { QSParams, canManageFlights, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { createLinkWithQS } from 'utils/functionHelpers';
import { usePrevious } from 'utils/hooks/usePrevious';
import { GOAL_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS, WizardSteps } from 'containers/BulkCreateStrategyWizard/constants';
import GoalSection from './GoalSection';
import { useBulkCreateGoalSectionContext } from './contexts/GoalSectionProvider';
import BudgetSection from './BudgetSection';

type Props = {
  qsParams: QSParams
};

const GoalSelection = ({ qsParams }: Props) => {
  const { dispatch, router: { navigate } } = useBulkCreateStrategyWizardContext();
  const { wizardFormValues } = useBulkCreateGoalSectionContext();
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const {
    attachFlightsStep: { optimizationLevel, member },
    goalSelectionStep: { goal },
  } = wizardFormValues;

  const initialFormValues = {
    goal: {
      type: _.get(goal, 'type'),
      target: _.get(goal, 'target'),
      impValueFilters: _.get(goal, 'impValueFilters'),
    },
  };

  const [confirmedGoal, setConfirmedGoal] = useState<string>(initialFormValues.goal.type);

  const formMethods = useForm<WizardFormGoalSelection>({
    defaultValues: initialFormValues,
    mode: 'onSubmit',
  });

  const { formState, getValues, control, trigger, handleSubmit } = formMethods;
  const goalTarget = _.toNumber(useWatch({ name: 'goal.target', control }));
  const goalType = useWatch({ name: 'goal.type', control });
  const prevFormValues = usePrevious(wizardFormValues);
  const showBackButton = member && canManageFlights(user, member);

  const isHigherLevelOptimizationLevel = isHigherOrderExternalType(_.head(_.get(optimizationLevel, 'externalTypeIds')));

  const disabled = !_.isEmpty(formState.errors) || !goalTarget || !goalType;

  const onSubmit = () => {
    dispatch({
      type: SELECT_GOALS,
      payload: getValues(),
    });
    const values = {
      ...wizardFormValues,
      goalSelectionStep: getValues(),
    };
    const pathname = `${BULK_CREATE_WIZARD_BASE}${BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`;
    updateQueryString(values, qsParams, navigate, prevFormValues, pathname);
  };

  const handleFormSubmit = async () => {
    // Trigger validation for all fields
    const isValid = await trigger();
    if (isValid) {
      // If all fields are valid, submit the form
      handleSubmit(onSubmit)();
    }
  };

  const onBackClick = () => {
    setConfirmedGoal(null);
    const linkWithQS = createLinkWithQS(`${BULK_CREATE_WIZARD_BASE}${BULK_CREATE_WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`);
    navigate(linkWithQS);
  };

  return (
    <FormProvider {...formMethods}>
      <Form>
        <div style={GOAL_SECTION_STYLES.goalBudgSectionStyle}>
          <div style={wppBodyContainer}>
            <BudgetSection />
          </div>
          <div style={wppBodyContainer}>
            <GoalSection
              initialValues={initialFormValues}
              isHigherLevelOptimizationLevel={isHigherLevelOptimizationLevel}
              confirmedGoal={confirmedGoal}
              setConfirmedGoal={setConfirmedGoal}
            />
          </div>
        </div>
        <NavFooter
          strategyId={null}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
          onNextClick={handleFormSubmit}
          nextButtonDisabled={disabled}
        />
      </Form>
    </FormProvider>
  );
};

export default GoalSelection;
