import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const NEW_USER_STYLES: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[24],
    alignItems: 'center',
    marginTop: SPACING[60],
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
    alignItems: 'center',
  },
  headerDescription: {
    maxWidth: '400px',
    textAlign: 'center',
  },
};
