export const JOBS_DETAIL_FETCH = 'JOBS_DETAIL_FETCH';
export const JOBS_DETAIL_FETCH_FAILED = 'JOBS_DETAIL_FETCH_FAILED';
export const JOBS_DETAIL_FETCH_COMPLETED = 'JOBS_DETAIL_FETCH_COMPLETED';

export const TASK_FETCH_DETAILS = 'TASK_FETCH_DETAILS';
export const TASK_FETCH_DETAILS_COMPLETED = 'TASK_FETCH_DETAILS_COMPLETED';
export const TASK_FETCH_DETAILS_FAILED = 'TASK_FETCH_DETAILS_FAILED';

export const TASK_KEYS = [
  'taskFilter',
  'taskAnalytics',
  'taskCampaign',
  'taskDomainList',
  'taskProcess',
  'taskProfile',
  'taskSync',
  'taskTree',
];
