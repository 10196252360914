export const DASHBOARD_SELECT_FLIGHT = 'DASHBOARD_SELECT_FLIGHT';
export const DASHBOARD_SELECT_TASK = 'DASHBOARD_SELECT_TASK';
export const DASHBOARD_SELECT_RESULT = 'DASHBOARD_SELECT_RESULT';
export const DASHBOARD_DOWNLOAD_RESULT = 'DASHBOARD_DOWNLOAD_RESULT';
export const DASHBOARD_SELECT_RUN = 'DASHBOARD_SELECT_RUN';

export const FETCH_STRATEGY_FLIGHT_RUNS = 'FETCH_STRATEGY_FLIGHT_RUNS';
export const FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED = 'FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED';
export const FETCH_STRATEGY_FLIGHT_RUNS_FAILED = 'FETCH_STRATEGY_FLIGHT_RUNS_FAILED';

export const DASHBOARD_FETCH_RESULT = 'DASHBOARD_FETCH_RESULT';
export const DASHBOARD_FETCH_RESULT_FAILED = 'DASHBOARD_FETCH_RESULT_FAILED';
export const DASHBOARD_FETCH_RESULT_COMPLETED = 'DASHBOARD_FETCH_RESULT_COMPLETED';
export const DASHBOARD_DOWNLOAD_RESULT_FAILED = 'DASHBOARD_DOWNLOAD_RESULT_FAILED';

export const DASHBOARD_FETCH_LOG = 'DASHBOARD_FETCH_LOG';
export const DASHBOARD_FETCH_LOG_FAILED = 'DASHBOARD_FETCH_LOG_FAILED';
export const DASHBOARD_FETCH_LOG_COMPLETED = 'DASHBOARD_FETCH_LOG_COMPLETED';

export const STATUS_ICONS = {
  SUCCESS: 'check',
  FAILED: 'x',
  FLIGHT_EXTERNAL_NOT_FOUND: 'x',
  NO_DATA: 'x',
  PENDING: 'play',
  RUNNING: 'play',
};

export const STATUS_COLORS = {
  SUCCESS: 'green',
  FAILED: 'green',
  FLIGHT_EXTERNAL_NOT_FOUND: 'red',
  NO_DATA: 'red',
  PENDING: 'yellow',
  RUNNING: 'yellow',
};

// Error when no flights.
export const MSG_NO_FLIGHTS = 'No flights found.';
// Error when no runs.
export const MSG_NO_RUNS = 'No runs found.';
// Error when no tasks.
export const MSG_NO_TASKS = 'No tasks found.';
// Error when no result paths.
export const MSG_NO_RESULTS = 'No results found.';
// Error when S3 fetch fails.
export const MSG_S3_FAILED = 'Could not load S3 path';

export const HEADER_COLOR = 'black';
