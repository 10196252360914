export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_GET_SESSION_PENDING = 'LOGIN_GET_SESSION_PENDING';
export const LOGIN_GET_SESSION_FAILED = 'LOGIN_GET_SESSION_FAILED';
export const LOGIN_GET_SESSION_SUCCEEDED = 'LOGIN_GET_SESSION_SUCCEEDED';
export const LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED = 'LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED';
export const LAST_LOGIN_UPDATE_FAILED = 'LAST_LOGIN_UPDATE_FAILED';
export const LAST_LOGIN_UPDATE_SUCCEEDED = 'LAST_LOGIN_UPDATE_SUCCEEDED';
export const LOAD_CURRENT_ENV = 'LOAD_CURRENT_ENV';
export const LOGIN_USER_UPDATE_SUCCEEDED = 'LOGIN_USER_UPDATE_SUCCEEDED';

const STAR_STRATEGY_PREFIX = 'STAR_STRATEGY';
export const FETCH_STARRED_USER_STRATEGY = {
  STARTED: `${STAR_STRATEGY_PREFIX}:FETCH_STARRED_USER_STRATEGY_STARTED`,
  COMPLETED: `${STAR_STRATEGY_PREFIX}:FETCH_STARRED_USER_STRATEGY_COMPLETED`,
  FAILED: `${STAR_STRATEGY_PREFIX}:FETCH_STARRED_USER_STRATEGY_FAILED`,
};
export const ADD_STARRED_USER_STRATEGY = `${STAR_STRATEGY_PREFIX}:ADD_STARRED_USER_STRATEGY`;
export const REMOVE_STARRED_USER_STRATEGY = `${STAR_STRATEGY_PREFIX}:REMOVE_STARRED_USER_STRATEGY`;

export const oktaSubmitButtonContainerClass = '.o-form-button-bar';
export const oktaSubmitButtonIdentifier = '#okta-signin-submit.button.button-primary';
export const oktaLoginErrorId = 'copilot-okta-login-error';
