import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { WppAccordion } from 'buildingBlocks';
import { BulkCreateWizardFormAttachFlights } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';
import ChildLevelFlightDetails from 'containers/StrategyWizard/steps/AttachFlights/components/AttachFlightsTable/ChildLevelFlightDetails';
import { Flight } from 'utils/types';
import FlightDetailsTable from './FlightDetailsTable';

type AttachedFlightsTableProps = {
  flightsToDisplay: Array<Flight>
};

const AttachedFlightsTable = (props: AttachedFlightsTableProps) => {
  const { flightsToDisplay } = props;
  const { setValue, watch } = useFormContext<BulkCreateWizardFormAttachFlights>();
  const attachedFlights = watch('attachedFlights');

  const removeFlight = (externalId: string) => {
    const updatedFlights = _.filter(attachedFlights, (flight: Flight) => flight.externalId !== externalId);
    setValue('attachedFlights', updatedFlights);
  };

  return (
    <>
      {_.map(flightsToDisplay, (flight: Flight) => (
        <WppAccordion
          key={flight.externalId}
          size="m"
          className="object-attach-accordion"
        >
          <div slot="header">
            <FlightDetailsTable
              flight={flight}
              removeFlight={removeFlight}
            />
          </div>
          <ChildLevelFlightDetails parentFlightExtId={flight.externalId} flight={flight} />
        </WppAccordion>
      ))}
    </>
  );
};

export default AttachedFlightsTable;
