import _ from 'lodash';
import React from 'react';
import { WppStepper, WppStep } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { wizardStep } from 'containers/BulkCreateStrategyWizard/style';
import { BULK_CREATE_WIZARD_STEPS, StepItem, WizardSteps } from './constants';
import { BulkCreateWizardFormValues } from './types';
import { useBulkCreateStrategyWizardContext } from './contexts/BulkCreateStrategyWizardProvider';

type Props = {
  step: number
  wizardFormValues: BulkCreateWizardFormValues
};

type StepDescriptionProps = {
  formField: string
  additionalFieldsForDisplay: Array<string>
  wizardFormValues: BulkCreateWizardFormValues
  description: string
  dynamicDescription: (formField: string, ...args: Array<string>) => string
  currentStep: number
  stepId: number
};

const StepDescription = ({
  formField,
  additionalFieldsForDisplay,
  wizardFormValues,
  description,
  dynamicDescription,
  currentStep,
  stepId,
}: StepDescriptionProps) => {
  if (stepId >= currentStep || currentStep <= _.toNumber(BULK_CREATE_WIZARD_STEPS[WizardSteps.attachFlightsStep].id)) {
    return null;
  }
  // handle dynamic descriptions based on form field and additional fields
  if (formField) {
    const additionalFieldValues = _.map(additionalFieldsForDisplay, (field) => _.get(wizardFormValues, field));

    return (
      <span slot="description" style={strategyWizardStyles.stepperDescription}>
        {dynamicDescription(formField, ...additionalFieldValues)}
      </span>
    );
  }
  return <span slot="description" style={strategyWizardStyles.stepperDescription}>{description}</span>;
};

const renderWizardSteps = (wizardStepBasedOnCreateStrat, wizardFormValues: BulkCreateWizardFormValues, step: number) => _.map(wizardStepBasedOnCreateStrat, (stepItem: StepItem) => {
  const { id, title, description } = stepItem;
  const dynamicDescription = _.get(stepItem, 'dynamicDescription');
  const additionalFields = _.get(stepItem, 'additionalFieldsForDisplay', []);
  const formField = _.get(wizardFormValues, stepItem.field);

  return (
    <WppStep
      key={id}
      className="strategy-wizard-stepper-step"
    >
      <p slot="label" style={wizardStep.containerTitle}>
        {title}
      </p>
      <StepDescription
        formField={formField}
        additionalFieldsForDisplay={additionalFields}
        wizardFormValues={wizardFormValues}
        description={description}
        dynamicDescription={dynamicDescription}
        currentStep={step}
        stepId={id}
      />
    </WppStep>
  );
});

const BulkCreateStrategyWizardProgress = (props: Props) => {
  const { step, wizardFormValues } = props;
  const { router } = useBulkCreateStrategyWizardContext();
  return (
    <WppStepper activeStep={_.toNumber(router.params.step)} useDecimalSubSteps stepperWidth="100%">
      {renderWizardSteps(BULK_CREATE_WIZARD_STEPS, wizardFormValues, step)}
    </WppStepper>
  );
};

export default BulkCreateStrategyWizardProgress;
