import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Form, WppLabel, WppListItem, WppSelect } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { FilterOptionsActions } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { ExternalTypeOptimizationLevel, OPTIMIZATION_LEVELS } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { modalContainer } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/style';
import { BulkCreateWizardFormAttachFlights, FilterAction } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';
import { Member, SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';

type MemberFieldProps = {
  members: Array<Member>
  memberFetchError: string
  dispatchFilter: React.Dispatch<FilterAction>
};

const MemberField = (props: MemberFieldProps) => {
  const { members, memberFetchError, dispatchFilter } = props;
  const { flightCandidateInfo, setSessionAttachFlights } = useBulkCreateAttachModalContext();
  const { loadingFlightCandidates } = flightCandidateInfo;
  const { control, setValue } = useFormContext<BulkCreateWizardFormAttachFlights>();
  const selectedMember = useWatch({ name: 'member' });
  const disabled = !!memberFetchError || !_.size(members) || loadingFlightCandidates;

  // refetch flights and reset filter if new member is selected
  // fetching of flight candidates happens in useEffect within BulkCreateAttachModalProvider
  const handleOnMemberChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    const member = event.target.value;
    if (member !== selectedMember) {
      setValue('member', member);
      setValue('optimizationLevel', OPTIMIZATION_LEVELS[member.dsp][ExternalTypeOptimizationLevel.HIGHER_ORDER]);
      setSessionAttachFlights([]);
      dispatchFilter({ type: FilterOptionsActions.RESET_FILTERS });
    }
  };

  const getDisplayValue = () => {
    if (!_.size(members)) {
      return 'Loading Members...';
    }
    if (memberFetchError) {
      return 'Error fetching members';
    }
    return selectedMember ? selectedMember.displayName : 'Select a member to get started...';
  };

  return (
    <Form.Field style={{ width: '100%' }}>
      <WppLabel
        htmlFor="member"
        typography="s-strong"
        config={{ text: 'Member' }}
        style={modalContainer.label}
      />
      <Controller
        name="member"
        control={control}
        rules={{ required: true }}
        render={(field) => (
          <>
            <WppSelect
              {...field}
              value={selectedMember}
              placeholder="Search Members..."
              onWppChange={handleOnMemberChange}
              size="m"
              displayValue={getDisplayValue()}
              withSearch
              disabled={disabled}
            >
              {_.map(members, (mem) => (
                <WppListItem key={mem.id} value={mem}>
                  <p slot="right" style={{ marginTop: '10px' }}>
                    <DspIcon dspId={_.toNumber(mem.dsp)} />
                  </p>
                  <p slot="label">{mem.displayName}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </>
        )}
      />
    </Form.Field>
  );
};

export default MemberField;
