import _ from 'lodash';

/** Task key at which log S3 locations are stored. */
const KEY_LOGS = 'log_locations';
/** Task key at which exceptions are stored. */
const KEY_EXCEPTIONS = 'exceptions';
/** Task key at which warnings are stored. */
const KEY_WARNINGS = 'warnings';
/** Task key at which warnings are stored. */
const KEY_MESSAGES = 'messages';
/** Task keys containing meta data. */
const META_KEYS = [KEY_LOGS, KEY_EXCEPTIONS, KEY_WARNINGS, KEY_MESSAGES];
/** Suffix of S3 key, the presence of which indicates task success. */
const SUCCESS_KEY_SUFFFIX = '_AF_SUCCESS';
/** Suffix of S3 key, the presence of which indicates task failure. */
const FAILURE_KEY_SUFFFIX = '_AF_FAILED';
/** Human-readable labels corresponding to status suffixes. */
const STATUS_LABELS = {
  [SUCCESS_KEY_SUFFFIX]: 'success',
  [FAILURE_KEY_SUFFFIX]: 'failure',
};

export class Result {
  url: string;

  loaded: boolean;

  mimeType: string;

  data: Array<{}>;

  constructor(url) {
    this.url = url;
    this.loaded = false;
    this.mimeType = undefined;
    this.data = undefined;
  }

  shortName(prefix) {
    return this.url.slice(prefix.length);
  }

  get suffix() {
    return _(this.url)
      .chain()
      .split('/')
      .last()
      .value();
  }
}

export class Task {
  // The JSON path to task results, as represented in the DB field.
  RESULT_KEY_PREFIX = 'result.tasks';

  name: string;

  results: any;

  log: string;

  exception: {
    exception: string,
    traceback: string,
    type: string
  };

  logResult: string | null;

  constructor(name, results, log, exception) {
    this.name = name;
    this.results = _(results)
      .chain()
      .sortBy([
        (r) => r.url.split('/').length,
        (r) => r.url])
      .value();
    this.log = log;
    this.exception = exception;
    this.logResult = null;
  }

  get key() {
    return `${this.RESULT_KEY_PREFIX}.${this.name}`;
  }

  /**
   * @returns {STATUS_LABEL} The status, a value from the STATUS_LABEL enum.
   */
  get status() {
    return _([FAILURE_KEY_SUFFFIX, SUCCESS_KEY_SUFFFIX])
      .chain()
      .filter((suffix) => _.some(this.results, (result) => _.endsWith(result.url, suffix)))
      .first()
      .defaultTo(FAILURE_KEY_SUFFFIX)
      .thru((statusKey) => _.get(STATUS_LABELS, statusKey))
      .value();
  }

  get displayName() {
    return this.name.replace(/_/g, ' ');
  }

  get s3DirName() {
    if (this.results.length === 1) {
      return _(this.results[0].url.split('/'))
        .initial()
        .join('/');
    }
    const urls = this.results.map((r) => r.url);
    const segments = urls.map((url) => url.split('/'));
    return _(_.zip(...segments))
      .map(_.uniq)
      .takeWhile((parts) => _.uniq(parts).length === 1)
      .map(_.head)
      .join('/');
  }
}

const taskFactory = (logs, exceptions) => (resultUrls, name) => {
  const log = _.get(logs, name);
  const exception = _.get(exceptions, name);
  const results = _(resultUrls).map((url) => new Result(url)).value();
  return new Task(name, results, log, exception);
};

export const tasksForRun = (run: { result: { tasks: {} } }) => {
  // @ts-ignore
  const { log_locations: logs, exceptions } = _(run).chain()
    .get('result.tasks', {})
    .pick(META_KEYS)
    .value();
  const factory = taskFactory(logs, exceptions);
  return _(run).chain()
    .get('result.tasks', {})
    .omit(META_KEYS)
    .map(factory)
    .value();
};

export const getPreviousPageLink = (currentPage, defaultUrl) => {
  if (currentPage.search === window.previousLocation.search) {
    return defaultUrl;
  }
  return window.previousLocation;
};
