import _ from 'lodash';
import { MessagePriorities } from 'constantsBase';
import {
  NOTIFICATIONS_FETCH_EXISTING_COMPLETED,
  NOTIFICATIONS_READ_COMPLETED,
  NOTIFICATIONS_NEW_COMPLETED,
  SelectedCategoryFilter,
} from 'components/Notification/constants';
import { NotificationMsgObject } from 'components/Notification/types';
import { StarredUserStrategyType } from 'containers/StrategyAnalytics/types';
import { USER_SETTINGS_UPDATE_COMPLETED } from 'containers/UserSettings/constants';
import { dateTimeFormatter } from 'utils/dateTime';
import {
  LOGIN_SUCCEEDED,
  LOGIN_GET_SESSION_FAILED,
  LOGIN_GET_SESSION_SUCCEEDED,
  LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED,
  LAST_LOGIN_UPDATE_SUCCEEDED,
  LOAD_CURRENT_ENV,
  FETCH_STARRED_USER_STRATEGY,
  REMOVE_STARRED_USER_STRATEGY,
  ADD_STARRED_USER_STRATEGY,
  LOGIN_USER_UPDATE_SUCCEEDED,
} from './constants';
import { LOGOUT_SUCCESS } from '../Logout/constants';
import { UPDATE_USER_SETTING_COMPLETED } from '../StrategiesList/constants';

export const INITIAL_STATE = {
  isAuthenticated: false,
  isAuthenticatedWithoutUser: false,
  pendingAuth: true, // used if we are waiting to hear back on getting session from server
  profile: null,
  session: null,
  token: null,
  user: null,
  notifications: [],
  notificationsActionsUnreadCount: 0,
  notificationsUpdatesUnreadCount: 0,
  env: null,
};

// we get an array of one read notification from the end point
export const updateReadNotification = (notifications: Array<NotificationMsgObject>, readNotifications: Array<NotificationMsgObject>) => {
  const readNotification = _.head(readNotifications);
  const index = _.findIndex(notifications, { lastNotification: readNotification.lastNotification });
  const updatedNotifications = [...notifications];
  // ensure that the notification indices don't change
  updatedNotifications[index] = readNotification;
  return updatedNotifications;
};

// if read all request was successful, we are hacking the readAt of existing notifications,
// as it really doesn't matter what value the readAt is when it's not null
export const updateReadNotifications = (notifications: Array<NotificationMsgObject>) => {
  const readAt = dateTimeFormatter.isoDateTime(undefined, true);
  return _.map(notifications, (n) => ({ ...n, readAt }));
};

export const getActionRequiredNotificationsCount = (notifications: Array<NotificationMsgObject>) => _.size(_.filter(notifications, (n) => n.message?.priority >= MessagePriorities.medium && _.isNil(n.readAt)));

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedWithoutUser: false,
        pendingAuth: true,
        profile: action.payload.data.user.profile,
        session: action.payload,
        settings: action.payload.data.user.settings,
        token: action.payload.data.token,
      };
    case LOGIN_GET_SESSION_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticatedWithoutUser: false,
        pendingAuth: false,
        profile: action.payload.data.profile,
        session: action.payload,
        settings: action.payload.data.user.settings,
        token: action.payload.data.token,
        user: action.payload.data.user,
      };
    case LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedWithoutUser: true,
        pendingAuth: false,
        profile: action.payload.data.profile,
        session: action.payload,
        token: action.payload.data.token,
        user: action.payload.data.user,
      };
    case LAST_LOGIN_UPDATE_SUCCEEDED:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedWithoutUser: false,
        pendingAuth: true,
        profile: null,
        session: null,
        token: null,
        user: null,
      };
    case LOGIN_GET_SESSION_FAILED:
      return {
        ...state,
        pendingAuth: false,
      };
    case NOTIFICATIONS_FETCH_EXISTING_COMPLETED:
      return {
        ...state,
        ...action.payload,
      };
    case NOTIFICATIONS_READ_COMPLETED: {
      const updatedNotifications = updateReadNotification(state.notifications, action.payload.data);
      const payloadNotification = _.head(action.payload.data) as NotificationMsgObject;
      const { selectedCategoryFilter } = action.payload;
      const newCount = _.isNil(payloadNotification.readAt) ? (count: number) => count + 1 : (count: number) => count - 1;
      return {
        ...state,
        notifications: updatedNotifications,
        notificationsActionsUnreadCount:
          selectedCategoryFilter === SelectedCategoryFilter.actions
            ? Math.max(newCount(state.notificationsActionsUnreadCount), 0)
            : state.notificationsActionsUnreadCount,
        notificationsUpdatesUnreadCount:
          selectedCategoryFilter === SelectedCategoryFilter.updates
            ? Math.max(newCount(state.notificationsUpdatesUnreadCount), 0)
            : state.notificationsUpdatesUnreadCount,
      };
    }
    case NOTIFICATIONS_NEW_COMPLETED:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_SETTING_COMPLETED:
    case USER_SETTINGS_UPDATE_COMPLETED:
      return {
        ...state,
        settings: action.payload,
        user: { ...state.user, settings: action.payload },
      };
    case LOAD_CURRENT_ENV:
      return { ...state, env: action.payload };
    case FETCH_STARRED_USER_STRATEGY.COMPLETED:
      return {
        ...state,
        user: { ...state.user, starredUserStrategies: action.payload },
      };
    case REMOVE_STARRED_USER_STRATEGY:
      return {
        ...state,
        user: {
          ...state.user,
          starredUserStrategies: _.filter(
            state.user.starredUserStrategies,
            (sus: StarredUserStrategyType) => (_.isArray(action.payload) ? !_.includes(action.payload, sus.id) : !_.isEqual(sus.id, action.payload)),
          ),
        },
      };
    case ADD_STARRED_USER_STRATEGY:
      return {
        ...state,
        user: {
          ...state.user,
          starredUserStrategies: [
            ...state.user.starredUserStrategies,
            ...(_.isArray(action.payload) ? action.payload : [action.payload]),
          ],
        },
      };
    case LOGIN_USER_UPDATE_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          approver: action.payload,
        },
      };
    default:
      return state;
  }
};

export default loginReducer;
