import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _ from 'lodash';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { InputChangeEventDetail, SelectChangeEventDetail } from '@wppopen/components-library';
import { ValidatedCustomWppInput, WppButton, WppGrid, WppInlineMessage, WppLabel, WppListItem, WppSelect, WppSideModal, WppToggle, WppTypography } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { WppInputCustomEvent, WppSelectCustomEvent } from 'utils/types';
import Pacing from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/Pacing';
import Tooltip from 'containers/Tooltip';
import { VIEWABILITY_TOOLTIP } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { tooltipContent } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/Toggles/utils';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';
import { DSP, GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { getRealValue, setNumberValue } from 'buildingBlocks/ReactHookFormComponents/utils';
import { getGoalTargetProps } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/GoalConfiguration';
import { useAsyncEffect } from 'utils/functionHelpers';
import { getCurrency } from 'containers/StrategiesList/utils';
import { standardGoalsForBulkCreate } from '../constants';
import { strategyTableHeader } from '../style';
import { BulkCreateStrategyType } from '../types';

type Props = {
  isOpen: boolean
  handleOpenModal: () => void
  handleCloseModal: () => void
  setSelectedStratIndex: Dispatch<SetStateAction<Array<number>>>
  updateStrategy: (strategy: BulkCreateStrategyType, selectedStrats: Array<number>) => void
  selectedStratIndex: Array<number>
  setStrategiesSelected: Dispatch<SetStateAction<Record<string, BulkCreateStrategyType>>>
  dsp: Number | string
  showYoutubeGoals: boolean
};

const { objectContainer, objectDivStyle, viewabilityMargin, modalHeading } = strategyTableHeader;
const { budgetAllocation } = BUDGET_MANAGEMENT_STYLES;

export const EditStrategy = React.memo((props: Props) => {
  const { isOpen, handleOpenModal, handleCloseModal, selectedStratIndex, dsp, showYoutubeGoals, setSelectedStratIndex, updateStrategy, setStrategiesSelected } = props;
  const formStrategies = useWatch({ name: 'strategies' });
  const [selectedStrategy, setSelectedStrategy] = useState<BulkCreateStrategyType>(null);
  const [selectedGoalValueType, setGoalValueType] = useState<string>('');
  const [shouldValidatePercentage, setValidatepercentage] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>('');
  const isAmazonFlight = dsp === DSP.AMZN.id;
  const goalsToRender = !showYoutubeGoals
    ? standardGoalsForBulkCreate.filter(
      (goal) => goal.value !== GOAL_TYPES.vcrYoutube.value && goal.value !== GOAL_TYPES.cpvYoutube.value,
    ) : standardGoalsForBulkCreate;

  useAsyncEffect(async () => {
    if (!_.isEmpty(selectedStratIndex)) {
      const selectedStrat = await formStrategies[selectedStratIndex[0]];
      setSelectedStrategy(selectedStrat);
      const [{ code }] = await getCurrency(selectedStrat.currency);
      setCurrencyCode(code);
    }
  }, [selectedStratIndex]);

  const formMethods = useForm<BulkCreateStrategyType>({
    defaultValues: selectedStrategy,
    mode: 'all',
  });
  const { control, reset, watch, formState: { isValid }, getValues } = formMethods;
  const pacing = watch('dailyParentBudgetInflationRatio');
  const viewabilityEnabled = watch('viewability.enabled');

  useEffect(() => {
    if (selectedStrategy) {
      reset(selectedStrategy); // Reset form with new strategy when selected changes
      const { valueType } = _.get(GOAL_TYPES, selectedStrategy.goal.type);
      setGoalValueType(valueType);
      setValidatepercentage(valueType === GOAL_VALUE_TYPE.PERCENTAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy, reset]);

  const handleBackClick = () => {
    handleCloseModal();
    setSelectedStratIndex(null);
  };

  const handleStrategySave = () => {
    const formValues = getValues();
    updateStrategy(formValues, selectedStratIndex);
    setStrategiesSelected({});
  };

  const goalTargetProps = selectedStrategy && getGoalTargetProps(selectedGoalValueType, currencyCode);
  return (
    <WppSideModal
      open={isOpen}
      onWppSideModalClose={handleBackClick}
      onWppSideModalOpen={handleOpenModal}
      disableOutsideClick
      size="l"
    >
      {selectedStrategy
        && (
          <FormProvider {...formMethods}>
            <>
              <WppTypography slot="header" type="2xl-heading" style={modalHeading}>Editing Strategy</WppTypography>
              <WppGrid container fullWidth item all={24} slot="body">
                <WppGrid item all={24}>
                  <div style={{ ...wppBodyContainer, ...objectContainer }}>
                    <WppTypography tag="h3" type="m-midi">Attached Object</WppTypography>
                    {_.map(selectedStratIndex, (stratIndex) => (
                      <WppGrid container all={24} style={objectDivStyle} key={stratIndex}>
                        <WppGrid item all={23}>
                          <WppInlineMessage
                            size="m"
                            message={formStrategies[stratIndex].object}
                            type="information"
                            hideCloseBtn
                            showTooltipFrom={4000}
                          />
                        </WppGrid>
                        <WppGrid item all={1}>
                          <DspIcon dspId={_.toNumber(formStrategies[stratIndex].dsp)} />
                        </WppGrid>
                      </WppGrid>
                    ))}
                  </div>
                </WppGrid>
                {_.size(selectedStratIndex) === 1 && (
                  <WppGrid item fullWidth all={24}>
                    <Controller
                      name="strategyName"
                      control={control}
                      defaultValue={selectedStrategy?.strategyName}
                      rules={{
                        required: { value: true, message: 'Required' },
                      }}
                      render={(properties) => (
                        <ValidatedCustomWppInput
                          type="text"
                          title="Strategy Name"
                          value={properties.field.value}
                          onWppChange={properties.field.onChange}
                          {..._.omit(properties, 'formState')}
                          className="strategyName"
                          skipIsDirtyCheck
                        />
                      )}
                    />
                  </WppGrid>
                )}
                <WppGrid item all={24}>
                  <WppLabel htmlFor="goal">
                    <WppTypography tag="p" type="s-strong">Goal</WppTypography>
                  </WppLabel>
                  <Controller
                    name="goal.type"
                    control={control}
                    render={({ field }) => (
                      <WppSelect
                        id="goalType"
                        {...field}
                        value={field.value}
                        onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                          const selectedValue = event.detail.value;
                          field.onChange(selectedValue);
                          const updatedGoal = standardGoalsForBulkCreate.find((goal) => goal.value === selectedValue);
                          if (updatedGoal) {
                            const goalValType = updatedGoal.valueType;
                            setGoalValueType(goalValType);
                            setValidatepercentage(goalValType === GOAL_VALUE_TYPE.PERCENTAGE);
                          }
                        }}
                      >
                        {_.map(goalsToRender, (goalType) => (
                          <WppListItem key={goalType.text} value={goalType.value}>
                            <p slot="label">{goalType.strategyWizardAbbreviation}</p>
                          </WppListItem>
                        ))}
                      </WppSelect>
                    )}
                  />
                </WppGrid>
                <WppGrid item fullWidth all={24}>
                  <Controller
                    name="goal.target"
                    control={control}
                    rules={{
                      required: { value: true, message: 'Required' },
                      maxLength: { value: 4, message: 'Name has a maximum of 255 characters.' },
                    }}
                    defaultValue={selectedStrategy?.goal.target}
                    render={({ field, ...rest }) => (
                      <goalTargetProps.component
                        title="Goal Target (For Reporting Only)"
                        type="decimal"
                        size="s"
                        onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => {
                          field.onChange(setNumberValue(e.detail.value, shouldValidatePercentage));
                        }}
                        value={(shouldValidatePercentage && _.isNumber(field.value)) ? getRealValue(field.value, 2) : field.value}
                        skipIsDirtyCheck
                        {..._.omit(goalTargetProps, 'component')}
                        {..._.omit({ field, ...rest }, 'formState')}
                      />
                    )}
                  />
                </WppGrid>
                <WppGrid item fullWidth all={24}>
                  <Pacing pacing={_.toNumber(pacing)} />
                </WppGrid>
                <WppGrid item fullWidth all={24}>
                  <div style={strategyWizardStyles.viewabilityHeader}>
                    <WppTypography type="m-strong">Viewability</WppTypography>
                    <Tooltip
                      content={VIEWABILITY_TOOLTIP}
                      tooltipstyling={{ marginTop: '2px' }}
                    >
                      <WppInlineMessage
                        size="s"
                        type="information"
                      />
                    </Tooltip>
                  </div>
                  <Controller
                    name="viewability.enabled"
                    control={control}
                    defaultValue={selectedStrategy?.viewability.enabled}
                    render={(properties) => (
                      <WppToggle
                        checked={properties.field.value}
                        name="options"
                        disabled={isAmazonFlight}
                        onWppChange={({ detail: { checked } }) => {
                          properties.field.onChange(checked);
                        }}
                      />
                    )}
                  />
                  {viewabilityEnabled && (
                    <div style={viewabilityMargin}>
                      <Controller
                        name="viewability.target"
                        control={control}
                        render={({ field, fieldState }) => (
                          <ValidatedCustomWppInput
                            type="decimal"
                            label="%"
                            onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(setNumberValue(e.detail.value, true))}
                            value={_.isNumber(field.value) ? getRealValue(field.value, 2) : field.value}
                            style={{ width: 120 }}
                            size="m"
                            field={field}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </div>
                  )}
                </WppGrid>
                <WppGrid item fullWidth all={24}>
                  <div style={budgetAllocation.budgetAllocationHeader}>
                    <WppTypography type="m-strong">Intelligent Child Objects</WppTypography>
                    <Tooltip
                      content={tooltipContent(_.toNumber(selectedStrategy.dsp), 'intelligentChildObjects')}
                    >
                      <WppInlineMessage
                        size="s"
                        type="information"
                      />
                    </Tooltip>
                  </div>
                  <Controller
                    name="intelligentChildObjects"
                    control={control}
                    defaultValue={selectedStrategy?.intelligentChildObjects}
                    render={(properties) => (
                      <WppToggle
                        checked={!!properties.field.value}
                        disabled={isAmazonFlight}
                        name="options"
                        onWppChange={({ detail: { checked } }) => properties.field.onChange(checked)}
                      />
                    )}
                  />
                </WppGrid>
              </WppGrid>
              <div className="demoSiteButtons" slot="actions">
                <WppButton onClick={handleBackClick} variant="secondary">
                  Cancel
                </WppButton>
                <WppButton onClick={handleStrategySave} variant="primary" type="button" disabled={!isValid}>
                  Save
                </WppButton>
              </div>
            </>
          </FormProvider>
        )}
    </WppSideModal>
  );
});

export default EditStrategy;
