import React from 'react';
import { useSelector } from 'react-redux';
import { WppActionButton, WppIconExternalLink, WppBanner } from 'buildingBlocks';
import { GlobalState } from 'reducers';

type BannerComponentProps = {
  handleCompleteActionsClick: () => void,
  handleClose: () => void,
  showBanner: boolean,
};

const BannerComponent: React.FC<BannerComponentProps> = ({ handleCompleteActionsClick, handleClose, showBanner }: BannerComponentProps):
React.ReactElement => {
  const notificationsActionsUnreadCount = useSelector<GlobalState>((state) => state.login.notificationsActionsUnreadCount) as number;
  return (
    <WppBanner
      type="warning"
      show={showBanner}
      closable
      onWppClose={handleClose}
    >
      {`${notificationsActionsUnreadCount} issues need you attention. Please review and take actions.`}
      <WppActionButton slot="actions" variant="secondary" onClick={handleCompleteActionsClick}>
        Take Action
        <WppIconExternalLink slot="icon-end" />
      </WppActionButton>
    </WppBanner>
  );
};

export default BannerComponent;
