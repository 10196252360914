/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import { ObjectMultiDropdown } from 'buildingBlocks';
import { DropdownFilter } from '../types';

type Props = {
  filter: DropdownFilter,
  loading?: boolean,
  error?: string,
  options: Array<unknown>,
};

class FilterDropdown extends Component<Props> {
  render() {
    const {
      options, filter, loading, error,
    } = this.props;
    return (
      <ObjectMultiDropdown
        {...this.props}
        fluid
        selection
        scrolling
        // @ts-ignore ObjectMultiDropdown
        id={filter.id}
        name={filter.name}
        placeholder={filter.placeholder}
        disabled={loading || !!error}
        noResultsMessage={options.length > 0
          ? 'No remaining options.'
          : `No ${filter.name} available.`}
      />
    );
  }
}

export default FilterDropdown;
