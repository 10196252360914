import { JobStatusValue } from 'containers/Jobs/types';
import { StrategyFlightRunStatusValue } from 'utils/types';
import { DagRunStateValue } from './types';

/*
 *
 * CopilotHealth constants
 *
 */

export const GET_SUMMARY_DATA = 'COPILOT_HEALTH:GET_SUMMARY_DATA';
export const GET_SUMMARY_DATA_COMPLETED = 'COPILOT_HEALTH:GET_SUMMARY_DATA_COMPLETED';

// re-ordering the values of status constants below will re-order the data displayed in the table
export const JOB_STATUSES: Array<JobStatusValue> = [
  'SUCCESS',
  'FAILED',
  'RUNNING',
  'NEW',
  'WARNINGS',
];
export const STRATEGY_FLIGHT_RUN_STATUSES: Array<StrategyFlightRunStatusValue> = [
  'SUCCESS',
  'FAILED',
  'RUNNING',
  'NO_DATA',
  'FLIGHT_EXTERNAL_NOT_FOUND',
  'ABORT',
  'CANCELLED',
  'PENDING',
];
export const DAG_STATES: Array<DagRunStateValue> = ['success', 'failed', 'running', 'shutdown'];
