import React from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import env from 'env';
import { generateUUID } from 'utils/formattingUtils';
import { dateTimeFormatter } from 'utils/dateTime';
import QueryStringLink from 'components/QueryStringLink';
import { WppGrid, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTypography } from 'buildingBlocks';
import { DAG_STATES } from '../constants';
import HealthTableHeaders from './HealthTableHeaders';
import { DagRun } from '../types';
import { HEALTH_CARE_STYLES } from '../style';

const { healthTableGrid, healthTableStyle } = HEALTH_CARE_STYLES;

const HEADERS = ['Dag ID', 'Success', 'Failed', 'Running', 'Shutdown'];

type Props = {
  dagRuns: Array<DagRun>
  startDate: Moment
  endDate: Moment
};

const DagRunsHealthTable = ({ dagRuns, startDate, endDate }: Props) => (
  <WppGrid container fullWidth style={healthTableGrid}>
    <WppTable style={healthTableStyle}>
      <WppTableHeader>
        <HealthTableHeaders headers={HEADERS} />
      </WppTableHeader>
      <WppTableBody className="wpp-tbody">
        {
          _.map(dagRuns, ((dag) => (
            <WppTableBodyRow key={generateUUID()}>
              <WppTableBodyCell>
                <QueryStringLink
                  to={`${env.AIRFLOW_URL}/dagrun/list`}
                  params={{
                    _flt_3_state: dag.state,
                    _flt_3_dag_id: dag.dag_id,
                    _flt_1_execution_date: dateTimeFormatter.isoDateTime(startDate),
                    _flt_2_execution_date: dateTimeFormatter.isoDateTime(endDate),
                    sort: 2,
                  }}
                >
                  <WppTypography type="s-body" className="healthCareLinkColor">{dag.dag_id}</WppTypography>
                </QueryStringLink>
              </WppTableBodyCell>
              {
                _.map(DAG_STATES, (key) => (
                  <WppTableBodyCell key={generateUUID()}>
                    {
                      _.get(dag.state, key)
                        ? (
                          <QueryStringLink
                            to={`${env.AIRFLOW_URL}/dagrun/list`}
                            params={{
                              _flt_3_state: key,
                              _flt_3_dag_id: dag.dag_id,
                              _flt_1_execution_date: dateTimeFormatter.isoDateTime(startDate),
                              _flt_2_execution_date: dateTimeFormatter.isoDateTime(endDate),
                              sort: 2,
                            }}
                          >
                            <WppTypography type="s-body" className="healthCareLinkColor">{_.get(dag.state, key)}</WppTypography>
                          </QueryStringLink>
                        )
                        : <WppTypography type="s-body" className="healthCareLinkColor">-</WppTypography>
                    }
                  </WppTableBodyCell>
                ))
              }
            </WppTableBodyRow>
          )))
        }
      </WppTableBody>
    </WppTable>
  </WppGrid>
);

export default DagRunsHealthTable;
