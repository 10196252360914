import { call, put, takeEvery, all } from 'redux-saga/effects';
import { Jobs } from 'utils/copilotAPI';
import {
  JOBS_DETAIL_FETCH, JOBS_DETAIL_FETCH_COMPLETED,
  JOBS_DETAIL_FETCH_FAILED, TASK_KEYS,
} from './constants';

function* getJobSaga(action) {
  try {
    const params = {
      populate: [...TASK_KEYS, 'lineItem', 'member'],
    };
    const data = yield call(() => Jobs.getById(action.payload.jobId, params));
    yield put({ type: JOBS_DETAIL_FETCH_COMPLETED, payload: data.data });
  } catch (error) {
    yield put({ type: JOBS_DETAIL_FETCH_FAILED, error });
  }
}

export const sagas = [
  takeEvery(JOBS_DETAIL_FETCH, getJobSaga),
];

export function* jobDetailSagas() {
  yield all([
    ...sagas,
  ]);
}
