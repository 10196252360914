import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { WppButton, WppTypography, WppSideModal } from 'buildingBlocks';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { BULK_CREATE_MAX_FLIGHTS_ALLOWED, initialFlightsStatus } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { ExternalTypeOptimizationLevel, OPTIMIZATION_LEVELS } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import { Flight } from 'utils/types';
import BulkCreateAttachFlightsModalForm from './AttachFlightsModal/BulkCreateAttachFlightsModalForm';
import FlightCandidatesValidationProgress from './AttachFlightsModal/Validation/FlightCandidatesValidationProgress';
import FlightCandidatesAttachedToAnotherStrategy from './AttachFlightsModal/Validation/FlightCandidatesAttachedToAnotherStrategy';
import { useBulkCreateAttachModalContext } from '../contexts/BulkCreateAttachModalProvider';
import { BulkCreateWizardFormAttachFlights, FlightsStatusType } from '../types';
import { checkFlightsEligibility, fetchBulkBudgetAllocationData } from '../utils';
import 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style.scss';

type Props = {
  members: any
  memberFetchError: any
  modalIsOpen: boolean
  setModalIsOpen: (x: boolean) => void
};

const BulkCreateAttachFlightsModal = ({ members, memberFetchError, modalIsOpen, setModalIsOpen }: Props) => {
  const { sessionAttachFlights, setSessionAttachFlights } = useBulkCreateAttachModalContext();
  const { wizardFormValues, dispatch } = useBulkCreateStrategyWizardContext();
  const { budgetAllocationState } = wizardFormValues;
  const [flightsStatus, setFlightsStatus] = useState<FlightsStatusType>(initialFlightsStatus);
  const [alreadyAttachedFlightsToInclude, setAlreadyAttachedFlightsToInclude] = useState<Array<Flight>>([]);
  const [showValidationProgress, setShowValidationProgress] = useState<boolean>(false);
  const [showAlreadyAttachedTable, setShowAlreadyAttachedTable] = useState<boolean>(false);
  const { setValue } = useFormContext<BulkCreateWizardFormAttachFlights>();
  const optimizationLevel = useWatch({ name: 'optimizationLevel' });
  const formFlights: Array<Flight> = useWatch({ name: 'attachedFlights' });
  const { eligibleFlights, attachedToAnotherStrategy } = flightsStatus;
  const flightsAttachedToAnotherStratSelected = !!_.size(attachedToAnotherStrategy);
  // flight limit considerations
  const flightLimitExceeded = _.size(sessionAttachFlights) > BULK_CREATE_MAX_FLIGHTS_ALLOWED;

  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(modalIsOpen);

  const handleModalReset = () => {
    setFlightsStatus(initialFlightsStatus);
    setAlreadyAttachedFlightsToInclude([]);
    setShowValidationProgress(false);
    setShowAlreadyAttachedTable(false);
    setModalIsOpen(false);
  };

  const handleAttachAndModalReset = (flightsToAttach: Array<Flight>) => {
    setValue('attachedFlights', flightsToAttach);
    fetchBulkBudgetAllocationData(
      eligibleFlights,
      _.get(budgetAllocationState, 'data'),
      dispatch,
      optimizationLevel?.defaultStrat,
    );
    handleModalReset();
  };

  // if flight status changes then eligibility has been checked for the sessionAttachFlights
  useEffect(() => {
    if (!_.isEqual(flightsStatus, initialFlightsStatus)) {
      setShowValidationProgress(false);
      if (flightsAttachedToAnotherStratSelected) {
        setShowAlreadyAttachedTable(true);
      } else {
        handleAttachAndModalReset(eligibleFlights);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightsStatus]);

  const handleModalAttachBtnClick = async () => {
    // prevent 'Attach' button click if the limit is reached
    if (flightLimitExceeded) {
      return;
    }

    if (showAlreadyAttachedTable) {
      handleAttachAndModalReset([...eligibleFlights, ...alreadyAttachedFlightsToInclude]);
    } else {
      setShowValidationProgress(true);
      await checkFlightsEligibility(sessionAttachFlights, setFlightsStatus);
    }
  };

  const handleModalClose = () => {
    handleModalReset();
    // reset form based on users previous flight selections
    const prevMember = _.find(members, { id: _.get(_.head(formFlights), 'member') });
    setValue('member', prevMember);
    setValue('optimizationLevel', prevMember ? OPTIMIZATION_LEVELS[prevMember.dsp as number][ExternalTypeOptimizationLevel.HIGHER_ORDER] : null);
    setSessionAttachFlights(formFlights);
  };

  const handleBackClickFromAlreadyAttached = () => {
    setShowValidationProgress(false);
    setShowAlreadyAttachedTable(false);
    setFlightsStatus(initialFlightsStatus);
    setAlreadyAttachedFlightsToInclude([]);
  };

  const renderModalBody = () => {
    if (showValidationProgress) {
      return <FlightCandidatesValidationProgress />;
    }
    if (showAlreadyAttachedTable) {
      return (
        <FlightCandidatesAttachedToAnotherStrategy
          attachedToAnotherStrategy={attachedToAnotherStrategy}
          alreadyAttachedFlightsToInclude={alreadyAttachedFlightsToInclude}
          setAlreadyAttachedFlightsToInclude={setAlreadyAttachedFlightsToInclude}
        />
      );
    }
    return (
      <BulkCreateAttachFlightsModalForm
        members={members}
        memberFetchError={memberFetchError}
      />
    );
  };

  return (
    <>
      {modalIsOpen && (
        <>
          <WppSideModal
            open={modalIsOpen}
            size="2xl"
            onWppSideModalClose={handleModalClose}
            onWppSideModalOpenStart={() => setModalIsOpen(true)}
            disableOutsideClick
            id="attach-flights-modal"
          >
            <WppTypography type="2xl-heading" tag="h3" slot="header">Object Attachment</WppTypography>
            <div slot="body">
              {renderModalBody()}
            </div>
            <div slot="actions">
              <div style={MODAL_STYLES.actionButtonContainer}>
                <WppButton
                  variant="secondary"
                  disabled={showValidationProgress}
                  onClick={flightsAttachedToAnotherStratSelected ? handleBackClickFromAlreadyAttached : handleModalClose}
                >
                  {flightsAttachedToAnotherStratSelected ? 'Back' : 'Cancel'}
                </WppButton>
                <WppButton type="submit" onClick={handleModalAttachBtnClick} disabled={!_.size(sessionAttachFlights) || showValidationProgress || flightLimitExceeded}>Attach</WppButton>
              </div>
            </div>
          </WppSideModal>
        </>
      )}
    </>
  );
};

export default BulkCreateAttachFlightsModal;
