import _ from 'lodash';
import React from 'react';
import { WppActionButton, WppSegmentedControl, WppSegmentedControlItem } from 'buildingBlocks';
import { BULK_CREATE_MAX_FLIGHTS_ALLOWED, CandidateTableDisplay } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { toggles } from '../style';

const FlightCandidateToggle = () => {
  const {
    sessionAttachFlights,
    setSessionAttachFlights,
    candidateDisplay,
    setCandidateDisplay,
    flightCandidateInfo,
  } = useBulkCreateAttachModalContext();
  const { flightCandidates, loadingFlightCandidates } = flightCandidateInfo;
  const flightsToAlter = candidateDisplay === CandidateTableDisplay.all ? flightCandidates : sessionAttachFlights;

  const handleAllChange = (isSelectAll: boolean = false) => {
    const newSessionFlights = isSelectAll
      ? _.uniqBy([...sessionAttachFlights, ...flightsToAlter], 'externalId')
      : _.filter(sessionAttachFlights, (f) => !_.includes(_.map(flightsToAlter, 'externalId'), f.externalId));
    // prevent further selections of objects if the limit is reached
    if (isSelectAll && (_.size(newSessionFlights) && _.size(newSessionFlights) > BULK_CREATE_MAX_FLIGHTS_ALLOWED)) {
      return;
    }

    setSessionAttachFlights(newSessionFlights);
  };

  return (
    <div style={toggles.container}>
      <WppSegmentedControl
        value={candidateDisplay}
        onWppChange={(event) => setCandidateDisplay(event.detail.value as CandidateTableDisplay)}
      >
        <WppSegmentedControlItem value={CandidateTableDisplay.all}>
          {`All (${_.size(flightCandidates)})`}
        </WppSegmentedControlItem>
        <WppSegmentedControlItem value={CandidateTableDisplay.selected}>
          {`Selected (${_.size(sessionAttachFlights)})`}
        </WppSegmentedControlItem>
      </WppSegmentedControl>
      <div style={toggles.selectContainer}>
        <WppActionButton
          disabled={loadingFlightCandidates}
          variant="secondary"
          onClick={() => handleAllChange(true)}
        >
          Select All
        </WppActionButton>
        <WppActionButton
          disabled={loadingFlightCandidates}
          variant="secondary"
          onClick={() => handleAllChange()}
        >
          Deselect All
        </WppActionButton>
      </div>
    </div>
  );
};

export default FlightCandidateToggle;
