import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Checkbox } from 'buildingBlocks';
import { DATE_TIME_FORMAT, dateTimeFormatter } from 'utils/dateTime';
import { CheckBoxHandler } from 'utils/types';

export function getFontColor(startDate, endDate) {
  const startDateTime = moment(startDate, DATE_TIME_FORMAT);
  const endDateTime = moment(endDate, DATE_TIME_FORMAT);
  const currentDateTime = moment(dateTimeFormatter.dateTime(moment()), DATE_TIME_FORMAT);
  if (startDateTime <= currentDateTime && endDateTime >= currentDateTime) {
    return 'green';
  } if (startDateTime <= currentDateTime && endDate === 'N/A') {
    return 'green';
  } if (startDateTime > currentDateTime) {
    return 'blue';
  }
  return 'grey';
}

type Props = {
  message: {
    id: number,
    subject: string,
    content: string,
    startDate: string,
    endDate: string,
  },
  onSelected: CheckBoxHandler,
  selected: boolean,
};

export const MessageDetails = ({
  onSelected,
  selected,
  message: {
    id,
    subject,
    content,
    startDate,
    endDate,
  },
}: Props) => {
  const startDateTime = dateTimeFormatter.dateTime(startDate);
  const endDateTime = endDate ? dateTimeFormatter.dateTime(endDate) : 'N/A';
  return (
    <Table.Row key={id} style={{ color: getFontColor(startDateTime, endDateTime) }}>
      <Table.Cell collapsing>
        <Checkbox onChange={onSelected} checked={selected} />
      </Table.Cell>
      <Table.Cell>
        <Link to={`/messages/${id}`}>{subject}</Link>
      </Table.Cell>
      <Table.Cell>{ _.truncate(content, { length: 60, separator: /[.,!?]? +/ }) }</Table.Cell>
      <Table.Cell>{ startDateTime }</Table.Cell>
      <Table.Cell>{ endDateTime }</Table.Cell>
    </Table.Row>
  );
};

export default MessageDetails;
