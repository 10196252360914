import _ from 'lodash';
import qs from 'qs';
import { NavigateFunction } from 'react-router';
import { OPERATOR } from 'constantsBase';
import { Brand, Advertiser as AdvertiserAPI, AdvertiserBrand } from 'utils/copilotAPI';
import { MEMBER, membersForFeature, Permission } from 'utils/featureFlags';
import { pluralizer } from 'utils/formattingUtils';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';
import { Advertiser, Brand as BrandType, User } from 'utils/types';
import { BRANDS_STARTING_FETCH_LIMIT, BRAND_NOT_FOUND_ERROR, INITIAL_BRAND_DETAILS_FORM_VALUES } from './constants';
import { BrandDetailsFormType } from './types';

export const brandPluralizer = pluralizer('Brand', 'Brands');

/**
 * Update the url based on user configured settings
 * @param limit - number of brands to be listed per page
 * @param skip - index to start from
 * @param search - search string via input
 * @param location - location via user's browser
 * @param navigate - navigate via react-router-dom
 */
export const browserUrlUpdate = (
  limit: number,
  skip: number,
  search: string,
  location: { pathname: string },
  navigate: NavigateFunction,
) => {
  const newFilter = { search, limit, skip };
  navigate(`${location.pathname}?${qs.stringify(newFilter)}`, { replace: true });
};

/**
 * Fetching of brands listed based on user configured settings
 * @param limit - number of brands to be listed per page
 * @param skip - index to start from
 * @param searchStr - search string via input
 * @param setBrandsCount - set the brands count
 * @param setBrands: set the displayed brands
 */
export const getBrands = async (
  limit: number = BRANDS_STARTING_FETCH_LIMIT,
  skip: number = 0,
  searchStr: string = '',
  setBrandsCount: (x: any) => void,
  setBrands: (x: any) => void,
) => {
  const filter = {
    isDeleted: false,
    ...(!_.isEmpty(searchStr) && { or: [{ name: { [OPERATOR.CONTAINS]: searchStr } }] }),
  };

  const brandsCountRes = await Brand.count({ where: filter });
  const brandsRes = await Brand.get({
    where: filter,
    limit,
    skip,
    sort: 'name asc',
    populate: 'advertisers',
  });

  setBrandsCount(brandsCountRes.data.count);
  setBrands(brandsRes.data);
};

/**
 * Fetcher to initialize the Brands form
 * @param brandId - necessary to determine create or edit mode to populate the default values
 * @param user - user
 * @returns a list of selectable advertisers to add to a brand, as well as default values for the form
 */
export const useBrandDetailsFormFetcher = (brandId: string, user: User) => {
  const getBrandFormDetails = async () => {
    // get valid advertisers that are not already tied to a brand and have members that the user may access
    const validAdvertisersRes = await AdvertiserAPI.validAdvertisersForBrand();
    // initialize defaultValues as create mode values
    const data = { validAdvertisers: validAdvertisersRes.data, defaultValues: INITIAL_BRAND_DETAILS_FORM_VALUES };

    if (!brandId) {
      return { kind: PossibleStates.hasData, data };
    }

    // initialize default values for edit mode
    const brandRes = await Brand.get({ where: { id: brandId, isDeleted: false }, populate: 'advertisers' });
    const brand = _.head(brandRes.data) as BrandType;

    // return brandNotFound kind if brand does not exist or isDeleted
    if (!brand) {
      return { kind: PossibleStates.error, errorObject: { message: BRAND_NOT_FOUND_ERROR } };
    }

    const defaultValues = _.pick(brand, ['name', 'advertisers']);
    // filter out advertisers the user does not have access to
    const validMembers = membersForFeature(user, Permission.accessStrategyWizard);
    if (!_.isEqual(validMembers, MEMBER.ALL) && _.isArray(validMembers)) {
      defaultValues.advertisers = _.filter(defaultValues.advertisers, (adv: Advertiser) => (_.includes(validMembers, adv.member)));
    }
    return { kind: PossibleStates.hasData, data: { ...data, defaultValues } };
  };

  const currentState = useFetcher(getBrandFormDetails, []);
  return currentState;
};

/**
 * simple function comparing the differences between two arrays
 * @param firstArray - necessary to determine create or edit mode to populate the default values
 * @param secondArray - defaultValues to compare the differences with the configured values
 * @returns the values that are in the secondArray and NOT in the firstArray
 */
export const getAdvsToAttachOrRemove = (firstArray: Array<Advertiser>, secondArray: Array<Advertiser>): Array<Advertiser> => {
  const inUseKeys = _.map(firstArray, 'id');
  return _.filter(secondArray, (adv) => inUseKeys.indexOf(adv.id) === -1);
};

/**
 * function to create/update name and advertisers tied to a brand
 * @param brandId - necessary to determine create or edit mode to populate the default values
 * @param defaultValues - defaultValues to compare the differences with the configured values
 * @param formValues - name and selected advertisers configured by the user
 * @param naviagte - navigation via react-router-dom
 */
export const handleBrandDetailsFormSubmit = async (
  brandId: string,
  defaultValues: BrandDetailsFormType,
  formValues: BrandDetailsFormType,
  navigate: NavigateFunction,
) => {
  const savedOrUpdated = brandId ? 'updated' : 'saved';
  const name = _.trim(formValues.name);
  try {
    const brandReqBody = { name };
    const advsToAdd = getAdvsToAttachOrRemove(defaultValues.advertisers, formValues.advertisers);
    const advsToRemove = _.map(getAdvsToAttachOrRemove(formValues.advertisers, defaultValues.advertisers), 'id');

    // create or edit the brand
    const brandRes = brandId ? await Brand.put(brandId, brandReqBody) : await Brand.post(brandReqBody);
    // create or delete the respective AdvertiserBrand associations
    await AdvertiserBrand.post(_.map(advsToAdd, (adv: Advertiser) => ({ advertiser: adv.id, brand: brandRes.data.id })));
    if (_.size(advsToRemove) > 0) {
      await AdvertiserBrand.bulkDeleteWhere(`{ "advertiser": [${advsToRemove}], "brand": ${brandRes.data.id} }`);
    }
    // update the navigation state so that the success message is displayed
    navigate(
      '/brands',
      {
        state: {
          statusMessage: {
            header: `Brand ${name} ${savedOrUpdated}!`,
            body: `Brand ${name} ${savedOrUpdated} successfully.`,
            isFailure: false,
          },
        },
      },
    );
  } catch (e) {
    // update the navigation state so that the failed message is displayed
    navigate(
      '/brands',
      {
        state: {
          statusMessage: {
            header: 'Error!',
            body: `Brand ${name} failed to save.`,
            isFailure: true,
          },
        },
      },
    );
  }
};
