import _ from 'lodash';
import { Moment } from 'moment';
import { dateTimeFormatter } from 'utils/dateTime';
import { JobTypeValue } from 'containers/Jobs/types';
import {
  StatusEnum,
  StatusColumn,
  DagRunTypeValue,
  TypeColumn,
  Job,
  GroupedJob,
  StrategyFlightRun,
  GroupedStrategyFlightRun,
  DagRun,
  GroupedDagRun,
  StrategyFlightRunTypeValue,
} from './types';

export const getIsoDateTime = (date: Moment) => dateTimeFormatter.isoDateTime(date);

export const filterDagRuns = (dagRuns: Array<DagRun>): Array<DagRun> => _.filter(dagRuns, (run) => (
  !(_.startsWith(run.dag_id, 'strat_')
  || _.startsWith(run.dag_id, 'algo_')
  || _.startsWith(run.dag_id, 'user_initiated')
  )));

/**
 * Get rows formatted for the table. Each type has a row for each status. For example, if job type 'lld' has jobs that
 * are both failed and completed, there will be a row for each.
 * @param {*} rowsForType array of rows for each job/dag/sfr type
 * @param {*} type the type of job/dag/sfr, ie 'lld' or 'strat_seg_rec'
 * @param {*} statusEnum Possible values for status ie 'SUCCESS', 'FAILED'
 * @param {*} statusColumn The column in the group count query for status, ie 'status' for jobs, 'state' for dag runs
 * @param {*} typeColumn The column name for the type of run
 */
function getDataTableRowPerType(
  columnsForType: Array<GroupedJob | GroupedDagRun>,
  type: JobTypeValue | DagRunTypeValue,
  statusEnum: StatusEnum,
  statusColumn: StatusColumn,
  typeColumn: TypeColumn,
) {
  const pairs = _.map(columnsForType, (col: { count: number }) => [col[statusColumn], col.count]);
  return {
    [typeColumn]: type,
    [statusColumn]: { ..._.zipObject(statusEnum), ..._.fromPairs(pairs) },
  };
}

/**
 * Prepare data in a format that can be used to create the datatable
 * @param {*} groupedCountData Data to process
 * @param {*} groupBy The field to group by ie type, strategyType, dag_id
 * @param {*} statusEnum Possible values for status ie 'SUCCESS', 'FAILED'
 * @param {*} statusColumn The column in the group count query for status, ie 'status' for jobs, 'state' for dag runs
 */
export const processGroupedCountData = (
  groupedCountData: Array<Job | DagRun>,
  groupBy: TypeColumn,
  statusEnum: StatusEnum,
  statusColumn: StatusColumn,
) => _(groupedCountData)
  .groupBy(groupBy)
  .map((columnsForType: Array<GroupedJob & GroupedDagRun>, type: JobTypeValue | DagRunTypeValue) => getDataTableRowPerType(columnsForType, type, statusEnum, statusColumn, groupBy))
  .value();

const getSFRTableRow = (
  columnsForType: Array<GroupedStrategyFlightRun>,
  algoType: StrategyFlightRunTypeValue,
  statusEnum: StatusEnum,
  stratType: StrategyFlightRunTypeValue,
) => {
  const pairs = _.map(columnsForType, (col) => [col.status, col.count]);
  return {
    strategyType: stratType,
    // null check is due to issue mentioned in https://github.com/lodash/lodash/issues/3060
    // @ts-ignore string null check on number
    algorithmType: algoType === 'null' ? null : algoType,
    status: { ..._.zipObject(statusEnum), ..._.fromPairs(pairs) },
  };
};

export const processSfrGroupedCountData = (
  groupedCountData: Array<StrategyFlightRun>,
  statusEnum: StatusEnum,
) => {
  const groupedStratData = _.groupBy(groupedCountData, 'strategyType');
  const stratDataByAlgoType = {};
  _.forEach(groupedStratData, (_v, k) => {
    stratDataByAlgoType[k] = _.groupBy(groupedStratData[k], 'algorithmType');
  });
  return _.flatten(_.map(
    stratDataByAlgoType,
    (groupedAlgoData, stratType: string) => _.map(
      groupedAlgoData,
      (columnsForType: Array<GroupedStrategyFlightRun>, algoType) => getSFRTableRow(columnsForType, algoType, statusEnum, parseInt(stratType, 10)),
    ),
  ));
};
