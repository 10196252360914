import _ from 'lodash';
import React from 'react';
import { WppGrid, WppLabel } from 'buildingBlocks';
import { FilterOptionsActions } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { modalContainer } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/style';
import { FilterAction, FilterState } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';
import AdvertiserFilter from './AdvertiserFilter';
import FlightDateFilter from './FlightDateFilter';
import SearchFilter from './SearchFilter';

type FilterContainerProps = {
  filterState: FilterState
  dispatchFilter: React.Dispatch<FilterAction>
  loadingFlightCandidates: boolean
};

const FilterContainer: React.FC<FilterContainerProps> = (props) => {
  const { filterState, dispatchFilter, loadingFlightCandidates } = props;
  const updateFlightsFilter = (type: FilterOptionsActions, payload: any) => {
    dispatchFilter({ type, payload });
  };

  const debouncedSearchTermChange = _.debounce((searchTerm: string) => {
    updateFlightsFilter(FilterOptionsActions.SET_SEARCH, searchTerm);
  }, 300);

  return (
    <>
      <WppLabel
        htmlFor="objects"
        typography="s-strong"
        config={{ text: 'Objects' }}
        style={{ ...modalContainer.label, paddingLeft: 8 }}
      />
      <WppGrid container item all={24} fullWidth rowSpacing={2} alignItems="center">
        <div style={modalContainer.filterContainer}>
          <SearchFilter
            filterSearchTerm={filterState.search}
            setFilterSearchTerm={debouncedSearchTermChange}
            disabled={loadingFlightCandidates}
          />
          <AdvertiserFilter
            handleAdvertiserChange={(advertiser: Array<number>) => updateFlightsFilter(FilterOptionsActions.SET_ADVERTISER, advertiser)}
            filterAdvertiser={filterState.advertiser}
            disabled={loadingFlightCandidates}
          />
          <FlightDateFilter
            flightDate={filterState.flightDate}
            onFlightDateChange={(dateRange) => updateFlightsFilter(FilterOptionsActions.SET_FLIGHT_DATE, dateRange)}
            disabled={loadingFlightCandidates}
          />
        </div>
      </WppGrid>
    </>
  );
};

export default FilterContainer;
