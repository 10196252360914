import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

export const mainContainer = {
  padding: '20px',
  color: '#607d8b',
};

export const tableHeader = {
  backgroundColor: NEUTRALS.N0_WHITE,
};

export const messageTable = {
  marginTop: '1%',
  borderRadius: '0',
};

export const searchInput = {
  backgroundColor: NEUTRALS.N0_WHITE,
};

export const iconPadding = {
  paddingLeft: '10px',
};

export const messageButoon = {
  float: 'right',
  background: '#1BA6EF',
  color: NEUTRALS.N0_WHITE,
};
