import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DeveloperApiToken } from 'utils/copilotAPI';
import { User } from 'utils/types';
import { StoreState, Token, TokenStatus, TokenResponse } from './types';
import { FAILURE_TEXT } from './constants';
import DeveloperTokenForm from './DeveloperTokenForm';

type Props = {
  user: User
};

export const DeveloperToken = (props: Props) => {
  const [copied, setCopied] = useState(false);
  const [tokenState, setTokenState] = useState<TokenResponse>({ kind: TokenStatus.initial, data: {} });

  useEffect(() => {
    const getApiToken = async () => {
      try {
        const token: { data: Array<Token> } = await DeveloperApiToken.get({ user: props.user.id });
        const data = _.isEmpty(token.data) ? {} : token.data[0];
        setTokenState({ kind: TokenStatus.hasData, data });
      } catch (error) {
        setTokenState({ kind: TokenStatus.error, error: FAILURE_TEXT });
      }
    };
    getApiToken();
  }, [props.user.id]);

  const generateApiToken = async () => {
    setCopied(false);
    try {
      setTokenState({ kind: TokenStatus.loading });
      const token: { data: Token } = await DeveloperApiToken.genApiToken();
      setTokenState({ kind: TokenStatus.hasData, data: token.data });
    } catch (error) {
      setTokenState({ kind: TokenStatus.error, error: FAILURE_TEXT });
    }
  };

  const handleCopy = (copy: boolean) => setCopied(copy);

  const commonProps = {
    copied,
    generateApiToken,
    handleCopy,
  };

  switch (tokenState.kind) {
    case TokenStatus.initial:
    case TokenStatus.loading:
      return (
        <DeveloperTokenForm
          apiToken={{}}
          {...commonProps}
        />
      );
    case TokenStatus.hasData:
      return (
        <DeveloperTokenForm
          apiToken={tokenState.data}
          {...commonProps}
        />
      );
    case TokenStatus.error:
      return (
        <DeveloperTokenForm
          apiToken={{}}
          tokenState={tokenState}
          {...commonProps}
        />
      );
    default:
      return (
        <DeveloperTokenForm
          apiToken={{}}
          {...commonProps}
        />
      );
  }
};

const mapStateToProps = (state: StoreState) => ({
  user: state.login.session.data.user,
});

export default connect(mapStateToProps)(DeveloperToken);
