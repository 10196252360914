import React, { useEffect, useReducer } from 'react';
import { useWatch } from 'react-hook-form';
import { WppGrid } from 'buildingBlocks';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { INITIAL_FILTER_STATE } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { filterOptionReducer } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/FlightsFilterOptions/filterOptionReducer';
import { candidateContainer } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';
import { filterFlightCandidates } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/utils';
import { Member } from 'utils/types';
import FilterContainer from './FlightsFilterOptions/FilterContainer';
import MemberField from './MemberField';
import FlightCandidatesContainer from './FlightCandidateTable/FlightCandidatesContainer';
import FlightCandidateToggle from './FlightCandidateTable/FlightCandidateToggle';

type AttachFlightsModalFormProps = {
  members: Array<Member>
  memberFetchError: string
};

const BulkCreateAttachFlightsModalForm: React.FC<AttachFlightsModalFormProps> = ({
  members,
  memberFetchError,
}) => {
  const member = useWatch({ name: 'member' });
  const [filterState, dispatchFilter] = useReducer(filterOptionReducer, INITIAL_FILTER_STATE);
  const { flightCandidateInfo, setFlightCandidateInfo } = useBulkCreateAttachModalContext();
  const { loadingFlightCandidates } = flightCandidateInfo;

  useEffect(() => {
    filterFlightCandidates(filterState, flightCandidateInfo, setFlightCandidateInfo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  return (
    <WppGrid container fullWidth>
      <WppGrid container fullWidth>
        <WppGrid container item all={24}>
          <MemberField
            dispatchFilter={dispatchFilter}
            members={members}
            memberFetchError={memberFetchError}
          />
        </WppGrid>
        {member && (
          <FilterContainer
            filterState={filterState}
            loadingFlightCandidates={loadingFlightCandidates}
            dispatchFilter={dispatchFilter}
          />
        )}
      </WppGrid>
      {member && (
        <WppGrid container item all={24} fullWidth style={candidateContainer.grid}>
          <WppGrid container fullWidth style={candidateContainer.selectionContainer}>
            <FlightCandidateToggle />
            <FlightCandidatesContainer />
          </WppGrid>
        </WppGrid>
      )}
    </WppGrid>
  );
};

export default BulkCreateAttachFlightsModalForm;
