import React from 'react';
import { withQueryString } from 'utils/functionHelpers';
import withRouter, { RouterProps } from 'utils/withRouter';
import { QSParams } from 'containers/StrategyWizard/utils';
import BulkCreateStrategyWizardProvider from './contexts/BulkCreateStrategyWizardProvider';
import BulkCreateStrategyWizard from './BulkCreateStrategyWizard';

type BulkCreateStrategyWizardWithProviderProps = RouterProps & { qsParams: QSParams };

const BulkCreateStrategyWizardWithProvider = (props: BulkCreateStrategyWizardWithProviderProps) => (
  <BulkCreateStrategyWizardProvider router={props.router}>
    <BulkCreateStrategyWizard qsParams={props.qsParams} />
  </BulkCreateStrategyWizardProvider>
);

export default withRouter(withQueryString(BulkCreateStrategyWizardWithProvider));
