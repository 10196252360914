import { User } from 'utils/types';

export type StoreState = {
  login: User['login'],
};

export type Token = {
  token?: 'string',
  expirationDate?: 'string',
};

export enum TokenStatus {
  loading = 'loading',
  hasData = 'hasData',
  initial = 'initial',
  error = 'error',
}

export type TokenResponse =
  | { kind: TokenStatus.initial, data: {} }
  | { kind: TokenStatus.loading }
  | { kind: TokenStatus.error, error: string }
  | { kind: TokenStatus.hasData, data: Token };
