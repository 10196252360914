import React, { Dispatch, SetStateAction } from 'react';
import { ColDef } from 'utils/types';
import { WppIconEdit } from 'buildingBlocks';
import { dateTimeFormatter } from 'utils/dateTime';
import { ButtonActions } from './ButtonActions';
import { DEMO_SITES_STYLE } from '../styles';
import { ActionTypes } from '../constants';
import { DemoSite } from '../types';

const { actionButtonDiv, hyperLinkStyles } = DEMO_SITES_STYLE;

const SitesTableHeader = (
  isAdminOrQAGlobal: boolean,
  isAdminUser: boolean,
  handleSingleSiteDelete: (id: number) => void,
  setEditDemoSiteId: (id: number) => void,
  setEditLoading: Dispatch<SetStateAction<boolean>>,
): Array<ColDef> => [
  {
    field: 'domain',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: DemoSite }) => data.domain,
    flex: 1,
    maxWidth: 800,
    headerCheckboxSelection: isAdminOrQAGlobal,
    checkboxSelection: isAdminOrQAGlobal,
  },
  {
    field: 'brand',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: DemoSite }) => data.brandNameToDisplay,
    minWidth: 240,
    hide: !isAdminUser,
  },
  {
    field: 'dateCreated',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: DemoSite }) => dateTimeFormatter.date(data.createdAt),
    minWidth: 240,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: 'dateUpdated',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: DemoSite }) => dateTimeFormatter.date(data.updatedAt),
    minWidth: 240,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: 'actions',
    sortable: false,
    resizable: false,
    headerName: '',
    minWidth: 140,
    cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
    cellRenderer: ({ data }: { data: DemoSite }) => {
      const { domain, id } = data;
      return (
        <div style={actionButtonDiv}>
          <ButtonActions domain={domain} actionType={ActionTypes.hyperLinks} />
          <WppIconEdit
            color="var(--wpp-grey-color-700)"
            style={hyperLinkStyles}
            onClick={() => {
              setEditDemoSiteId(id);
              setEditLoading(true);
            }}
          />
          <ButtonActions
            domain={domain}
            actionType={ActionTypes.more}
            handleDelete={handleSingleSiteDelete}
            id={id}
          />
        </div>
      );
    },
  },
];

export default SitesTableHeader;
