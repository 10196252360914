import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { WppTypography, WppIconTableSortAsc, WppIconTableSortDesc } from 'buildingBlocks';

const HeaderCell: FunctionComponent<IHeaderParams> = (props) => {
  const [sortType, setSortType] = useState<string>(props.column.getSort());
  const handleHeaderCellClick = () => {
    if (!props.enableSorting) return;

    const items: ('asc' | 'desc' | null)[] = ['asc', 'desc', null];
    const selectedSort = props.column.getSort();
    const selectedSortIndex = selectedSort ? items.indexOf(selectedSort) : -1;
    const nextSortType = selectedSortIndex === -1 || selectedSortIndex === items.length - 1 ? 'asc' : items[selectedSortIndex + 1];
    props.setSort(nextSortType);
    setSortType(nextSortType);
  };

  const sortClass = () => {
    if (props.column.isSortAscending()) {
      return 'active';
    }
    if (props.column.isSortNone()) {
      return '';
    }
    return 'not-active';
  };

  return (
    <div className="ag-header-cell-comp">
      <WppTypography type="s-strong">{props.displayName}</WppTypography>
      {props.enableSorting && (
        <div className="ag-sorting">
          {_.isEqual(sortType, 'asc')
            ? <WppIconTableSortAsc className={`top-sort-icon ${sortClass()}`} onClick={() => handleHeaderCellClick()} />
            : <WppIconTableSortDesc className={`top-sort-icon ${sortClass()}`} onClick={() => handleHeaderCellClick()} /> }
        </div>
      )}
    </div>
  );
};

export default HeaderCell;
