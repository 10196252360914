import { UPDATE_AD_BLOCK_STATUS } from './constants';

type State = {
  adblockDetected: boolean,
};

type Payload = {
  adblockDetected: boolean,
};

type Action = {
  type: string,
  payload: Payload,
};

const INITIAL_STATE: State = {
  adblockDetected: false,
};

export default function appReducer(state: State = INITIAL_STATE, { payload, type }: Action) {
  switch (type) {
    case UPDATE_AD_BLOCK_STATUS:
      return { ...state, adblockDetected: payload };
    default:
      return state;
  }
}
