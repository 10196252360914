import { FilterState, FilterAction } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';
import { FilterOptionsActions, INITIAL_FILTER_STATE } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';

export const filterOptionReducer: React.Reducer<FilterState, FilterAction> = (
  state: FilterState = INITIAL_FILTER_STATE,
  action: FilterAction,
): FilterState => {
  switch (action.type) {
    case FilterOptionsActions.SET_ADVERTISER:
      return {
        ...state,
        advertiser: action.payload,
      };
    case FilterOptionsActions.SET_FLIGHT_DATE:
      return {
        ...state,
        flightDate: action.payload,
      };
    case FilterOptionsActions.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case FilterOptionsActions.RESET_FILTERS:
      return INITIAL_FILTER_STATE;
    default:
      return state;
  }
};
