import _ from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { BUDGET_OPTIMIZATION_MICRO_SERVICE, UPDATE_BUDGET_ALLOCATION_STATE } from 'containers/StrategyWizard/constants';
import { FAILURE_TEXT, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { StrategyWizardAction } from 'containers/StrategyWizard/types';
import { transformBudgetAllocationData } from 'containers/StrategyWizard/utils';
import { Flight as FlightApi, Strategy, Eligibility } from 'utils/copilotAPI';
import { Flight } from 'utils/types';
import { FilterState, FlightCandidateInfoType, FlightsStatusType } from './types';

export const checkSearchMatches = (search: string, flight: Flight) => {
  const loweredSearch = _.toLower(search);
  return _.includes(_.toLower(flight.name), loweredSearch)
    || _.includes(_.toLower(flight.externalId), loweredSearch);
};

const checkFlightDates = (flightDate: { startDate: string, endDate: string }, flight: Flight) => {
  const filterStartDate = moment(flightDate.startDate);
  const filterEndDate = moment(flightDate.endDate).endOf('day');
  return moment(flight.startDate).isBetween(filterStartDate, filterEndDate, undefined, '[]')
    && moment(flight.endDate).isBetween(filterStartDate, filterEndDate, undefined, '[]');
};

export const filterFlightCandidates = (
  filterState: FilterState,
  flightCandidateInfo: FlightCandidateInfoType,
  setFlightCandidateInfo: (x: FlightCandidateInfoType) => void,
) => {
  const { allMemberFlights } = flightCandidateInfo;

  const filteredFlightCandidates = _.filter(allMemberFlights, (flight: Flight) => {
    const { advertiser, flightDate, search } = filterState;
    return (_.size(advertiser) ? _.includes(advertiser, flight.advertiser) : true)
      && (search ? checkSearchMatches(search, flight) : true)
      && (flightDate ? checkFlightDates(flightDate, flight) : true);
  });
  setFlightCandidateInfo({
    ...flightCandidateInfo,
    flightCandidates: filteredFlightCandidates,
  });
};

export const onFlightSelect = (
  flight: Flight,
  setSessionAttachFlights: React.Dispatch<React.SetStateAction<Array<Flight>>>,
) => {
  setSessionAttachFlights((prevSessionAttachFlights) => (_.some(prevSessionAttachFlights, ['externalId', flight.externalId])
    ? _.filter(prevSessionAttachFlights, (f) => !_.isEqual(f.externalId, flight.externalId))
    : _.orderBy([...prevSessionAttachFlights, flight], 'externalId', 'desc')));
};

export const fetchBulkBudgetAllocationData = async (
  flights: Array<Flight>,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData | string },
  dispatch: Dispatch<StrategyWizardAction>,
  stratTypeId: number,
) => {
  await dispatch({ type: UPDATE_BUDGET_ALLOCATION_STATE, payload: { kind: Status.loading } });
  const flightsToFetchFor = _.filter(flights, (f: Flight) => !_.includes(_.keys(budgetAllocationData), f.externalId) || budgetAllocationData[f.externalId] === FAILURE_TEXT);
  const newBudgetAllocationData = { ...budgetAllocationData };
  try {
    const resp = await FlightApi.bulkBudgetAllocationData({
      flights: flightsToFetchFor,
      service: BUDGET_OPTIMIZATION_MICRO_SERVICE,
      stratTypeId,
    });
    // transform res data
    _.forEach(resp.data, (response, flightExtId) => {
      if (_.isString(response)) {
        // set as failuree if the response is a string (which Indicates failure)
        newBudgetAllocationData[flightExtId] = FAILURE_TEXT;
      } else {
        //  transform and store the data
        newBudgetAllocationData[flightExtId] = transformBudgetAllocationData(response);
      }
    });
  } catch (err) {
    console.error(`Error fetching buddget allocation data for flightExtId: ${_.map(flightsToFetchFor, 'externalId').join(', ')}`);
  } finally {
    const hasOnlyErrors = _.size(_.filter(_.values(newBudgetAllocationData), (budgetAllocData) => _.isEqual(budgetAllocData, FAILURE_TEXT))) === _.size(flights);
    const payload = hasOnlyErrors ? { kind: Status.error, error: FAILURE_TEXT } : { kind: Status.hasData, data: newBudgetAllocationData };
    dispatch({ type: UPDATE_BUDGET_ALLOCATION_STATE, payload });
  }
};

const getStrategyNameMap = async (flightsAttachedToAnotherStrategy: Array<Flight>) => {
  if (_.isEmpty(flightsAttachedToAnotherStrategy)) {
    return {};
  }
  const strategyIds = _.map(flightsAttachedToAnotherStrategy, 'strategy');
  const res = await Strategy.get({ where: { id: strategyIds } });
  return _.mapValues(_.keyBy(res.data, 'id'), 'name');
};

export const checkFlightsEligibility = async (
  sessionAttachFlights: Array<Flight>,
  setFlightsStatus: (x: FlightsStatusType) => void,
) => {
  if (_.size(sessionAttachFlights)) {
    try {
      // fetch all strategy names for flights attached to another strategy
      const flightsAttachedToAnotherStrategy = _.filter(sessionAttachFlights, (f) => _.isFinite(f.strategy));
      const strategyNameMap = await getStrategyNameMap(flightsAttachedToAnotherStrategy);

      const reducedPayloadFlights = _.map(sessionAttachFlights, (f) => _.pick(f, ['externalId', 'externalType', 'member', 'advertiser']));
      const eligibility = await Eligibility.flightFirst({ flights: reducedPayloadFlights });
      const eligibleFlights: Array<Flight> = [];
      const attachedToAnotherStrategy: Array<Flight> = [];

      // process each flight's eligibility
      _.forEach(eligibility.data, (flight) => {
        const flightMetadata = _.find(sessionAttachFlights, ['externalId', flight.externalId]);
        if (flight.eligible) {
          if (_.isFinite(flightMetadata.strategy)) {
            _.set(flightMetadata, 'strategyName', _.get(strategyNameMap, flightMetadata.strategy));
            attachedToAnotherStrategy.push(flightMetadata);
          } else {
            eligibleFlights.push(flightMetadata);
          }
        }
      });

      setFlightsStatus({ eligibleFlights, attachedToAnotherStrategy });
    } catch (error) {
      console.error(error);
    }
  }
};
