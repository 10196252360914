import { take, call, put, select, takeLatest, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import qs from 'qs';
import moment from 'moment';

import { Jobs, Member } from 'utils/copilotAPI';
import { membersForFeature, Permission, MEMBER } from 'utils/featureFlags';
import { RESULTS_LIMIT } from 'constantsBase';
import {
  INIT,
  FETCH_JOBS,
  FETCH_MEMBERS,
  FILTER_PATCH,
  FILTER_RESET,
  FILTER_SET,
  RANGE_SET,
  SORT_SET,
} from './constants';

import {
  apiJobQueryForState,
  parseFilterFromUrl,
  urlQueryForState,
} from './utils';

export const jobStateSelector = (state) => state.jobs;

export function* getMembersSaga(action) {
  try {
    const query = {
      limit: RESULTS_LIMIT,
      where: { id: action.payload.id },
      populate: ['dsp'],
    };
    const members = yield call(Member.get, query);
    yield put({ type: FETCH_MEMBERS.COMPLETED, payload: members.data });
  } catch (error) {
    yield put({ type: FETCH_MEMBERS.FAILED, payload: 'Failed to fetch members' });
  }
}

export function* getJobsSaga() {
  try {
    const jobState = yield select(jobStateSelector);
    const query = apiJobQueryForState(jobState);
    const [jobsCount, jobs] = yield all([
      call(Jobs.count, { where: query.where }),
      call(Jobs.get, query),
    ]);
    yield put({
      type: FETCH_JOBS.COMPLETED,
      payload: {
        jobs: jobs.data,
        jobsCount: jobsCount.data.count,
      },
    });
  } catch (error) {
    yield put({ type: FETCH_JOBS.FAILED, error });
  }
}

export function* setBrowserUrl({ payload: { router } }: AnyAction) {
  const jobState = yield select(jobStateSelector);
  const query = urlQueryForState(jobState);
  const { location, navigate } = router;
  navigate({ pathname: location.pathname, search: `?${qs.stringify(query)}` }, { replace: true });
}

export function* initJobSaga(action) {
  const {
    user, jobTypeOptions, jobStatusOptions, memberOptions, router,
  } = action.payload;

  let members = membersForFeature(user, Permission.manageJobs);
  members = members === MEMBER.ALL ? [] : members;

  yield put({ type: FETCH_MEMBERS.STARTED, payload: { id: members } });
  yield take(FETCH_MEMBERS.COMPLETED);

  const { location } = router;
  const { filter, range } = parseFilterFromUrl(jobTypeOptions, jobStatusOptions, memberOptions, location);
  if (!filter.startDate) {
    filter.startDate = moment().subtract(7, 'days').startOf('day');
  }
  if (!filter.endDate) {
    filter.endDate = moment().endOf('day');
  }
  yield put({ type: FILTER_SET, payload: { filter, range, router } });
}

export function* jobsSagas() {
  yield all([
    takeLatest(
      INIT,
      initJobSaga,
    ),
    takeLatest(
      FETCH_MEMBERS.STARTED,
      getMembersSaga,
    ),
    takeLatest(
      [FILTER_PATCH, FILTER_SET, FILTER_RESET, RANGE_SET, SORT_SET],
      getJobsSaga,
    ),
    takeLatest(
      [FILTER_PATCH, FILTER_SET, FILTER_RESET, RANGE_SET, SORT_SET],
      setBrowserUrl,
    ),
  ]);
}

export default jobsSagas;
