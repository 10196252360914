import _ from 'lodash';
import React, { useRef, useState, useEffect, CSSProperties, SetStateAction, Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { Loader, WppActionButton, WppInlineMessage, WppInput, WppListItem, WppSegmentedControl, WppSegmentedControlItem, WppSelect, WppTypography } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { isBulkCreateMode, useFetchDspSpecificChildren } from 'containers/StrategyWizard/utils';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { Flight, GoalType, InputChangeEventDetail, SelectChangeEventDetail, User, WppInputCustomEvent, WppSelectCustomEvent } from 'utils/types';
import MultiGoalView from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/MultiGoalView';
import { GoalTypeSearchCriteria } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { BUDGET_SECTION_STYLES, GOAL_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { filterGoals, populateBaseGoalTypes } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useBulkCreateGoalSectionContext } from '../contexts/GoalSectionProvider';

type GoalSelectionProps = {
  initialValues: WizardFormGoalSelection
  isHigherLevelOptimizationLevel: boolean
  confirmedGoal: string
  setConfirmedGoal: Dispatch<SetStateAction<string>>
};

const GoalSelection = (props: GoalSelectionProps) => {
  const { confirmedGoal, setConfirmedGoal, initialValues, isHigherLevelOptimizationLevel } = props;
  const isBulkCreate = isBulkCreateMode(location?.pathname ?? '');
  const {
    successEventFilter, setGoalSuccessEventFilter, dsp, wizardFormValues,
  } = useBulkCreateGoalSectionContext();
  const { attachFlightsStep: { optimizationLevel, member, attachedFlights }, budgetAllocationState } = wizardFormValues;
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const { reset } = useFormContext<WizardFormGoalSelection>();
  const budgetSettings = useWatch({ name: 'budgetSettings' });
  const [searchValue, setSearchValue] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [focusSearchInput, setFocusSearchInput] = useState<boolean>(false);
  const [baseGoalTypes, setBaseGoalTypes] = useState<{ [key: string]: GoalType }>({});
  const [visibleGoals, setVisibleGoals] = useState<{ [key: string]: GoalType }>({});
  const [goalTypeFilter, setGoalTypeFilter] = useState<GoalTypeSearchCriteria>(GoalTypeSearchCriteria.standard);
  const flightExtType = _.head(_.get(optimizationLevel, 'externalTypeIds')) as number;
  const firstflight: Flight = _.first(attachedFlights);
  const firstExtId = _.get(firstflight, 'externalId');
  const dspSpecificChildrenState = useFetchDspSpecificChildren(dsp, budgetAllocationState, firstExtId);

  useEffect(() => {
    const getGT = () => {
      const baseGT = populateBaseGoalTypes(
        user,
        flightExtType,
        // @ts-ignore expecting BudgetAllocationData type
        _.get(dspSpecificChildrenState, 'data', []),
        optimizationLevel,
        _.pick(member, ['externalId', 'businessModel']),
        false,
        false,
        isBulkCreate,
      );
      setBaseGoalTypes(baseGT);
      const filteredGoals = filterGoals(baseGT, {
        goalType: goalTypeFilter,
        successEvent: successEventFilter,
        revenueTypeEnabled: false,
        isCrossPlatformOptimization: false,
        isCampaignOptimization: true,
        flightExtType,
        user,
      });
      setVisibleGoals(filteredGoals);
    };
    getGT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dspSpecificChildrenState.kind]);

  useEffect(() => {
    reset({ ...initialValues, budgetSettings });
    setConfirmedGoal(initialValues.goal.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.goal.type]);

  useEffect(() => {
    // Hide filters when user starts typing search
    if (_.size(searchValue) && showFilters) {
      setShowFilters(false);
    }
    // Show filters when user resets filters
    if (!_.size(searchValue) && !showFilters) {
      setShowFilters(true);
    }
  }, [searchValue, showFilters]);

  useEffect(() => {
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: successEventFilter, revenueTypeEnabled: false, flightExtType, user, isCrossPlatformOptimization: false, isCampaignOptimization: true });
    setVisibleGoals(filteredGoals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter, successEventFilter]);

  useEffect(() => {
    setSearchValue('');
  }, [successEventFilter]);

  useEffect(() => {
    if (!searchValue) setGoalSuccessEventFilter('all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setGoalSuccessEventFilter('all');
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled: false, flightExtType, user, isCrossPlatformOptimization: false, isCampaignOptimization: true });
    setVisibleGoals(filteredGoals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter]);

  const onSearchChange = (_evt: WppInputCustomEvent<InputChangeEventDetail>, { value }) => {
    setSearchValue(value);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, textSearch: value, revenueTypeEnabled: false, flightExtType, user, isCrossPlatformOptimization: false, isCampaignOptimization: true }));
  };

  const searchRef = useRef<HTMLWppInputElement | null>(null);
  useEffect(() => {
    onSearchChange(null, { value: '' });
    if (searchRef.current) {
      searchRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusSearchInput]);

  const resetSearch = () => {
    setConfirmedGoal(null);
    setFocusSearchInput(!focusSearchInput);
  };

  const changeGoal = () => {
    setGoalSuccessEventFilter('all');
    setConfirmedGoal(null);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled: false, flightExtType, user, isCrossPlatformOptimization: false, isCampaignOptimization: true }));
  };

  const singleGoalView = !_.isNil(confirmedGoal) && _.has(visibleGoals, confirmedGoal);

  const visibleGoalSuccessEvents = _.chain(
    baseGoalTypes,
  )
    .filter((gT) => (!gT.isCustom))
    .map('successEvent')
    .uniq()
    .sortBy(_.identity)
    .value();

  const renderGoalCards = () => {
    // for Tactic Optimization Level, there is no call to budgetAllocationState
    if (!isHigherLevelOptimizationLevel || dspSpecificChildrenState.kind === PossibleStates.hasData) {
      return (
        <MultiGoalView
          visibleGoals={singleGoalView ? _.pick(visibleGoals, confirmedGoal) : visibleGoals}
          confirmedGoal={confirmedGoal}
          setConfirmedGoal={setConfirmedGoal}
          resetSearch={resetSearch}
          initialValues={initialValues}
          goalTypeFilter={goalTypeFilter}
          awgRef={null}
          wizardFormValues={wizardFormValues}
        />
      );
    }
    switch (dspSpecificChildrenState.kind) {
      case PossibleStates.initial:
      case PossibleStates.loading:
        return (<Loader size="large" style={GOAL_SECTION_STYLES.loader} inline />);
      case PossibleStates.error:
      default:
        return (
          <div style={GOAL_SECTION_STYLES.noVisibleGoals}>
            <p>Error fetching goals. Please try again later.</p>
          </div>
        );
    }
  };

  const onGoalFilterChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setGoalSuccessEventFilter(event.detail.value);
    setSearchValue('');
  };

  return (
    <div style={GOAL_SECTION_STYLES.container as CSSProperties}>
      <div style={GOAL_SECTION_STYLES.goalConfigAndReviewStyle}>
        <WppTypography type="xl-heading" tag="p">
          Goals
        </WppTypography>
        <WppTypography type="s-body" tag="p">
          Select the goal for Copilot to optimize towards.
        </WppTypography>
      </div>
      <div style={BUDGET_SECTION_STYLES.budgetIntervalInfobanner}>
        <WppInlineMessage
          size="l"
          titleText="Create Bulk Strategies"
          message="The selected goal will apply to all new strategies. You can adjust individual goals during the confirmation step."
          type="information"
          showTooltipFrom={4000}
        />
      </div>
      {singleGoalView ? (
        <div style={GOAL_SECTION_STYLES.editGoal as CSSProperties}>
          <WppActionButton onClick={changeGoal} className="changegoal" tabIndex={0}>
            Change Goal
          </WppActionButton>
        </div>
      ) : (
        <div style={GOAL_SECTION_STYLES.wppsearchContainer}>
          <WppInput
            name="searchGoals"
            ref={searchRef}
            className="searchBarGoalsStrategyWizard"
            placeholder="Search goals"
            value={searchValue}
            type="search"
            size="s"
            onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onSearchChange(event, { value: event.detail.value })}
          />
          {showFilters && (
            <>
              <WppSegmentedControl
                value={goalTypeFilter}
                size="s"
                style={GOAL_SECTION_STYLES.wppGoalSegment}
                className="goalTypeSegment"
              >
                <WppSegmentedControlItem
                  onClick={() => setGoalTypeFilter(GoalTypeSearchCriteria.standard)}
                  value={GoalTypeSearchCriteria.standard}
                >
                  <WppTypography className="wppTypogrphySegmentColor" type="s-midi" tag="p">{_.capitalize(GoalTypeSearchCriteria.standard)}</WppTypography>
                </WppSegmentedControlItem>
                <WppSegmentedControlItem
                  disabled={isBulkCreate}
                  value={GoalTypeSearchCriteria.advanced}
                >
                  <WppTypography className="wppTypogrphySegmentColor" type="s-midi" tag="p">{_.capitalize(GoalTypeSearchCriteria.advanced)}</WppTypography>
                </WppSegmentedControlItem>
              </WppSegmentedControl>
              <WppSelect
                onWppChange={onGoalFilterChange}
                size="s"
                value={successEventFilter}
                style={GOAL_SECTION_STYLES.wppGoalSelect}
              >
                <WppListItem value="all" key="all">
                  <p slot="label">All</p>
                </WppListItem>
                {_.map(visibleGoalSuccessEvents, (successEvent) => (
                  <WppListItem value={successEvent} key={successEvent}>
                    <p slot="label">{successEvent}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          )}
        </div>
      )}
      {renderGoalCards()}
    </div>
  );
};

export default GoalSelection;
