import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { WppButton, WppTypography, WppEmptyContent } from 'buildingBlocks';
import { User } from 'utils/types';
import { Mode } from 'containers/User/types';
import { NEW_USER_STYLES } from './style';

type Props = {
  user: User
};

const NewUser: React.FC<Props> = ({ user }: Props): React.ReactElement => {
  const message = _.get(user, 'approver')
    ? 'Thank you for creating an account. Your request is being reviewed by an approver. You will receive an email notification once your account is activated.'
    : 'To get started please press button below to setup your profile and get access to a DSP account.';

  return (
    <div style={NEW_USER_STYLES.container}>
      <WppEmptyContent />
      <div style={NEW_USER_STYLES.header}>
        <WppTypography tag="h1" type="m-strong">Welcome to Copilot!</WppTypography>
        <WppTypography tag="p" type="s-body" style={NEW_USER_STYLES.headerDescription}>{message}</WppTypography>
      </div>
      <Link
        to="/userprofile"
        state={{ userId: `${user.id}`, userEmail: user.email, mode: Mode.create }}
      >
        <WppButton>Setup Profile</WppButton>
      </Link>
    </div>
  );
};

export default NewUser;
