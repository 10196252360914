import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Auth } from 'utils/copilotAPI';

import { LOGOUT, LOGOUT_SUCCESS } from './constants';

function* logout({ payload: session }: AnyAction) {
  const response = yield call(Auth.logout, session);
  if (response.status === 200) {
    location.replace('/');
    yield put({ type: LOGOUT_SUCCESS });
  }
}

export default function* logoutSaga() {
  yield takeLatest(LOGOUT, logout);
}
