export const MESSAGES_FETCH = 'MESSAGES_FETCH';
export const MESSAGES_FETCH_COMPLETED = 'MESSAGES_FETCH_COMPLETED';
export const MESSAGES_FETCH_FAILED = 'MESSAGES_FETCH_FAILED';
export const MESSAGES_STARTING_ELEMENTS_PER_PAGE = 10;
export const MESSAGES_ELEMENTS_PER_PAGE_OPTIONS = [10, 25, 50];
export const MESSAGES_PER_PAGE_LIMIT = 'MESSAGES_PER_PAGE_LIMIT';
export const SELECT_ALL = 'SELECT_ALL';
export const SELECT_NONE = 'SELECT_NONE';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const DELETE_MESSAGES = 'DELETE_MESSAGES';
export const MESSAGES_SELECTED = 'MESSAGES_SELECTED';
export const DELETE_MESSAGES_COMPLETED = 'DELETE_MESSAGES_COMPLETED';
export const DELETE_MESSAGES_FAILED = 'DELETE_MESSAGES_FAILED';
export const CLEAR_RESULT_MESSAGE = 'CLEAR_RESULT_MESSAGE';
export const CLEAR_SELECTED_MESSAGES = 'CLEAR_SELECTED_MESSAGES';

export const messagesListingTableColumns = [
  { text: 'Subject', value: 'Subject' },
  { text: 'Message', value: 'Message' },
  { text: 'Start Date', value: 'Start Date' },
  { text: 'End Date', value: 'End Date' },
];
