import _ from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { ViewStrategyFlightRun, StrategyFlightRun, ViewFlight, Strategy } from 'utils/copilotAPI';
import { RESULTS_LIMIT } from 'constantsBase';
import {
  FETCH_STRATEGY_FLIGHT_RUNS,
  FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED,
  FETCH_STRATEGY_FLIGHT_RUNS_FAILED,
  DASHBOARD_FETCH_LOG,
  DASHBOARD_FETCH_LOG_COMPLETED,
  DASHBOARD_SELECT_RESULT,
  DASHBOARD_DOWNLOAD_RESULT,
  DASHBOARD_SELECT_FLIGHT,
  DASHBOARD_SELECT_RUN,
  DASHBOARD_FETCH_RESULT_COMPLETED,
  DASHBOARD_FETCH_RESULT_FAILED,
  DASHBOARD_DOWNLOAD_RESULT_FAILED,
} from './constants';

import { tasksForRun } from './utils';

export function* fetchTaskResult(action) {
  const { run, task, result } = action.payload;
  try {
    const response = yield call(StrategyFlightRun.getResultAtKey, run.id, { key: task.key, suffix: result.suffix });
    const payload = {
      data: response.data,
      run,
      task,
      result,
    };
    yield put({ type: DASHBOARD_FETCH_RESULT_COMPLETED, payload });
  } catch (error) {
    yield put({ type: DASHBOARD_FETCH_RESULT_FAILED, error });
  }
}

export function* downloadTaskResult(action) {
  const { run: { id }, task: { key }, result: { suffix } } = action.payload;
  try {
    const response = yield call(StrategyFlightRun.getResultAtKey, id, {
      key,
      suffix: `/${suffix}`,
      presignedurl: true,
    });
    // _self attribute is to prevent opening a new window
    window.open(response.data, '_self');
  } catch (error) {
    yield put({ type: DASHBOARD_DOWNLOAD_RESULT_FAILED, error });
  }
}

export function* fetchLog(action) {
  const { run, task } = action.payload;
  const logKey = `result.tasks.log_locations.${task.name}`;
  try {
    const response = yield call(StrategyFlightRun.getResultAtKey, run.id, { key: logKey, suffix: '' });
    const payload = {
      data: response.data,
      run,
      task,
    };
    yield put({ type: DASHBOARD_FETCH_LOG_COMPLETED, payload });
  } catch (error) {
    yield put({ type: DASHBOARD_FETCH_LOG_COMPLETED, error });
  }
}

export function* fetchStrategyFlightRun(action) {
  const { strategyId, flightId, runId } = action.payload;
  const params = {
    run: { strategy: strategyId },
    flight: { strategy: strategyId, limit: RESULTS_LIMIT },
  };
  const populate = ['member', 'brand', 'algorithmType', 'strategyType', 'strategyCreatedBy', 'strategyLastUpdatedBy', 'advertiser'];

  try {
    const results = yield all([
      call(ViewFlight.get, params.flight),
      call(StrategyFlightRun.getLatest, strategyId, params.run),
      call(Strategy.getById, strategyId, { populate: 'strategyGoals' }),
      call(ViewStrategyFlightRun.getById, runId, { populate }),
    ]);

    const [rawFlights, rawRuns, strategy, run] = _.map(results, 'data');
    const runForFlight = _.keyBy(rawRuns, 'flight');

    const runs = _.map(rawRuns, (r) => _.extend(r, { tasks: tasksForRun(run) }));
    const flights = _.map(rawFlights, (f) => _.extend(f, { run: _.get(runForFlight, f.id, null) }));

    run.tasks = tasksForRun(run);

    const payload = { flights, runs, strategy };

    yield put({ type: FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED, payload });
    yield put({ type: DASHBOARD_SELECT_FLIGHT, payload: { flightId } });
    yield put({ type: DASHBOARD_SELECT_RUN, payload: { run } });
  } catch (error) {
    yield put({ type: FETCH_STRATEGY_FLIGHT_RUNS_FAILED, error });
  }
}

export const sagas = [
  takeLatest(FETCH_STRATEGY_FLIGHT_RUNS, fetchStrategyFlightRun),
  takeLatest(DASHBOARD_SELECT_RESULT, fetchTaskResult),
  takeLatest(DASHBOARD_DOWNLOAD_RESULT, downloadTaskResult),
  takeLatest(DASHBOARD_FETCH_LOG, fetchLog),
];

export function* dashboardSagas() {
  yield all([...sagas]);
}

export default dashboardSagas;
