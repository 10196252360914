import { Moment } from 'moment';
import { ReactNode } from 'react';
import { Member, User } from 'utils/types';
import { RouterProps } from 'utils/withRouter';

export type Option<T> = {
  text: string,
  value: T,
};

type Action = Function;

// Options

export type JobStatusValue =
  | 'NEW'
  | 'RUNNING'
  | 'WARNINGS'
  | 'SUCCESS'
  | 'FAILED';

export type JobTypeValue =
  | 'algo_detachment'
  | 'amzn_report_analytics'
  | 'amzn_report_daily_etl'
  | 'amzn_report_dl'
  | 'apn_impression_value_analytics'
  | 'apn_standard_feed_analytics'
  | 'apn_standard_feed_daily_etl'
  | 'apn_video_feed_analytics'
  | 'apn_video_feed_daily_etl'
  | 'apn_video_report_analytics'
  | 'apn_view_duration_analytics'
  | 'db_model_clean_up'
  | 'dbm_feature_report_dl'
  | 'dbm_general_feature_geo_report_daily_etl'
  | 'dbm_general_feature_report_daily_etl'
  | 'dbm_reach_report_analytics'
  | 'dbm_report_analytics'
  | 'dbm_trueview_feature_device_report_etl'
  | 'dbm_trueview_feature_geo_report_etl'
  | 'dbm_trueview_feature_report_daily_etl'
  | 'dbm_trueview_report_analytics'
  | 'flight_attachment'
  | 'flight_detachment'
  | 'lld'
  | 'parquet_conversion'
  | 'populate_hourly_spend_statistics'
  | 'sync'
  | 'ttd_feature_report_analytics_daily_etl'
  | 'ttd_feature_report_analytics_dl'
  | 'ttd_impression_feed_analytics'
  | 'ttd_impression_feed_daily_etl'
  | 'ttd_impression_value_analytics'
  | 'ttd_report_analytics'
  | 'ttd_video_events_feed_analytics'
  | 'ttd_video_events_feed_daily_etl'
  | 'wmt_feature_report_analytics_daily_etl'
  | 'wmt_feature_report_analytics_dl'
  | 'wmt_impression_feed_analytics'
  | 'wmt_impression_feed_daily_etl'
  | 'wmt_impression_value_analytics'
  | 'wmt_report_analytics'
  | 'wmt_video_events_feed_analytics'
  | 'wmt_video_events_feed_daily_etl';

export enum TimeZoneValue {
  myLocal = 'myLocal',
  utc = 'utc',
}

export type JobTypeOption = Option<JobTypeValue>;
export type JobStatusOption = Option<JobStatusValue>;
export type MemberOption = Option<number> & Member;
export type TimeZoneOption = Option<string>;

// Data
export type Job = {
  id: number,
  type: string,
  member: Member,
  createdAt: string,
  updatedAt: string,
  status: JobStatusValue,
  statusDetail?: string,
  uuid?: string,
};

export type DropdownFilter = {
  id: string,
  name: string,
  placeholder: string,
  options?: Array<Option<string>>,
};

export type Filter = {
  startDate?: Moment,
  endDate?: Moment,
  member?: Array<Member>,
  type?: Array<Option<string>>,
  status?: Array<Option<string>>,
};

export type Location = {
  pathname: string,
  search: string,
};

export type JobProps = RouterProps & {
  user: User,
  resetFilter: (router: any) => void,
  selectedTimezone: TimeZoneValue,
  handleTimezoneChange: (timezoneVal: string) => void,
  updateFilter: Function,
  filter: Filter,
  jobStatusOptions: Array<JobStatusOption>,
  initJobs: Function,

  getSeat: Action,
  getJobs: Action,
  filterUpdate: Action,
  setRange: Action,
  setSort: Action,

  jobs: Array<Job>,
  members: Array<Member>,

  jobTypeOptions: Array<JobTypeOption>,
  memberOptions: Array<MemberOption>,
  statusOptions: Array<JobStatusOption>,

  selectedTypes: Array<JobTypeOption>,
  selectedStatuses: Array<JobStatusOption>,
  selectedMembers: Array<MemberOption>,

  sort: { column: string, direction: SortDirection },
  range: { limit: number, skip: number },

  jobsCount: number,
  memberFetchError: string,
  loading: boolean,
  pageNumber: number,
};

export type FilterLabelProps = {
  children?: ReactNode,
};

export type SortDirection =
  | 'ascending'
  | 'descending';

export type Query = {
  startDate?: string,
  endDate?: string,
  member?: Array<Member>,
  type?: Array<Option<string>>,
  status?: Array<Option<string>>,
};
