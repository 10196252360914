import React from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { WppListItem, WppSelect } from '@wppopen/components-library-react';
import { ColDef, SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { DEMO_SITES_STYLE } from 'containers/DemoSites/styles';
import { BulkCreateStrategyConfirmationForm } from 'containers/BulkCreateStrategyWizard/types';
import { ValidatedCustomWppInput } from 'buildingBlocks';
import { GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { getRealValue } from 'buildingBlocks/ReactHookFormComponents/utils';
import { standardGoalsForBulkCreate } from '../constants';
import { BulkCreateStrategyType } from '../types';
import { MoreActionsPopover } from './MoreActionsPopover';
import { strategyTableHeader } from '../style';
import { showPacingError } from '../utils';

const { actionButtonDiv } = DEMO_SITES_STYLE;
const { actionCellStyle } = strategyTableHeader;

const StrategiesTableHeader = (
  handleSingleStrategyDelete: (id: number) => void,
  setStratIndex: (id: Array<number>) => void,
  setStrategyModalStatus: (state: boolean) => void,
  showYoutubeGoals: boolean,
): Array<ColDef> => {
  const { control, setValue, getValues } = useFormContext<BulkCreateStrategyConfirmationForm>();
  const goalsToRender = !showYoutubeGoals
    ? standardGoalsForBulkCreate.filter(
      (goal) => goal.value !== GOAL_TYPES.vcrYoutube.value && goal.value !== GOAL_TYPES.cpvYoutube.value,
    ) : standardGoalsForBulkCreate;

  const updateGoalTarget = (goalVal, index) => {
    const { valueType } = _.get(GOAL_TYPES, goalVal);
    const goalTarget = getValues(`strategies.${index}.goal.target`);
    const updatedGoalTarget = valueType === GOAL_VALUE_TYPE.PERCENTAGE ? getRealValue(goalTarget, 2) : goalTarget;
    setValue(`strategies.${index}.goal.target`, _.toNumber(updatedGoalTarget));
  };

  return (
    [
      {
        field: 'strategyName',
        cellRenderer: ({ data }: { data: BulkCreateStrategyType }) => data.strategyName,
        flex: 4,
        sortable: false,
        resizable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellClass: 'strategy-name',
      },
      {
        field: 'object',
        cellRenderer: ({ data }: { data: BulkCreateStrategyType }) => data.object,
        flex: 4,
        sortable: false,
        resizable: false,
        cellClass: 'object-name',
      },
      {
        field: 'dateRange',
        cellRenderer: ({ data }: { data: BulkCreateStrategyType }) => data.dateRange,
        sortable: false,
        resizable: false,
        maxWidth: 160,
      },
      {
        field: 'budget',
        cellRenderer: ({ data }: { data: BulkCreateStrategyType }) => data.budget,
        sortable: false,
        maxWidth: 90,
        resizable: false,
      },
      {
        field: 'goal',
        cellRenderer: ({ rowIndex }: { rowIndex: number }) => (
          <div className="goal-cell" style={{ width: '100%' }}>
            <Controller
              name={`strategies.${rowIndex}.goal.type`}
              control={control}
              render={({ field }) => (
                <WppSelect
                  id="goalType"
                  {...field}
                  value={field.value}
                  onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                    field.onChange(event.detail.value);
                    updateGoalTarget(event.detail.value, rowIndex);
                  }}
                  size="s"
                >
                  {_.map(goalsToRender, (goalType) => (
                    <WppListItem key={goalType.text} value={goalType.value}>
                      <p slot="label">{goalType.strategyWizardAbbreviation}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              )}
            />
          </div>
        ),
        sortable: false,
        maxWidth: 130,
        resizable: false,
      },
      {
        field: 'pacing',
        cellRenderer: ({ rowIndex }: { rowIndex: number }) => (
          <>
            <Controller
              name={`strategies.${rowIndex}.dailyParentBudgetInflationRatio`}
              control={control}
              rules={{
                required: { value: true, message: '' },
                min: { value: 0.5, message: '' },
                max: { value: 1.5, message: '' },
              }}
              render={(properties) => (
                <ValidatedCustomWppInput
                  labelTooltipConfig={{ onShow: () => false }}
                  type="text"
                  messageType={showPacingError(_.toNumber(properties.field.value)) ? 'error' : undefined}
                  value={properties.field.value ? Math.round(Number(properties.field.value) * 100) : ''}
                  onWppChange={(e) => {
                    const rawValue = parseFloat(e.target.value);
                    properties.field.onChange(isNaN(rawValue) ? '' : rawValue / 100);
                  }}
                  {..._.omit(properties, 'formState')}
                  className="bulk-create-pacing"
                  skipIsDirtyCheck
                  size="s"
                />
              )}
            />
            %
          </>
        ),
        sortable: false,
        resizable: false,
        maxWidth: 100,
      },
      {
        field: 'actions',
        sortable: false,
        resizable: false,
        maxWidth: 30,
        headerName: '',
        cellStyle: { actionCellStyle },
        cellRenderer: ({ data, rowIndex }: { data: BulkCreateStrategyType, rowIndex: number }) => (
          <div style={actionButtonDiv}>
            <MoreActionsPopover
              handleDelete={handleSingleStrategyDelete}
              id={data.tempId}
              rowIndex={rowIndex}
              setSelectedStratIndex={setStratIndex}
              setStrategyModalStatus={setStrategyModalStatus}
            />
          </div>
        ),
      },
    ]);
};

export default StrategiesTableHeader;
