import _ from 'lodash';
import React, { Dispatch } from 'react';
import { WppInlineMessage } from 'buildingBlocks';
import { alreadyAttachedHeaderMessages } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { validationProgressStyles } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';
import { Flight } from 'utils/types';
import AttachedToAnotherStrategyFlightTable from './AttachedToAnotherStrategyFlightTable';

type FlightCandidatesAttachedToAnotherStrategyProps = {
  attachedToAnotherStrategy: Array<Flight>
  alreadyAttachedFlightsToInclude: Array<Flight>
  setAlreadyAttachedFlightsToInclude: Dispatch<React.SetStateAction<Array<Flight>>>
};

const FlightCandidatesAttachedToAnotherStrategy = (props: FlightCandidatesAttachedToAnotherStrategyProps) => {
  const { attachedToAnotherStrategy, alreadyAttachedFlightsToInclude, setAlreadyAttachedFlightsToInclude } = props;
  const hasIncludedFlights = _.size(alreadyAttachedFlightsToInclude);

  return (
    <>
      {!_.isEmpty(attachedToAnotherStrategy) && (
        <div style={validationProgressStyles.container}>
          <WppInlineMessage
            size="l"
            titleText="Some Objects Already Attached"
            message={hasIncludedFlights ? alreadyAttachedHeaderMessages.objectsIncludes : alreadyAttachedHeaderMessages.default}
            type={hasIncludedFlights ? 'warning' : 'information'}
            showTooltipFrom={1000}
            style={validationProgressStyles.inlineMessage}
            hideCloseBtn
          />
          <AttachedToAnotherStrategyFlightTable
            attachedToAnotherStrategy={attachedToAnotherStrategy}
            alreadyAttachedFlightsToInclude={alreadyAttachedFlightsToInclude}
            setAlreadyAttachedFlightsToInclude={setAlreadyAttachedFlightsToInclude}
          />
        </div>
      )}
    </>
  );
};

export default FlightCandidatesAttachedToAnotherStrategy;
