import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { ColDef, User } from 'utils/types';
import { NotificationMsgObject } from 'components/Notification/types';
import { State as FilterState } from 'containers/NotificationCenter/types';
import { NUMBER_DATE_FORMAT } from 'utils/dateTime';
import { WppTypography } from 'buildingBlocks';
import NotificationMessage from './NotificationMessage';
import NotificationSubject from './NotificationSubject';
import NotificationInfoWpp from './NotificationInfoWpp';
import NotificationActionsWpp from './NotificationActionsWpp';

const NotificationTableHeader = (filterState: FilterState): Array<ColDef> => {
  const { id: userId } = useSelector<GlobalState>((state) => state.login.user) as User;
  return [
    {
      field: 'date',
      sortable: true,
      resizable: false,
      valueGetter: ({ data }: { data: NotificationMsgObject }) => moment(data.createdAt).format(NUMBER_DATE_FORMAT),
      cellRenderer: ({ data }: { data: NotificationMsgObject }) => (
        <WppTypography tag="p" type="s-body">{ moment(data.createdAt).format(NUMBER_DATE_FORMAT) }</WppTypography>
      ),
      maxWidth: 120,
      minWidth: 85,
    },
    {
      field: 'subject',
      sortable: false,
      resizable: false,
      cellRenderer: ({ data }: { data: NotificationMsgObject }) => (
        <NotificationSubject
          notification={data}
          userId={userId}
        />
      ),
      maxWidth: 250,
    },
    {
      field: 'message',
      sortable: false,
      resizable: false,
      cellRenderer: ({ data }: { data: NotificationMsgObject }) => (
        <NotificationMessage notification={data} />
      ),
      minWidth: 257,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'strategyInfo',
      sortable: false,
      resizable: false,
      valueGetter: ({ data }: { data: NotificationMsgObject }) => data.strategyName,
      cellRenderer: ({ data }: { data: NotificationMsgObject }) => (
        <NotificationInfoWpp
          notification={data}
          filterState={filterState}
        />
      ),
      minWidth: 257,
      wrapText: true,
      autoHeight: true,
    },
    {
      field: 'actions',
      sortable: false,
      resizable: false,
      cellRenderer: ({ data }: { data: NotificationMsgObject }) => (
        <NotificationActionsWpp
          notification={data}
          filterState={filterState}
          userId={userId}
        />
      ),
      maxWidth: 214,
    },
  ];
};

export default NotificationTableHeader;
