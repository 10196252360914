import { SelectedCategoryFilter, DEFAULT_END_DATE, DEFAULT_START_DATE } from 'components/Notification/constants';
import { assertUnreachable } from 'utils/types';
import { ViewFilters, Action, ActionTypes, State, DateSortOrder } from './types';

export const NOTIFICATIONS_INITIAL_STATE = {
  selectedBrands: [],
  selectedCategoryFilter: SelectedCategoryFilter.actions,
  viewFilter: ViewFilters.my,
  dateRange: `${DEFAULT_START_DATE} - ${DEFAULT_END_DATE}`,
  unreadOnly: false,
  elementsPerPage: 10,
  pageNumber: 1,
  numberOfElements: 0,
  skip: 0,
  sortOrder: DateSortOrder.desc,
};

export const notificationsReducer = (state: State = NOTIFICATIONS_INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return NOTIFICATIONS_INITIAL_STATE;
    }
    case ActionTypes.RESET_PAGINATION: {
      return {
        ...state,
        pageNumber: NOTIFICATIONS_INITIAL_STATE.pageNumber,
        skip: NOTIFICATIONS_INITIAL_STATE.skip,
      };
    }
    case ActionTypes.SELECT_BRANDS: {
      return {
        ...state,
        selectedBrands: action.payload.selectedBrands,
      };
    }
    case ActionTypes.SELECT_CATEGORY_FILTER: {
      return {
        ...state,
        selectedCategoryFilter: action.payload.selectedCategoryFilter,
      };
    }
    case ActionTypes.SELECT_VIEW_FILTER: {
      return {
        ...state,
        viewFilter: action.payload.viewFilter,
      };
    }
    case ActionTypes.SELECT_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    }
    case ActionTypes.SELECT_UNREADY_ONLY: {
      return {
        ...state,
        unreadOnly: action.payload.unreadOnly,
      };
    }
    case ActionTypes.CHANGE_ELEMENTS_PER_PAGE_OR_PAGE_NUM: {
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        elementsPerPage: action.payload.elementsPerPage,
        skip: action.payload.skip,
      };
    }
    case ActionTypes.CHANGE_NUM_OF_ELEMENTS: {
      return {
        ...state,
        numberOfElements: action.payload.numberOfElements,
      };
    }
    case ActionTypes.INIT_FROM_QS: {
      return {
        selectedBrands: action.payload.selectedBrands,
        selectedCategoryFilter: action.payload.selectedCategoryFilter,
        viewFilter: action.payload.viewFilter,
        dateRange: action.payload.dateRange,
        unreadOnly: action.payload.unreadOnly,
        elementsPerPage: +action.payload.elementsPerPage,
        pageNumber: +action.payload.pageNumber,
        numberOfElements: +action.payload.numberOfElements,
        skip: +action.payload.skip,
        sortOrder: action.payload.sortOrder,
      };
    }
    case ActionTypes.CHANGE_SORT_ORDER: {
      return {
        ...state,
        sortOrder: action.payload.sortOrder,
      };
    }
    default:
      return assertUnreachable(action.type);
  }
};
