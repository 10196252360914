// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterSelectButton::part(single-select-input) {
  border-radius: 0.14285714rem;
  border: 1px solid var(--wpp-grey-color-500);
}`, "",{"version":3,"sources":["webpack://./src/containers/BulkCreateStrategyWizard/style.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;EACA,2CAAA;AAAR","sourcesContent":[".filterSelectButton {\n    &::part(single-select-input) {\n        border-radius: .14285714rem;\n        border: 1px solid var(--wpp-grey-color-500);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
