import React, { Dispatch } from 'react';
import { WppSelect, WppListItem } from 'buildingBlocks';
import { myAllFilterStyles } from 'containers/NotificationCenter/style';
import { ViewFilters } from 'containers/NotificationCenter/types';

type NotificationToggleViewProps = {
  viewFilter: ViewFilters
  updateViewFilter: Dispatch<ViewFilters>
};

const NotificationToggleView = ({ viewFilter, updateViewFilter }: NotificationToggleViewProps) => {
  const handleViewFilterChange = (event: CustomEvent) => {
    const selectedFilter: ViewFilters = event.detail.value;
    if (selectedFilter) {
      updateViewFilter(selectedFilter);
    }
  };

  return (
    <div style={myAllFilterStyles.filterContainer}>
      <WppSelect
        onWppChange={handleViewFilterChange}
        placeholder={viewFilter}
        value={viewFilter}
        size="s"
        style={myAllFilterStyles.notificationType}
      >
        <WppListItem value={ViewFilters.my}>
          <p slot="label">My Notifications</p>
        </WppListItem>
        <WppListItem value={ViewFilters.all}>
          <p slot="label">All Notifications</p>
        </WppListItem>
      </WppSelect>
    </div>
  );
};

export default NotificationToggleView;
