import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { List } from 'buildingBlocks';
import { selectTask } from '../actions';
import { Task as TaskType } from '../utils';

const iconStyle = { marginRight: 0 };

const itemStatus = (task: TaskType) => _.get(
  {
    success: ['green', 'check'],
    failure: ['red', 'x'],
  },
  task.status,
);

type Props = {
  selectTask: Function,
  selectedTask: TaskType,
  task: TaskType,
};

const Task = (props: Props) => {
  const { task, selectedTask } = props;
  const active = selectedTask && selectedTask.name === task.name;
  const [color, icon] = itemStatus(task);
  return (
    <List.Item onClick={() => props.selectTask(task)} active={active}>
      <List.Icon
        size="small"
        color={color}
        style={iconStyle}
        name={icon}
      />
      <List.Content>
        <List.Header>
          {task.displayName}
        </List.Header>
        {task.results.length} outputs
      </List.Content>
    </List.Item>
  );
};

const mapStateToProps = (state) => ({
  selectedTask: state.dashboard.selectedTask,
});

export default connect(mapStateToProps, { selectTask })(Task);
