import _ from 'lodash';
import React from 'react';
import { QSParams } from 'containers/StrategyWizard/utils';
import GoalSelection from './steps/GoalSelection';
import { BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS, WizardSteps } from './constants';
import BulkCreateAttachFlightsStep from './steps/AttachFlights/BulkCreateAttachFlightsStep';
import GoalTypeProvider from './steps/GoalSelection/contexts/GoalSectionProvider';
import StrategyConfigurationStep from './steps/StrategyConfiguration/StrategyConfigurationStep';
import { useBulkCreateStrategyWizardContext } from './contexts/BulkCreateStrategyWizardProvider';
import Confirm from './steps/StrategyConfirmation/Confirm';

type Props = {
  qsParams: QSParams
};

const BulkCreateNewStrategyRoutes = (props: Props) => {
  const {
    qsParams,
  } = props;
  const { router } = useBulkCreateStrategyWizardContext();
  const { location: { pathname } } = router;
  const stepId = _.get(_.find(BULK_CREATE_WIZARD_STEPS, (step) => _.isEqual(pathname, `${BULK_CREATE_WIZARD_BASE}${step.id}`)), 'id');
  switch (stepId) {
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.attachFlightsStep].id:
      return (
        <BulkCreateAttachFlightsStep
          qsParams={qsParams}
        />
      );
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.goalSelectionStep].id:
      return (
        <GoalTypeProvider>
          <GoalSelection qsParams={qsParams} />
        </GoalTypeProvider>
      );
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id:
      return (
        <StrategyConfigurationStep
          qsParams={qsParams}
        />
      );
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id:
      return (
        <Confirm />
      );
    default:
      return null;
  }
};

export default BulkCreateNewStrategyRoutes;
