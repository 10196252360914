import _ from 'lodash';
import React from 'react';

import { Table, Grid } from 'buildingBlocks';

import { JobProps } from 'containers/Jobs/types';
import { COLUMNS } from 'containers/Jobs/constants';

import Body from './Body';
import Pagination from './Pagination';

const DataTable = (props: JobProps) => {
  const { setSort, sort, router } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Table fixed sortable>
            <Table.Header>
              <Table.Row>
                {_.map(COLUMNS, (column) => (
                  <Table.HeaderCell
                    key={column.value}
                    sorted={column.value === sort.column ? sort.direction : null}
                    onClick={() => setSort(column.value, router)}
                  >
                    {column.text}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Body {...props} />
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Pagination {...props} />
      </Grid.Row>
    </Grid>
  );
};

export default DataTable;
export {
  Pagination,
  Body,
};
