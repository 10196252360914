import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { Header, List, Segment } from 'buildingBlocks';

import Task from './Task';

import { Run } from '../types';

type Props = {
  selectedRun: Run
};
const showTask = (task) => <Task key={task.name} task={task} />;

const TaskList = (props: Props) => {
  const { selectedRun: run } = props;

  if (_.isEmpty(run.tasks)) {
    return <span>No tasks</span>;
  }

  return (
    <div>
      <Header as="h3" attached="top"> {run.tasks.length} Tasks</Header>
      <Segment attached>
        <List selection size="small">
          { run.tasks.map(showTask) }
        </List>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedRun: state.dashboard.selectedRun,
});

export default connect(mapStateToProps)(TaskList);
