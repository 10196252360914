import React, { useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { AgGrid, WppGrid } from 'buildingBlocks';
import { NotificationMsgObject } from 'components/Notification/types';
import { State as FilterState } from 'containers/NotificationCenter/types';
// eslint-disable-next-line no-restricted-imports
import 'containers/NotificationCenter/style.scss';
import HeaderCell from './HeaderCell';
import NotificationTableHeader from './NotificationTableHeader';

type NotificationTableProps = {
  notifications: Array<NotificationMsgObject>
  filterState: FilterState
  isLoading: boolean
};

const NotificationTable = ({
  notifications,
  filterState,
  isLoading,
}: NotificationTableProps) => {
  const gridRef = useRef<AgGridReact>();
  const onGridSizeChanged = useCallback(
    (params: GridSizeChangedEvent) => {
      // get the current grids width
      const gridWidth = document.querySelector('.ag-body-viewport')!.clientWidth;
      // keep track of which columns to hide/show
      const columnsToShow = [];
      const columnsToHide = [];
      // iterate over all columns (visible or not) and work out
      // now many columns can fit (based on their minWidth)
      let totalColsWidth = 0;
      const allColumns = params.api.getColumns();
      if (allColumns && allColumns.length > 0) {
        allColumns.forEach((column) => {
          totalColsWidth += column.getMinWidth();
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        });
      }
      // show/hide columns based on current grid width
      params.api.setColumnsVisible(columnsToShow, true);
      params.api.setColumnsVisible(columnsToHide, false);
      // wait until columns stopped moving and fill out
      // any available space to ensure there are no gaps
      window.setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 10);
    },
    [],
  );

  return (
    <WppGrid container fullWidth>
      <WppGrid item>
        <AgGrid
          ref={gridRef}
          defaultColDef={{
            headerComponent: HeaderCell,
          }}
          columnDefs={NotificationTableHeader(filterState)}
          loading={!!isLoading}
          rowData={notifications}
          domLayout="autoHeight"
          embedFullWidthRows
          onGridSizeChanged={onGridSizeChanged}
        />
      </WppGrid>
    </WppGrid>
  );
};

export default NotificationTable;
