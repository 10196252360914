/*
 *
 * MessageCreator constants
 *
 */

const PREFIX = 'MESSAGE_CREATOR';

export const SUBMIT_FORM = `${PREFIX}:SUBMIT_FORM`;
export const SUBMIT_FORM_COMPLETED = `${PREFIX}:SUBMIT_FORM_COMPLETED`;
export const SUBMIT_FORM_FAILED = `${PREFIX}:SUBMIT_FORM_FAILED`;
export const FETCH_MESSAGE = `${PREFIX}:FETCH_MESSAGE`;
export const FETCH_MESSAGE_COMPLETED = `${PREFIX}:FETCH_MESSAGE_COMPLETED`;
export const FETCH_MESSAGE_FAILED = `${PREFIX}:FETCH_MESSAGE_FAILED`;
export const CLEAR_FORM_STATE = `${PREFIX}:CLEAR_FORM_STATE`;

export const MARKDOWN_OPTIONS = {
  preset: 'default',
  html: true,
  xhtmlOut: false,
  breaks: false,
  langPrefix: 'language-',
  linkify: true,
  typographer: false,
  quotes: '“”‘’',
};

export const FORM_NAME = 'messageDetail';
export const PAGE_NAME = 'Message';
export const FORM_FIELDS = [
  'algoTypes',
  'subject',
  'content',
  'startDate',
  'endDate',
  'startTime',
  'hasEndDate',
  'isDismissible',
  'priority',
  'publishOption',
];

export const PRIORITIES = [
  { name: 'Updates (2)', value: 2 },
  { name: 'Action Required (4)', value: 4 },
  { name: 'Action Required (6)', value: 6 },
];

export const ALGORITHM_TYPE = 'algorithmtype';
export const ADMIN_MESSAGE = 'admin_message';
