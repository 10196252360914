/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'buildingBlocks';
import TimePicker from 'components/TimePicker';
import { TOOLTIP_DESCRIPTIONS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { TIME_FORMAT, roundToNearest15Min } from 'utils/dateTime';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import SubHeader from './SubHeader';
import DatePicker from './CustomDatePicker';
import '../override.css';
import { handleDisabledMins } from '../utils';

const disabledMinutes = _.memoize(handleDisabledMins);
const DatePickerWrapper = validatedFieldWrapper(DatePicker);

const StartDateTime = () => {
  const { control } = useFormContext();

  return (
    <Grid.Row columns={4}>
      <SubHeader
        headerContent="Publish Start Date & Time"
        subContent="in (UTC)"
        toolTipContent={_.get(TOOLTIP_DESCRIPTIONS, 'startDateTime')}
      />
      <Grid.Column>
        <Controller
          name="startDate"
          control={control}
          render={(props) => (
            <DatePickerWrapper
              placeholder="Select Start Date"
              startDate={props.field.value}
              minDate={moment()}
              onChange={props.field.onChange}
              selected={props.field.value}
              closeOnSelect
              hasMinDate
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </Grid.Column>
      <Grid.Column>
        <Controller
          name="startTime"
          control={control}
          render={(props) => (
            <TimePicker
              input={{ value: moment(props.field.value), onChange: props.field.onChange }}
              defaultValue={roundToNearest15Min(props.field.value)}
              defaultOpenValue={roundToNearest15Min(props.field.value)}
              placeholder="Enter Time"
              className="messageTimePicker"
              style={{ width: '58%' }}
              disabledMinutes={disabledMinutes}
              hideDisabledOptions
              showSecond={false}
              allowEmpty={false}
              format={TIME_FORMAT}
              use12Hours
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default StartDateTime;
