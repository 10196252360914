/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import KeyBinding from 'react-keybinding-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { GlobalState } from 'reducers';
import { Form, WppGrid, WppTypography } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import {
  ATTACH_FLIGHTS, RESET_GOALS_AND_STRATEGY_TYPE,
  UPDATE_BUDGET_ALLOCATION_STATE, SET_BULK_STRATEGY_TYPE,
} from 'containers/StrategyWizard/constants';
import { QSParams } from 'containers/StrategyWizard/utils';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { wppBodyContainer } from 'components/PageTemplate/style';
import PageForbidden from 'containers/403';
import { BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS, WizardSteps } from 'containers/BulkCreateStrategyWizard/constants';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { BulkCreateWizardFormAttachFlights } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';
import { BulkCreateWizardFormValues } from 'containers/BulkCreateStrategyWizard/types';
import { getStrategyTypeByDsp, updateQueryString } from 'containers/BulkCreateStrategyWizard/utils';
import ConfirmationPopup from 'containers/StrategyWizard/components/ConfirmationPopup';
import { Permission } from 'utils/featureFlags';
import { useMemberFetcher } from 'utils/hooks/useMemberFetcher';
import { usePrevious } from 'utils/hooks/usePrevious';
import KEY_MAP, { KeyboardEvent } from 'utils/keymap';
import { User } from 'utils/types';
import BulkCreateAttachFlightsTable from './components/AttachFlightsModal/BulkCreateAttachFlightsTable';
import AttachedFlightsActions from './components/AttachedFlightsTable/AttachedFlightsActions';
import BulkCreateAttachFlightsModal from './components/BulkCreateAttachFlightsModal';
import BulkCreateOptimizationTypeBanner from './components/BulkCreateOptimizationTypeBanner';
import BulkCreateAttachModalProvider from './contexts/BulkCreateAttachModalProvider';

type Props = {
  qsParams: QSParams
};

const BulkCreateAttachFlightsStep = (props: Props) => {
  const { wizardFormValues, dispatch, router } = useBulkCreateStrategyWizardContext();
  const { qsParams } = props;
  const navigate = useNavigate();
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const prev = usePrevious(wizardFormValues);
  const { attachFlightsStep: contextAttachedFlightsStep, budgetAllocationState } = wizardFormValues;
  const budgetAllocationData = _.get(budgetAllocationState, 'data');
  const loadingBudgetAllocationState = budgetAllocationState.kind === Status.loading;
  const contextAttachedFlights = contextAttachedFlightsStep.attachedFlights;
  const [searchValue, setSearchValue] = useState<string>('');
  const initialFormValues = {
    member: _.get(contextAttachedFlightsStep, 'member'),
    optimizationLevel: _.get(contextAttachedFlightsStep, 'optimizationLevel'),
    attachedFlights: contextAttachedFlights,
  };
  const formMethods = useForm<BulkCreateWizardFormAttachFlights>({ defaultValues: initialFormValues });
  const { control, reset } = formMethods;
  const member = useWatch({ name: 'member', control });
  const optimizationLevel = useWatch({ name: 'optimizationLevel', control });
  const attachedFlights = useWatch({ name: 'attachedFlights', control });
  const attachedFlightsLen = _.size(attachedFlights);
  const hasFlightsAttached = attachedFlightsLen > 0;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const isDisabled = !!(((loadingBudgetAllocationState || _.isEmpty(attachedFlights)))
    || (_.isEmpty(contextAttachedFlights) && _.isEmpty(attachedFlights)));
  /// fetch members & any fetch errors from API
  const [members, memberFetchError] = useMemberFetcher(user);

  useEffect(() => {
    reset({
      member: initialFormValues.member,
      optimizationLevel: initialFormValues.optimizationLevel,
      attachedFlights: initialFormValues.attachedFlights,
    });
  }, [initialFormValues.member]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleOnClick = async () => {
    const attachFlightsStepValues = {
      member,
      optimizationLevel,
      attachedFlights,
    };

    // filter out removed flights from budget allocation state
    if (_.size(_.keys(budgetAllocationData)) > _.size(attachedFlights)) {
      const filteredBudgetAllocationData = _.pick(budgetAllocationData, _.map(attachedFlights, 'externalId'));
      dispatch({ type: UPDATE_BUDGET_ALLOCATION_STATE, payload: { kind: Status.hasData, data: filteredBudgetAllocationData } });
    }

    if (!_.isEmpty(attachedFlights)) {
      const firstFlight = _.first(attachedFlights);
      const flightDsp = _.get(firstFlight, 'dsp');
      const strategyType = getStrategyTypeByDsp(flightDsp);
      dispatch({
        type: SET_BULK_STRATEGY_TYPE,
        payload: strategyType,
      });
    }

    dispatch({
      type: ATTACH_FLIGHTS,
      payload: attachFlightsStepValues,
    });

    // create flow: if user changes basic info depending on selected member, we reset GoalSelectionStep and StrategyTypeSelectionStep
    const prevValues = _.pick(prev.attachFlightsStep, ['member']);
    const currValues = _.pick(attachFlightsStepValues, ['member']);

    const shouldResetOtherStrats = !_.isEqual(prevValues.member, currValues.member);
    if ((!_.isNil(prevValues.member)) && (shouldResetOtherStrats)) {
      dispatch({ type: RESET_GOALS_AND_STRATEGY_TYPE });
    }

    const values = {
      ...wizardFormValues,
      attachFlightsStep: attachFlightsStepValues,
      ...({ strategyConfirmationStep: { ...wizardFormValues.strategyConfirmationStep } }),
    } as BulkCreateWizardFormValues;

    const pathname = `${BULK_CREATE_WIZARD_BASE}${BULK_CREATE_WIZARD_STEPS[WizardSteps.goalSelectionStep].id}`;
    updateQueryString(values, qsParams, router.navigate, prev, pathname);
  };

  const runShortcuts = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.keyCode === KEY_MAP.ENTER) {
      e.preventDefault();
      router.navigate(`${BULK_CREATE_WIZARD_BASE}/${BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id}`);
    }
  };

  const onBackClick = () => {
    navigate('/');
  };

  return (
    <PermissionWrapper
      user={user}
      permissions={Permission.manageStrategyFlights}
      unauthorizedComponent={<PageForbidden message="You are not permitted to manage strategies." />}
    >
      <FormProvider {...formMethods}>
        <Form style={wppBodyContainer}>
          <WppGrid fullWidth container>
            <WppGrid item all={24} style={ATTACH_DSP_OBJECT_STYLES.header}>
              <WppTypography type="xl-heading" tag="h4">Object Attachment</WppTypography>
              <WppTypography type="s-body">Select the platform objects to be attached to your strategies.</WppTypography>
            </WppGrid>
            <WppGrid item all={24}>
              <BulkCreateOptimizationTypeBanner />
              <AttachedFlightsActions
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setModalIsOpen={setModalIsOpen}
              />
              <BulkCreateAttachModalProvider>
                <BulkCreateAttachFlightsModal
                  members={members}
                  memberFetchError={memberFetchError}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                />
              </BulkCreateAttachModalProvider>
            </WppGrid>
            <WppGrid item all={24}>
              {hasFlightsAttached && (
                <BulkCreateAttachFlightsTable
                  searchValue={searchValue}
                />
              )}
            </WppGrid>
            <KeyBinding onKey={(e) => runShortcuts(e)} type="keydown" />
          </WppGrid>
        </Form>
        <NavFooter
          onBackClick={hasFlightsAttached ? togglePopup : onBackClick}
          onNextClick={hasFlightsAttached ? handleOnClick : null}
          showBackButton
          nextButtonDisabled={isDisabled}
          backButtonText={hasFlightsAttached ? 'Cancel' : 'Back'}
        />
        <ConfirmationPopup
          isOpen={isOpen}
          togglePopup={togglePopup}
          cancelBtnPath="/"
        />
      </FormProvider>
    </PermissionWrapper>
  );
};

export default BulkCreateAttachFlightsStep;
