import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { BreadcrumbItemEventDetails, WppBreadcrumbCustomEvent } from 'utils/types';
import { WppGrid, WppTypography, WppIconChevron, WppBreadcrumb } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import { wppBodyContainer } from 'components/PageTemplate/style';
import withRouter, { RouterProps } from 'utils/withRouter';
import { withQueryString } from 'utils/functionHelpers';
import { breadCrumContainer, breadCrumBackLink, taskContainerStyle } from '../style';
import { fetchStrategyFlightRuns } from '../actions';
import { DashboardStateType } from '../types';
import RunDetail from './RunDetail';
import ResultList from './ResultList';
import TaskList from './TaskList';

const REQUIRED_PERMISSIONS = [Permission.strategyFlightRuns];

const Dashboard = (props: RouterProps) => {
  const { router: { navigate, location } } = props;
  const dispatch = useDispatch();

  const { selectedRun, selectedTask } = useSelector((state: { dashboard: DashboardStateType }) => state.dashboard);

  useEffect(() => {
    const { strategy, flight, run } = qs.parse(location.search.replace(/^\?/, ''));
    dispatch(fetchStrategyFlightRuns(strategy as string, flight as string, run as string));
  }, [location.search, dispatch]);

  const dashboardHeader = (
    <div>
      <div style={breadCrumContainer}>
        <WppIconChevron direction="left" size="s" />
        <WppBreadcrumb
          items={[{
            label: 'Back to Flight Runs',
            path: '/strategyflightruns',
          }]}
          onWppChange={(event: WppBreadcrumbCustomEvent<BreadcrumbItemEventDetails>) => navigate(event.detail.path)}
          style={breadCrumBackLink}
        />
      </div>
      <WppTypography tag="p" type="3xl-heading">Flight Run Detail</WppTypography>
    </div>
  );

  const name = 'Troubleshooting Dashboard';

  return (
    <WppPermissionPageTemplate
      name={name}
      title={name}
      customHeader={dashboardHeader}
      permissions={REQUIRED_PERMISSIONS}
      withoutLayout
    >
      <WppGrid container fullWidth style={wppBodyContainer}>
        <WppGrid item all={24}>
          {selectedRun && <RunDetail />}
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth style={{ ...wppBodyContainer, ...taskContainerStyle }}>
        <WppGrid item all={12}>
          {selectedRun && <TaskList />}
        </WppGrid>
        <WppGrid item all={12}>
          {selectedTask && <ResultList />}
        </WppGrid>
      </WppGrid>
    </WppPermissionPageTemplate>
  );
};

export default withRouter(withQueryString(Dashboard));
