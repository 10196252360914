import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WppTypography, WppTag, WppGrid } from 'buildingBlocks';
import { NotificationMsgObject } from 'components/Notification/types';
import { subjectStyles } from 'containers/NotificationCenter/style';

const { subjectStyle, countStyle, subjectContainer } = subjectStyles;

type NotificationSubjectProps = {
  notification: NotificationMsgObject
  userId: number
};

const NotificationSubject = ({ notification, userId }: NotificationSubjectProps) => {
  const { subject, count, readAt } = notification;
  const isUsersNotification = _.isEqual(notification.user, userId);
  const [read, setRead] = useState<boolean>(isUsersNotification ? !!readAt : false);

  useEffect(() => {
    if (isUsersNotification) {
      setRead(!!readAt);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <WppGrid container fullWidth>
      <WppGrid
        item
        all={24}
        style={subjectContainer}
      >
        <WppTypography tag="p" type="s-midi" style={{ ...subjectStyle, color: (read ? 'var(--wpp-grey-color-600)' : 'var(--wpp-grey-color-1000)') }}>
          {subject}
        </WppTypography>
        {count > 1 && (
          <WppTag
            label={count.toString()}
            variant="warning"
            style={countStyle}
          />
        )}
      </WppGrid>
    </WppGrid>
  );
};

export default NotificationSubject;
