import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { AgGrid, AgGridHeader, AgGridReact, WppGrid } from 'buildingBlocks';
import WppDeleteConfirmationModal from 'components/WppDeleteConfirmationModal';
import { BulkCreateStrategyConfirmationForm } from 'containers/BulkCreateStrategyWizard/types';
import { DSP } from 'constantsBase';
import StrategiesTableHeader from './StrategiesTableHeader';
import { BulkCreateStrategyType } from '../types';
import StrategyEditModal from './StrategyEditModal';

type Props = {
  hasAllTrueViewLIs: boolean
  strategiesToCreate: Array<BulkCreateStrategyType>
};

const StrategiesTable = ({ hasAllTrueViewLIs, strategiesToCreate }: Props) => {
  const gridRef = useRef<AgGridReact<BulkCreateStrategyType>>(null);
  const { setValue } = useFormContext<BulkCreateStrategyConfirmationForm>();
  const firstStrategy = _.first(strategiesToCreate);
  const dsp = _.get(firstStrategy, 'dsp');
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isSingleDeletion, setIsSingleDeletion] = useState<boolean>(false);
  const [stratgiesToRender, setStrategiesToRender] = useState<Array<BulkCreateStrategyType>>(strategiesToCreate);
  const [strategiesSelected, setStrategiesSelected] = useState<Record<string, BulkCreateStrategyType>>({});
  const [isStrategyModalOpen, setStrategyModalStatus] = useState<boolean>(false);
  const [stratIndex, setStratIndex] = useState<Array<number>>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const showDeletePanel = !_.isEmpty(strategiesSelected) && !isSingleDeletion;
  const handleDeselectAll = () => gridRef.current!.api.deselectAll();
  const handleStrategyModalOpen = () => setStrategyModalStatus(true);
  const handleStrategyModalClose = () => setStrategyModalStatus(false);
  const showYoutubeGoals = _.isEqual(dsp, DSP.DBM.id) ? hasAllTrueViewLIs : false;

  const handleRowsSelect = () => {
    const selectedStrategiesData: Array<BulkCreateStrategyType> = gridRef && gridRef.current!.api.getSelectedRows();
    const newStrategiesToDelete = {};
    selectedStrategiesData?.forEach((strategy: BulkCreateStrategyType) => {
      newStrategiesToDelete[strategy.tempId] = strategy;
    });
    setIsSingleDeletion(false);
    setStrategiesSelected(newStrategiesToDelete);
  };

  const onSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setStrategiesSelected({});
    if (!search) {
      setStrategiesToRender(strategiesToCreate);
      return;
    }
    const filteredStrats = _.filter(strategiesToCreate, ({ strategyName, object }) => _.includes(strategyName.toLowerCase(), search.toLowerCase()) || _.includes(object.toLowerCase(), search.toLowerCase()));
    setStrategiesToRender(filteredStrats);
  };

  const onDelete = async () => {
    setDeleting(true);
    const updatedStrats = _.filter(strategiesToCreate, (strat) => !strategiesSelected[strat.tempId]);
    setValue('strategies', updatedStrats);
    setStrategiesToRender(updatedStrats);
    setStrategiesSelected({});
    setDeleteModalOpen(false);
    setDeleting(false);
  };

  const handleSingleStrategyDelete = (id: number) => {
    const matchingStrats = _.find(strategiesToCreate, (strategy) => strategy.tempId === id);
    const newStrategiesToDelete = { [matchingStrats.tempId]: matchingStrats };
    setStrategiesSelected(newStrategiesToDelete);
    setDeleteModalOpen(true);
    setIsSingleDeletion(true);
  };

  const updateStrategy = (formValues: BulkCreateStrategyType, selectedStrats: Array<number>) => {
    const isMultiEdit = _.size(selectedStrats) > 1;
    // Map over selected indexes and update strategies accordingly
    const updatedStrategies = _.map(strategiesToCreate, (strategy, index) => {
      if (_.includes(selectedStrats, index)) {
        return isMultiEdit
          ? _.merge({}, formValues, _.pick(strategy, ['strategyName', 'object', 'tempId']))
          : formValues;
      }
      return strategy;
    });
    setValue('strategies', updatedStrategies);
    setStrategiesToRender(updatedStrategies);
    setStrategiesSelected({});
    handleStrategyModalClose();
  };

  const handleEditRowAction = () => {
    const selectedIds = _.map(_.keys(strategiesSelected), Number);

    if (_.isEmpty(selectedIds)) return;
    const selectedIndexes = _.filter(
      _.map(strategiesToCreate, (strategy, index) => (_.includes(selectedIds, strategy.tempId) ? index : null)),
      _.isNumber,
    );
    setStratIndex(selectedIndexes);
    setStrategyModalStatus(true);
  };

  return (
    <>
      <WppGrid fullWidth>
        <WppGrid item all={24}>
          <AgGridHeader
            handleDeleteRowAction={() => setDeleteModalOpen(!deleteModalOpen)}
            handleEditRowAction={handleEditRowAction}
            onSearchChange={_.debounce(onSearchChange, 800)}
            searchValue={searchTerm}
            searchPlaceholder="Search for strategies"
            withSearch
            withDeletePanel={showDeletePanel}
            selectedRowCount={_.size(strategiesSelected)}
            handleDeselectAll={handleDeselectAll}
          />
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth>
        <WppGrid item all={24}>
          <AgGrid
            ref={gridRef}
            rowSelection="multiple"
            onRowSelected={handleRowsSelect}
            columnDefs={StrategiesTableHeader(handleSingleStrategyDelete, setStratIndex, setStrategyModalStatus, showYoutubeGoals)}
            rowMultiSelectWithClick
            rowData={stratgiesToRender}
            suppressRowClickSelection
            className="bulk-create-strategies"
            domLayout="autoHeight"
          />
        </WppGrid>
        <WppGrid item all={24}>
          <WppDeleteConfirmationModal
            // @ts-ignore expecting id in strategiesToDelete
            itemsToDelete={strategiesSelected}
            onCancel={() => setDeleteModalOpen(false)}
            open={deleteModalOpen}
            onDelete={onDelete}
            deleteItemName="Strategies"
            deleting={deleting}
            itemName="strategyName"
            modalName="Delete Strategies"
          />
        </WppGrid>
      </WppGrid>
      {isStrategyModalOpen
        && (
          <StrategyEditModal
            isOpen={isStrategyModalOpen}
            handleOpenModal={handleStrategyModalOpen}
            handleCloseModal={handleStrategyModalClose}
            selectedStratIndex={stratIndex}
            setSelectedStratIndex={setStratIndex}
            updateStrategy={updateStrategy}
            setStrategiesSelected={setStrategiesSelected}
            showYoutubeGoals={showYoutubeGoals}
            dsp={dsp}
          />
        )}
    </>
  );
};

export default StrategiesTable;
