import React, { useRef } from 'react';
import _ from 'lodash';
import { WppIconCopy, WppListItem, WppPopover, WppTypography } from 'buildingBlocks';
import { getHyperLinks } from '../utils';
import { ActionTypes } from '../constants';
import { DEMO_SITES_STYLE } from '../styles';

const { actionButtonDiv, moreItems, popOverContent, textStyles } = DEMO_SITES_STYLE;

export const LinksPopover = ({ domain }: { domain: string }) => {
  const linksToDisplay = getHyperLinks(domain);
  const ref = useRef<HTMLWppPopoverElement>(null);

  const handleCopyLink = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      // Just for logging purpose.
      console.log(err);
    }
  };

  return (
    <WppPopover
      config={{ placement: 'left-end' }}
      ref={ref}
    >
      <WppListItem
        key={ActionTypes.copyLinks}
        selectable
        isExtended
        slot="trigger-element"
        style={moreItems}
      >
        <div slot="label" style={_.omit(actionButtonDiv, 'justifyContent')}>
          <WppIconCopy color="var(--wpp-grey-color-700)" />
          <WppTypography tag="span" type="s-midi">
            {_.startCase(ActionTypes.copyLinks)}
          </WppTypography>
        </div>
      </WppListItem>
      <div style={popOverContent}>
        {_.map(linksToDisplay, ({ link, name }) => (
          <WppListItem key={name} value={link} onWppChangeListItem={() => handleCopyLink(link)}>
            <span style={textStyles} slot="label">{name}</span>
            <WppTypography slot="label" tag="span" type="s-midi" />
          </WppListItem>
        ))}
      </div>
    </WppPopover>
  );
};
