import React from 'react';
import { Advertiser, ColDef } from 'utils/types';
import { WppActionButton, WppIconTrash, WppTypography } from 'buildingBlocks';

const SelectedAvertisersHeader = (disabled: boolean, displayFn: (x: unknown) => string, onClick: Function): Array<ColDef> => [
  {
    field: 'includedAdvertisers',
    headerComponent: () => <WppTypography tag="p" type="s-strong">Included Advertisers</WppTypography>,
    sortable: false,
    cellRenderer: ({ data }: { data: Advertiser }) => displayFn(data),
    flex: 1,
  },
  {
    field: 'action',
    sortable: false,
    headerName: '',
    maxWidth: 120,
    cellStyle: { display: 'flex' },
    cellRenderer: ({ data }: { data: Advertiser }) => (
      <WppActionButton disabled={disabled} slot="right" onClick={() => onClick(data)} variant="destructive">Remove
        <WppIconTrash slot="icon-start" />
      </WppActionButton>
    ),
  },
];

export default SelectedAvertisersHeader;
