import { GET_AUTH_HEADERS, GET_AUTH_HEADERS_COMPLETED, GET_AUTH_HEADERS_FAILED } from './constants';
import { Headers } from './types';

export const getAuthHeaders = () => ({
  type: GET_AUTH_HEADERS,
});

export const getAuthHeadersCompleted = (headers: Headers) => ({
  type: GET_AUTH_HEADERS_COMPLETED,
  payload: headers,
});

export const getAuthHeadersFailed = () => ({
  type: GET_AUTH_HEADERS_FAILED,
});

export default getAuthHeaders;
