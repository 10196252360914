import _ from 'lodash';
import React, { useState, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch } from 'react-redux';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import useMutationObserver from 'utils/hooks/useMutationObserver';
import withRouter, { RouterProps } from 'utils/withRouter';
import { login } from './actions';
import { oktaLoginErrorId, oktaSubmitButtonContainerClass, oktaSubmitButtonIdentifier } from './constants';
import { loginStyles } from './style';
import './style.scss';

const { loginWrapper, oktaContainer, signInMsg } = loginStyles;
const OktaSignIn = require('@okta/okta-signin-widget');

const baseUrl = 'https://wpp.okta.com';
const oktaSignIn = new OktaSignIn({ baseUrl });
const MAX_WINDOW_HEIGHT_BEFORE_RESIZE = 750;

type LoginProps = RouterProps & {
  code: string
};

const Login = ({ code, router }: LoginProps) => {
  const [showSignInMsg, setShowSignInMsg] = useState<boolean>(true);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const dispatch = useDispatch();
  const oktaLoginRef = useRef();

  useMutationObserver(oktaLoginRef, (mutationList, observer) => {
    const mutationListClassNames = _.map(mutationList, 'target.className');

    // check if the mutations to the okta login container include the MFA classes
    // if so, stop showing sign in message and disconnect the mutation observer
    if (_.includes(mutationListClassNames, 'mfa-verify transition-from-right')) {
      setShowSignInMsg(false);
      observer.disconnect();
    }
  });

  useMount(() => {
    if (!code) {
      oktaSignIn.renderEl(
        {
          el: '#okta-login-container',
          features: {
            hideSignOutLinkInMFA: false,
            rememberMe: true,
          },
        },
        (oktaRes) => dispatch(login(oktaRes, router)),
      );

      // add event listener for sign in button click to remove error element if it exists
      oktaSignIn.on('afterRender', () => {
        const submitButton = document.querySelector(oktaSubmitButtonIdentifier);
        submitButton?.addEventListener('click', () => {
          document.getElementById(oktaLoginErrorId)?.remove();
        });
      });

      // add error element to okta container
      oktaSignIn.on('afterError', (_context: {}, error: { message: string }) => {
        const errorElement = document.createElement('p');
        errorElement.id = oktaLoginErrorId;
        errorElement.textContent = error.message;
        document.querySelector(oktaSubmitButtonContainerClass).appendChild(errorElement);
      });
    }

    // handle window resizing to add additional styles to sign in message
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    // clean up function
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div>
      <DocumentTitle title="Login" />
      <div className="login-wrapper" style={loginWrapper}>
        <div id="okta-login-container" className="login-container" style={oktaContainer} ref={oktaLoginRef}>
          {showSignInMsg && (
            <div style={{ ...signInMsg, ...(windowHeight <= MAX_WINDOW_HEIGHT_BEFORE_RESIZE && { top: '109px' }) }}>
              Sign In With Your WPP OKTA Credentials
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
