/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, Header, Input, TextArea, ObjectDropdown, Checkbox } from 'buildingBlocks';
import { useAlgoTypeFetcher } from 'utils/hooks/useAlgoTypeFetcher';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import AlgorithmTypeSelection from './AlgorithmTypeSelection';
import StartDateTime from './StartDateTime';
import EndDateOption from './EndDateOption';
import PublishMsgOptions from './PublishMsgOptions';
import { messageContentHeader } from '../style';
import { Priority, PublishOptions } from '../types';
import SubHeader from './SubHeader';
import { PRIORITIES } from '../constants';

const ValidatedInput = validatedFieldWrapper(Input);
const ValidatedTextArea = validatedFieldWrapper(TextArea);
const ValidatedDropdown = validatedFieldWrapper(ObjectDropdown);

const MessageForm = () => {
  const [allAlgoTypes, error] = useAlgoTypeFetcher(true);
  const { control } = useFormContext();
  const publishOption = useWatch({ name: 'publishOption', control });
  const hasExpirationDate = useWatch({ name: 'hasExpirationDate', control });

  return (
    <Grid.Row>
      <Grid.Column width={12}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4" content="Message Content" style={messageContentHeader} />
            </Grid.Column>
          </Grid.Row>
          <AlgorithmTypeSelection
            // @ts-ignore
            options={allAlgoTypes}
            error={error}
          />
          <Grid.Row columns={4}>
            <SubHeader headerContent="Subject" />
            <Grid.Column>
              <Controller
                name="subject"
                control={control}
                render={(props) => (
                  <ValidatedInput
                    type="text"
                    placeholder="Enter Subject"
                    value={props.field.value}
                    onChange={props.field.onChange}
                    skipIsDirtyCheck
                    {..._.omit(props, 'formState')}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <SubHeader headerContent="Message" />
            <Grid.Column>
              <Controller
                name="content"
                control={control}
                render={(props) => (
                  <ValidatedTextArea
                    type="text"
                    placeholder="Enter your message here..."
                    value={props.field.value}
                    onChange={props.field.onChange}
                    skipIsDirtyCheck
                    {..._.omit(props, 'formState')}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <SubHeader headerContent="Priority" />
            <Grid.Column>
              <Controller
                name="priority"
                control={control}
                render={(props) => (
                  <ValidatedDropdown
                    fluid
                    selection
                    placeholder="Select Priority"
                    options={PRIORITIES}
                    onChange={props.field.onChange}
                    value={props.field.value}
                    keyFn={(p: Priority) => p.name}
                    {..._.omit(props, 'formState')}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <PublishMsgOptions publishOption={publishOption} />
          {_.isEqual(publishOption, PublishOptions.custom) && (<StartDateTime />)}
          <EndDateOption hasExpirationDate={hasExpirationDate} />
          <Controller
            name="isDismissible"
            control={control}
            render={(props) => (
              <Grid.Row columns={4}>
                <SubHeader headerContent="Dismiss Mode" />
                <Grid.Column>
                  <Checkbox
                    label="Message is dismissible by the user"
                    checked={props.field.value}
                    onChange={props.field.onChange}
                    {..._.omit(props, 'formState')}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          />
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export default MessageForm;
