import _ from 'lodash';
import React from 'react';
import { WppEmptyContent, WppGrid, WppSpinner, WppTypography } from 'buildingBlocks';
import { candidateLoaderStyles } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { candidateOptions } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/style';
import FlightCandidatesTable from './FlightCandidatesTable';

const FlightCandidatesContainer = () => {
  const { flightCandidateInfo } = useBulkCreateAttachModalContext();
  const { flightCandidates, loadingFlightCandidates } = flightCandidateInfo;

  if (loadingFlightCandidates) {
    return (
      <WppGrid container fullWidth justifyContent="center" alignItems="center" style={candidateLoaderStyles.loadingGrid}>
        <WppSpinner size="m" />
        <div>Loading flight candidates...</div>
      </WppGrid>
    );
  }

  if (!_.size(flightCandidates)) {
    return (
      <WppGrid container fullWidth justifyContent="center" alignItems="center">
        <div style={candidateOptions.container}>
          <WppEmptyContent width={150} />
          <WppTypography type="s-strong" tag="p">No results found</WppTypography>
          <WppTypography type="s-body" tag="p">
            Try adjusting your search or filter options to find what you&apos;re looking for
          </WppTypography>
        </div>
      </WppGrid>
    );
  }

  return (
    <FlightCandidatesTable
      flightCandidates={flightCandidates}
    />
  );
};

export default FlightCandidatesContainer;
