import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  WppExpandableCard, WppTypography, WppActionButton, WppTextareaInput, WppSpinner,
} from 'buildingBlocks';
import { selectResult, downloadResult } from '../actions';
import { DashboardStateType } from '../types';
import {
  resultListContainer, resultAccordianStyle, resultAccordianContentStyle, accordianLoaderStyle, accordianTextareaStyle,
} from '../style';

const ResultLoader = ({ result, accordianExpanded }: { result: { data: any }, accordianExpanded: boolean }) => {
  const active = _.isNil(result.data);

  return (
    <div style={accordianLoaderStyle}>
      {(active && accordianExpanded) && (
        <WppSpinner label="Loading..." />
      )}
      {!(active && accordianExpanded) && (
        <WppTextareaInput
          name="log"
          value={JSON.stringify(result.data, null, 2)}
          style={accordianTextareaStyle}
        />
      )}
    </div>
  );
};

const ResultList: React.FC = () => {
  const dispatch = useDispatch();

  const { selectedRun, selectedTask } = useSelector((state: { dashboard: DashboardStateType }) => state.dashboard);

  const [accordianExpanded, setAccordianExpanded] = useState<boolean>(false);

  const onTitleClick = (event, index: number) => {
    setAccordianExpanded(event.detail.expanded);
    if (event.detail.expanded) {
      dispatch(selectResult(selectedRun, selectedTask, selectedTask.results[index], index));
      setAccordianExpanded(true);
    }
  };

  const onDownloadClick = (run: any, task: any, result: any) => (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(downloadResult(run, task, result));
  };

  const showAccordion = !_.isEmpty(selectedTask?.results);
  const showNoResultsMessage = _.isEmpty(selectedTask?.results);

  return (
    <div style={resultListContainer}>
      {showAccordion && (
        _.map(selectedTask.results, (result, index) => (
          <WppExpandableCard
            size="m"
            variant="secondary"
            key={result.url}
            onWppChange={(event) => onTitleClick(event, _.toNumber(index))}
            style={resultAccordianStyle}
          >
            <WppTypography type="m-strong" slot="header">{result.shortName(selectedTask.s3DirName)}</WppTypography>
            <div style={resultAccordianContentStyle}>
              <WppTypography type="xs-body">{result.url}</WppTypography>
              <ResultLoader result={result} accordianExpanded={accordianExpanded} />
            </div>
            <WppActionButton variant="secondary" slot="actions" onClick={onDownloadClick(selectedRun, selectedTask, result)}>
              Download
            </WppActionButton>
          </WppExpandableCard>
        ))
      )}
      {showNoResultsMessage && <WppTypography type="s-body">No results for this task.</WppTypography>}
    </div>
  );
};

export default ResultList;
