import { CSSProperties } from 'react';

export const HEALTH_CARE_STYLES: { [key: string]: CSSProperties } = {
  tabStyle: {
    width: 'fit-content',
  },
  healthTableGrid: {
    marginTop: '13px',
  },
  healthTableStyle: {
    margin: '0px 8px',
  },
};
