import _ from 'lodash';
import moment from 'moment';
import { MessageAlgoType, PublishOptions } from './types';

export const getUTCDateTime = (date, time) => {
  const hour = _.toNumber(moment(new Date(time)).format('HH'));
  const min = _.toNumber(moment(new Date(time)).format('mm'));
  const m = moment(date).set({ h: hour, m: min });
  return m.utc().toISOString();
};

export const handleDisabledMins = () => _.filter(_.range(0, 60), (m) => m % 15 !== 0);

export const getPublishOption = (createdAt: string, startDate: string) => {
  if (moment(startDate).isSameOrBefore(createdAt)) {
    return PublishOptions.immediately;
  }
  if (moment(startDate).diff(createdAt, 'hour') === 1) {
    return PublishOptions.oneHour;
  }
  return PublishOptions.custom;
};

export const getAlgoTypeIds = (hasSelectedAll: boolean, algoTypes: Array<MessageAlgoType>) => {
  if (_.isEmpty(algoTypes)) {
    return null;
  }
  if (hasSelectedAll) {
    return _.toString(_.flatMap(algoTypes, 'ids'));
  }
  return _.toString(_.map(algoTypes, 'id'));
};
