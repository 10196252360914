export const BRANDS_STARTING_FETCH_LIMIT = 10;
export const BRANDS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const BRAND_NOT_FOUND_ERROR = 'Brand is deleted or does not exist.';

export const brandsHelpCenterLink = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001232233';
export const whatIsABrandHelpCenterLink = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001232232-what-is-a-brand';

export const INITIAL_BRANDS_TO_DELETE_FORM_VALUES = {
  brandsToDelete: {},
};

export const INITIAL_BRAND_DETAILS_FORM_VALUES = {
  name: '',
  advertisers: [],
};
