import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const modalContainer: { [key: string]: CSSProperties | any } = {
  label: {
    marginBottom: SPACING[4],
    marginTop: SPACING[12],
  },
  filterContainer: {
    display: 'flex',
    gap: SPACING[12],
    justifyContent: 'flex-start',
    padding: '0px 8px 8px',
  },
};

export const filterButton: { [key: string]: CSSProperties | any } = {
  filterContainerStyle: {
    marginTop: SPACING[16],
    width: '220px !important',
  },
  addFilterButton: {
    marginBottom: SPACING[10],
  },
  filterOptionsList: {
    padding: SPACING[10],
    maxWidth: '250px',
  },
  selectedFilters: {
    marginTop: SPACING[20],
  },
  dateFieldContainerStyle: {
    width: '30%',
  },
};

export const candidateOptions: { [key: string]: CSSProperties | any } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[24],
    alignItems: 'center',
    marginTop: SPACING[60],
    marginLeft: '200px',
  },
};

export const attachedFlightsTable: { [key: string]: CSSProperties | any } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
  },
  attachButton: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    width: '211px',
    height: SPACING[32],
    marginTop: SPACING[24],
  },
  editButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    flex: '1',
    maxWidth: '300px',
  },
  searchAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
  },
};
