import React, { useRef } from 'react';
import _ from 'lodash';
import { WppIconEdit, WppIconMore, WppIconTrash, WppListItem, WppPopover } from 'buildingBlocks';
import { DEMO_SITES_STYLE } from 'containers/DemoSites/styles';
import { ActionTypes } from '../constants';
import { strategyTableHeader } from '../style';

const {
  hyperLinkStyles,
  popOverContent,
  moreItems,
} = DEMO_SITES_STYLE;

const { actionButtonDiv } = strategyTableHeader;

type MoreActionsPopoverProps = {
  handleDelete: (id: number) => void
  setSelectedStratIndex: (id: Array<number>) => void
  setStrategyModalStatus: (state: boolean) => void
  id: number
  rowIndex: number
};

export const MoreActionsPopover = ({ id, handleDelete, setSelectedStratIndex, setStrategyModalStatus, rowIndex }: MoreActionsPopoverProps) => {
  const ref = useRef<HTMLWppPopoverElement>(null);

  const handleEditClick = () => {
    setSelectedStratIndex([rowIndex]);
    setStrategyModalStatus(true);
  };

  return (
    <WppPopover
      config={{ placement: 'left-end' }}
      closable={false}
      ref={ref}
    >
      <WppIconMore
        slot="trigger-element"
        color="var(--wpp-grey-color-700)"
        style={hyperLinkStyles}
      />
      <div style={popOverContent}>
        <WppListItem
          key={ActionTypes.delete}
          style={moreItems}
          onWppChangeListItem={() => handleDelete(id)}
        >
          <p slot="label" style={actionButtonDiv}>
            <WppIconTrash color="var(--wpp-grey-color-700)" />
            <span>{_.capitalize(ActionTypes.delete)}</span>
          </p>
        </WppListItem>
        <WppListItem
          key={ActionTypes.edit}
          style={moreItems}
          onWppChangeListItem={handleEditClick}
        >
          <p slot="label" style={actionButtonDiv}>
            <WppIconEdit color="var(--wpp-grey-color-700)" />
            <span>{_.capitalize(ActionTypes.edit)}</span>
          </p>
        </WppListItem>
      </div>
    </WppPopover>
  );
};
