import _ from 'lodash';
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { WppListItem, WppSelect } from 'buildingBlocks';
import { Advertiser as AdvertiserAPI } from 'utils/copilotAPI';
import { Advertiser, SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { useAsyncEffect } from 'utils/functionHelpers';
import { RESULTS_LIMIT } from 'constantsBase';

type AdvertiserFilterProps = {
  filterAdvertiser: Array<number>
  handleAdvertiserChange: (advertiserIds: Array<number>) => void
  disabled: boolean
};

const AdvertiserFilter = (props: AdvertiserFilterProps) => {
  const { handleAdvertiserChange, filterAdvertiser, disabled } = props;

  const [advertisers, setAdvertisers] = useState<Array<Advertiser>>([]);
  const [advertiserFetchError, setAdvertiserFetchError] = useState<string>('');
  const [loadingAdvertisers, setLoadingAdvertiser] = useState<boolean>(false);
  const member = useWatch({ name: 'member' });

  const shouldDisable = !_.isEmpty(advertiserFetchError) || disabled;
  const displayText = (loadingAdvertisers || disabled) ? 'Advertisers' : (advertiserFetchError || 'Advertisers');

  useAsyncEffect(async () => {
    if (member) {
      setLoadingAdvertiser(true);
      try {
        const res = await AdvertiserAPI.get({
          where: { member: member.id },
          sort: 'updatedAt DESC',
          limit: RESULTS_LIMIT,
          populate: 'defaultCurrency',
        });
        setAdvertisers(res.data);
      } catch {
        setAdvertiserFetchError('Failed to fetch advertisers');
      } finally {
        setLoadingAdvertiser(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member.id]);

  const handleOnChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    handleAdvertiserChange(event.target.value);
  };

  return (
    <WppSelect
      type="multiple"
      value={filterAdvertiser}
      onWppChange={handleOnChange}
      size="s"
      loading={loadingAdvertisers}
      placeholder={displayText}
      style={{ width: 363 }}
      disabled={shouldDisable}
      withSearch
    >
      {_.map(advertisers, (adv) => {
        const { id, externalId, name } = adv;
        return (
          <WppListItem key={`${id}-${externalId}`} value={adv.id}>
            <p slot="label">{`[${externalId}] - ${name}`}</p>
          </WppListItem>
        );
      })}
    </WppSelect>
  );
};

export default AdvertiserFilter;
