import _ from 'lodash';
import { Dispatch } from 'react';
import { Action } from 'redux';
import { NavigateFunction } from 'react-router';
import { GOAL_TYPES, RESULTS_LIMIT, StrategySource, apiEnums } from 'constantsBase';
import { buildFlightName } from 'containers/StrategyWizard/utils';
import { BulkCreateWizardFormValues } from 'containers/BulkCreateStrategyWizard/types';
import { Brand, Currency, BulkCreateStrategy, BulkStrategy, Advertiser } from 'utils/types';
import { getConfigToSave } from 'containers/StrategyWizard/steps/StrategyConfirmation/utils';
import { Advertiser as AdvertiserApi, AdvertiserBrand, Currency as CurrencyApi, Strategy } from 'utils/copilotAPI';
import { numberOrUndefined } from 'utils/functionHelpers';
import { toSnakeCase } from 'utils/formattingUtils';
import { saveBulkStrategyInProgress } from 'containers/StrategiesList/actions';
import { BulkCreateStrategyType } from './types';

export const getAdvertiserById = async (advertiserId) => {
  const advertiserRes = await AdvertiserApi.get({
    id: advertiserId,
    populate: [apiEnums.defaultCurrency],
  });
  return _.get(advertiserRes, 'data[0]');
};

const saveStrategies = (strategies: Array<BulkCreateStrategy>, navigate: NavigateFunction, dispatch: Dispatch<Action>) => {
  Strategy.bulkCreateConfiguration(strategies);
  dispatch(saveBulkStrategyInProgress());
  navigate('/');
};

type AdvertiserBrandCurrencyMap = Record<number, {
  brand: Brand
  advertiser: Advertiser
  currency: {
    code: string
    symbol: string
  }
}>;

export const getBrandByAdvertiserId = async (
  advertiserIds: Array<number>,
  currencyIds: Array<number>,
) => {
  try {
    const [brandsArrRes, currenyRes] = await Promise.all([
      AdvertiserBrand.get({
        where: { advertiser: advertiserIds },
        populate: ['brand', 'advertiser'],
        limit: RESULTS_LIMIT,
      }),
      CurrencyApi.get({
        where: { id: currencyIds },
        limit: RESULTS_LIMIT,
      }),
    ]);

    const brandAdvertisers = _.get(brandsArrRes, 'data', []);
    const currencies = _.get(currenyRes, 'data', []);

    // Build a map for quick currency lookup by ID
    const currencyMap = _.keyBy(currencies, 'id');

    const result = brandAdvertisers.reduce((acc, item) => {
      const advertiserId = item.advertiser.id;
      const advertiser = item.advertiser;
      const brand = item.brand;

      const currency = currencyMap[advertiser.defaultCurrency];

      acc[advertiserId] = {
        brand,
        advertiser,
        currency: {
          code: currency?.code || '',
          symbol: currency?.symbol || '',
        },
      };

      return acc;
    }, {} as AdvertiserBrandCurrencyMap);

    return result;
  } catch (err) {
    console.error('Failed to fetch brand/advertiser/currency:', err);
    return null;
  }
};

// transform strategy goal values from what the form expects to what the database expects
export const transformStrategyGoals = (strategy: BulkCreateStrategyType) => {
  const { goal } = strategy;
  const type = goal.type;

  const primaryGoalObj = {
    type,
    target: numberOrUndefined(goal, 'target'),
  };

  let strategyGoals = [
    primaryGoalObj,
  ];

  if (_.get(strategy, 'viewability.enabled')) {
    strategyGoals = [...strategyGoals, {
      type: GOAL_TYPES.ivrMeasured.value,
      target: _.toNumber(_.get(strategy, 'viewability.target')),
    }];
  }

  return _.map(strategyGoals, (g) => ({ ...g, type: toSnakeCase(g.type) }));
};

export const createStrategy = (
  wizardForm: BulkCreateWizardFormValues,
  strategy: BulkCreateStrategyType,
  configToSave: {},
  brand: number,
): { [key: string]: any } => {
  const {
    strategyConfigurationStep,
  } = wizardForm;
  const strategyGoals = transformStrategyGoals(strategy);

  const memberId = _.get(wizardForm.attachFlightsStep, 'member.id', null);
  const strategyToSave = {
    name: strategy.strategyName,
    strategyGoals,
    strategyType: wizardForm.strategyType.id,
    config: configToSave,
    member: memberId,
    advertiser: strategy.advertiser,
    brand,
  };

  const additionalAlgos = _.get(strategyConfigurationStep, 'additionalAlgos');
  // additionalAlgos is used by admins to activate the MAB
  return additionalAlgos
    ? { ...strategyToSave, additionalAlgos }
    : strategyToSave;
};

// update form values with revenue, pixel and strategy goals info
export const modifyFormValues = (strategy, strategyConfigurationValues) => {
  const impValueFilters = null;
  const cyodEnabled = false;
  const budgetSettings = {};
  const strategyGoals = transformStrategyGoals(strategy);
  const modifiedFormValues = { ...strategyConfigurationValues, strategyGoals, impValueFilters, budgetSettings, cyodEnabled };

  return modifiedFormValues;
};

/**
 * handles saving of the Bulk create strategies. regarding the flights we want to send to the API:
 * @param wizardForm the final values of the wizard form
 */
export const handleBulkCreateWizardSubmit = async (
  wizardForm: BulkCreateWizardFormValues,
  navigate: NavigateFunction,
  updateAdminConfig: boolean,
  dispatch: Dispatch<Action>,
) => {
  const {
    strategyType,
    strategyConfigurationStep: strategyConfigurationValues,
    strategyConfirmationStep: { strategies },
  } = wizardForm;
  const member = _.get(wizardForm.attachFlightsStep, 'member');
  const uniqueAdvertisers = _.uniq(_.map(strategies, 'advertiser'));
  const uniqueCurrencies = _.uniq(_.map(strategies, 'currency'));
  const brandAdvertisers = await getBrandByAdvertiserId(uniqueAdvertisers, uniqueCurrencies);

  const bulkStrategiesToSave = await Promise.all(
    strategies.map(async (strategy) => {
      const { flight, dailyParentBudgetInflationRatio, viewability, intelligentChildObjects } = strategy;
      const flightsToSave = { addedFlights: [flight] };
      const updatedConfigValues = {
        ..._.cloneDeep(strategyConfigurationValues),
        dailyParentBudgetInflationRatio,
        viewability,
        intelligentChildObjects,
      };
      const modifiedStratConfigFormValues = modifyFormValues(strategy, updatedConfigValues);
      const advertiserData = brandAdvertisers?.[flight.advertiser];
      const defaultCurrency = advertiserData?.currency as Currency;

      const configToSave = getConfigToSave(
        strategyType.id,
        modifiedStratConfigFormValues,
        member,
        advertiserData?.advertiser,
        advertiserData?.brand,
        defaultCurrency,
        [flight],
        updateAdminConfig,
        false,
      );

      const strategyToSave = createStrategy(wizardForm, strategy, configToSave, advertiserData?.brand?.id) as BulkStrategy;
      return {
        strategy: {
          ...strategyToSave,
          strategySource: StrategySource.bulkCreateStrategy,
        },
        ...flightsToSave,
      };
    }),
  );

  saveStrategies(bulkStrategiesToSave, navigate, dispatch);
};

const formatDateRange = (startDate, endDate): string => `${new Date(startDate).toLocaleDateString('en-US')} - ${new Date(endDate).toLocaleDateString('en-US')}`;

export const getStrategiesData = async (wizardFormValues: BulkCreateWizardFormValues) => {
  try {
    const {
      attachFlightsStep: { attachedFlights },
      goalSelectionStep: { goal },
      // @ts-ignore strategyConfigurationStep step values mismatch
      strategyConfigurationStep: { dailyParentBudgetInflationRatio, viewability, intelligentChildObjects },
    } = wizardFormValues;

    const strategies: Array<BulkCreateStrategyType> = await Promise.all(
      _.map(attachedFlights, async (flight, index) => ({
        tempId: index,
        strategyName: buildFlightName(flight),
        object: buildFlightName(flight),
        goal,
        dateRange: formatDateRange(flight.startDate, flight.endDate),
        dailyParentBudgetInflationRatio,
        viewability,
        intelligentChildObjects,
        dsp: flight.dsp,
        currency: flight.currency,
        budget: flight.budgetAmount,
        advertiser: flight.advertiser,
        flight,
      })),
    );
    return strategies;
  } catch (e) {
    console.error('Error in getStrategiesData:', e);
    return [];
  }
};

export const showPacingError = (pacing: number) => !_.isNumber(pacing) || !_.inRange(pacing, 0.5, 1.5);
