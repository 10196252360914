import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Accordion, Button, Dimmer, Header, Loader, Modal, Segment, Grid,
} from 'buildingBlocks';
import { fetchLog, selectResult, downloadResult } from '../actions';
import {
  preStyle, accordionTitleStyle, resultTitleStyle, s3DirNameStyle,
} from '../style';

type ResultListProps = {
  fetchLog: Function,
  downloadResult: Function,
  selectedTask: {
    results: {},
    name: string,
    logResult: any
  },
  selectedRun: {},
  selectedResultIndices: Array<number>,
  selectResult: Function,
};

type ResultLoaderProps = {
  result: {
    data: {}
  }
};

const ResultLoader = (props: ResultLoaderProps) => {
  const result = props.result;
  const active = _.isNil(result.data);
  return (
    <Segment>
      <Dimmer active={active} inverted>
        <Loader />
      </Dimmer>
      <pre style={preStyle}>
        {JSON.stringify(result.data, null, 2)}
      </pre>
    </Segment>
  );
};

type ResultListState = {
  open: boolean
};

const resultPanels = (run, task, onDownloadClick) => {
  if (_.isNil(task)) {
    return [];
  }
  return _.map(
    task.results,
    (result) => ({
      content: { content: (<ResultLoader result={result} />), key: `${result.url}-load` },
      title: {
        content: (
          <Grid fluid="true" style={accordionTitleStyle}>
            <Grid.Row style={{ paddingBottom: 'initial' }}>
              <Grid.Column textAlign="left" style={resultTitleStyle}>
                {result.shortName(task.s3DirName)}
                <Button primary size="mini" floated="right" onClick={onDownloadClick(run, task, result)}>
                  Download
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 'initial' }}>
              <Grid.Column textAlign="left" style={s3DirNameStyle}>
                {result.url}
              </Grid.Column>
            </Grid.Row>
          </Grid>),
      },
      key: result.url,
    }),
  );
};

class ResultList extends Component<ResultListProps, ResultListState> {
  constructor(props: ResultListProps) {
    super(props);

    this.onLogLoad = this.onLogLoad.bind(this);
    this.onTitleClick = this.onTitleClick.bind(this);

    // Modal state handling.
    this.state = { open: false };
  }

  close = () => this.setState({ open: false });

  // eslint-disable-next-line react/no-unused-class-component-methods
  show = () => this.setState({ open: true });

  onTitleClick = (run, task) => (_event, { index }) => {
    this.props.selectResult(run, task, task.results[index], index);
  };

  onDownloadClick = (run, task, result) => (event) => {
    event.stopPropagation();
    this.props.downloadResult(run, task, result);
  };

  onLogLoad = (run, task) => () => {
    this.props.fetchLog(run, task);
    this.setState({ open: true });
  };

  render() {
    const {
      selectedResultIndices,
      selectedRun: run,
      selectedTask: task,
    } = this.props;

    const panels = resultPanels(run, task, this.onDownloadClick);
    const open = this.state.open;

    const showAccordion = !_.isEmpty(task.results);
    const showNoResultsMessage = _.isEmpty(task.results);

    return (
      <div>
        <Header as="h3" attached="top">
          <Button primary size="mini" floated="right" onClick={this.onLogLoad(run, task)} content="Show Log" />
          Task: {task.name}
        </Header>
        <Segment attached>
          {showAccordion
            && (
              <Accordion
                exclusive={false}
                styled
                activeIndex={selectedResultIndices}
                style={{ width: '100%' }}
                onTitleClick={this.onTitleClick(run, task)}
                panels={panels}
              />
            )}
          {showNoResultsMessage && <span>No results for this task.</span>}
          <Modal onClose={this.close} size="fullscreen" open={open}>
            <Modal.Content>
              <pre style={preStyle}>
                {task.logResult}
              </pre>
            </Modal.Content>
          </Modal>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedRun: state.dashboard.selectedRun,
  selectedResultIndices: state.dashboard.selectedResultIndices,
  selectedTask: state.dashboard.selectedTask,
});

export default connect(mapStateToProps, { downloadResult, selectResult, fetchLog })(ResultList);
