import { PossibleMoment } from 'utils/dateTime';
import { AlgorithmType, ButtonClickHandler } from 'utils/types';

export enum PublishOptions {
  immediately,
  oneHour,
  custom,
}

type SelectAllOption = {
  name: string
  ids?: Array<number>
};

export type MessageAlgoType = AlgorithmType | SelectAllOption;

export type MessageFormObj = {
  algoTypes: Array<MessageAlgoType>
  subject: string
  content: string
  startDate: PossibleMoment
  startTime: string
  hasExpirationDate: boolean
  isDismissible: boolean
  priority: Priority | {}
  publishOption: PublishOptions
  endDate?: PossibleMoment
};

export type Option = {
  text: string
  value: string
};

export type Priority = {
  name: string
  value: number
};

export type TriggerButtonProps = {
  onClick: ButtonClickHandler
  disabled?: boolean
};
