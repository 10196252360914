import moment from 'moment';
import { roundToNearest15Min } from 'utils/dateTime';
import {
  SUBMIT_FORM,
  SUBMIT_FORM_COMPLETED,
  SUBMIT_FORM_FAILED,
  FETCH_MESSAGE_COMPLETED,
  FETCH_MESSAGE,
  FETCH_MESSAGE_FAILED,
  CLEAR_FORM_STATE,
} from './constants';
import { PublishOptions } from './types';

export const INITIAL_STATE = {
  formSubmitting: false,
  loadedMessageData: {
    algoTypes: [],
    subject: '',
    content: '',
    startDate: moment(),
    startTime: roundToNearest15Min(),
    endDate: null,
    hasExpirationDate: false,
    isDismissible: true,
    priority: null,
    publishOption: PublishOptions.immediately,
  },
  loadingMessage: false,
  fetchError: false,
  fetchErrorMsg: '',
  submitError: false,
  messageContextId: '',
};

function messageCreatorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUBMIT_FORM:
      return {
        ...state,
        formSubmitting: true,
      };
    case SUBMIT_FORM_COMPLETED:
      return {
        ...state,
        formSubmitting: false,
        loadedMessageData: INITIAL_STATE.loadedMessageData,
      };
    case SUBMIT_FORM_FAILED:
      return {
        ...state,
        loadingMessage: false,
        submitError: true,
        loadedMessageData: INITIAL_STATE.loadedMessageData,
      };
    case FETCH_MESSAGE_COMPLETED:
      return {
        ...state,
        loadedMessageData: {
          ...action.payload,
        },
        loadingMessage: false,
        messageContextId: action.payload.messageContextId,
      };
    case FETCH_MESSAGE:
      return {
        ...state,
        loadingMessage: true,
      };
    case FETCH_MESSAGE_FAILED:
      return {
        ...state,
        fetchError: true,
        loadingMessage: false,
        fetchErrorMsg: action.payload.fetchErrorMsg,
      };
    case CLEAR_FORM_STATE:
      return {
        ...state,
        loadedMessageData: INITIAL_STATE.loadedMessageData,
      };
    default:
      return state;
  }
}

export default messageCreatorReducer;
