import { AgGridReact } from 'ag-grid-react';
import React, { useRef } from 'react';
import { AgGrid } from 'buildingBlocks';
import { CandidateTableDisplay } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { tableStyles } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { onFlightSelect } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/utils';
import { Flight } from 'utils/types';
import FlightCandidatesTableColumns from './FlightCandidatesTableColumns';

type FlightCandidatesTableProps = {
  flightCandidates: Array<Flight>
};

const FlightCandidatesTable = ({
  flightCandidates,
}: FlightCandidatesTableProps) => {
  const gridRef = useRef<AgGridReact>();
  const { candidateDisplay, sessionAttachFlights, setSessionAttachFlights } = useBulkCreateAttachModalContext();

  const rowData = candidateDisplay === CandidateTableDisplay.all ? flightCandidates : sessionAttachFlights;

  return (
    <div style={tableStyles.div}>
      <div data-testid="ag-grid-table" className="ag-theme-wpp flight-candidates-table" style={tableStyles.container}>
        <AgGrid
          ref={gridRef}
          rowSelection="multiple"
          columnDefs={FlightCandidatesTableColumns()}
          localeText={{ noRowsToShow: 'No Objects Selected' }}
          rowData={rowData}
          // handles selection for checkbox as well as row being clicked
          onRowClicked={(e) => onFlightSelect(e.data, setSessionAttachFlights)}
        />
      </div>
    </div>
  );
};

export default FlightCandidatesTable;
