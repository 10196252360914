import _ from 'lodash';
import React from 'react';
import { WppTableBodyRow, WppTableBodyCell, WppTypography, WppIconInfo, WppIconWarning, WppSegmentedControl, WppSegmentedControlItem, WppTooltip } from 'buildingBlocks';
import { FlightAlreadyAttachedSelection } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/constants';
import { Flight } from 'utils/types';

type AttachedToAnotherStrategyFlightRowProps = {
  flight: Flight & { strategyName?: string }
  onFlightSelectionChange: (x: Flight) => void
  isIncluded: boolean
};

export const AttachedToAnotherStrategyFlightRow = ({ flight, onFlightSelectionChange, isIncluded }: AttachedToAnotherStrategyFlightRowProps) => (
  <WppTableBodyRow className="wpp-table-body-row already-attached-table-row">
    <WppTableBodyCell className="wpp-table-body-cell flight-name">
      <div className="flight-name-div">
        {isIncluded ? (
          <WppTooltip
            warning
            text="The object will be detached from existing strategy and attach to the new one."
            config={{ placement: 'right' }}
          >
            <WppIconWarning />
          </WppTooltip>
        ) : (
          <WppTooltip
            text="The object will not be included in the strategy creation."
            config={{ placement: 'right' }}
          >
            <WppIconInfo />
          </WppTooltip>
        )}
        <WppTypography type="s-body" tag="p">{` [${flight.externalId}] - ${flight.name}`}</WppTypography>
      </div>
    </WppTableBodyCell>
    <WppTableBodyCell className="wpp-table-body-cell strategy-name">
      <WppTypography type="s-body" tag="p">{flight.strategyName}</WppTypography>
    </WppTableBodyCell>
    <WppTableBodyCell className="wpp-table-body-cell row-selection">
      <WppSegmentedControl
        value={isIncluded ? FlightAlreadyAttachedSelection.include : FlightAlreadyAttachedSelection.exclude}
      >
        {_.map(FlightAlreadyAttachedSelection, (selection) => (
          <WppSegmentedControlItem key={selection} value={selection} onClick={() => onFlightSelectionChange(flight)}>
            {_.startCase(selection)}
          </WppSegmentedControlItem>
        ))}
      </WppSegmentedControl>
    </WppTableBodyCell>
  </WppTableBodyRow>
);

export default React.memo(AttachedToAnotherStrategyFlightRow);
