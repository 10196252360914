import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { dateTimeFormatter } from 'utils/dateTime';
import 'moment-precise-range-plugin';
import { Button, Header, Icon, Label, Segment, Table } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { Flight, Strategy } from 'utils/types';
import { Run } from '../types';

enum AirflowStatus {
  success = 'SUCCESS',
  running = 'RUNNING',
  failed = 'FAILED',
  cancelled = 'CANCELLED',
  pending = 'PENDING',
  noData = 'NO_DATA',
}

type Props = {
  run: Run
  flight: Flight
  strategy: Strategy
};

const stratTypeLabel = (run: Run) => run.strategyType && run.strategyType.displayName;
const algoTypeLabel = (run: Run) => run.algorithmType && run.algorithmType.name;
const goalLabel = (strategy: Strategy) => _.camelCase(_.get(_.find(strategy.strategyGoals, { active: true, priority: 1 }), 'type'));
const memberLabel = (run: Run) => run.member && `${run.member.displayName} (${run.member.externalId})`;

const tableRow = ([label, data], index) => (
  <Table.Row key={index}>
    <Table.Cell> <Header as="h5"> {label} </Header> </Table.Cell>
    <Table.Cell> {data} </Table.Cell>
  </Table.Row>
);

const RunDetail = (props: Props) => {
  const { flight, run, strategy } = props;

  if (_.isNil(run)) {
    return <span>No runs loaded.</span>;
  }

  const { id, createdAt, updatedAt, strategyCreatedBy, strategyLastUpdatedBy, triggerType, status, algorithm, advertiser, result, algorithmType } = run;

  const createdFull = dateTimeFormatter.dateTime(createdAt, true);
  const updatedFull = dateTimeFormatter.dateTime(updatedAt, true);
  const elapsed = moment.preciseDiff(moment(createdAt), moment(updatedAt));
  const strategyUrl = `/strategies/${strategy.id}`;
  const flightTitle = _.isEmpty(flight) ? '' : `${flight.name} (${flight.id})`;
  const flightExternalId = _.isEmpty(flight) ? '' : flight.externalId;
  const createdBy = _.get(strategyCreatedBy, 'email', '');
  const updatedBy = _.get(strategyLastUpdatedBy, 'email', '');
  const trigger = triggerType ? 'User Initiated' : 'Programmatic';
  const airflowDisabled = _.includes([AirflowStatus.cancelled, AirflowStatus.pending], status);
  const dagUrl = _.get(result, 'dag_run_url');

  const recentSfrParams = {
    strategy: [strategy.id],
    algorithmType: [_.get(algorithmType, 'id', '')],
  };
  const recentSfrUrl = `/strategyflightruns?${qs.stringify(recentSfrParams)}`;

  const tableBody = [
    ['Type', <Label basic> {stratTypeLabel(run)} </Label>],
    ['Status', status],
    ['Created', <span title={createdFull}> {createdFull} </span>],
    ['Updated', <span title={updatedFull}> {updatedFull} </span>],
    ['Elapsed', elapsed],
    ['Advertiser', `${_.get(advertiser, 'name')} (${_.get(advertiser, 'externalId')})`],
    ['Member', memberLabel(run)],
    ['Strategy', <Link to={strategyUrl}> {strategy.id} </Link>],
    ['Goal', _.get(GOAL_TYPES, `${goalLabel(strategy)}.strategyWizardLongText`)],
    ['Strategy Created By', <span title={createdBy}> {createdBy} </span>],
    ['Strategy Last Updated By', <span title={updatedBy}> {updatedBy} </span>],
    ['Algorithm', algorithm],
    ['Algorithm Type', <Label basic> {algoTypeLabel(run)} </Label>],
    ['Flight',
      <span style={{ wordWrap: 'break-word' }} title={flightTitle}>
        {flightTitle} - {flightExternalId}
      </span>],
    ['Trigger Type', trigger],
    ['Recent SFR', <Link to={recentSfrUrl}> Recent SFR </Link>]].map(tableRow);

  return (
    <div>
      <Header as="h3" attached="top">
        Run #{id}
        <Button primary floated="right" size="mini" target="_blank" href={dagUrl} disabled={airflowDisabled}>
          {airflowDisabled ? status : 'Airflow'} &nbsp;
          <Icon name="external" />
        </Button>
      </Header>
      <Segment attached>
        <Table basic="very" fixed>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  run: state.dashboard.selectedRun,
  flight: state.dashboard.selectedFlight,
  strategy: state.dashboard.strategy,
});

export default connect(mapStateToProps)(RunDetail);
