import _ from 'lodash';
import React, { Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import qs from 'qs';
import WppPageTemplate from 'components/PageTemplate/WppPageTemplate';
import { WppPagination, WppTypography, WppEmptyNothingFound } from 'buildingBlocks';
import { NotificationMsgObject } from 'components/Notification/types';
import { Action, State as FilterState, NotificationStates } from 'containers/NotificationCenter/types';
import { resetPagination, updatePagination } from 'containers/NotificationCenter/actions';
import { noNotificationsStyle, spinnerStyle, nothingFoundStyle, paginationStyle } from 'containers/NotificationCenter/style';
import { ISO_DATE } from 'utils/dateTime';
import { usePrevious } from 'utils/hooks/usePrevious';
import { Brand, PaginationChangeEventDetail } from 'utils/types';
import { PAGE_NAME } from 'containers/NotificationCenter/NotificationCenter';
import PageLoading from 'components/PageLoading';
import NotificationsFilters from '../Filters/NotificationsFilters';
import NotificationTable from './NotificationTable';

const NO_RESULT_MSG = 'No Notifications';
const ELEMENTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const LOADING_MSG = 'Loading Notifications';

type NotificationsPageProps = {
  pageName: string
  filterState: FilterState
  dispatch: Dispatch<Action>
  notifications: Array<NotificationMsgObject>
  brandCandidates: Array<Brand>
  loadingBrands: boolean
  brandError: string
  notificationState: string
};

const NotificationsPage = ({
  pageName,
  filterState,
  dispatch,
  notifications,
  brandCandidates,
  loadingBrands,
  brandError,
  notificationState,
}: NotificationsPageProps) => {
  const navigate = useNavigate();
  const prevFilter = usePrevious(filterState);

  const {
    selectedCategoryFilter,
    dateRange,
    selectedBrands,
    unreadOnly,
    viewFilter,
    elementsPerPage,
    pageNumber,
    numberOfElements,
    skip,
    sortOrder,
  } = filterState;

  const updateBrowserUrl = () => {
    const [startDate, endDate] = dateRange.split(' - ');
    const brandIds = _.map(selectedBrands, 'id');
    const startDateISO = moment(startDate).format(ISO_DATE);
    const endDateISO = moment(endDate).format(ISO_DATE);

    const filter = {
      ..._.omit(filterState, ['selectedBrands', 'dateRange']),
      brands: brandIds,
      startDate: startDateISO,
      endDate: endDateISO,
    };

    const qsParam = qs.stringify(filter);
    navigate({ search: qsParam });
  };

  const getOnlyFilters = (filterObj) => _.omit(filterObj, ['elementsPerPage', 'pageNumber', 'skip']);

  useEffect(() => {
    if (!_.isEmpty(prevFilter) && !_.isEmpty(filterState) && !_.isEqual(filterState, prevFilter)) {
      const prev = getOnlyFilters(prevFilter);
      const cur = getOnlyFilters(filterState);
      // reset pagination if filters are changed
      if (!_.isEqual(prev, cur)) {
        dispatch(resetPagination());
      }
      updateBrowserUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryFilter, dateRange, selectedBrands, unreadOnly, viewFilter, elementsPerPage, pageNumber, skip, sortOrder]);

  const handlePagination = ({ limit, start }: { limit: number, start: number }) => {
    dispatch(updatePagination(limit, start));
  };

  const handleWppPaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail;
    handlePagination({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (notificationState === NotificationStates.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [notificationState]);

  return (
    <WppPageTemplate name={pageName}>
      <NotificationsFilters
        dateRange={dateRange}
        isLoading={isLoading}
        dispatch={dispatch}
        filterState={filterState}
        brandCandidates={brandCandidates}
        loadingBrands={loadingBrands}
        brandError={brandError}
      />
      {(notificationState === NotificationStates.initial || isLoading)
        && <div style={spinnerStyle}><PageLoading pageName={PAGE_NAME} showDimmer={false}>{LOADING_MSG}</PageLoading></div>}
      {_.size(notifications) && !isLoading
        ? (
          <>
            <NotificationTable
              notifications={notifications}
              filterState={filterState}
              isLoading={isLoading}
            />
            <WppPagination
              count={numberOfElements}
              itemsPerPage={ELEMENTS_PER_PAGE_OPTIONS}
              activePageNumber={pageNumber}
              selectedItemPerPage={elementsPerPage}
              onWppChange={handleWppPaginationChange}
              style={paginationStyle}
            />
          </>
        )
        : (
          <div style={noNotificationsStyle}>
            {!isLoading && (
              <>
                <WppEmptyNothingFound style={nothingFoundStyle} />
                <WppTypography tag="p" type="m-strong">{NO_RESULT_MSG}</WppTypography>
                <WppTypography tag="p" type="s-body">Please check back later.</WppTypography>
              </>
            )}
          </div>
        )}
    </WppPageTemplate>
  );
};

export default NotificationsPage;
