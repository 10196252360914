import React from 'react';
import _ from 'lodash';
import { Moment } from 'moment';
import { generateUUID } from 'utils/formattingUtils';
import { WppGrid, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTypography } from 'buildingBlocks';
import QueryStringLink from 'components/QueryStringLink';
import { JOB_STATUSES } from '../constants';
import HealthTableHeaders from './HealthTableHeaders';
import { Job } from '../types';
import { getIsoDateTime } from '../utils';
import { HEALTH_CARE_STYLES } from '../style';

const { healthTableGrid, healthTableStyle } = HEALTH_CARE_STYLES;

const HEADERS = ['Type', 'Success', 'Failed', 'Running', 'New', 'Warnings'];

type Props = {
  jobs: Array<Job>
  startDate: Moment
  endDate: Moment
};

const JobsHealthTable = ({ jobs, startDate, endDate }: Props) => (
  <WppGrid container fullWidth style={healthTableGrid}>
    <WppTable style={healthTableStyle}>
      <WppTableHeader>
        <HealthTableHeaders headers={HEADERS} />
      </WppTableHeader>
      <WppTableBody className="wpp-tbody">
        {_.map(jobs, (job) => (
          <WppTableBodyRow key={job.type}>
            <WppTableBodyCell className={job.type && 'jobType'}>
              <QueryStringLink
                to="/jobs"
                params={{
                  type: [job.type],
                  startDate: getIsoDateTime(startDate),
                  endDate: getIsoDateTime(endDate),
                }}
              >
                <WppTypography type="s-body" className="healthCareLinkColor">{job.type}</WppTypography>
              </QueryStringLink>
            </WppTableBodyCell>
            {_.map(JOB_STATUSES, (key) => (
              <WppTableBodyCell key={generateUUID()}>
                {_.get(job.status, key)
                  ? (
                    <QueryStringLink
                      to="/jobs"
                      params={{
                        type: [job.type],
                        startDate: getIsoDateTime(startDate),
                        endDate: getIsoDateTime(endDate),
                        status: [key],
                      }}
                    >
                      <WppTypography type="s-body" className="healthCareLinkColor">{_.get(job.status, key)}</WppTypography>
                    </QueryStringLink>
                  ) : <WppTypography type="s-body">-</WppTypography>}
              </WppTableBodyCell>
            ))}
          </WppTableBodyRow>
        ))}
      </WppTableBody>
    </WppTable>
  </WppGrid>
);

export default JobsHealthTable;
