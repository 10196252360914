import React from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { WppIconTrash, ValidatedCustomWppInput } from 'buildingBlocks';
import { DEMO_SITES_STYLE } from '../styles';

const { pixelRowStyle, pixelInput } = DEMO_SITES_STYLE;
type Props = {
  pixelGroup: { pixels: Array<string> }
  pixel: string
  groupKey: string
  pixelIndex: number | string
  handleLineItemDelete: (groupKey, pixelIndex) => void
};

export const PixelRow = ({ pixelGroup, pixel, pixelIndex, groupKey, handleLineItemDelete }: Props) => {
  const { control } = useFormContext();

  return (
    <div key={pixelIndex} style={pixelRowStyle}>
      <Controller
        name={`pixels.${groupKey}[${pixelIndex}]`}
        control={control}
        defaultValue={pixel}
        render={(properties) => (
          <ValidatedCustomWppInput
            type="text"
            {..._.omit(properties, 'formState')}
            style={pixelInput}
            onWppChange={properties.field.onChange}
          />
        )}
      />
      <WppIconTrash
        onClick={() => {
          if (_.size(pixelGroup) > 1) {
            handleLineItemDelete(groupKey, pixelIndex);
          }
        }}
      />
    </div>
  );
};
