import React, { ReactNode } from 'react';
import Markdown from 'react-markdown-renderer';
import { ButtonClickHandler, GenericMouseEventHandler } from 'utils/types';
import { Modal, Button, Header } from 'buildingBlocks';
import { MARKDOWN_OPTIONS } from '../constants';

export const ConfirmationModal = ({
  trigger, open, content, loading, onConfirm, onCancel,
} : ConfirmationModalProps) => (
  <Modal
    size="small"
    trigger={trigger}
    open={open}
  >
    <Header>Confirmation</Header>
    <Modal.Content><Markdown markdown={content || ''} options={MARKDOWN_OPTIONS} /></Modal.Content>
    <Modal.Actions>
      <Button type="submit" primary loading={loading} onClick={onConfirm}>Yes</Button>
      <Button secondary disabled={loading} onClick={onCancel}>No</Button>
    </Modal.Actions>
  </Modal>
);

type ConfirmationModalProps = {
  trigger: ReactNode,
  open: boolean,
  onCancel: ButtonClickHandler,
  content: string,
  onConfirm: GenericMouseEventHandler,
  loading: boolean,
};

export default ConfirmationModal;
