/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from 'components/Notification/constants';
import { RESULTS_LIMIT } from 'constantsBase';
import { Brand, Notifications } from 'utils/copilotAPI';
import useFetcher from 'utils/hooks/useFetcher';
import { Brand as BrandType } from 'utils/types';
import { initFiltersFromQS } from './actions';
import { NOTIFICATIONS_INITIAL_STATE } from './reducer';
import { NotificationFetcherState, NotificationStates, QSParams, State as FilterState, ActionTypes } from './types';

const DEFAULT_PARAMS = _.pick(NOTIFICATIONS_INITIAL_STATE, ['elementsPerPage', 'selectedCategoryFilter']);

export const getFiltersFromQS = (parsedQS: QSParams, brandCandidates: Array<BrandType>): FilterState => {
  const { brands, startDate, endDate, elementsPerPage, pageNumber, numberOfElements, skip } = parsedQS;
  const selectedBrands = _.filter(brandCandidates, (b) => _.includes(brands, _.toString(b.id)));
  const dateRange = `${startDate} - ${endDate}`;
  // @ts-ignore
  return {
    ..._.omit(parsedQS, ['elementsPerPage, pageNumber, numberOfElements']),
    elementsPerPage: +elementsPerPage,
    pageNumber: +pageNumber,
    numberOfElements: +numberOfElements,
    skip: +skip,
    dateRange,
    selectedBrands,
  };
};

export const initFromQSParams = async (qsParams: QSParams): Promise<FilterState> => {
  const brands = await Brand.get({
    limit: RESULTS_LIMIT,
    skip: 0,
    sort: 'name asc',
    populate: 'advertisers',
    isDeleted: false,
  });
  return getFiltersFromQS(qsParams, brands.data);
};

export const useNotificationFetcher = (qsParams: QSParams, dispatch, deps: Array<unknown>): NotificationFetcherState => {
  const fetchNotifications = async () => {
    // init from QS
    if (!_.isEmpty(qsParams)) {
      const filters = await initFromQSParams(qsParams);
      dispatch(initFiltersFromQS(filters));
      const updatedFilters = _.omit(filters, ['dateRange', 'numberOfElements', 'selectedBrands']);
      const notificationRes = await Notifications.getGroupedNotifications(updatedFilters);
      return { kind: NotificationStates.initFromQs, data: notificationRes.data };
    }
    const defaultQuery = { ...DEFAULT_PARAMS, startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE };
    dispatch({ type: ActionTypes.RESET_STATE });
    const notificationRes = await Notifications.getGroupedNotifications(defaultQuery);
    return { kind: NotificationStates.hasData, data: notificationRes.data };
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentState = useFetcher(fetchNotifications, deps);
  return currentState as unknown as NotificationFetcherState;
};
