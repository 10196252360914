import React from 'react';
import { Message, Transition } from 'buildingBlocks';
import { StatusMessage } from '../types';

type AnimatedMessageProps = {
  statusMessage: StatusMessage
  clearStatusMsg: Function
};

const AnimatedMessage = ({ statusMessage, clearStatusMsg }: AnimatedMessageProps) => (
  <Transition.Group
    animation="fade"
    duration={500}
  >
    {statusMessage && (
      <Message
        style={{ marginBottom: '20px' }}
        size="small"
        success={!statusMessage.isFailure}
        error={statusMessage.isFailure}
        icon={statusMessage.isFailure ? 'remove' : 'check'}
        header={statusMessage.header}
        content={statusMessage.body}
        onDismiss={() => clearStatusMsg()}
      />
    )}
  </Transition.Group>
);

export default AnimatedMessage;
