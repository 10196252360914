import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { useNavigate } from 'react-router-dom';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Form, Icon, Message, WppTypography, WppBreadcrumb, WppGrid, WppLabel, WppIconUndo, WppButton, WppActionButton, ValidatedCustomWppInput as NameField } from 'buildingBlocks';
import { NEW_FRESHDESK_TICKET_LINK } from 'containers/App/constants';
import { hasBrandManagerRole, isAdmin } from 'containers/User/utils';
import { GlobalState } from 'reducers';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { Advertiser, BreadcrumbItemEventDetails, InputChangeEventDetail, User, WppBreadcrumbCustomEvent, WppInputCustomEvent } from 'utils/types';
import WppPageTemplate from 'components/PageTemplate/WppPageTemplate';
import { BrandDetailsFormType } from '../types';
import { BRAND_PAGE_STYLES } from '../styles';
import BrandAdvertisersField from './BrandAdvertisersField';
import { handleBrandDetailsFormSubmit } from '../utils';
import { whatIsABrandHelpCenterLink } from '../constants';

const brandsLandingPagePath = '/brands';

const {
  noAccessMessageContainer,
  noAccessIcon,
  actionButtonsContainer,
  helpCenterText,
  brandNameContainer,
  selectionOptionsListContainer,
  selectionOptionsListHeader,
} = BRAND_PAGE_STYLES;

const NoAccessMessage: React.FC = (): React.ReactElement => (
  <WppGrid item all={24}>
    <Message style={noAccessMessageContainer}>
      <Icon name="exclamation" size="small" style={noAccessIcon} id="no-access-brands-icon" circular bordered />
      <Message.Content>
        You do not have permissions to edit brands. Please contact your
        <a href={whatIsABrandHelpCenterLink} target="_blank" rel="noopener noreferrer"> Brand Manager</a>.
      </Message.Content>
    </Message>
  </WppGrid>
);

type BrandDetailsFormProps = {
  brandId: string
  defaultValues: BrandDetailsFormType
  validAdvertisers: Array<Advertiser>
};

const BrandDetailsForm: React.FC<BrandDetailsFormProps> = (props: BrandDetailsFormProps): React.ReactElement => {
  const { brandId, defaultValues, validAdvertisers } = props;
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isAdminUser = isAdmin(user);
  const isQAGlobal = isQAGlobalReadOnly(user);
  const isAdminOrQAGlobal = isAdminUser || isQAGlobal;
  const isBrandManager = hasBrandManagerRole(user);
  const formDisabled = !isAdminOrQAGlobal && !isBrandManager;

  const formMethods = useForm<BrandDetailsFormType>({ mode: 'onChange', defaultValues });
  const { control, reset, formState, getValues } = formMethods;
  const name = useWatch({ name: 'name', control });
  const advertisers = useWatch({ name: 'advertisers', control });
  const disableUndo = formDisabled || !formState.isDirty;
  const disableSubmit = submitDisabled
    || disableUndo
    || isQAGlobal
    || _.isEmpty(name)
    || (!isAdminUser && (_.isEmpty(advertisers) || !brandId))
    || !_.isEmpty(formState.errors);

  const onCancel = () => navigate(brandsLandingPagePath);
  const onSubmit = () => {
    setLoading(true);
    setSubmitDisabled(true);
    handleBrandDetailsFormSubmit(brandId, defaultValues, getValues(), navigate);
  };

  const pageHeaderText = `${brandId ? 'Edit' : 'Create'} Brand`;

  const CustomHeader = (
    <div>
      <WppBreadcrumb
        items={[{
          label: 'Back to Brands',
          path: '/brands',
        }]}
        onWppChange={(event: WppBreadcrumbCustomEvent<BreadcrumbItemEventDetails>) => navigate(event.detail.path)}
      />
      <WppTypography tag="p" type="3xl-heading">{pageHeaderText}</WppTypography>
    </div>
  );

  return (
    <WppPageTemplate
      name="Edit Brand"
      title="Edit Brand"
      customHeader={CustomHeader}
      actionElements={(
        <div style={actionButtonsContainer}><WppButton variant="secondary" size="m" onClick={onCancel}>Cancel</WppButton>
          <WppButton
            type="submit"
            onClick={onSubmit}
            disabled={disableSubmit}
            size="m"
            loading={loading}
          >
            Save
          </WppButton>
        </div>
    )}
    >
      <DocumentTitle title={pageHeaderText} />
      <FormProvider {...formMethods}>
        <Form>
          <WppGrid container fullWidth>
            <WppGrid item all={24} style={brandNameContainer}>
              <WppLabel htmlFor="name">
                <WppTypography tag="p" type="s-strong">
                  Brand Name
                </WppTypography>
              </WppLabel>
              <Controller
                name="name"
                rules={{
                  required: { value: true, message: 'Required' },
                  pattern: { value: /\w/, message: 'Name must contain at least one character' },
                  maxLength: { value: 255, message: 'Name has a maximum of 255 characters.' },
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <NameField
                    type="text"
                    placeholder="Enter a Brand Name"
                    onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(e.detail.value)}
                    field={field}
                    fieldState={fieldState}
                    skipIsDirtyCheck
                    disabled={!isAdminOrQAGlobal}
                  />
                )}
              />
            </WppGrid>
            <WppGrid item all={24}>
              <BrandAdvertisersField
                defaultValue={defaultValues.advertisers}
                validAdvertisers={validAdvertisers}
                isAdminOrQAGlobal={isAdminOrQAGlobal}
                formDisabled={formDisabled}
                selectionOptionsListHeader={(
                  <div style={selectionOptionsListContainer}>
                    <div style={selectionOptionsListHeader}>
                      <WppLabel htmlFor="advertisers">
                        <WppTypography tag="p" type="s-strong">Available advertisers</WppTypography>
                      </WppLabel>
                      {!formDisabled && (
                      <WppActionButton variant="secondary" disabled={disableUndo} onClick={() => reset(defaultValues)}>Undo
                        <WppIconUndo slot="icon-start" />
                      </WppActionButton>
                      )}
                    </div>
                    <WppTypography tag="p" type="s-body">
                      Choose from available advertisers to be included in the brand.
                    </WppTypography>
                  </div>
                )}
              />
            </WppGrid>
            {formDisabled && <NoAccessMessage />}
          </WppGrid>
        </Form>
      </FormProvider>
      {(isQAGlobal || isBrandManager) && (
        <p style={helpCenterText}>
          Changes cannot be undone. If you make an error, please
          <a href={NEW_FRESHDESK_TICKET_LINK} target="_blank" rel="noopener noreferrer"> submit a ticket</a>.
        </p>
      )}
    </WppPageTemplate>
  );
};

export default BrandDetailsForm;
