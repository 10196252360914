import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WppActionButton, WppIconExternalLink, WppIconMore, WppMenuContext, WppListItem, WppGrid, WppIconMailRead } from 'buildingBlocks';
import { State as FilterState } from 'containers/NotificationCenter/types';
import { NotificationMsgObject } from 'components/Notification/types';
import { getMessageAction, readNotification } from 'components/Notification/utils';
import { actionsStyles } from 'containers/NotificationCenter/style';

const {
  tableCell,
  actionsContainer,
  anchorText,
  takeAction,
  contextMenu,
} = actionsStyles;

type NotificationEllipsisProps = {
  notification: NotificationMsgObject
  filterState: FilterState
  userId: number
};

const NotificationActions = ({ notification, filterState, userId }: NotificationEllipsisProps) => {
  const { readAt, metadata } = notification;
  const isUsersNotification = _.isEqual(notification.user, userId);

  const [read, setRead] = useState<boolean>(isUsersNotification ? !!readAt : false);
  const dispatch = useDispatch();

  const metaDataObj = JSON.parse(metadata);
  const action = getMessageAction(_.get(metaDataObj, 'actions', []));
  const actionLink = action?.link;
  // actions.displayValue may not exist until model update - default will be 'Take Action'
  const actionLinkDisplayValue = _.get(action, 'displayValue', 'Take Action');

  // syncs read when user read/unread notification and switches My/All filter
  useEffect(() => {
    if (isUsersNotification) {
      setRead(!!readAt);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const updateNotificationReadStatus = async (uId: number, readBool: boolean = true) => {
    if (isUsersNotification) {
      setRead(readBool);
      await readNotification(notification.id, uId, filterState.selectedCategoryFilter, readBool, dispatch);
    }
  };

  const handleTakeAction = async () => {
    await updateNotificationReadStatus(userId);
    window.open(actionLink, '_blank');
  };

  const toggleNotificationReadStatus = async () => {
    await updateNotificationReadStatus(userId, !read);
  };

  return (
    <WppGrid
      item
      style={tableCell}
    >
      <div style={actionsContainer}>
        <div
          className="notif-center-action-link"
          style={{ ...takeAction, ...(actionLink && anchorText) }}
        >
          {actionLink && (
            <WppActionButton onClick={handleTakeAction}>
              <WppIconExternalLink slot="icon-start" />
              {actionLinkDisplayValue}
            </WppActionButton>
          )}
        </div>
        <WppMenuContext style={contextMenu}>
          <WppActionButton slot="trigger-element">
            <WppIconMore color="var(--wpp-brand-color)" direction="horizontal" />
          </WppActionButton>
          <div>
            <WppListItem disabled={!isUsersNotification} onClick={toggleNotificationReadStatus}>
              <WppIconMailRead slot="left" />
              <p slot="label">
                Mark as {read ? 'Unread' : 'Read'}
              </p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
    </WppGrid>
  );
};

export default NotificationActions;
