import _ from 'lodash';
import {
  FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED,

  DASHBOARD_SELECT_FLIGHT,
  DASHBOARD_SELECT_TASK,
  DASHBOARD_SELECT_RESULT,
  DASHBOARD_SELECT_RUN,

  DASHBOARD_FETCH_LOG_COMPLETED,
  DASHBOARD_FETCH_RESULT_COMPLETED,
} from './constants';

const INITIAL_STATE = {
  runs: [],
  flights: [],
  strategy: null,
  result: null,
  loading: true,
  selectedFlight: null,
  selectedRun: null,
  selectedTask: null,
  selectedResult: null,
  selectedResultIndices: [],
  selectedTaskName: '',
  filter: {},
};

export default function dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_STRATEGY_FLIGHT_RUNS_COMPLETED: {
      const { runs, flights, strategy } = action.payload;
      const loading = false;
      return {
        ...state, runs, flights, strategy, loading,
      };
    }

    case DASHBOARD_FETCH_LOG_COMPLETED: {
      const { data, task } = action.payload;
      task.logResult = data;
      return { ...state, runs: [...state.runs], selectedRun: { ...state.selectedRun } };
    }

    case DASHBOARD_FETCH_RESULT_COMPLETED: {
      const { data, result } = action.payload;
      result.data = data;
      // Force re-render by replacing runs with a new array.

      // XXX(kenan): This should be a smaller change to the state. Currently, the result and task
      // for which we're fetching a result is not on teh state tree; runs is the closest we can get.
      // On the plus side, this ought to handle the case where the user changes the selected task
      // or run before this fetch completes.
      // We also are replacing selectedRun to force an update on the ResultList which does not look
      // at all runs, unfortunately this would force a re-render in the case the user changes the
      // selected task or run before the fetch completes.
      return { ...state, runs: [...state.runs], selectedRun: { ...state.selectedRun } };
    }

    case DASHBOARD_SELECT_RESULT: {
      const idx = action.payload.index;
      const indices = [...state.selectedResultIndices];
      if (_.includes(indices, idx)) {
        _.remove(indices, (val) => val === idx);
      } else {
        indices.push(idx);
      }
      return { ...state, selectedResultIndices: indices };
    }

    case DASHBOARD_SELECT_FLIGHT: {
      const flights = state.flights;
      const flightId = action.payload.flightId;

      // If selecting a null flight, use default.
      const selectedFlight = _.chain(flights).keyBy('id').get(flightId).value();
      const selectedRun: { tasks: Array<any> } = _.get(selectedFlight, 'run');

      return {
        ...state,
        selectedFlight,
        selectedRun,
        selectedTask: _(selectedRun)
          .chain()
          .get('tasks', [])
          .first()
          .value(),
        selectedTaskName: INITIAL_STATE.selectedTask,
        selectedResult: INITIAL_STATE.selectedResult,
      };
    }

    case DASHBOARD_SELECT_RUN: {
      const selectedRun: { tasks: Array<any> } = action.payload.run;
      return {
        ...state,
        selectedRun,
        selectedTask: _(selectedRun)
          .chain()
          .get('tasks', [])
          .first()
          .value(),
        selectedResultIndices: INITIAL_STATE.selectedResultIndices,
      };
    }

    case DASHBOARD_SELECT_TASK:
      return {
        ...state,
        selectedTask: action.payload.task,
        selectedResult: INITIAL_STATE.selectedResult,
        selectedResultIndices: INITIAL_STATE.selectedResultIndices,
      };

    default:
      return state;
  }
}
