import React from 'react';
import _ from 'lodash';
import { WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';

type Props = {
  headers: Array<string>
};

const HealthTableHeaders = ({ headers }: Props) => (
  <WppTableHeaderRow>
    {_.map(headers, (header) => (
      <WppTableHeaderCell key={header}>
        <WppTypography type="s-strong">
          {header}
        </WppTypography>
      </WppTableHeaderCell>
    ))}
  </WppTableHeaderRow>
);

export default HealthTableHeaders;
