/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalState } from 'reducers';
import { Grid, Input } from 'buildingBlocks';
import BtnPageHeader from 'components/BtnPageHeader';
import PaginationControl from 'components/PaginationControl';
import DataLoading from 'components/DataLoading';
import DeletePanel from 'components/DeletePanel';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { pluralizer } from 'utils/formattingUtils';
import { Permission } from 'utils/featureFlags';
import {
  getMessages,
  selectAll,
  selectNone,
  selectMessages,
  clearStatusMsg,
  clearSelectedMessages,
  toggleModal as toggleModalAction,
} from './actions';
import {
  MESSAGES_ELEMENTS_PER_PAGE_OPTIONS,
  MESSAGES_STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import AnimatedMessage from './components/AnimatedMessage';
import MessageTable from './components/MessageTable';
import { State as MessageState } from './reducer';

const messagePluralizer = pluralizer('Message', 'Messages');
const REQUIRED_PERMISSIONS = [Permission.manageMessages];
const PAGE_NAME = 'Messages';

export const CustomHeader = () => (
  <BtnPageHeader title={PAGE_NAME} to="/messages/create" permission={REQUIRED_PERMISSIONS} />
);

export const Messages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    messages,
    messagesCount,
    selectedMessages,
    deleteEnabledMessages,
    modalOpen,
    statusMessage,
    deleting,
    loading,
  } = useSelector<GlobalState>((state) => state.messages) as MessageState;

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(MESSAGES_STARTING_ELEMENTS_PER_PAGE);
  const [startingPage, setStartingPage] = useState<number>(1);

  const updatePaginationAndGetMessages = (limit, skip, search) => {
    setSearch(search);
    setLimit(limit);
    setStartingPage((skip / limit) + 1);
    getMessages(limit, skip, search, dispatch);
  };

  const browserUrlUpdate = (limit, skip, search) => {
    const newFilter = { search, limit, skip };
    navigate({
      pathname: location.pathname,
      search: `?${qs.stringify(newFilter)}`,
    });
  };

  useEffect(() => {
    const { limit, skip, search } = qs.parse(location.search.replace(/^\?/, ''));
    const parsedLimit = (_.toNumber(limit) > 0 && parseInt(limit as string, 10)) || MESSAGES_STARTING_ELEMENTS_PER_PAGE;
    const parsedSkip = (_.toNumber(skip) >= 0 && parseInt(skip as string, 10)) || 0;
    const parsedSearch = _.isUndefined(search) ? '' : search;
    updatePaginationAndGetMessages(parsedLimit, parsedSkip, parsedSearch);

    return () => {
      dispatch(clearStatusMsg());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search, location.search]);

  const onMessageSelected = (_e, message) => {
    dispatch(selectMessages(message));
  };

  const onDataRangeChange = ({ limit, start }) => {
    dispatch(clearSelectedMessages());
    const skip = start;
    browserUrlUpdate(limit, skip, search);
    updatePaginationAndGetMessages(limit, skip, search);
  };

  const onSearchChange = (search) => {
    browserUrlUpdate(limit, 0, search);
    updatePaginationAndGetMessages(limit, 0, search);
  };

  const toggleModal = () => {
    dispatch(toggleModalAction());
  };

  const handleSelectAll = ({ checked }) => {
    if (checked) {
      dispatch(selectAll());
    } else {
      dispatch(selectNone());
    }
  };

  const deletePanelDisplayMsg = `
  ${_.size(selectedMessages)}
  Selected
  ${messagePluralizer(_.size(selectedMessages))}
`;

  return (
    <div>
      <PermissionPageTemplate
        name={PAGE_NAME}
        permissions={REQUIRED_PERMISSIONS}
        customHeader={<CustomHeader />}
      >
        <DataLoading loading={loading} pageName={PAGE_NAME}>
          <Grid style={_.size(selectedMessages) > 0 ? { marginBottom: '50px' } : {}}>
            <Grid.Column widescreen={12}>
              <AnimatedMessage statusMessage={statusMessage} clearStatusMsg={clearStatusMsg} />
              <Input
                icon="search"
                placeholder="Search..."
                onChange={(event) => onSearchChange((event.target as HTMLInputElement).value)}
                value={search}
              />
              <MessageTable
                messages={messages}
                selectedMessages={selectedMessages}
                deleteEnabledMessages={deleteEnabledMessages}
                modalOpen={modalOpen}
                deleting={deleting}
                handleSelectAll={handleSelectAll}
                onMessageSelected={onMessageSelected}
                toggleModal={toggleModal}
                dispatch={dispatch}
              />
              <PaginationControl
                numberOfElements={messagesCount}
                onDataRangeChange={(obj) => onDataRangeChange(obj)}
                startingElementsPerPage={limit}
                elementsPerPageOptions={MESSAGES_ELEMENTS_PER_PAGE_OPTIONS}
                startingPage={startingPage}
              />
            </Grid.Column>
          </Grid>
        </DataLoading>
      </PermissionPageTemplate>
      {!_.isEmpty(selectedMessages)
        && (
          <DeletePanel
            toggleModal={toggleModal}
            displayMessage={deletePanelDisplayMsg}
          />
        )}
    </div>
  );
};

export default Messages;
