/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { DSP } from 'constantsBase';
import { Label, Button, Grid, Input, Segment, Dropdown } from 'buildingBlocks';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { DATE_FORMAT_JS_DATE } from 'utils/dateTime';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { FilterDropdown } from '.';
import { FILTER, TIME_ZONE_OPTIONS } from '../constants';
import { timeZoneDropDown } from '../style';
import { FilterLabelProps, JobProps } from '../types';

const FilterLabel = (props: FilterLabelProps) => {
  const style = {
    border: 0,
  };

  return (
    <Label basic style={style} size="medium">
      {props.children}
    </Label>
  );
};

class Filters extends Component<JobProps> {
  handleTZChange = (_e, data) => {
    this.props.handleTimezoneChange(data.value);
  };

  render() {
    const {
      filter,
      jobStatusOptions,
      jobTypeOptions,
      memberFetchError,
      memberOptions,
      resetFilter,
      updateFilter,
      router,
      selectedTimezone,
    } = this.props;
    const prettyMembers = getOptionsWithDspIcon(memberOptions);

    const {
      startDate,
      endDate,
      member,
      type,
      status,
    } = filter;

    return (
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <FilterLabel>Start Date</FilterLabel>
              <ReactDatePicker
                customInput={<Input />}
                id="startDate"
                name="startDate"
                label="date label"
                selected={startDate}
                onChange={(date) => updateFilter('startDate', moment(date), router)}
                utcOffset={0}
                dateFormat={DATE_FORMAT_JS_DATE}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FilterLabel>End Date</FilterLabel>
              <ReactDatePicker
                customInput={<Input />}
                id="endDate"
                name="endDate"
                label="date label"
                selected={endDate}
                onChange={(date) => updateFilter('endDate', moment(date), router)}
                utcOffset={0}
                dateFormat={DATE_FORMAT_JS_DATE}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FilterLabel>Time Zone</FilterLabel>
              <Dropdown
                name="timeZone"
                placeholder=""
                options={TIME_ZONE_OPTIONS}
                fluid
                selection
                defaultValue={selectedTimezone}
                onChange={this.handleTZChange}
                style={timeZoneDropDown}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <FilterDropdown
                // @ts-ignore child class
                search={{ searchType: 'local' }}
                onChange={(selected) => updateFilter('type', selected, router)}
                filter={FILTER.JOB_TYPE}
                options={jobTypeOptions}
                value={type}
                keyFn={(option) => option.text}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FilterDropdown
                // @ts-ignore child class
                search={{ searchType: 'local' }}
                onChange={(selected) => updateFilter('status', selected, router)}
                filter={FILTER.STATUS}
                options={jobStatusOptions}
                value={status}
                keyFn={(option) => option.text}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <FilterDropdown
                // @ts-ignore child class
                onChange={(selected) => updateFilter('member', selected, router)}
                filter={FILTER.MEMBER}
                options={prettyMembers}
                value={member}
                error={memberFetchError}
                keyFn={(mem) => `${mem.displayName
                  // NOTE: to ensure different keys we need to prepend dispalyname with dsp
                  ? `${DSP.getById(mem.dsp.id).code} - ${mem.displayName}`
                  : `${DSP.getById(mem.dsp.id).code} - ${mem.name}`}`}
                search={{
                  searchType: 'local',
                  onSearchChange: searchByNameOrExtId(),
                }}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button onClick={() => resetFilter(router)}>
                Reset
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Filters;
