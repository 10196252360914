import _ from 'lodash';
import React from 'react';
import { Loader, WppIconTrash, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { getDspFromExtTypeId, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { useFormContext } from 'react-hook-form';
import { BulkCreateWizardFormAttachFlights } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/types';

const {
  baseTable,
  loaderOrAccordion,
  firstHeaderCell,
  flightName,
  dspIcon,
  flightDates,
  endIcon,
  iconStyle,
} = FLIGHT_DETAIL_STYLES;

type FlightDetailsTableProps = {
  flight: Flight
  removeFlight: Function
};

const FlightDetailsTable = (props: FlightDetailsTableProps) => {
  const { getValues } = useFormContext<BulkCreateWizardFormAttachFlights>();
  const { optimizationLevel } = getValues();
  const isHigherOrderOptLvl = isHigherOrderExternalType(_.head(_.get(optimizationLevel, 'externalTypeIds')));
  const { flight, removeFlight } = props;
  const { name, startDate, endDate, timezone, externalType, externalId } = flight;
  const { wizardFormValues: { budgetAllocationState } } = useBulkCreateStrategyWizardContext();
  const { kind } = budgetAllocationState;
  const flightDatesToDisplay = `${displayDate(startDate, externalType, timezone)} - ${displayDate(endDate, externalType, timezone)}`;

  return (
    <WppTable style={{ ...baseTable, border: 'none', borderBottom: 'none' }}>
      <WppTableBody>
        <WppTableBodyRow>
          {_.isEqual(kind, Status.loading) && (
            <WppTableBodyCell style={(!isHigherOrderOptLvl || !_.isEqual(kind, Status.hasData)) ? firstHeaderCell : loaderOrAccordion}>
              {(_.isEqual(kind, Status.loading))
                && (
                  <Loader active size="mini" inline />
                )}
            </WppTableBodyCell>
          )}
          <WppTableBodyCell style={flightName}>
            <WppTypography type="s-body" title={name}>{name}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={dspIcon}>
            <DspIcon dspId={getDspFromExtTypeId(externalType)} />
          </WppTableBodyCell>
          <WppTableBodyCell style={flightDates}>
            <WppTypography type="s-body">{flightDatesToDisplay}</WppTypography>
          </WppTableBodyCell>
          <WppTableBodyCell style={endIcon}>
            <WppIconTrash onClick={() => removeFlight(externalId)} style={iconStyle} color="var(--wpp-grey-color-800)" />
          </WppTableBodyCell>
        </WppTableBodyRow>
      </WppTableBody>
    </WppTable>
  );
};

export default FlightDetailsTable;
