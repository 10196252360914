import _ from 'lodash';
import React from 'react';
import { WppIconClose, WppIconSearch, WppInput } from 'buildingBlocks';
import { WppInputCustomEvent, InputChangeEventDetail } from 'utils/types';

type SearchFilterProps = {
  filterSearchTerm: string
  setFilterSearchTerm: (term: string) => void
  disabled: boolean
};

const SearchFilter = (props: SearchFilterProps) => {
  const { filterSearchTerm, setFilterSearchTerm, disabled } = props;

  const resetSearch = () => {
    setFilterSearchTerm('');
  };

  const onSearchChange = (searchQuery: string) => {
    setFilterSearchTerm(searchQuery);
  };

  return (
    <WppInput
      id="flight-candidate-search"
      value={filterSearchTerm}
      style={{ width: 395 }}
      placeholder="Search for objects"
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onSearchChange(event.detail.value)}
      size="s"
      type="search"
      disabled={disabled}
    >
      <WppIconSearch slot="icon-start" />
      {!_.isEmpty(filterSearchTerm) && <WppIconClose slot="icon-end" onClick={resetSearch} />}
    </WppInput>
  );
};

export default SearchFilter;
