export const preStyle = {
  fontFamily: 'monospace',
  fontSize: '8pt',
  whitespace: 'pre',
};

export const resultTitleStyle = {
  color: 'black',
  display: 'block',
  paddingLeft: '2em',
};

export const s3DirNameStyle = {
  fontSize: '8pt',
  paddingLeft: '2em',
  wordWrap: 'break-word',
};

export const accordionTitleStyle = {
  display: 'inline-flex',
  verticalAlign: 'middle',
  width: '100%',
};
