import React from 'react';
import { WppInlineMessage, WppTypography } from 'buildingBlocks';
import { bannerDescription, IMAGE_BASE_SRC } from 'containers/BulkCreateStrategyWizard/constants';
import { optimizationTypeBanner } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';

const BulkCreateOptimizationTypeBanner = () => (
  <div style={optimizationTypeBanner.optTypeContainer}>
    <WppInlineMessage
      size="l"
      titleText="Bulk Create Strategies"
      showTooltipFrom={1000}
      hideCloseBtn
    />
    <WppTypography type="s-body" tag="p" style={optimizationTypeBanner.subHeading}>{bannerDescription}</WppTypography>
    <img
      src={`${IMAGE_BASE_SRC}/optimization-type-banner.svg`}
      style={optimizationTypeBanner.imageContainer}
      alt="bulk create flow"
    />
  </div>
);

export default BulkCreateOptimizationTypeBanner;
