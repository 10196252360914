// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast-body.toastBodyStyle {
  margin: 0 !important;
  padding: 0;
  width: 335px;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/containers/App/overrideToast.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;EACV,YAAY;EACZ,YAAY;AACd","sourcesContent":[".Toastify__toast-body.toastBodyStyle {\n  margin: 0 !important;\n  padding: 0;\n  width: 335px;\n  height: 80px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
