import _ from 'lodash';
import React, { useState } from 'react';
import { WppBreadcrumb, WppIconChevron, WppGrid } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import ErrorBoundary from 'components/ErrorBoundary';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { Permission } from 'utils/featureFlags';
import StrategyWizardHeader from 'containers/StrategyWizard/components/StrategyWizardHeader';
import { StrategyWizardResponse } from 'containers/StrategyWizard/types';
import { QSParams } from 'containers/StrategyWizard/utils';
import ConfirmationPopup from 'containers/StrategyWizard/components/ConfirmationPopup';
import BulkCreateStrategyWizardProgress from '../BulkCreateStrategyWizardProgress';
import BulkCreateNewStrategyRoutes from '../BulkCreateNewStrategyRoutes';
import BulkCreateStrategyWizardRedirector from './BulkCreateStrategyWizardRedirector';
import { PAGE_NAME } from '../BulkCreateStrategyWizard';
import { BulkCreateWizardFormValues } from '../types';
import { getInitialBulkCreateWizardFormFieldsUIValues } from '../utils';

const REQUIRED_PERMISSIONS = [Permission.bulkCreateStrategy];

type Props = {
  step: number
  wizardFormValues: BulkCreateWizardFormValues
  qsParams: QSParams
  bulkCreateStrategyState: StrategyWizardResponse
};

const BulkCreateStrategyWizardRoutes = (props: Props) => {
  const {
    wizardFormValues,
    step,
    qsParams,
    bulkCreateStrategyState,
  } = props;

  const wizardFormValuesForRedirector = _.get(wizardFormValues, 'attachFlightsStep.member') ? wizardFormValues : getInitialBulkCreateWizardFormFieldsUIValues(bulkCreateStrategyState) as BulkCreateWizardFormValues;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const hasWizardFormValues = !_.isEmpty(wizardFormValues);
  return (
    <ErrorBoundary>
      {hasWizardFormValues && (
        <BulkCreateStrategyWizardRedirector
          step={step}
          wizardFormValues={wizardFormValuesForRedirector}
        >
          <WppPermissionPageTemplate
            name={PAGE_NAME}
            permissions={REQUIRED_PERMISSIONS}
            allPermissions={false}
            customHeader={(
              <div style={{ display: 'block' }}>
                <div style={strategyWizardStyles.breadCrumContainer}>
                  <WppIconChevron direction="left" size="s" />
                  <WppBreadcrumb
                    items={[{
                      label: 'Back',
                      path: '/',
                    }]}
                    onWppChange={() => {
                      togglePopup();
                    }}
                    style={strategyWizardStyles.breadCrumBackLink}
                  />
                </div>
                <StrategyWizardHeader isNewStrategy />
              </div>
            )}
            withoutLayout
            member={_.get(wizardFormValues, 'member')}
          >
            <WppGrid container fullWidth all={24}>
              <WppGrid item all={3}>
                <BulkCreateStrategyWizardProgress
                  step={step}
                  wizardFormValues={wizardFormValues}
                />
              </WppGrid>
              <WppGrid item all={21}>
                <BulkCreateNewStrategyRoutes
                  qsParams={qsParams}
                />
              </WppGrid>
            </WppGrid>
            <ConfirmationPopup
              isOpen={isOpen}
              togglePopup={togglePopup}
              cancelBtnPath="/"
            />
          </WppPermissionPageTemplate>
        </BulkCreateStrategyWizardRedirector>
      )}
    </ErrorBoundary>
  );
};

export default BulkCreateStrategyWizardRoutes;
