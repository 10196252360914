import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { WppTypography, WppGrid } from 'buildingBlocks';
import { stratInfoStyles } from 'containers/NotificationCenter/style';
import { SelectedCategoryFilter } from 'components/Notification/constants';
import { StrategyInfo as StrategyInfoType, MoreDetailsInfo, InfoToDisplay, State as FilterState } from 'containers/NotificationCenter/types';
import { NotificationMsgObject } from 'components/Notification/types';
import { truncate } from 'utils/formattingUtils';

const SINGLE_LINE_LIMIT = 30;
const THREE_LINE_LIMIT = 140;

const {
  container, colorMatter, memAdvSeparate,
  adv, mem, colorSherpa, memAdvOneLine,
} = stratInfoStyles;

type StrategyInfoProps = {
  infoToDisplay: StrategyInfoType
};

const StrategyInfo = ({ infoToDisplay }: StrategyInfoProps) => {
  const { strategyName, advertiser, member, createdByName, strategyId, strategyActive } = infoToDisplay;
  const strategyText = truncate(strategyName, THREE_LINE_LIMIT);
  const strategyLink = `/strategies/${strategyId}`;
  return (
    <WppGrid
      item
      style={container}
    >
      <WppTypography tag="p" type="s-body" style={colorMatter}>
        {strategyActive ? (
          <Link
            to={strategyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {strategyText}
          </Link>
        ) : strategyText}
      </WppTypography>
      <WppTypography tag="p" type="xs-body" style={_.size(advertiser) > SINGLE_LINE_LIMIT ? memAdvSeparate : memAdvOneLine}>
        <span style={adv}>{advertiser}</span>
        <span style={mem}>{member}</span>
      </WppTypography>
      <WppTypography tag="p" type="xs-body" style={colorMatter}>
        <span style={colorSherpa}>Strategy Creator:</span> {createdByName}
      </WppTypography>
    </WppGrid>
  );
};

type MoreDetailsInfoProps = {
  infoToDisplay: MoreDetailsInfo
};

const MoreDetails = ({ infoToDisplay }: MoreDetailsInfoProps) => {
  const displayText = truncate(infoToDisplay?.displayValue ?? '', THREE_LINE_LIMIT);
  const isStrategyLink = _.isEqual(infoToDisplay?.title, 'Strategy');
  const showLink = infoToDisplay?.link && ((isStrategyLink && infoToDisplay.strategyActive) || !isStrategyLink);
  return (
    <WppGrid
      item
      all={24}
      style={container}
    >
      <WppTypography tag="p" type="xs-body" style={colorMatter}>
        {showLink ? <a href={infoToDisplay.link} target="_blank" rel="noopener noreferrer">{displayText}</a> : displayText}
      </WppTypography>
    </WppGrid>
  );
};

type NotificationInfoWppProps = {
  notification: NotificationMsgObject
  filterState : FilterState
};

const NotificationInfoWpp = ({ notification, filterState }: NotificationInfoWppProps) => {
  const { metadata, strategyName } = notification;

  const metaDataObj = JSON.parse(metadata);
  const displayStratInfo = filterState.selectedCategoryFilter === SelectedCategoryFilter.actions && !!strategyName;
  const moreDetailsInfoToDisplay = _.head(metaDataObj?.summary) as object ?? {};
  const infoToDisplay = displayStratInfo
    ? _.pick(notification, ['createdByName', 'strategyName', 'member', 'advertiser', 'strategyId', 'strategyActive'])
    : { ...moreDetailsInfoToDisplay, strategyActive: notification.strategyActive };
  const isStrategyInfo = (displayObj: InfoToDisplay): displayObj is StrategyInfoType => !!_.get(displayObj, 'strategyName');

  return (
    <WppGrid container fullWidth>
      {isStrategyInfo(infoToDisplay as any)
        ? (<StrategyInfo infoToDisplay={infoToDisplay as StrategyInfoType} />)
        : (<MoreDetails infoToDisplay={infoToDisplay as MoreDetailsInfo} />)}
    </WppGrid>
  );
};

export default NotificationInfoWpp;
