import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Container, Segment, Header, Grid, Button } from 'buildingBlocks';
import PageForbidden from 'containers/403';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { isAdmin } from 'containers/User/utils';
import { GlobalState } from 'reducers';
import { Microservices } from 'utils/copilotAPI';
import { pluralizer } from 'utils/formattingUtils';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import { Member, User } from 'utils/types';
import FLIGHT_SYNC_STYLES from './styles';
import { FlightSyncForm, initialValues, MAX_EXTERNAL_IDS_ALLOWED } from './constants';
import MemberField from './components/MemberField';
import ExternalIdsField from './components/ExternalIdsField';
import SyncMessageModal from './components/SyncMessageModal';

const PAGE_NAME = 'Flight Sync';

const { headerContainer, headerText, headerDesc, footerContainer, warningTriangle, warningSpan, submitBtn } = FLIGHT_SYNC_STYLES;

const FlightSync = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [textArea, setTextArea] = useState<string>('');

  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const canAccessPage = isAdminOrQAGlobal(user);
  const isAdminUser = isAdmin(user);

  const formMethods = useForm<FlightSyncForm>({
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const { control } = formMethods;
  const member = useWatch<FlightSyncForm>({ name: 'member', control }) as Member;
  const externalIds = useWatch<FlightSyncForm>({ name: 'externalIds', control }) as Array<string>;

  const externalIdLimitExceeded = _.size(externalIds) > MAX_EXTERNAL_IDS_ALLOWED;
  const numberOfIdsExceeded = _.size(externalIds) - MAX_EXTERNAL_IDS_ALLOWED;
  const disabled = !isAdminUser || _.isNil(member) || _.isEmpty(externalIds) || externalIdLimitExceeded;

  const handleSubmit = () => {
    Microservices.runService(
      {
        user_id: user.id,
        member_ext_id: member.externalId,
        member_name: member.displayName,
        external_type: FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id,
        io_ext_ids: externalIds,
      },
      'sync_dbm_ios',
    );
    setModalOpen(true);
  };

  return (canAccessPage
    ? (
      <Container>
        <DocumentTitle title={PAGE_NAME} />
        <div style={headerContainer}>
          <Header as="h2" content={PAGE_NAME} style={headerText} />
          <p style={headerDesc}>
            Sync the latest data from a list of DV360 Insertion Orders
          </p>
        </div>
        <Segment>
          <Grid>
            <FormProvider {...formMethods}>
              <MemberField user={user} />
              <ExternalIdsField
                textArea={textArea}
                setTextArea={setTextArea}
              />
              <SyncMessageModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setTextArea={setTextArea}
              />
            </FormProvider>
            <Grid.Row>
              <Grid.Column>
                <div style={footerContainer}>
                  <div>
                    {externalIdLimitExceeded && (
                      <>
                        <img alt="warning triangle" src="/img/icons/16x16/red-exclamation-triangle-outline.svg" style={warningTriangle} />
                        <span style={warningSpan}>
                          External ID limit exceeded. Please remove {numberOfIdsExceeded} {pluralizer('ID', 'IDs')(numberOfIdsExceeded)} to continue.
                        </span>
                      </>
                    )}
                  </div>
                  <Button
                    style={submitBtn}
                    onClick={handleSubmit}
                    disabled={disabled}
                    primary
                  >
                    Submit
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    ) : (
      <PageForbidden
        message="You are not permitted to sync flights."
        button
      />
    )
  );
};

export default FlightSync;
