import _ from 'lodash';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Permission } from 'utils/featureFlags';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import {
  WppInlineMessage,
  WppButton,
  WppGrid,
  WppTypography,
} from 'buildingBlocks';
import { dateTimeFormatter } from 'utils/dateTime';
import { Token, TokenResponse, TokenStatus } from './types';
import { FAILURE_TEXT, TOKEN_VALIDATE_TEXT } from './constants';
import { actionContainer, infoContainer, infoLabel } from './style';

const tokenData = (props: Token) => [
  {
    label: 'Expires At',
    value: props.token ? (<span>{dateTimeFormatter.dateTime(props.expirationDate, true)}</span>) : '',
  },
  {
    label: 'Token',
    value: props.token,
  },
];

type TokenRowProps = {
  label: string
  value: any
};

const TokenRow = (props: TokenRowProps) => (
  <div style={infoContainer}>
    <WppTypography style={infoLabel} type="m-strong">
      {props.label}
    </WppTypography>
    <WppTypography type="m-body">{props.value}</WppTypography>
  </div>
);

type TokenDisplayComponentProps = {
  token: Token
};

export const TokenDisplayComponent = ({
  token,
}: TokenDisplayComponentProps) => (
  <>
    <WppGrid item all={24}>
      {tokenData(token).map((data) => (
        <TokenRow key={data.label} {...data} />
      ))}
    </WppGrid>
    <WppGrid item all={8}>
      <WppInlineMessage size="m" message={TOKEN_VALIDATE_TEXT} type="information" />
    </WppGrid>
    <WppGrid />
  </>
);

type DevTokenFormProps = {
  apiToken: Token
  copied: boolean
  handleCopy: (arg0: boolean) => void
  generateApiToken: () => void
  tokenState?: TokenResponse
};

export const DeveloperTokenForm = (props: DevTokenFormProps) => {
  const { apiToken, generateApiToken, copied, handleCopy, tokenState } = props;

  return (
    <WppPermissionPageTemplate name="Developer Token" permissions={[Permission.developerAPIAccess]}>
      <WppGrid container fullWidth>
        {tokenState && _.isEqual(tokenState.kind, TokenStatus.error) ? (
          <WppGrid item all={6}>
            <WppInlineMessage data-testid="error-message" size="m" message={FAILURE_TEXT} type="error" />
          </WppGrid>
        ) : (!_.isEmpty(apiToken) && <TokenDisplayComponent token={apiToken} />)}
        {_.isEmpty(apiToken) && !tokenState && (
          <WppGrid item>
            <WppTypography type="m-body">
              No tokens available. Click to generate a new token.
            </WppTypography>
          </WppGrid>
        )}
        <WppGrid item all={24} style={actionContainer}>
          <WppButton onClick={() => generateApiToken()}>
            Generate New Token
          </WppButton>
          {!_.isEmpty(apiToken) && (
            <CopyToClipboard text={apiToken.token} onCopy={() => handleCopy(true)}>
              <WppButton variant="secondary">Copy Token</WppButton>
            </CopyToClipboard>
          )}
          {copied ? <WppInlineMessage size="s" message="Token copied to clipboard." type="success" /> : null}
        </WppGrid>
      </WppGrid>
    </WppPermissionPageTemplate>
  );
};

export default DeveloperTokenForm;
