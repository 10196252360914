import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { NotificationMsgObject } from 'components/Notification/types';
import ErrorComponent from 'components/ErrorComponent';
import { GlobalState } from 'reducers';
import { withQueryString } from 'utils/functionHelpers';
import withRouter from 'utils/withRouter';
import { useBrandFetcher } from 'utils/hooks/useBrandFetcher';
import { updateNumOfElements } from './actions';
import { NotificationData, NotificationStates, QSParams } from './types';
import NotificationsPage from './components/Table/NotificationsPage';
import { NOTIFICATIONS_INITIAL_STATE, notificationsReducer } from './reducer';
import { useNotificationFetcher } from './utils';

export const PAGE_NAME = 'Notifications';
const ERROR_MSG = 'Please check back later or contact support.';

type NotificationCenterProps = {
  qsParams: QSParams
};

const NotificationCenter = ({ qsParams }: NotificationCenterProps) => {
  const usersNotifications = useSelector<GlobalState>((state) => state.login.notifications) as Array<NotificationMsgObject>;
  const [notifications, setNotifications] = useState<Array<NotificationMsgObject>>([]);
  const [filterState, dispatch] = useReducer(notificationsReducer, NOTIFICATIONS_INITIAL_STATE);
  const location = useLocation();

  const notificationState = useNotificationFetcher(qsParams, dispatch, [location.search]);
  const { brandCandidates, loadingBrands, brandError } = useBrandFetcher();

  useEffect(() => {
    if ([NotificationStates.initFromQs, NotificationStates.hasData].includes(notificationState.kind)) {
      const { notifications: notificationsData, totalCount } = _.get(notificationState, 'data') as NotificationData;
      setNotifications(notificationsData);
      dispatch(updateNumOfElements(totalCount));
    }
  }, [notificationState]);

  useEffect(() => {
    // update read status notifications as socket messages come in
    const notificationsCopy = _.map([...notifications], (currNotification) => {
      const updatedNotification = _.find(usersNotifications, { id: currNotification.id });
      return updatedNotification ?? currNotification;
    });
    setNotifications(notificationsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersNotifications]);

  switch (notificationState.kind) {
    case NotificationStates.error:
      return (
        <ErrorComponent
          pageTitle={PAGE_NAME}
          msg={`${notificationState.errorObject.message}\n${ERROR_MSG}`}
          multiLineMsg
        />
      );
    case NotificationStates.initFromQs:
    case NotificationStates.hasData:
    default:
      return (
        <NotificationsPage
          pageName={PAGE_NAME}
          filterState={filterState}
          dispatch={dispatch}
          notifications={notifications}
          brandCandidates={brandCandidates}
          loadingBrands={loadingBrands}
          brandError={brandError}
          notificationState={notificationState.kind}
        />
      );
  }
};

export default withRouter(withQueryString(NotificationCenter));
