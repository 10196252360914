import React, { CSSProperties } from 'react';
import DocumentTitle from 'react-document-title';
import { Container, Header } from 'buildingBlocks';
import { COPILOT_TYPOGRAPHY } from 'globalStyles';
import MAINTENANCE_STYLES from './styles';

const { HEADING: { H2 } } = COPILOT_TYPOGRAPHY;

const { mainContainer, imageContainer, maintenanceImg, textContainer, message } = MAINTENANCE_STYLES;
const windowWidth = window.innerWidth;
const mainContainerStyle: CSSProperties = windowWidth >= 800 ? { ...mainContainer, width: 800 } : mainContainer;

const Maintenance = () => (
  <>
    <DocumentTitle title="Copilot Maintenance" />
    <Container>
      <div style={mainContainerStyle}>
        <div style={imageContainer}>
          <img style={maintenanceImg} src="/img/ui/indicators/maintenance.svg" alt="maintenance" />
        </div>
        <div style={textContainer}>
          <Header as="h2" style={H2}>
            Copilot is temporarily down for scheduled maintenance.
          </Header>
          <p style={message}>
            During this time, all live campaigns will continue to run as planned. Please make any needed campaign adjustments or deactivations in the DSP directly.
          </p>
          <p style={message}>
            We apologize for any inconvenience. Thank you for your patience!
          </p>
        </div>
      </div>
    </Container>
  </>
);

export default Maintenance;
