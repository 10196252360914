import React from 'react';
import _ from 'lodash';
import { dateTimeFormatter } from 'utils/dateTime';
import { Brand, ColDef } from 'utils/types';
import { AgGridActionButton } from 'buildingBlocks';

const BrandsTableHeader = (isAdminOrQAGlobal: boolean, isAdminUser: boolean, canEditBrand: boolean): Array<ColDef> => [
  {
    field: 'brand',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: Brand }) => data.name,
    flex: 1,
    headerCheckboxSelection: isAdminOrQAGlobal,
    checkboxSelection: isAdminOrQAGlobal,
  },
  {
    field: 'advertisers',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: Brand }) => _.size(data.advertisers),
    maxWidth: 120,
    hide: !isAdminUser,
    cellDataType: 'number',
    cellStyle: { textAlign: 'right' },
  },
  {
    field: 'lastModified',
    sortable: false,
    resizable: false,
    valueGetter: ({ data }: { data: Brand }) => dateTimeFormatter.dateTime(data.updatedAt),
    maxWidth: 240,
  },
  {
    field: 'action',
    sortable: false,
    resizable: false,
    headerName: '',
    maxWidth: 120,
    cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
    cellRenderer: ({ data }: { data: Brand }) => (<AgGridActionButton to={`/brands/${data.id}`} type={canEditBrand ? 'edit' : 'view'} />),
  },
];

export default BrandsTableHeader;
