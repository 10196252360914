import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import {
  Grid,
  Header,
  Menu,
} from 'buildingBlocks';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { header } from 'components/SubNav/style';
import TextLinkBack from 'components/TextLinkBack';
import withRouter, { RouterProps } from 'utils/withRouter';

import { fetchStrategyFlightRuns } from '../actions';
import RunDetail from './RunDetail';
import ResultList from './ResultList';
import TaskList from './TaskList';
import { getPreviousPageLink } from '../utils';

type Props = RouterProps & {
  selectedTask: {},
  selectedRun: {},
  fetchStrategyFlightRuns: Function,
};
const dashboardHeader = (
  <Menu.Item fitted>
    <Header style={header}>
      Flight Run Detail
    </Header>
  </Menu.Item>
);

class Dashboard extends Component<Props> {
  UNSAFE_componentWillMount() {
    const router = this.props.router;
    const { location } = router;
    const { strategy, flight, run } = qs.parse(location.search.replace(/^\?/, ''));
    this.props.fetchStrategyFlightRuns(strategy, flight, run);
  }

  render() {
    const name = 'Troubleshooting Dashboard';
    const {
      selectedRun: run,
      selectedTask: task,
      router,
    } = this.props;
    return (
      // @ts-ignore page template
      <PermissionPageTemplate
        title={name}
        name={name}
        customHeader={dashboardHeader}
        subNavElements={(
          <TextLinkBack
            name="Back to Flight Runs"
            linkTo={getPreviousPageLink(router.location, '/strategyflightruns')}
          />
        )}
        unauthorized={false}
        unauthorizedProps={{ message: `You are not authorized to see strategy ${router.params.strategyId}.` }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {run && <RunDetail />}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {run && <TaskList />}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={7}>
              {task && <ResultList />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PermissionPageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  // Models.
  strategy: state.dashboard.strategy,

  // UI Status.
  loading: state.dashboard.loading,

  // List selection.
  selectedRun: state.dashboard.selectedRun,
  selectedTask: state.dashboard.selectedTask,
});

export default connect(mapStateToProps, { fetchStrategyFlightRuns })(withRouter(Dashboard));
