import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const authTokenPageStyle: { [key: string]: CSSProperties | any } = {
  selectContainer: {
    width: '196px',
    marginBottom: SPACING[16],
  },
  formattedOutput: {
    backgroundColor: 'var(--wpp-grey-color-100)',
    borderRadius: 'var(--wpp-border-radius-m)',
    padding: '12px 16px',
    marginBottom: SPACING[4],
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: SPACING[16],
    gap: SPACING[12],
  },
  spinnerStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default authTokenPageStyle;
