import { JOBS_DETAIL_FETCH, TASK_FETCH_DETAILS } from './constants';

export const fetchTaskDetails = (params) => (
  {
    type: TASK_FETCH_DETAILS,
    payload: { ...params },
  }
);

export const fetchJob = (jobId) => (
  {
    type: JOBS_DETAIL_FETCH,
    payload: { jobId },
  }
);
