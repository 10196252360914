import { Router } from 'utils/withRouter';
import { LOGIN_REQUESTED, LOGIN_GET_SESSION_PENDING, LOAD_CURRENT_ENV, FETCH_STARRED_USER_STRATEGY } from './constants';

export const login = (oktaRes, router: Router) => ({
  type: LOGIN_REQUESTED,
  payload: { oktaRes, router },
});

export const getSession = (router: Omit<Router, 'params'>) => ({
  type: LOGIN_GET_SESSION_PENDING,
  payload: { router },
});

export const setCurrentEnv = (env: string) => ({
  type: LOAD_CURRENT_ENV,
  payload: env,
});

export const fetchStarredUserStrategy = (id: number) => ({
  type: FETCH_STARRED_USER_STRATEGY.STARTED,
  payload: id,
});
