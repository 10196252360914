import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Grid } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { ButtonClickHandler, User } from 'utils/types';
import { ConfirmationModal } from './ConfirmationModal';
import { TriggerButtonProps } from '../types';
import { messageFooter } from '../style';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;

const { undoBtn, submitBtn, footerCol, footerRow } = messageFooter;

export const TriggerButton = ({ onClick, disabled } : TriggerButtonProps) => (
  <Button
    style={{ ...submitBtn, backgroundColor: (disabled ? BLUES.B100_FISH : BLUES.B500_WAVE) }}
    disabled={disabled}
    onClick={onClick}
    primary
  >
    Submit
  </Button>
);

type MessageFooterProps = {
  user: User
  open: boolean
  onCancel: ButtonClickHandler
  content: string
  onConfirm: ButtonClickHandler
  loading: boolean
  handleReset: ButtonClickHandler
};

const MessageFooter = ({
  user,
  open,
  onCancel,
  content,
  onConfirm,
  loading,
  handleReset,
}: MessageFooterProps) => {
  const { getValues, formState: { errors } } = useFormContext();
  const { subject, priority } = getValues();
  const qAReadOnly = isQAGlobalReadOnly(user);

  const disable = qAReadOnly || !_.isEmpty(errors) || !subject || !content || !priority;
  return (
    <Grid.Row style={footerRow}>
      <Grid.Column style={footerCol}>
        <Button
          type="button"
          onClick={handleReset}
          style={undoBtn}
          secondary
        >
          Undo
        </Button>
        <ConfirmationModal
          trigger={<TriggerButton onClick={onCancel} disabled={disable} />}
          open={open}
          onCancel={onCancel}
          content={content}
          onConfirm={onConfirm}
          loading={loading}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default MessageFooter;
