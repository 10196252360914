import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppListItem, WppIconError, WppIconSuccess, WppActionButton } from 'buildingBlocks';
import { selectTask } from '../actions';
import { Task as TaskType } from '../utils';
import { DashboardStateType, Run } from '../types';
import { errorIconStyle, successIconStyle, listItemSelected, listItemSelectedLabel, showLogButtonSytle, showLogButtonHide } from '../style';

const itemStatus = (task: TaskType) => _.get(
  {
    success: ['green', 'check'],
    failure: ['red', 'x'],
  },
  task.status,
);

const Task: React.FC<{ task: TaskType, onLogLoad: (run: Run, task: TaskType) => void; }> = ({ task, onLogLoad }) => {
  const { selectedRun, selectedTask } = useSelector((state: { dashboard: DashboardStateType }) => state.dashboard);
  const dispatch = useDispatch();
  const active = selectedTask && selectedTask.name === task.name;
  const renderIcon = () => {
    const iconType = itemStatus(task)[0];
    return (
      <>
        {_.isEqual(iconType, 'green') && (
          <WppIconSuccess slot="left" style={successIconStyle} />
        )}
        {_.isEqual(iconType, 'red') && (
          <WppIconError slot="left" style={errorIconStyle} />
        )}
      </>
    );
  };

  // Dispatch the selectTask action when the item is clicked
  const handleSelectTask = () => {
    dispatch(selectTask(task));
  };

  return (
    <WppListItem onWppChangeListItem={handleSelectTask} active={active} style={active ? listItemSelected : null}>
      <span slot="label" style={active ? listItemSelectedLabel : null}>{task.displayName}</span>
      <span slot="caption">{task.results.length} outputs</span>
      {renderIcon()}
      <WppActionButton
        variant="secondary"
        slot="right"
        style={active ? showLogButtonSytle : showLogButtonHide}
        onClick={() => onLogLoad(selectedRun, selectedTask)}
      >
        Show Log
      </WppActionButton>
    </WppListItem>
  );
};

export default Task;
