import { CSSProperties } from 'react';

const appStyles: { [key: string]: CSSProperties } = {
  mainContainer: { backgroundColor: '#eee' },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

export default appStyles;
