import React from 'react';
import { Form, WppGrid, WppDivider } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { wppBodyContainer } from 'components/PageTemplate/style';
import ModuleHeader from './Header';

const { divider } = strategyWizardStyles;

type Props = {
  strategyType: StrategyType
  hasBudgetAllocationError: boolean
  disabled?: boolean
  loading?: boolean
  onSubmit?: () => void
  onBackClick?: () => void
  children?: React.ReactNode
};

const ModuleForm = (props: Props) => {
  const { onSubmit, onBackClick, strategyType, loading, disabled, hasBudgetAllocationError } = props;

  return (
    <Form
      noValidate
      loading={loading}
    >
      <div style={wppBodyContainer}>
        <WppGrid container fullWidth item all={24}>
          <WppGrid container item all={24}>
            <ModuleHeader {...strategyType} />
          </WppGrid>
          {!hasBudgetAllocationError && <WppDivider style={divider} />}
          <WppGrid container item all={24}>
            {props.children}
          </WppGrid>
        </WppGrid>
      </div>
      <NavFooter
        backButtonText="Back"
        onBackClick={onBackClick}
        showBackButton
        nextButtonText="Next"
        nextButtonDisabled={disabled}
        onNextClick={onSubmit}
      />
    </Form>
  );
};

export default ModuleForm;
