import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import { WppInlineMessage, WppButton, WppSpinner, WppSelect, WppListItem, WppTypography } from 'buildingBlocks';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import authTokenPageStyle from 'containers/AuthToken/style';
import { getAuthHeaders } from './actions';
import { Headers, LoadingState } from './types';
import { LOADING_STATES, EXPIRE_TEXT, FAILURE_TEXT } from './constants';

type Props = {
  headers: Headers
  getAuthHeaders: typeof getAuthHeaders
  loadingState: LoadingState
};

type StoreState = {
  authToken: {
    headers: Headers
    loadingState: LoadingState
  },
};

type AuthDisplayFormatComponentProps = {
  headers: Headers
  setAuthTokenValue: Dispatch<SetStateAction<string>>
};

type AuthTokenDisplayProps = {
  headers: Headers
  loadingState: LoadingState
  setAuthTokenValue: Dispatch<SetStateAction<string>>
};

const formatOptions = [
  {
    key: 'keyvalue',
    text: 'Key / Value',
    value: 'keyvalue',
  },
  {
    key: 'JSON',
    text: 'JSON',
    value: 'json',
  },
];

export const AuthDisplayFormatComponent = ({ headers, setAuthTokenValue }: AuthDisplayFormatComponentProps) => {
  const [selectedFormat, setFormat] = useState('keyvalue');
  const keyValueToken = _.map(headers, (value, key) => `${key}: ${value}`);
  const jsonToken = JSON.stringify(headers, null, 2);
  const authTokenFormattedValue = _.isEqual(selectedFormat, 'keyvalue') ? keyValueToken.join('\n') : jsonToken;

  useEffect(() => {
    setAuthTokenValue(authTokenFormattedValue);
  }, [authTokenFormattedValue, setAuthTokenValue]);

  return (
    <>
      <WppSelect
        onWppChange={(event) => setFormat(event.detail.value)}
        value={selectedFormat}
        style={authTokenPageStyle.selectContainer}
        dropdownWidth="200"
        placeholder="Select Format"
        size="s"
      >
        {formatOptions.map((option) => (
          <WppListItem key={option.key} value={option.value}>
            <p slot="label">{option.text}</p>
          </WppListItem>
        ))}
      </WppSelect>
      <div style={authTokenPageStyle.formattedOutput} data-test-id="auth-display-format">
        <WppTypography type="s-body"><pre>{authTokenFormattedValue}</pre></WppTypography>
      </div>
      <WppInlineMessage size="s" message={EXPIRE_TEXT} type="information" showTooltipFrom={100} />
    </>
  );
};

export const AuthTokenDisplay = ({ loadingState, headers, setAuthTokenValue }: AuthTokenDisplayProps) => {
  if (loadingState === LOADING_STATES.error) {
    return <WppTypography type="l-strong">{FAILURE_TEXT}</WppTypography>;
  }

  return <AuthDisplayFormatComponent headers={headers} setAuthTokenValue={setAuthTokenValue} />;
};

export const AuthToken = ({ headers, getAuthHeaders: fetchAuthHeaders, loadingState }: Props) => {
  const [authTokenValue, setAuthTokenValue] = useState<string>('');
  const [copyToClipBoardMessage, setCopyToClicpBoardMessage] = useState<boolean>(false);

  useMount(() => {
    fetchAuthHeaders();
  });

  const handleGenerateNewToken = () => {
    setCopyToClicpBoardMessage(false);
    fetchAuthHeaders();
  };

  const handleCopyToClipboard = () => {
    if (authTokenValue) {
      setCopyToClicpBoardMessage(true);
    }
  };

  return (
    <WppPermissionPageTemplate
      name="Generate API Auth Token"
      permissions={[Permission.manageJobs]}
    >
      {
        loadingState === LOADING_STATES.loading ? (
          <div style={authTokenPageStyle.spinnerStyle}><WppSpinner size="l" /></div>
        ) : (
          <>
            <AuthTokenDisplay loadingState={loadingState} headers={headers} setAuthTokenValue={setAuthTokenValue} />
            <div style={authTokenPageStyle.buttonContainer}>
              <WppButton onClick={handleGenerateNewToken}>Generate New Token</WppButton>
              <CopyToClipboard variant="secondary" text={authTokenValue}>
                <WppButton onClick={handleCopyToClipboard}>Copy to Clipboard</WppButton>
              </CopyToClipboard>
              {copyToClipBoardMessage && <WppInlineMessage size="s" message="Token copied to clipboard." type="success" showTooltipFrom={100} />}
            </div>
          </>
        )
      }
    </WppPermissionPageTemplate>
  );
};

const mapStateToProps = (state: StoreState) => ({
  headers: state.authToken.headers,
  loadingState: state.authToken.loadingState,
});

export default connect(mapStateToProps, { getAuthHeaders })(AuthToken);
