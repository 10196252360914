import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { WppDatepicker } from 'buildingBlocks';
import { NUMBER_DATE_FORMAT, WPP_DATE_PICKER_FORMAT } from 'utils/dateTime';
import { DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types';
import 'containers/BulkCreateStrategyWizard/style.scss';

type FlightDateFilterProps = {
  flightDate: { startDate: string; endDate: string } | null
  onFlightDateChange: (date: { startDate: string; endDate: string }) => void
  disabled: boolean
};

const FlightDateFilter = (props: FlightDateFilterProps) => {
  const { flightDate, onFlightDateChange, disabled } = props;

  const handleFlightDateChange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const { formattedDate } = event.detail;
    if (_.size(formattedDate) === 2) {
      onFlightDateChange({ startDate: formattedDate[0], endDate: formattedDate[1] });
    }
  };

  return (
    <WppDatepicker
      className="flightDatesFilter"
      range
      onWppChange={handleFlightDateChange}
      onWppDateClear={() => onFlightDateChange(null)}
      placeholder="Flight Dates"
      locale={{
        dateFormat: WPP_DATE_PICKER_FORMAT,
      }}
      disabled={disabled}
      size="s"
      style={{ width: 295 }}
      value={flightDate ? [flightDate.startDate, flightDate.endDate] : null}
      minDate={moment().subtract(5, 'years').format(NUMBER_DATE_FORMAT)}
      maxDate={moment().add(5, 'years').format(NUMBER_DATE_FORMAT)}
    />
  );
};

export default FlightDateFilter;
