import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'reducers';
import { Form, WppGrid, WppInlineMessage, WppTypography } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import PermissionWrapper from 'components/PermissionWrapper';
import { Permission, checkPermissions } from 'utils/featureFlags';
import { isQAGlobalReadOnly, useAsyncEffect } from 'utils/functionHelpers';
import { Flight, User } from 'utils/types';
import AdminConfig from 'containers/StrategyWizard/steps/StrategyConfirmation/confirmComponents/AdminConfig';
import { updateFormWithAdminBoxValues } from 'containers/StrategyWizard/steps/StrategyConfiguration/utils';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { BulkCreateStrategyConfirmationForm } from 'containers/BulkCreateStrategyWizard/types';
import { useFetchDspSpecificChildren } from 'containers/StrategyWizard/utils';
import { hasAllTrueViewAndZeroNonSkippableLIs } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import ConfirmButton from './ConfirmComponents/ConfirmButton';
import StrategiesTable from './ConfirmComponents/StrategiesTable';
import { getStrategiesData, handleBulkCreateWizardSubmit } from './utils';
import { CONTAINER_NAME } from './constants';
import { strategyTableHeader } from './style';

const { strategyTitleMarginBottom } = strategyTableHeader;

const Confirm = () => {
  const reduxDispatch = useDispatch();
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isQAGlobal = isQAGlobalReadOnly(user);
  const { wizardFormValues, dispatch, router: { navigate } } = useBulkCreateStrategyWizardContext();
  const {
    attachFlightsStep,
    goalSelectionStep,
    strategyType,
    strategyConfigurationStep,
    strategyConfirmationStep: { userBidModifierRemovalConsent },
    budgetAllocationState,
  } = wizardFormValues;
  const {
    member,
    attachedFlights,
  } = attachFlightsStep;

  const canSaveStrategy = checkPermissions(user, Permission.saveUpdateStrategy, member);
  const formMethods = useForm<BulkCreateStrategyConfirmationForm>({ mode: 'onChange' });
  const { goal, budget } = goalSelectionStep;
  const revenueOutcomeType = _.get(goalSelectionStep, 'revenueOutcomeType');
  const hasFlightsAttached = _.size(attachedFlights) > 0;
  const { getValues, reset, control, formState, setValue } = formMethods;
  const strategiesToCreate = useWatch({ name: 'strategies', control });
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [updateAdminConfig, setUpdateAdminConfig] = useState<boolean>(!!_.get(strategyConfigurationStep, 'minDeliveryFracLever'));
  const disabled = !canSaveStrategy || !_.isEmpty(formState.errors) || isQAGlobal || !_.size(strategiesToCreate);
  const firstflight: Flight = _.first(attachedFlights);
  const firstExtId = _.get(firstflight, 'externalId');
  const dsp = _.get(firstflight, 'dsp');
  const dspSpecificChildrenState = useFetchDspSpecificChildren(dsp, budgetAllocationState, firstExtId);
  const hasAllTrueViewLIs = hasAllTrueViewAndZeroNonSkippableLIs(_.get(dspSpecificChildrenState, 'data', []));

  useAsyncEffect(async () => {
    const strategies = await getStrategiesData(wizardFormValues);
    setValue('strategies', strategies);
  }, [wizardFormValues]);

  useEffect(() => {
    reset({ userBidModifierRemovalConsent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBidModifierRemovalConsent]);

  const strategyTypeId = _.get(strategyType, 'id');
  const strategyGoals = [goal];

  const formValuesWithStrategyGoals = {
    ...strategyConfigurationStep,
    strategyGoals,
    ...(goal.impValueFilters && { impValueFilters: goal.impValueFilters }),
    ...(_.size(budget) && { hasCustomRevenueType: true, revenueOutcomeType }),
  };

  if (hasFlightsAttached) {
    return (
      <FormProvider {...formMethods}>
        <Form
          onSubmit={() => {
            setSavingInProgress(true);
            handleBulkCreateWizardSubmit(
              {
                ...wizardFormValues,
                strategyConfirmationStep: {
                  userBidModifierRemovalConsent: getValues('userBidModifierRemovalConsent'),
                  strategies: strategiesToCreate,
                },
              },
              navigate,
              updateAdminConfig,
              reduxDispatch,
            );
          }}
          loading={savingInProgress}
        >
          <WppGrid container fullWidth item all={24}>
            <WppGrid item all={24}>
              <div style={wppBodyContainer}>
                <WppTypography type="xl-heading" style={strategyTitleMarginBottom}>{CONTAINER_NAME}</WppTypography>
                {_.size(strategiesToCreate) > 0
                  ? <StrategiesTable hasAllTrueViewLIs={hasAllTrueViewLIs} strategiesToCreate={strategiesToCreate} />
                  : (
                    <WppInlineMessage
                      className="validated-error-msg"
                      size="s"
                      message="No strategies to display.!"
                      type="error"
                    />
                  )}
              </div>
            </WppGrid>
            <PermissionWrapper permissions={Permission.wizardAdmin}>
              <AdminConfig
                name="adminBox"
                strategyWizardFormValues={wizardFormValues}
                strategyType={strategyType}
                member={member}
                advertiser={null}
                defaultCurrency={null}
                formValues={formValuesWithStrategyGoals}
                updateAdminConfig={updateAdminConfig}
                setUpdateAdminConfig={setUpdateAdminConfig}
                onEditorSubmit={(values) => {
                  updateFormWithAdminBoxValues(values, strategyTypeId, strategyConfigurationStep, goalSelectionStep, dispatch, _.get(strategyType, 'dsp'));
                }}
              />
            </PermissionWrapper>
          </WppGrid>
          <ConfirmButton
            disabled={disabled}
          />
        </Form>
      </FormProvider>
    );
  }
  return <></>;
};

export default Confirm;
