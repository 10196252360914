import _ from 'lodash';
import moment from 'moment-timezone';
import qs from 'qs';
import { dateTimeFormatter } from 'utils/dateTime';
import { SemanticCOLORS } from 'buildingBlocks';

import {
  JOB_STATUS_COLOR_MAP,
  JOB_STATUS_OPTIONS_OBJ,
  DEFAULT_JOB_STATUS_COLOR,
  DEFAULT_JOB_POPULATE,
  DEFAULT_WHERE,
  STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import {
  JobProps, JobStatusValue, JobTypeOption, JobStatusOption, MemberOption, Job, Filter, Query,
} from './types';

const optionValues = (options: Array<unknown>) => _.map(options, 'value');

const sortDirectionMap = {
  ascending: 'ASC',
  descending: 'DESC',
};

export const serializedDatesForState = (state: JobProps) => {
  const { startDate, endDate } = state.filter;
  const dates = _({ startDate, endDate })
    .chain()
    .omitBy(_.isNil)
    .mapValues((d) => dateTimeFormatter.isoDateTime(d))
    .value();
  return dates;
};

export const colorForStatus = (status: JobStatusValue): SemanticCOLORS => _.get(JOB_STATUS_COLOR_MAP, status, DEFAULT_JOB_STATUS_COLOR);

export const parseFilterFromUrl = (
  jobTypeOptions: Array<JobTypeOption>,
  jobStatusOptions: Array<JobStatusOption>,
  memberOptions: Array<MemberOption>,
  location: Location,
) => {
  const filter: Filter = {};

  const query = qs.parse(location.search.replace(/^\?/, ''));

  const limit = (_.toNumber(query.limit) > 0 && parseInt(query.limit as string, 10)) || STARTING_ELEMENTS_PER_PAGE;
  const skip = (_.toNumber(query.skip) >= 0 && parseInt(query.skip as string, 10)) || 0;
  const range = { limit, skip };

  if (query.startDate) {
    filter.startDate = moment(query.startDate as string);
  }
  if (query.endDate) {
    filter.endDate = moment(query.endDate as string);
  }

  if (_.isArray(query.type)) {
    filter.type = _.filter(jobTypeOptions, (o: JobTypeOption) => _.includes(query.type, o.value));
  }
  if (_.isArray(query.status)) {
    filter.status = _.filter(jobStatusOptions, (o: JobStatusOption) => _.includes(query.status, o.value));
  }
  if (_.isArray(query.member)) {
    const memberIds = _.map(query.member, _.toNumber);
    filter.member = _.filter(memberOptions, (o: MemberOption) => _.includes(memberIds, o.value));
  }

  return { filter, range };
};

export const urlQueryForState = (state: JobProps) => {
  const { type, status, member } = state.filter;
  const query: Query = {
    ...state.range,
    type: optionValues(type),
    status: optionValues(status),
    member: optionValues(member),
  };

  const dates = serializedDatesForState(state);
  if (dates.startDate) {
    query.startDate = dates.startDate;
  }
  if (dates.endDate) {
    query.endDate = dates.endDate;
  }

  return query;
};

export const apiJobQueryForState = (state: JobProps) => {
  const { type, status, member } = state.filter;
  const { limit, skip } = state.range;
  const { column, direction } = state.sort;
  // Serialize dates.
  const dates = serializedDatesForState(state);
  const dateFilter = { createdAt: {} };
  if (_.get(dates, 'startDate')) {
    dateFilter.createdAt['>='] = dates.startDate;
  }
  if (_.get(dates, 'endDate')) {
    dateFilter.createdAt['<='] = dates.endDate;
  }

  const sortString = `${column} ${_.get(sortDirectionMap, direction)}`;

  const where = {
    ...DEFAULT_WHERE,
    ...dateFilter,
    type: optionValues(type),
    status: optionValues(status),
    member: optionValues(member),
  };

  return {
    sort: sortString,
    populate: DEFAULT_JOB_POPULATE,
    limit,
    skip,
    where,
  };
};

export const jobStatusText = (job: Job) => _.get(JOB_STATUS_OPTIONS_OBJ[job.status], 'text');

export const jobStatusColor = (job: Job): SemanticCOLORS => _.get(JOB_STATUS_COLOR_MAP, job.status);
