import React, { Dispatch, SetStateAction, useRef } from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { WppActionButton, WppIconEdit, WppIconMore, WppIconTrash, WppListItem, WppPopover, WppTypography } from 'buildingBlocks';
import { DEMO_SITES_STYLE } from '../styles';

const { popOverContent, actionButtonDiv } = DEMO_SITES_STYLE;

type Props = {
  stratIndex: number
  setStratIndex: Dispatch<SetStateAction<number>>
  handleStrategyModalOpen: () => void
};

export const ActionsPopover = ({ stratIndex, setStratIndex, handleStrategyModalOpen }: Props) => {
  const ref = useRef<HTMLWppPopoverElement>(null);
  const { getValues, setValue } = useFormContext();

  const handleStrategyDelete = () => {
    const strategiesTemp = getValues('strategies');
    // @ts-ignore unusedVal issue
    const updatedStrategies = strategiesTemp.filter((unusedVal, index) => index !== stratIndex);
    setValue('strategies', updatedStrategies);
  };

  const handleStrategyEdit = () => {
    setStratIndex(stratIndex);
    handleStrategyModalOpen();
  };

  return (
    <div slot="actions">
      <WppPopover
        config={{ placement: 'left-end' }}
        ref={ref}
      >
        <WppActionButton variant="secondary" slot="trigger-element">
          <WppIconMore slot="icon-start" direction="horizontal" />
        </WppActionButton>
        <div style={popOverContent}>
          <WppListItem
            key="edit"
            onWppChangeListItem={handleStrategyEdit}
          >
            <div slot="label" style={_.omit(actionButtonDiv, 'justifyContent')}>
              <WppIconEdit color="var(--wpp-grey-color-700)" />
              <WppTypography tag="span" type="s-midi">
                Edit
              </WppTypography>
            </div>
          </WppListItem>
          <WppListItem
            key="delete"
            onWppChangeListItem={handleStrategyDelete}
          >
            <div slot="label" style={_.omit(actionButtonDiv, 'justifyContent')}>
              <WppIconTrash color="var(--wpp-grey-color-700)" />
              <WppTypography tag="span" type="s-midi">
                Delete
              </WppTypography>
            </div>
          </WppListItem>
        </div>
      </WppPopover>
    </div>
  );
};
