import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import qs from 'qs';
import { STRATEGY_TYPE } from 'constantsBase';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { CONFIGURE_STRATEGY, RESET_NEXT_BTN_CLICK, SET_NEXT_BTN_CLICK, WizardSteps, WizardMode } from 'containers/StrategyWizard/constants';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { useInitialDataFetcher } from 'containers/StrategyWizard/hooks';
import { StrategyConfigurationStep as StrategyConfigurationStepFormType } from 'containers/StrategyWizard/types';
import { QSParams } from 'containers/StrategyWizard/utils';
import { getStepUrl } from 'containers/BulkCreateStrategyWizard/utils';
import { createLinkWithQS } from 'utils/functionHelpers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { StrategyType } from 'utils/types';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { isBudgetOptimization } from 'containers/StrategyWizard/steps/StrategyConfirmation/utils';
import { getValidatorPerStrategyType } from 'containers/StrategyWizard/steps/StrategyConfiguration/validate';
import { BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS } from 'containers/BulkCreateStrategyWizard/constants';
import BulkCreateLoader from './components/BulkCreateLoader';
import StrategyConfigurationForm from './components/StrategyConfigurationForm';

type StrategyConfigurationStepProps = {
  qsParams: QSParams
};

const StrategyConfigurationStep = (props: StrategyConfigurationStepProps) => {
  const { qsParams } = props;
  const { wizardFormValues, dispatch, router: { navigate } } = useBulkCreateStrategyWizardContext();
  const {
    attachFlightsStep: { member, attachedFlights },
    goalSelectionStep,
    strategyConfigurationStep,
    budgetAllocationState,
    strategyType,
  } = wizardFormValues;
  const goalType = _.get(goalSelectionStep, 'goal.type');
  const strategyTypeId = _.toNumber(_.get(qsParams, 'wizardForm.strategyType'));
  const stratType = strategyType ?? STRATEGY_TYPE.getById(strategyTypeId);

  const loadingState = useInitialDataFetcher(
    _.isEmpty(strategyConfigurationStep) ? WizardMode.newStrategy : WizardMode.formPreviouslyFilled,
    member,
    null,
    attachedFlights,
    stratType as StrategyType,
    undefined,
    strategyConfigurationStep,
    null,
    goalType,
    _.get(qsParams, 'populateSegments', false),
    false,
    goalSelectionStep,
  );

  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const nextLink = createLinkWithQS(getStepUrl(BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id));

  const initialValues = _.get(loadingState, 'data');
  const formMethods = useForm<StrategyConfigurationStepFormType>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: getValidatorPerStrategyType(strategyTypeId ?? _.get(strategyType, 'id')),
  });

  const { reset, formState: { errors }, getValues } = formMethods;
  const isBudgetOptimizationStrategy = isBudgetOptimization(strategyType?.id);
  const hasBudgetAllocationError = (isBudgetOptimizationStrategy && budgetAllocationState.kind === Status.error);

  useMount(() => {
    dispatch({ type: RESET_NEXT_BTN_CLICK });
  });

  useEffect(() => {
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const updateQueryString = (values: StrategyConfigurationStepFormType) => {
    // omit some values from being set in the url because they cause very long urls and chrome has a 64k char limit
    const filteredFormValues = _.omit(values, ['groupSettings']);
    navigate(`?${qs.stringify({ ...qsParams, moduleForm: filteredFormValues })}`, { replace: true });
  };

  // redirect after the form has been submitted successfully
  if (shouldRedirect) {
    return <Navigate to={nextLink} />;
  }

  const goToConfirmStep = () => {
    const strategyConfiguration = getValues();
    dispatch({ type: SET_NEXT_BTN_CLICK, payload: true });
    dispatch({ type: CONFIGURE_STRATEGY, payload: strategyConfiguration });
    // omit prevSetIntelChildObj for QS - only used to track if user touched INT toggle
    updateQueryString(_.omit(strategyConfiguration, 'prevSetIntelChildObj') as StrategyConfigurationStepFormType);
    setShouldRedirect(true);
  };

  const handleSubmit = () => goToConfirmStep();

  const onBackClick = () => {
    const strategyConfigureId = BULK_CREATE_WIZARD_STEPS[WizardSteps.goalSelectionStep].id;
    navigate(createLinkWithQS(`${BULK_CREATE_WIZARD_BASE}${strategyConfigureId}`));
  };

  switch (loadingState.kind) {
    case PossibleStates.hasData: {
      return (
        <StrategyConfigurationForm
          strategyType={strategyType}
          hasBudgetAllocationError={hasBudgetAllocationError}
          disabled={!_.isEmpty(errors) || hasBudgetAllocationError}
          onSubmit={handleSubmit}
          onBackClick={onBackClick}
        >
          {
            _.size(attachedFlights) > 0 && (
              <FormProvider {...formMethods}>
                <BulkCreateLoader
                  strategyTypeId={strategyTypeId}
                  initialValues={initialValues}
                  setShouldRedirect={setShouldRedirect}
                  goToConfirmStep={goToConfirmStep}
                />
              </FormProvider>
            )
          }
        </StrategyConfigurationForm>
      );
    }

    case PossibleStates.initial:
    case PossibleStates.loading:
    default:
      return (
        <StrategyConfigurationForm
          strategyType={strategyType}
          hasBudgetAllocationError={hasBudgetAllocationError}
          loading
        />
      );
  }
};

export default StrategyConfigurationStep;
