import { CSSProperties } from 'react';
import { COPILOT_COLORS } from 'globalStyles';

export const headerLogo = {
  background: 'url(/img/copilot-logo-main.svg) center center no-repeat',
  backgroundSize: '50%',
  padding: '3.5rem 2rem',
};

export const loginTroubleshootWrapper: CSSProperties = {
  background: 'white',
  margin: 'auto',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '400px',
  height: '845px',
  padding: '20px',
  zIndex: 4000,
};

export const needHelpLink = {
  marginTop: 10,
  cursor: 'pointer',
  color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
};

export const loginStyles: { [key: string]: CSSProperties } = {
  loginWrapper: {
    flexDirection: 'column',
  },
  oktaContainer: {
    position: 'relative',
  },
  signInMsg: {
    position: 'absolute',
    zIndex: 1,
    top: '209px',
    width: '400px',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    textAlign: 'center',
    fontSize: 12,
  },
};
