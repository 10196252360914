import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { STRATEGY_TYPE, DSP, RESULTS_LIMIT } from 'constantsBase';
import { BudgetAllocationData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { DisabledStrategyTypeReasons, StrategyWizardStates, WizardMode } from 'containers/StrategyWizard/constants';
import { StrategyConfigurationStep, StrategyWizardResponse, WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { getOptLevelByFlightExtType, hasExternalCustomValueMetric, ModuleInitStates, QSParams } from 'containers/StrategyWizard/utils';
import { Member, FlightCandidate, StrategyType, StrategyRecommendation } from 'utils/copilotAPI';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';
import { StrategyType as STType, Member as MemberType, Flight } from 'utils/types';
import { getInitialValuesByStrategyType } from 'containers/StrategyWizard/steps/StrategyConfiguration/utils';
import { ExternalTypeOptimizationLevel, OPTIMIZATION_LEVELS } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI } from '../constants';
import { fetchBulkBudgetAllocationData } from '../steps/AttachFlights/utils';
import { BulkCreateWizardFormValues } from '../types';
import { getInitialValuesByExternalType } from '../utils';

export const useBulkStrategyWizardInitialization = (
  mode: WizardMode,
  member: MemberType,
  attachedFlights: Array<Flight>,
  strategyType: STType,
  moduleFormValues: StrategyConfigurationStep,
  goalType: string,
  hasMultiRevType: boolean,
  goalSelectionStep: WizardFormGoalSelection,
) => {
  const [currentState, setCurrentState] = useState<ModuleInitStates>({
    kind: PossibleStates.initial,
  });
  const bulkCreateWizardMode: WizardMode = WizardMode.newStrategy;
  // hack: fix for react memory leak error
  const isMounted = useRef<boolean>(false);
  const strategyTypeId = _.get(strategyType, 'id');

  const getInitData = async () => {
    setCurrentState({ kind: PossibleStates.loading });
    const externalType = _.get(_.head(attachedFlights), 'externalType');
    const data = await getInitialValuesByExternalType(externalType);
    return data;
  };

  // when loading strategy via QS the mode will default to newStrategy until context populates
  useEffect(() => {
    isMounted.current = true;
    const initializeFormValues = async () => {
      const defaultValues = await getInitData();
      const data = (mode === bulkCreateWizardMode)
        ? defaultValues
        : { ...defaultValues, ...moduleFormValues };
      const hasExternalCustomValue = hasExternalCustomValueMetric(goalType, goalSelectionStep);
      if (hasExternalCustomValue || _.isEqual(strategyTypeId, STRATEGY_TYPE.amznBudgetOptimization.id) || hasMultiRevType) {
        _.set(data, 'intelligentChildObjects', false);
      }
      // logic for setting intelligentChildObj here bc step 4 form rerenders multiple times due to useEffect for initialValues
      if (!hasMultiRevType && !hasExternalCustomValue) {
        const onlyAMZNFlights = _.every(attachedFlights, ['dsp', DSP.AMZN.id]);
        const intelligentChildObj = _.get(moduleFormValues, 'prevSetIntelChildObj') ? (!onlyAMZNFlights && _.get(moduleFormValues, 'intelligentChildObjects')) : !onlyAMZNFlights;
        _.set(data, 'intelligentChildObjects', intelligentChildObj);
      }
      if (isMounted.current) {
        setCurrentState({ kind: PossibleStates.hasData, data });
      }
    };

    if (mode === bulkCreateWizardMode) {
      initializeFormValues();
    }
    // cleanup function
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, member]);

  return currentState;
};

// if the query string has null values (?a=&b=3) or (?a&b=3) the value 'a' gets parsed to empty string ie { a: '', b: 3}
// this removes any values that may have gotten passed as empty.
const removeEmptyQsValues = (qsParams: QSParams) => _.omitBy(
  {
    ...qsParams.wizardForm,
    flightExtIds: _.filter(qsParams.wizardForm.flightExtIds, _.negate(_.isEmpty)),
    moduleForm: _.omitBy({ ...qsParams.moduleForm }, _.isNil),
  },
  _.isEmpty,
);

// make appropriate api calls to get the full objects from the DB based on query string IDs, which will be used to
// initialize the form
const getPopulatedValuesFromQueryString = async (
  filteredQsValues: any,
) => {
  const {
    flightExtIds,
    member: memberExtId,
    strategyType: strategyTypeId,
    optimizationLevel,
    goalType,
    goalTarget,
    moduleForm,
  } = filteredQsValues;
  // @ts-ignore: ignore error about missing 'PartialDeep' export in the '_' namespace
  const populatedValues: _.PartialDeep<BulkCreateWizardFormValues> = {};
  // populate member
  if (memberExtId) {
    const memberRes = await Member.get({ externalId: memberExtId });
    populatedValues.attachFlightsStep = {
      member: _.head(memberRes.data),
    };
  }

  populatedValues.attachFlightsStep.optimizationLevel = getOptLevelByFlightExtType(populatedValues.attachFlightsStep.member.dsp, +optimizationLevel);

  // function to check if an object is a valid Flight
  function isValidFlight(obj: any): boolean {
    return _.has(obj, 'id') && _.has(obj, 'externalId');
  }

  // load flight candidates
  if (flightExtIds) {
    const { id: memberId, dsp } = _.get(populatedValues.attachFlightsStep, 'member');
    const flightCandidatesRes = await FlightCandidate.get({
      advertiserId: 'ALL',
      memberId,
      limit: RESULTS_LIMIT,
      sort: 'externalId DESC',
      isCrossPlatform: false,
      externalIds: flightExtIds,
      externalTypeIds: OPTIMIZATION_LEVELS[dsp][ExternalTypeOptimizationLevel.HIGHER_ORDER].externalTypeIds,
    });
    const attachedFlights = _.uniqBy(_.filter(flightCandidatesRes.data, isValidFlight), 'externalId') as Array<Flight>;
    populatedValues.attachFlightsStep.attachedFlights = attachedFlights;
  }

  // load strategy type
  if (strategyTypeId && (populatedValues.attachFlightsStep.member)) {
    const strategyTypeRes = await StrategyType.get({ id: strategyTypeId });
    if (
      _.get(populatedValues.attachFlightsStep, 'member.dsp') === DSP.APN.id
      && !_.isEmpty(populatedValues.attachFlightsStep.attachedFlights)
    ) {
      const restrictionsRes = await StrategyRecommendation.stratTypeRestrictions(
        _.map(populatedValues.attachFlightsStep.attachedFlights, (af) => ({
          ...af,
          dsp: DSP.APN.id,
        })),
      );
      // we currently only need unsupportedAdTypes and unsupportedFlightTypes to determine strategy type's validity
      const restrictionsForStrategyType = _.pick(restrictionsRes.data[strategyTypeId], [
        DisabledStrategyTypeReasons.unsupportedAdTypes,
        DisabledStrategyTypeReasons.unsupportedFlightTypes,
      ]);
      const strategyTypeValid = _.isEmpty(_.flatten(_.values(restrictionsForStrategyType)));
      if (strategyTypeValid) {
        populatedValues.strategyType = strategyTypeRes.data[0];
      }
    } else {
      populatedValues.strategyType = strategyTypeRes.data[0];
    }
  }

  // load goal type and target and budget info
  if (goalType && goalTarget) {
    // QS pixels are stored with dsp coded keys, and revType only present in QS in Single Platform Campaign Opt Type
    populatedValues.goalSelectionStep = {
      goal: {
        type: goalType,
        target: _.toNumber(goalTarget),
      },
    };
  }

  // load strategy config
  if (strategyTypeId) {
    const baseDefaultConfig = await getInitialValuesByStrategyType(strategyTypeId);
    populatedValues.strategyConfigurationStep = moduleForm ? { ...baseDefaultConfig, ...moduleForm } : baseDefaultConfig;
  } else {
    populatedValues.strategyConfigurationStep = {};
  }

  return { ..._.omit(BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI, 'budgetAllocationState'), ...populatedValues };
};

const initNewStrategy = async (
  qsParams: QSParams,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  if (!_.get(qsParams, 'wizardForm')) {
    return { kind: StrategyWizardStates.newStrategy };
  }

  const filteredQsValues = removeEmptyQsValues(qsParams);
  try {
    const populatedValues = await getPopulatedValuesFromQueryString(
      filteredQsValues,
    );
    // fetch budget opt data if flights are higher order
    const attachedFlights = populatedValues.attachFlightsStep.attachedFlights as Array<Flight>;
    await fetchBulkBudgetAllocationData(
      attachedFlights,
      budgetAllocationData,
      dispatch,
      _.get(populatedValues, 'optimizationLevel.defaultStrat'),
    );

    return { kind: StrategyWizardStates.initFromQs, data: populatedValues };
  } catch (error) {
    return { kind: StrategyWizardStates.newStrategy };
  }
};

export default (
  qsParams: QSParams,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  dispatch: Dispatch<SetStateAction<any>>,
): StrategyWizardResponse => {
  const getStrategy = async () => {
    const newStrategyRes = await initNewStrategy(qsParams, budgetAllocationData, dispatch);
    return newStrategyRes;
  };
  const currentState = useFetcher(getStrategy);
  if (currentState.kind === PossibleStates.error && currentState.errorObject?.response?.status === 403) {
    return { kind: StrategyWizardStates.unauthorized };
  }
  return currentState;
};
