import React from 'react';
import { connect } from 'react-redux';
import { WppIconLogout, WppListItem } from 'buildingBlocks';
import { clearLocalStorage } from 'utils/localstorage/actions';

import logout from './actions';

type Props = {
  session: {}
  logout: Function
  clearLocalStorage: Function
};

export const Logout: React.FC<Props> = (props: Props): React.ReactElement => (
  <WppListItem
    id="user-logout"
    data-testid="user-logout"
    onWppChangeListItem={() => {
      props.clearLocalStorage();
      props.logout(props.session);
    }}
  >
    <WppIconLogout slot="left" />
    <p slot="label">Logout</p>
  </WppListItem>
);

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { logout, clearLocalStorage })(Logout);
