import _ from 'lodash';
import { GOAL_TYPES } from 'constantsBase';
import { formatFlightDisplayName } from 'containers/StrategyWizard/constants';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { Flight } from 'utils/types';
import { formatGoal } from 'utils/formattingUtils';
import { BulkCreateWizardFormValues } from './types';

export const IMAGE_BASE_SRC = '/img/components/BulkCreateStrategyWizard';
export const BULK_CREATE_WIZARD_BASE = '/bulkStrategies/wizard/';
export const bannerDescription = 'Copilot will create one strategy for each object you attach.';

export enum WizardSteps {
  attachFlightsStep = 'attachFlightsStep',
  goalSelectionStep = 'goalSelectionStep',
  strategyConfigurationStep = 'strategyConfigurationStep',
  strategyConfirmationStep = 'strategyConfirmationStep',
}

export const BULK_CREATE_WIZARD_STEPS = {
  [WizardSteps.attachFlightsStep]: {
    id: 1,
    title: 'Attach',
    field: 'attachFlightsStep.attachedFlights',
    dynamicDescription: (attachedFlights: Array<Flight>) => `${formatFlightDisplayName([...attachedFlights])} Attached`,
  },
  [WizardSteps.goalSelectionStep]: {
    id: 2,
    title: 'Goal',
    field: 'goalSelectionStep.goal',
    additionalFieldsForDisplay: ['attachFlightsStep.defaultCurrency.code'],
    dynamicDescription: (
      goal,
      currencyCode: string,
    ) => {
      if (!_.isEmpty(goal.type)) {
        const { type, target } = goal;
        return formatGoal(GOAL_TYPES[type], _.round(target, 4), currencyCode);
      }
      return '';
    },
  },
  [WizardSteps.strategyConfigurationStep]: {
    id: 3,
    title: 'Configure',
    field: 'config',
  },
  [WizardSteps.strategyConfirmationStep]: {
    id: 4,
    title: 'Confirm',
    field: 'confirm',
  },
} as const;

export const BULK_CREATE_ATTACH_FLIGHTS_LINK = `${BULK_CREATE_WIZARD_BASE}${BULK_CREATE_WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;

export type StepItem = {
  readonly id: number
  readonly title: string
  readonly description: string
  readonly field?: string
  readonly dynamicDescription?: (...args: Array<unknown>) => string
  readonly additionalFieldsForDisplay?: Array<string>
};

export const BULK_CREATE_INITIAL_WIZARD_VALUES_FOR_UI: BulkCreateWizardFormValues = {
  attachFlightsStep: {
    member: null,
    optimizationLevel: null,
    attachedFlights: [],
  },
  goalSelectionStep: {
    goal: {
      type: null,
      target: null,
    },
    budget: null,
  },
  strategyConfigurationStep: null,
  strategyConfirmationStep: {
    strategies: [],
  },
  budgetAllocationState: {
    kind: Status.initial,
  },
  nextBtnClick: false,
  strategyType: null,
};
