import React from 'react';
import { Grid, Header, SemanticWIDTHS } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip';
import { subHeaderStyle, toolTip } from '../style';

type SubHeaderProps = {
  headerContent: string
  subContent?: string
  toolTipContent?: string
  width?: SemanticWIDTHS
};

const SubHeader = ({ headerContent, subContent, toolTipContent, width }: SubHeaderProps) => (
  <Grid.Column width={width} style={{ display: 'flex', flexDirection: subContent ? 'column' : 'row' }}>
    <Header as="h5" content={headerContent} style={{ ...subHeaderStyle, margin: subContent && 0 }} />
    {toolTipContent && (
      <div style={{ display: 'flex' }}>
        {subContent && <Header as="h5" content={subContent} style={subHeaderStyle} />}
        <Tooltip
          content={toolTipContent}
          tooltipstyling={toolTip}
        />
      </div>
    )}
  </Grid.Column>
);

export default SubHeader;
