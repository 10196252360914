import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppTypography, WppFullScreenModal, WppButton } from 'buildingBlocks';
import { fetchLog } from '../actions';
import Task from './Task';
import { DashboardStateType, Run } from '../types';
import { Task as TaskType } from '../utils';
import { preStyle, modalActionStyle, taskListHeader } from '../style';

const TaskList: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedRun, selectedTask } = useSelector((state: { dashboard: DashboardStateType }) => state.dashboard);
  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => setOpen(false);
  const showModal = () => setOpen(true);

  const onLogLoad = (run: Run, task: TaskType) => {
    dispatch(fetchLog(run, task));
    showModal();
  };

  if (_.isEmpty(selectedRun?.tasks)) {
    return <WppTypography type="s-body">No tasks</WppTypography>;
  }

  return (
    <>
      <WppTypography type="l-strong" style={taskListHeader}>{selectedRun.tasks.length} Tasks</WppTypography>
      {selectedRun.tasks.map((task) => (
        <Task key={task.name} task={task} onLogLoad={onLogLoad} />
      ))}
      <WppFullScreenModal open={open}>
        <h3 slot="header">Log</h3>
        <pre style={preStyle} slot="body">
          {selectedTask?.logResult}
        </pre>
        <div slot="actions" style={modalActionStyle}>
          <WppButton variant="primary" size="s" onClick={closeModal}>
            Close
          </WppButton>
        </div>
      </WppFullScreenModal>
    </>
  );
};

export default TaskList;
