import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { WppTable, WppTableHeaderCell, WppTypography, WppTableHeader, WppTableHeaderRow, WppInlineMessage, WppGrid } from 'buildingBlocks';
import { checkSearchMatches } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/utils';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';
import AttachedFlightsTable from '../AttachedFlightsTable/AttachedFlightsTable';
import { attachedFlightsTableStyles } from './style';

const { headerWppTable, flightDates, endIcon, attachFlightTableHeaderContainer, dspHeaderText, attachedFlightsCount } = FLIGHT_DETAIL_STYLES;
const { flightName, dspHeader } = attachedFlightsTableStyles;

type AttachFlightsTableHeaderProps = {
  totalFlights: number
};

type BulkCreateAttachFlightsTableProps = {
  searchValue: string
};

const AttachFlightsTableHeader = ({ totalFlights }: AttachFlightsTableHeaderProps) => (
  <WppGrid item all={24}>
    <WppInlineMessage size="m" message={`${totalFlights} objects have been validated and are ready to attach.`} type="success" style={attachedFlightsCount} />
    <div style={attachFlightTableHeaderContainer}>
      <WppTable style={headerWppTable}>
        <WppTableHeader>
          <WppTableHeaderRow>
            <WppTableHeaderCell style={flightName}><WppTypography type="s-strong" tag="p">Object</WppTypography></WppTableHeaderCell>
            <WppTableHeaderCell style={dspHeader}><WppTypography style={dspHeaderText} type="s-strong" tag="p">DSP</WppTypography></WppTableHeaderCell>
            <WppTableHeaderCell style={flightDates}><WppTypography type="s-strong" tag="p">Date Range</WppTypography></WppTableHeaderCell>
            <WppTableHeaderCell style={endIcon} />
          </WppTableHeaderRow>
        </WppTableHeader>
      </WppTable>
    </div>
  </WppGrid>
);

const BulkCreateAttachFlightsTable = ({ searchValue }: BulkCreateAttachFlightsTableProps) => {
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const [flightsToDisplay, setFlightsToDisplay] = useState<Array<Flight>>(attachedFlights);

  useEffect(() => {
    setFlightsToDisplay(_.filter(attachedFlights, (flight) => checkSearchMatches(searchValue, flight)));
  }, [searchValue, attachedFlights]);

  const flightsToDisplayCount = _.size(flightsToDisplay);

  return (
    <WppGrid container fullWidth>
      {flightsToDisplayCount > 0 && (
        <>
          <AttachFlightsTableHeader
            totalFlights={flightsToDisplayCount}
          />
          <AttachedFlightsTable
            flightsToDisplay={flightsToDisplay}
          />
        </>
      )}
    </WppGrid>
  );
};

export default BulkCreateAttachFlightsTable;
