import _ from 'lodash';
import { DSP, RESULTS_LIMIT } from 'constantsBase';
import { MEMBER, membersForFeature, Permission } from 'utils/featureFlags';
import useFetcher, { PossibleStates } from 'utils/hooks/useFetcher';
import { User } from 'utils/types';
import { Member } from 'utils/copilotAPI';

export const useFlightSyncMemberFetcher = (user: User) => {
  const fetchMembers = async () => {
    const validMembers = membersForFeature(user, Permission.accessStrategyWizard);
    // flight sync is only accessible by Admin users
    if (!_.isEqual(validMembers, MEMBER.ALL)) {
      return { kind: PossibleStates.permissionDenied };
    }

    // flight sync only works for DV360 flights
    const where = { enabled: 1, dsp: DSP.DBM.id };
    const members = await Member.get({ where, limit: RESULTS_LIMIT });
    return { kind: PossibleStates.hasData, data: members.data };
  };

  const currentState = useFetcher(fetchMembers, []);
  const error = _.isEqual(currentState.kind, PossibleStates.error) && 'Failed to fetch members';
  // @ts-ignore
  return [currentState.data, error];
};
