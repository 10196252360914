import { GET_AUTH_HEADERS, GET_AUTH_HEADERS_COMPLETED, GET_AUTH_HEADERS_FAILED, LOADING_STATES } from './constants';
import { Headers } from './types';

type State = { headers: Headers, loadingState: string };

export const INITIAL_STATE: State = {
  headers: {},
  loadingState: LOADING_STATES.notLoading,
};

const authTokenReducer = (state: State = INITIAL_STATE, action: { type: string, payload: Headers }): State => {
  switch (action.type) {
    case GET_AUTH_HEADERS:
      return { ...state, loadingState: LOADING_STATES.loading };
    case GET_AUTH_HEADERS_COMPLETED:
      return { ...state, loadingState: LOADING_STATES.notLoading, headers: action.payload };
    case GET_AUTH_HEADERS_FAILED:
      return { ...state, loadingState: LOADING_STATES.error };
    default:
      return state;
  }
};

export default authTokenReducer;
