import React, { Dispatch, SetStateAction } from 'react';
import { STRATEGY_TYPE } from 'constantsBase';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import DBMBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/DBMBudgetOptimization';
import APNBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/ApnBudgetOptimization';
import TTDBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/TTDBudgetOptimization';
import WMTBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/WMTBudgetOptimization';
import AMZNBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/AMZNBudgetOptimization';

const MODULE_BY_STRATEGY_TYPE = {
  [STRATEGY_TYPE.apnBudgetOptimization.id]: APNBudgetOptimization,
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: TTDBudgetOptimization,
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: WMTBudgetOptimization,
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: DBMBudgetOptimization,
  [STRATEGY_TYPE.amznBudgetOptimization.id]: AMZNBudgetOptimization,
};

export type ModuleProps = {
  initialValues: StrategyConfigurationStep
  formValues?: StrategyConfigurationStep
  strategyTypeId?: number
  setShouldRedirect?: Dispatch<SetStateAction<boolean>>
  goToConfirmStep?: (x: number) => void
};

const ModuleLoader = ({ strategyTypeId, ...rest }: ModuleProps) => {
  const Component = MODULE_BY_STRATEGY_TYPE[strategyTypeId];
  return <Component {...rest} />;
};

export default ModuleLoader;
