import React, { Component } from 'react';
import { connect } from 'react-redux';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import withRouter from 'utils/withRouter';
import {
  getMembers, initJobs, setFilter, setRange, setSort, updateFilter, resetFilter,
} from './actions';
import { PAGE_NAME, REQUIRED_PERMISSIONS } from './constants';
import { JobProps, TimeZoneValue } from './types';
import { Filters, DataTable } from './components';

type State = {
  selectedTimezone: TimeZoneValue
};

class Jobs extends Component<JobProps, State> {
  constructor(props: JobProps) {
    super(props);

    this.state = {
      selectedTimezone: TimeZoneValue.utc,
    };
  }

  componentDidMount() {
    const { user, jobTypeOptions, jobStatusOptions, memberOptions, router } = this.props;
    this.props.initJobs(user, jobTypeOptions, jobStatusOptions, memberOptions, router);
  }

  handleTimezoneChange = (newTimezone: TimeZoneValue) => {
    this.setState({ selectedTimezone: newTimezone });
  };

  render() {
    const propsWithTimezone = {
      ...this.props,
      selectedTimezone: this.state.selectedTimezone,
      handleTimezoneChange: this.handleTimezoneChange,
    };
    return (
      <div>
        <Filters {...propsWithTimezone} />
        <DataTable {...this.props} selectedTimezone={this.state.selectedTimezone} />
      </div>
    );
  }
}

const PermissionJobs = (props) => (
  <PermissionPageTemplate
    title={PAGE_NAME}
    name="Jobs"
    permissions={REQUIRED_PERMISSIONS}
  >
    <Jobs {...props} />
  </PermissionPageTemplate>
);

const mapStateToProps = (state) => ({
  endDate: state.jobs.endDate,
  filter: {
    ...state.jobs.filter,
    startDate: state.jobs.filter.startDate?.toDate(),
    endDate: state.jobs.filter.endDate?.toDate(),
  },
  jobStatusOptions: state.jobs.jobStatusOptions,
  jobTypeOptions: state.jobs.jobTypeOptions,
  jobs: state.jobs.jobs,
  jobsCount: state.jobs.jobsCount,
  loading: state.jobs.jobsLoading,
  memberOptions: state.jobs.memberOptions,
  pageNumber: (state.jobs.range.skip / state.jobs.range.limit) + 1,
  range: state.jobs.range,
  sort: state.jobs.sort,
  startDate: state.jobs.startDate,
  user: state.login.session.data.user,
});

const mapDispatchToProps = {
  getMembers,
  initJobs,
  resetFilter,
  setFilter,
  setRange,
  setSort,
  updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PermissionJobs));
