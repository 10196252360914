import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import parse from 'html-react-parser';
import { WppTypography, WppGrid, WppTooltip, WppInlineMessage } from 'buildingBlocks';
import { messageStyles } from 'containers/NotificationCenter/style';
import { NotificationMsgObject } from 'components/Notification/types';
import 'containers/NotificationCenter/style.scss';
import { NUMBER_DATE_FORMAT } from 'utils/dateTime';

const TEN_LINES_LIMIT = 500;

type DuplicateMessageProps = {
  dupMessage: string
};

const DuplicateMessage = ({ dupMessage }: DuplicateMessageProps) => (
  <WppInlineMessage size="s" message={dupMessage} type="warning" />
);

type NotificationMessageProps = {
  notification: NotificationMsgObject
};

const NotificationMessage = ({ notification }: NotificationMessageProps) => {
  const { content, count } = notification;
  // some messages' content contains HTML
  // add target="_blank" to open up links in new tab
  const parsedContent = parse(_.replace(content, '<a', '<a target="_blank"'));
  const dupMessage = `This notification has repeated ${notification.count} times since ${moment(notification.repeatedSince).format(NUMBER_DATE_FORMAT)}`;

  return (
    <WppGrid container fullWidth>
      <WppGrid
        item
        all={24}
      >
        {_.size(content) > TEN_LINES_LIMIT
          ? (
            <WppTooltip config={{ placement: 'right', allowHTML: true }}>
              <WppTypography tag="p" type="s-body">
                <div style={messageStyles.truncatedContentStyle}>{parsedContent}</div>
              </WppTypography>
              <div
                slot="tooltip-content"
                style={messageStyles.toolTipStyle}
                className="toolTipContent"
              >
                {parsedContent}
              </div>
            </WppTooltip>
          )
          : (<WppTypography tag="p" type="s-body" style={messageStyles.message}>{parsedContent}</WppTypography>)}
        {count > 1 && (<DuplicateMessage dupMessage={dupMessage} />)}
      </WppGrid>
    </WppGrid>
  );
};

export default NotificationMessage;
