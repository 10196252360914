/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { useState, useEffect, Dispatch } from 'react';
import { WppTable, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTableBody, WppTypography, WppInput, WppIconSearch, WppActionButton, WppIconClose } from 'buildingBlocks';
import { alreadyAttachedTableStyles } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style';
import { Flight } from 'utils/types';
import AttachedToAnotherStrategyFlightRow from './AttachedToAnotherStrategyFlightRow';

const { searchIncludeContainer, objectNameHeader, strategyNameHeader, selectionModeHeader, strategyText } = alreadyAttachedTableStyles;

type AttachedToAnotherStrategyFlightTableProps = {
  attachedToAnotherStrategy: Array<Flight>
  alreadyAttachedFlightsToInclude: Array<Flight>
  setAlreadyAttachedFlightsToInclude: Dispatch<React.SetStateAction<Array<Flight>>>
};

const AttachedToAnotherStrategyFlightTable = (props: AttachedToAnotherStrategyFlightTableProps) => {
  const { attachedToAnotherStrategy, alreadyAttachedFlightsToInclude, setAlreadyAttachedFlightsToInclude } = props;
  const [search, setSearch] = useState<string>('');
  const [flightsToDisplay, setFlightsToDisplay] = useState<Array<Flight>>(attachedToAnotherStrategy);

  useEffect(() => {
    setFlightsToDisplay(_.filter(attachedToAnotherStrategy, (f) => {
      const loweredSearch = _.toLower(search);
      return _.includes(_.toLower(f.name), loweredSearch) || _.includes(_.toLower(f.externalId), loweredSearch);
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onAllToggleClick = (isIncludeAll?: boolean) => {
    const newSessionFlights = isIncludeAll
      ? _.uniqBy([...alreadyAttachedFlightsToInclude, ...flightsToDisplay], 'externalId')
      : _.filter(alreadyAttachedFlightsToInclude, (f) => !_.includes(_.map(flightsToDisplay, 'externalId'), f.externalId));
    setAlreadyAttachedFlightsToInclude(newSessionFlights);
  };

  const onFlightSelectionChange = (flight: Flight) => {
    setAlreadyAttachedFlightsToInclude((prevIncludedFlights) => (_.some(prevIncludedFlights, ['externalId', flight.externalId])
      ? _.filter(prevIncludedFlights, (f) => !_.isEqual(f.externalId, flight.externalId))
      : _.orderBy([...prevIncludedFlights, flight], 'externalId', 'desc')));
  };

  return (
    <>
      <div style={searchIncludeContainer}>
        <WppInput
          size="s"
          placeholder="Search by name or ID"
          value={search}
          onWppChange={(e) => setSearch(e.target.value)}
          style={{ width: 240 }}
        >
          <WppIconSearch slot="icon-start" aria-label="Search icon" />
          {!_.isEmpty(search) && <WppIconClose slot="icon-end" onClick={() => setSearch('')} />}
        </WppInput>
        <div>
          <WppActionButton variant="secondary" onClick={() => onAllToggleClick(true)}>Include All</WppActionButton>
          <WppActionButton variant="secondary" onClick={() => onAllToggleClick()}>Exclude All</WppActionButton>
        </div>
      </div>
      <WppTable>
        <WppTableHeader>
          <WppTableHeaderRow className="wpp-table-header-row">
            <WppTableHeaderCell style={objectNameHeader}>
              <WppTypography type="s-strong" tag="p">Object</WppTypography>
            </WppTableHeaderCell>
            <WppTableHeaderCell style={strategyNameHeader}>
              <WppTypography type="s-strong" tag="p" style={strategyText}>Attached Strategy</WppTypography>
            </WppTableHeaderCell>
            <WppTableHeaderCell style={selectionModeHeader} colSpan={1}>
              <WppTypography type="s-strong" tag="p" />
            </WppTableHeaderCell>
          </WppTableHeaderRow>
        </WppTableHeader>
        <WppTableBody>
          {_.map(flightsToDisplay, (flight: Flight) => (
            <AttachedToAnotherStrategyFlightRow
              key={flight.externalId}
              flight={flight}
              onFlightSelectionChange={onFlightSelectionChange}
              isIncluded={_.some(alreadyAttachedFlightsToInclude, ['externalId', flight.externalId])}
            />
          ))}
        </WppTableBody>
      </WppTable>
    </>
  );
};

export default AttachedToAnotherStrategyFlightTable;
