import _ from 'lodash';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Grid } from 'buildingBlocks';
import SubHeader from './SubHeader';
import { PublishOptions } from '../types';
import '../override.css';

type PublishMsgOptionsProps = {
  publishOption: PublishOptions
};

const PublishMsgOptions = ({ publishOption }: PublishMsgOptionsProps) => {
  const { setValue } = useFormContext();

  const handleOnChange = (_e: SyntheticEvent, data: { value: number }) => {
    if (_.includes([PublishOptions.immediately, PublishOptions.oneHour], data.value)) {
      const startDate = _.isEqual(PublishOptions.immediately, data.value)
        ? moment()
        : moment().add(1, 'hour');
      setValue('startDate', startDate);
      setValue('startTime', startDate);
    }
    setValue('publishOption', data.value);
  };

  return (
    <Grid.Row columns={2}>
      <SubHeader width={3} headerContent="Publish Message" />
      <Grid.Column width={8} style={{ display: 'flex', gap: '8px' }}>
        <Checkbox
          radio
          className="publishOptionsCheckBox"
          name="publishOptions"
          label="Immediately"
          value={PublishOptions.immediately}
          checked={_.isEqual(publishOption, PublishOptions.immediately)}
          onChange={handleOnChange}
        />
        <Checkbox
          radio
          className="publishOptionsCheckBox"
          name="publishOptions"
          label="In one hour"
          value={PublishOptions.oneHour}
          checked={_.isEqual(publishOption, PublishOptions.oneHour)}
          onChange={handleOnChange}
        />
        <Checkbox
          radio
          className="publishOptionsCheckBox"
          name="publishOptions"
          label="At a specified date and time"
          value={PublishOptions.custom}
          checked={_.isEqual(publishOption, PublishOptions.custom)}
          onChange={handleOnChange}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default PublishMsgOptions;
