import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const AD_BLOCKER_STYLES: { [key: string]: CSSProperties } = {
  adBlockerLink: {
    textDecoration: 'underline',
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'absolute',
    right: SPACING[12],
    top: SPACING[12],
    fontSize: 20,
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  contentContainer: {
    textAlign: 'center',
    padding: '12px 0px',
  },
};
