import React from 'react';

import { Grid } from 'buildingBlocks';
import PaginationControl from 'components/PaginationControl';

import { ELEMENTS_PER_PAGE_OPTIONS } from 'containers/Jobs/constants';
import { JobProps } from 'containers/Jobs/types';

const Pagination = (props: JobProps) => (
  <Grid.Column>
    <PaginationControl
      numberOfElements={props.jobsCount}
      onDataRangeChange={(range) => props.setRange(range, props.router)}
      startingElementsPerPage={props.range.limit}
      elementsPerPageOptions={ELEMENTS_PER_PAGE_OPTIONS}
      startingPage={props.pageNumber}
    />
  </Grid.Column>
);

export default Pagination;
