import React from 'react';
import { WppTypography } from 'buildingBlocks';
import { ColDef, Flight } from 'utils/types';
import { FlightCandidateCheckbox } from './FlightCandidateCheckbox';

const FlightCandidatesTableColumns = (): Array<ColDef> => ([
  {
    field: 'select',
    pinned: 'left',
    cellStyle: {
      padding: '5px 8px',
    },
    maxWidth: 36,
    cellRenderer: ({ data }: { data: Flight }) => <FlightCandidateCheckbox flight={data} />,
  },
  {
    field: 'name',
    resizable: false,
    cellRenderer: ({ data }: { data: Flight }) => (
      <WppTypography tag="p" type="s-midi">
        {`[${data.externalId}] - ${data.name}`}
      </WppTypography>
    ),
  },
]);

export default FlightCandidatesTableColumns;
