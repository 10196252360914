import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Modal } from 'buildingBlocks';
import { FlightSyncForm, initialValues } from '../constants';
import FLIGHT_SYNC_STYLES from '../styles';

const { modalStyles, modalHeader, modalContent, syncButtonContainer, submitBtn } = FLIGHT_SYNC_STYLES;

const modalHeading = 'Syncing of the listed DV360 insertion orders have been added to the queue';
const modalMessage = `You will receive an email when it is complete. In the meantime, you may complete the form again to
  sync additional insertion orders, but please do not list any that were previously synced today.`;

type SyncMessageModalProps = {
  modalOpen: boolean
  setModalOpen: (x: any) => void;
  setTextArea: (x: any) => void;
};

const SyncMessageModal = (props: SyncMessageModalProps) => {
  const { modalOpen, setModalOpen, setTextArea } = props;
  const { reset } = useFormContext<FlightSyncForm>();

  const onClose = () => {
    setModalOpen(false);
    reset(initialValues);
    setTextArea('');
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      style={modalStyles}
      size="small"
      dimmer="blurring"
    >
      <Modal.Header style={modalHeader}>
        {modalHeading}
      </Modal.Header>
      <Modal.Content style={modalContent}>
        {modalMessage}
        <div style={syncButtonContainer}>
          <Button
            onClick={onClose}
            style={submitBtn}
            primary
          >
            Close
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SyncMessageModal;
