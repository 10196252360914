import _ from 'lodash';
import React from 'react';
import PageLoading from 'components/PageLoading/PageLoading';
import { useAsyncEffect } from 'utils/functionHelpers';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { INITIALIZE_FORM_VALUES, StrategyWizardStates } from 'containers/StrategyWizard/constants';
import { QSParams } from 'containers/StrategyWizard/utils';
import UnknownError from 'containers/UnknownError/components/UnknownError';
import BulkCreateStrategyWizardRoutes from './components/BulkCreateStrategyWizardRoutes';
import { useBulkCreateStrategyWizardContext } from './contexts/BulkCreateStrategyWizardProvider';
import useBulkCreateStrategyFetcher from './hooks/useBulkStrategyWizardInitialization';
import { getInitialBulkCreateWizardFormFieldsUIValues } from './utils';

export const PAGE_NAME = 'Bulk Create Strategy Wizard';

type Props = {
  qsParams: QSParams
};

const BulkCreateStrategyWizard = (props: Props) => {
  const { qsParams } = props;
  const { wizardFormValues, dispatch, router } = useBulkCreateStrategyWizardContext();
  const {
    budgetAllocationState,
  } = wizardFormValues;
  const { params } = router;
  const step = _.toNumber(params.step);
  const bulkCreateStrategyState = useBulkCreateStrategyFetcher(qsParams, _.get(budgetAllocationState, 'data'), dispatch);

  useAsyncEffect(async () => {
    if (bulkCreateStrategyState.kind === PossibleStates.hasData || bulkCreateStrategyState.kind === StrategyWizardStates.initFromQs) {
      const initialBulkCreateWizardFormFieldsUIValues = getInitialBulkCreateWizardFormFieldsUIValues(bulkCreateStrategyState);
      await dispatch({
        type: INITIALIZE_FORM_VALUES,
        payload: {
          ...initialBulkCreateWizardFormFieldsUIValues,
          attachFlightsStep: {
            ...initialBulkCreateWizardFormFieldsUIValues.attachFlightsStep,
          },
        },
      });
    }
  }, [bulkCreateStrategyState]);

  const commonProps = {
    step,
    wizardFormValues,
    qsParams,
    bulkCreateStrategyState,
  };

  switch (bulkCreateStrategyState.kind) {
    case PossibleStates.hasData:
    case StrategyWizardStates.initFromQs:
    case StrategyWizardStates.newStrategy:
      return <BulkCreateStrategyWizardRoutes {...commonProps} />;
    case PossibleStates.error:
    case StrategyWizardStates.unauthorized:
      return <UnknownError />;
    case PossibleStates.initial:
    case PossibleStates.loading:
    default:
      return <PageLoading pageName={PAGE_NAME}>Loading</PageLoading>;
  }
};

export default BulkCreateStrategyWizard;
