import _ from 'lodash';
import moment from 'moment';
import { required, applyAllValidators } from 'utils/reactHookFormValidators';

const MAX_CHAR_LENGTH = 1000;
const MAX_SUBJECT_LENGTH = 45;

const validateSubjectLen = (formValues, fieldName) => {
  const { subject } = formValues;
  if (_.size(subject) > MAX_SUBJECT_LENGTH) {
    return { [fieldName]: { message: `Max character length: ${MAX_SUBJECT_LENGTH}` } };
  }
  return {};
};

const validateContentLen = (formValues, fieldName) => {
  const { content } = formValues;
  if (_.size(content) > MAX_CHAR_LENGTH) {
    return { [fieldName]: { message: `Max character length: ${MAX_CHAR_LENGTH}` } };
  }
  return {};
};

const validateDate = (formValues, fieldName) => {
  const startDate = moment(formValues.startDate);

  if (!startDate.isValid()) {
    return { [fieldName]: { message: 'Start Date is required' } };
  }
  return {};
};

export default (values) => {
  const validators = {
    subject: [required, validateSubjectLen],
    content: [required, validateContentLen],
    priority: [required],
    startDate: [validateDate],
  };

  return { values, errors: applyAllValidators(values, validators) };
};
