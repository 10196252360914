import React, { useRef } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { WppIconExternalLink, WppListItem, WppPopover } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { getHyperLinks } from '../utils';
import { DEMO_SITES_STYLE } from '../styles';

const { hyperLinkStyles, popOverContent, textStyles } = DEMO_SITES_STYLE;
const { WPP } = COPILOT_COLORS;

type HyperLinksPopoverProps = {
  domain: string
};

export const HyperLinksPopover = (({ domain }: HyperLinksPopoverProps) => {
  const linksToDisplay = getHyperLinks(domain);
  const ref = useRef<HTMLWppPopoverElement>(null);

  return (
    <WppPopover
      config={{ placement: 'left-end' }}
      ref={ref}
    >
      <WppIconExternalLink
        slot="trigger-element"
        color={WPP.grey700}
        style={hyperLinkStyles}
      />
      <div style={popOverContent}>
        {_.map(linksToDisplay, ({ link, name }) => (
          <WppListItem key={name} value={link}>
            <Link to={link} slot="label" target="_blank" style={textStyles}>
              {name}
            </Link>
          </WppListItem>
        ))}
      </div>
    </WppPopover>
  );
});
