import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY, COPILOT_COLORS } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { HEADING } = COPILOT_TYPOGRAPHY;
const { NEW_DESIGN_SYSTEM: { NEUTRALS, REDS, YELLOWS } } = COPILOT_COLORS;

const FLIGHT_SYNC_STYLES: { [key: string]: CSSProperties } = {
  headerContainer: {
    marginTop: SPACING[40],
    marginBottom: SPACING[16],
  },
  headerText: {
    ...HEADING.H2,
    margin: 0,
  },
  headerDesc: {
    marginTop: SPACING[8],
    marginBottom: SPACING[16],
  },
  labelStyles: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    fontWeight: 500,
  },
  idFieldForm: {
    width: 'inherit',
  },
  idField: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    fontFamily: 'Gilroy',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: SPACING[4],
  },
  uploadMessage: {
    color: YELLOWS.Y700_METAL,
    margin: '4px 0px 0px 2px',
    fontSize: '14px',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  warningTriangle: {
    position: 'absolute',
    top: SPACING[2],
  },
  warningSpan: {
    marginLeft: SPACING[24],
    fontSize: '14px',
    fontWeight: 500,
    color: REDS.R700_VALENTINE,
  },
  submitBtn: {
    borderRadius: SPACING[4],
    fontSize: '14px',
    fontWeight: 600,
    padding: 0,
    width: '100px',
    height: SPACING[32],
    textTransform: 'capitalize',
  },
  modalStyles: {
    padding: '16px 12px',
  },
  modalHeader: {
    textAlign: 'center',
    fontWeight: 600,
    padding: SPACING[16],
  },
  modalContent: {
    padding: SPACING[16],
  },
  syncButtonContainer: {
    textAlign: 'center',
    marginTop: SPACING[16],
  },
};

export default FLIGHT_SYNC_STYLES;
