import React from 'react';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  WppExpandableCard,
  WppTypography,
  WppDivider,
  WppIconPlus,
} from 'buildingBlocks';
import { DEMO_SITES_DSP_NAMES } from '../constants';
import { DEMO_SITES_STYLE } from '../styles';
import { PixelRow } from './PixelRow';

const {
  pixelsContainer,
  pixelDesc,
  secondSubHeader,
  expandableContainer,
  addPixelStyle,
} = DEMO_SITES_STYLE;

export const StrategyPixels = () => {
  const { setValue } = useFormContext();
  const pixels = useWatch({ name: 'pixels' });

  const handleLineItemDelete = (groupKey, index) => {
    const updatedPixels = _.cloneDeep(pixels);
    if (updatedPixels[groupKey]?.length > 1) {
      updatedPixels[groupKey].splice(index, 1);
      setValue('pixels', updatedPixels);
    }
  };

  const handleAddPixel = (groupKey) => {
    const updatedPixels = [...pixels[groupKey], ''];
    setValue(`pixels.${groupKey}`, updatedPixels);
  };

  return (
    <>
      <h3 slot="header" className="demoSiteheaderStyles pixelHeader">Pixels</h3>
      <span style={pixelDesc}>
        Each group must have at least 1 pixel. You can add more pixels to each group.
      </span>
      {_.map(pixels, (group, groupKey) => (
        <WppExpandableCard key={groupKey} style={expandableContainer}>
          <WppTypography type="m-strong" slot="header">
            {DEMO_SITES_DSP_NAMES[groupKey]}
          </WppTypography>
          <div style={pixelsContainer}>
            <h5 style={secondSubHeader}>Pixels</h5>
            <WppDivider />
            {_.map(group, (pixel, index) => (
              <PixelRow
                pixel={pixel}
                pixelGroup={group}
                pixelIndex={index}
                groupKey={groupKey}
                handleLineItemDelete={handleLineItemDelete}
              />
            ))}
            <WppDivider />
            <span style={addPixelStyle} onClick={() => handleAddPixel(groupKey)} role="button" tabIndex={0}>
              <WppIconPlus slot="icon-start" /> Add Pixel
            </span>
          </div>
        </WppExpandableCard>
      ))}
    </>
  );
};
