import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Admin } from 'utils/copilotAPI';
import { GET_AUTH_HEADERS } from './constants';
import { getAuthHeadersCompleted, getAuthHeadersFailed } from './actions';
import { Headers } from './types';

export function* getAuthHeaders() {
  try {
    const headers: { data: Headers } = yield call(Admin.genAuthHeaders);
    yield put(getAuthHeadersCompleted(headers.data));
  } catch (error) {
    yield put(getAuthHeadersFailed());
  }
}

export default function* authTokenSagas() {
  yield all([
    takeLatest(GET_AUTH_HEADERS, getAuthHeaders),
  ]);
}
