import { ThemeStyles } from '@wppopen/components-library/dist/types';
import { OsContext } from '@wppopen/core';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

/**
 * use the theme from osContext create a variables string for ThemeWrapper
 * generateStyles will be called recursively as osContext.theme is a nested object
 * theme obj will look like:
 * {
 *   "font": { "family": "Inter" },
 *   "icon": {
 *     "color": {
 *       "hover": "#525252",
 *       "active": "#393939",
 *       "default": "#929292",
 *       "disabled": "#c6c6c6"
 *     },
 *   "color": {
 *     "brand": {
 *       "hover": "#9057FD",
 *       "active": "#4F03DE",
 *       "default": "#6E21FC",
 *       "disabled": "#C7A9FE",
 *     },
 *   }
 *   },
 *   ...
 * }
*/

const generateStyles = (obj: ThemeStyles | ThemeStyles[keyof ThemeStyles], prefix: string, isTopLevel: boolean = false) => {
  let styles = '';

  _.forEach(obj, (value, key) => {
    const newPrefix = prefix ? `${prefix}-${key}` : key;

    if (_.isObject(value)) {
      if (isTopLevel && key === 'color') {
        // special handling for the top level 'color' key - needs to have the child as the prefix followed by 'color'
        // ie: --wpp-brand-color-hover
        _.forEach(value, (subValue, subKey: string) => {
          // append 'color' after the first level of nesting
          const colorPrefix = `${subKey}-color`;
          // directly add variable to styles if subValue is a string
          styles += (_.isString(subValue) ? `--wpp-${colorPrefix}: ${subValue};\n` : generateStyles(subValue, colorPrefix));
        });
      } else {
        styles += generateStyles(value, newPrefix);
      }
    } else {
      // prepend with --wpp prefix which is used by wpp os design system
      const cssVarName = `--wpp-${_.kebabCase(newPrefix).toLowerCase()}`
        // remove dash from numbered prefixes for typography styles after kebab casing ie: 2-xs --> 2xs
        .replace(/(\d+)-?(xs|xl|l|m|s)/g, '$1$2')
        // remove any '-default' suffixes ie: --wpp-brand-color-default --> --wpp-brand-color
        .replace(/-default$/, '');
      // add a new line for each variable
      styles += `${cssVarName}: ${value};\n`;
    }
  });

  return styles;
};

// create the styled wrapper component using styled-components package
// generated css variables from osContext to be applied to children of the wrapper
const ThemeWrapper = styled.div`
  ${({ theme }: { theme: ThemeStyles }) => generateStyles(theme, '', true)}
`;

const WppOsThemeWrapper = ({ osContext, children }: { osContext: OsContext, children: React.ReactNode }) => (
  <ThemeWrapper theme={osContext?.theme || {}}>
    {children}
  </ThemeWrapper>
);

export default WppOsThemeWrapper;
