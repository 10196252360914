import React from 'react';
import { WppAccordion, WppDivider, WppInlineMessage, WppTypography } from 'buildingBlocks';
import { BUDGET_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { PENDO_HEADER_CLASS } from 'cssTagConstants';

const BudgetSection = () => (
  <div style={BUDGET_SECTION_STYLES.container}>
    <div style={BUDGET_SECTION_STYLES.budgetRevenueTypeStyle}>
      <WppTypography type="xl-heading" tag="p" className={PENDO_HEADER_CLASS}>Budget</WppTypography>
      <WppTypography type="s-body" tag="p">Control budget and revenue types.</WppTypography>
    </div>
    <WppDivider />
    <WppAccordion size="m" withDivider={false} className="budgetIntervalAccordion" expanded style={BUDGET_SECTION_STYLES.accordionStyle}>
      <WppTypography type="m-strong" tag="h5" slot="header">Budget Intervals</WppTypography>
      <div style={BUDGET_SECTION_STYLES.budgetIntervalsContainer}>
        <WppTypography type="s-body" tag="p">Review budget settings. Please note, delivery is prioritized over goal performance for each interval.</WppTypography>
        <div style={BUDGET_SECTION_STYLES.budgetIntervalInfobanner}>
          <WppInlineMessage
            size="l"
            titleText="Budget Intervals for Bulk Create Strategies"
            message="You can review intervals in the confirmation step."
            type="information"
            hideCloseBtn
            showTooltipFrom={4000}
          />
        </div>
      </div>
    </WppAccordion>
  </div>
);

export default BudgetSection;
