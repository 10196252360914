import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { dateTimeFormatter } from 'utils/dateTime';
import 'moment-precise-range-plugin';
import {
  WppExpandableCard,
  WppTypography,
  WppActionButton,
  WppIconExternalLink,
  WppTable,
  WppTableBody,
  WppTableBodyRow,
  WppTableBodyCell,
} from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { Strategy } from 'utils/types';
import { DashboardStateType, Run } from '../types';
import { runDetailContainer, runDetailTable, runDetailTableCell } from '../style';

enum AirflowStatus {
  success = 'SUCCESS',
  running = 'RUNNING',
  failed = 'FAILED',
  cancelled = 'CANCELLED',
  pending = 'PENDING',
  noData = 'NO_DATA',
}

const stratTypeLabel = (run: Run) => run.strategyType?.displayName;
const algoTypeLabel = (run: Run) => run.algorithmType?.name;
const goalLabel = (strategy: Strategy) => _.camelCase(
  _.get(_.find(strategy.strategyGoals, { active: true, priority: 1 }), 'type'),
);
const memberLabel = (run: Run) => run.member && `${run.member.displayName} (${run.member.externalId})`;

const tableRow = ([label, data]: [string, React.ReactNode], index: number) => (
  <WppTableBodyRow key={index}>
    <WppTableBodyCell>
      <WppTypography type="s-strong" style={runDetailTableCell}>{label}</WppTypography>
    </WppTableBodyCell>
    <WppTableBodyCell>
      <WppTypography type="s-body">{data}</WppTypography>
    </WppTableBodyCell>
  </WppTableBodyRow>
);

const RunDetail: React.FC = () => {
  const { selectedFlight, selectedRun, strategy } = useSelector((state: { dashboard: DashboardStateType }) => state.dashboard);

  if (_.isNil(selectedRun)) {
    return <WppTypography type="s-body">No runs loaded.</WppTypography>;
  }

  const {
    id, createdAt, updatedAt, strategyCreatedBy, strategyLastUpdatedBy, triggerType,
    status, algorithm, advertiser, result, algorithmType,
  } = selectedRun;

  const createdFull = dateTimeFormatter.dateTime(createdAt, true);
  const updatedFull = dateTimeFormatter.dateTime(updatedAt, true);
  const elapsed = moment.preciseDiff(moment(createdAt), moment(updatedAt));
  const strategyUrl = `/strategies/${strategy.id}`;
  const flightTitle = _.isEmpty(selectedFlight) ? '' : `${selectedFlight.name} (${selectedFlight.id})`;
  const flightExternalId = _.isEmpty(selectedFlight) ? '' : selectedFlight.externalId;
  const createdBy = _.get(strategyCreatedBy, 'email', '');
  const updatedBy = _.get(strategyLastUpdatedBy, 'email', '');
  const trigger = triggerType ? 'User Initiated' : 'Programmatic';
  const airflowDisabled = _.includes([AirflowStatus.cancelled, AirflowStatus.pending], status);
  const dagUrl = _.get(result, 'dag_run_url');

  const recentSfrParams = {
    strategy: [strategy.id],
    algorithmType: [_.get(algorithmType, 'id', '')],
  };
  const recentSfrUrl = `/strategyflightruns?${qs.stringify(recentSfrParams)}`;

  const tableBody = [
    ['Type', stratTypeLabel(selectedRun)],
    ['Status', status],
    ['Created', <span title={createdFull}>{createdFull}</span>],
    ['Updated', <span title={updatedFull}>{updatedFull}</span>],
    ['Elapsed', elapsed],
    ['Advertiser', `${_.get(advertiser, 'name')} (${_.get(advertiser, 'externalId')})`],
    ['Member', memberLabel(selectedRun)],
    ['Strategy', <Link to={strategyUrl}>{strategy.id}</Link>],
    ['Goal', _.get(GOAL_TYPES, `${goalLabel(strategy)}.strategyWizardLongText`)],
    ['Strategy Created By', <span title={createdBy}>{createdBy}</span>],
    ['Strategy Last Updated By', <span title={updatedBy}>{updatedBy}</span>],
    ['Algorithm', algorithm],
    ['Algorithm Type', algoTypeLabel(selectedRun)],
    ['Flight',
      <span style={{ wordWrap: 'break-word' }} title={flightTitle}>
        {flightTitle} - {flightExternalId}
      </span>,
    ],
    ['Trigger Type', trigger],
    ['Recent SFR', <Link to={recentSfrUrl}>Recent SFR</Link>],
  ].map(([label, data], index) => tableRow([_.toString(label), data], index));

  return (
    <div>
      <WppExpandableCard expandedByDefault size="l" style={runDetailContainer}>
        <WppTypography type="m-strong" slot="header">Run #{id}</WppTypography>
        <div style={runDetailTable}>
          <WppTable>
            <WppTableBody>{tableBody}</WppTableBody>
          </WppTable>
        </div>
        <WppActionButton
          variant="secondary"
          slot="actions"
          disabled={airflowDisabled}
          onClick={() => window.open(dagUrl, '_blank')}
        >
          Airflow
          <WppIconExternalLink slot="icon-start" />
        </WppActionButton>
      </WppExpandableCard>
    </div>
  );
};

export default RunDetail;
