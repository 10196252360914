import React, { useState } from 'react';
import _ from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DatePickerEventDetail } from '@wppopen/components-library';
import { WppDatepickerCustomEvent } from '@wppopen/components-library/dist/types/components';
import {
  WppDatepicker,
  WppGrid,
  WppListItem,
  WppSelect,
  WppDivider,
  WppIconTrash,
  WppIconPlus,
  WppInlineMessage,
  WppTypography,
  ValidatedCustomWppInput,
} from 'buildingBlocks';
import { useAsyncEffect } from 'utils/functionHelpers';
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { NUMBER_DATE_FORMAT, WPP_DATE_PICKER_FORMAT } from 'utils/dateTime';
import { getCurrencies } from '../utils';
import { CurrencyT } from '../types';
import { DEMO_SITES_STYLE } from '../styles';
import { StrategyPixels } from './StrategyPixels';

const { addLineItem, lineItemRow, noMargin, containerMargins } = DEMO_SITES_STYLE;

const CreateStrategy = () => {
  const { control, setValue, getValues, formState: { errors }, clearErrors } = useFormContext();
  const lineItems = useWatch({ name: 'lineItems' });
  const currency = useWatch({ name: 'currency' });
  const [currencies, setCurrencies] = useState<Array<CurrencyT>>([]);
  const currencyErr = _.get(errors, 'currency.message');
  const datesErr = _.get(errors, 'dates.message');

  useAsyncEffect(async () => {
    clearErrors();
    const currenciesRes = await getCurrencies();
    setCurrencies(currenciesRes);
  }, []);

  const handleAddLineItem = () => {
    const lineItemsArray = getValues('lineItems');
    const updatedLineItems = [...lineItemsArray, ''];
    setValue('lineItems', updatedLineItems);
  };

  const handleLineItemDelete = (index) => {
    const lineItemsArray = getValues('lineItems');
    if (_.size(lineItemsArray) > 1) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updatedLineItems = _.filter(lineItemsArray, (_, i) => i !== index);
      setValue('lineItems', updatedLineItems);
    }
  };

  return (
    <>
      <h3 slot="header" className="demoSiteheaderStyles subHeading">Basic Information</h3>
      <WppGrid container all={3} fullWidth columnSpacing={4} style={containerMargins}>
        <WppGrid item fullWidth all={24}>
          <Controller
            name="brand"
            control={control}
            render={(properties) => (
              <ValidatedCustomWppInput
                type="text"
                title="Brand"
                onWppChange={properties.field.onChange}
                {..._.omit(properties, 'formState')}
                className="brand"
                skipIsDirtyCheck
              />
            )}
          />
        </WppGrid>
        <WppGrid container all={24} style={noMargin} fullWidth>
          <WppGrid item all={12}>
            <Controller
              name="member"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  type="text"
                  title="Member"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  className="member"
                  skipIsDirtyCheck
                />
              )}
            />
          </WppGrid>
          <WppGrid item all={12}>
            <Controller
              name="advertiser"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  type="text"
                  title="Advertiser"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  className="advertiser"
                  skipIsDirtyCheck
                />
              )}
            />
          </WppGrid>
        </WppGrid>
        <WppGrid container all={24} style={noMargin} fullWidth>
          <WppGrid item all={12}>
            <Controller
              name="currency"
              control={control}
              defaultValue={currency}
              render={({ field }) => (
                <WppSelect
                  id="currency"
                  {...field}
                  labelConfig={{ text: 'Currency' }}
                  required
                  onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                    field.onChange(event.detail.value);
                  }}
                  value={currency}
                  loading={_.isEmpty(currencies)}
                >
                  {_.map(currencies, (curVal) => (
                    <WppListItem key={curVal.id} value={curVal}>
                      <p slot="label">{curVal.code}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              )}
            />
            {currencyErr
              && (
                <WppInlineMessage
                  size="s"
                  message={_.toString(currencyErr)}
                  type="error"
                />
              )}
          </WppGrid>
          <WppGrid item all={12}>
            <div className="demoSiteStrategyDates">
              <Controller
                name="dates"
                control={control}
                render={(properties) => (
                  <WppDatepicker
                    required
                    labelConfig={{ text: 'Dates' }}
                    range
                    onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                      const formattedDate = event.detail.formattedDate;
                      // Prevent overwriting the value with null
                      if (formattedDate) {
                        properties.field.onChange(formattedDate);
                      }
                    }}
                    placeholder={NUMBER_DATE_FORMAT}
                    locale={{
                      dateFormat: WPP_DATE_PICKER_FORMAT,
                    }}
                    {..._.omit(properties, 'formState')}
                    className="demoSiteStrategyDates"
                    size="m"
                    value={properties.field.value}
                  />
                )}
              />
              {datesErr
                && (
                  <WppInlineMessage
                    size="s"
                    message={_.toString(datesErr)}
                    type="error"
                  />
                )}
            </div>
          </WppGrid>
        </WppGrid>
      </WppGrid>
      <h3 slot="item" className="demoSiteheaderStyles subHeading">Insertion Order and Line Items</h3>
      <WppGrid container all={3} fullWidth columnSpacing={4} style={containerMargins}>
        <WppGrid container all={24} style={noMargin}>
          <WppGrid item all={12}>
            <Controller
              name="insertionOrder1"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  type="text"
                  title="Insertion Order 1 Name"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  className="insertionOrder1"
                  skipIsDirtyCheck
                />
              )}
            />
          </WppGrid>
          <WppGrid item all={12}>
            <Controller
              name="insertionOrder2"
              control={control}
              render={(properties) => (
                <ValidatedCustomWppInput
                  type="text"
                  title="Insertion Order 2 Name (Optional)"
                  onWppChange={properties.field.onChange}
                  {..._.omit(properties, 'formState')}
                  className="insertionOrder2"
                  skipIsDirtyCheck
                />
              )}
            />
          </WppGrid>
        </WppGrid>
      </WppGrid>
      <WppTypography tag="span" type="m-midi">
        Line Items
      </WppTypography>
      <WppGrid container all={3} fullWidth columnSpacing={4}>
        <WppGrid item all={24}>
          <WppDivider />
        </WppGrid>
        <WppGrid container fullWidth style={lineItemRow} all={24} className="strategyLineItems">
          {_.map(lineItems, (lineItem, index) => (
            <React.Fragment key={index}>
              <WppGrid item all={22}>
                <Controller
                  name={`lineItems[${index}]`}
                  control={control}
                  render={(properties) => (
                    <ValidatedCustomWppInput
                      type="text"
                      {..._.omit(properties, 'formState')}
                      onWppChange={properties.field.onChange}
                      value={lineItem}
                      skipIsDirtyCheck
                    />
                  )}
                />
              </WppGrid>
              <WppGrid item all={2}>
                <WppIconTrash onClick={() => handleLineItemDelete(index)} />
              </WppGrid>
            </React.Fragment>
          ))}
        </WppGrid>
        <WppGrid item all={24}>
          <WppDivider />
        </WppGrid>
      </WppGrid>
      <span style={addLineItem} onClick={handleAddLineItem} role="button" tabIndex={0}>
        <WppIconPlus slot="icon-start" /> Add Line Item
      </span>
      <br />
      <StrategyPixels />
    </>
  );
};

export default CreateStrategy;
