import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { WppGrid, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTypography } from 'buildingBlocks';
import { STRATEGY_TYPE, ALGORITHM_TYPE } from 'constantsBase';
import QueryStringLink from 'components/QueryStringLink';
import { generateUUID } from 'utils/formattingUtils';
import HealthTableHeaders from './HealthTableHeaders';
import { STRATEGY_FLIGHT_RUN_STATUSES } from '../constants';
import { StrategyFlightRun } from '../types';
import { getIsoDateTime } from '../utils';
import { HEALTH_CARE_STYLES } from '../style';

const { healthTableGrid, healthTableStyle } = HEALTH_CARE_STYLES;

const HEADERS = [
  'Strategy Type',
  'Algorithm Type',
  'Success',
  'Failed',
  'Running',
  'No Data',
  'Flight External Not Found',
  'Abort',
  'Cancelled',
  'Pending',
];

type Props = {
  strategyFlightRuns: Array<StrategyFlightRun>
  startDate: Moment
  endDate: Moment
};

const SFRHealthTable = ({ strategyFlightRuns, startDate, endDate }: Props) => (
  <WppGrid container fullWidth style={healthTableGrid}>
    <WppTable style={healthTableStyle}>
      <WppTableHeader>
        <HealthTableHeaders headers={HEADERS} />
      </WppTableHeader>
      <WppTableBody className="wpp-tbody">
        {
          _.map(strategyFlightRuns, (sfr) => (
            <WppTableBodyRow key={generateUUID()}>
              <WppTableBodyCell>
                <QueryStringLink
                  to="/strategyflightruns"
                  params={{
                    strategyType: [sfr.strategyType],
                    startDate: getIsoDateTime(startDate),
                    endDate: getIsoDateTime(endDate),
                  }}
                >
                  <WppTypography type="s-body" className="healthCareLinkColor">{STRATEGY_TYPE.getById(sfr.strategyType as number)?.displayName}</WppTypography>
                </QueryStringLink>
              </WppTableBodyCell>
              <WppTableBodyCell>
                <QueryStringLink
                  to="/strategyflightruns"
                  params={{
                    strategyType: [sfr.strategyType],
                    algorithmType: [sfr.algorithmType],
                    startDate: getIsoDateTime(startDate),
                    endDate: getIsoDateTime(endDate),
                  }}
                >
                  <WppTypography type="s-body" className="healthCareLinkColor">{sfr.algorithmType && ALGORITHM_TYPE.getById(sfr.algorithmType as number).name}</WppTypography>
                </QueryStringLink>
              </WppTableBodyCell>
              {
                _.map(STRATEGY_FLIGHT_RUN_STATUSES, (key) => (
                  <WppTableBodyCell key={generateUUID()}>
                    {
                      _.get(sfr.status, key)
                        ? (
                          <QueryStringLink
                            to="/strategyflightruns"
                            params={{
                              strategyType: [sfr.strategyType],
                              algorithmType: [sfr.algorithmType],
                              startDate: getIsoDateTime(startDate),
                              endDate: getIsoDateTime(endDate),
                              status: [key],
                            }}
                          >
                            <WppTypography type="s-body" className="healthCareLinkColor">{_.get(sfr.status, key)}</WppTypography>
                          </QueryStringLink>
                        )
                        : <WppTypography type="s-body">-</WppTypography>
                    }
                  </WppTableBodyCell>
                ))
              }
            </WppTableBodyRow>
          ))
        }
      </WppTableBody>
    </WppTable>
  </WppGrid>
);

export default SFRHealthTable;
