import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { dateTimeFormatter } from 'utils/dateTime';

import {
  Card, Container, Grid, Header, Segment, Table, Modal, Button, Label,
} from 'buildingBlocks';
import JobStatusLabel from 'components/JobStatusLabel/index';
import TaskCard from 'components/TaskCard/index';
import { colorForStatus } from 'containers/Jobs/utils';
import { Job } from 'containers/Jobs/types';
import { Permission } from 'utils/featureFlags';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import TextLinkBack from 'components/TextLinkBack';
import { getPreviousPageLink } from 'containers/Dashboard/utils';
import withRouter, { RouterProps } from 'utils/withRouter';

import { fetchJob } from './actions';
import { slash } from './style';
import { TASK_KEYS } from './constants';

const REQUIRED_PERMISSIONS = [Permission.manageJobs];

type DatumRowProps = {
  label: string,
  value: any
};
type JobDetailProps = RouterProps & {
  fetchJob: Function,
  job: Job,
  unauthorized: boolean,
};

const DatumRow = (props: DatumRowProps) => (
  <Table.Row>
    <Table.Cell>
      <b>
        {props.label}
      </b>
    </Table.Cell>
    <Table.Cell>
      {props.value}
    </Table.Cell>
  </Table.Row>
);

class JobDetail extends Component<JobDetailProps> {
  UNSAFE_componentWillMount() {
    const { router } = this.props;
    this.props.fetchJob(router.params.jobId);
  }

  data = (props: JobDetailProps) => [
    {
      label: 'Status',
      value: <JobStatusLabel
        job={props.job}
        color={colorForStatus(props.job.status)}
      />,
    },
    {
      label: 'Created',
      value: <span>{dateTimeFormatter.dateTime(props.job.createdAt, true)}</span>,
    },
    {
      label: 'Updated',
      value: <span>{dateTimeFormatter.dateTime(props.job.updatedAt, true)}</span>,
    },
    {
      label: 'UUID',
      value: props.job.uuid,
    },
    {
      label: 'Member',
      value: _.get(props, 'job.member.displayName'),
    },
  ];

  render() {
    const { router } = this.props;
    return (
      // @ts-ignore page template
      <PermissionPageTemplate
        name={`Job detail - ${router.params.jobId}`}
        subNavElements={(
          <TextLinkBack
            name="Back to Jobs"
            linkTo={getPreviousPageLink(router.location, '/jobs')}
          />
        )}
        permissions={REQUIRED_PERMISSIONS}
        unauthorized={this.props.unauthorized}
        unauthorizedProps={{
          message: `You are not authorized to see job ${router.params.jobId}.`,
          button: {
            url: '/jobs',
            content: 'Go back to jobs listing page',
          },
        }}
      >
        <Grid padded columns="1">
          <Grid.Row>
            <Grid.Column>
              <Container text>
                <Segment clearing>
                  <Header as="h1" floated="left">
                    <Link to={getPreviousPageLink(router.location, '/jobs')}>Jobs</Link>
                    <span style={slash}>/</span>
                    {this.props.job.id}
                  </Header>
                  <Header as="h3" floated="right">
                    <Label>{this.props.job.type}</Label>
                  </Header>
                  {this.props.job
                  && (
                  <Table basic="very" celled>
                    <Table.Body>
                      {this.data(this.props).map((datum) => (
                        <DatumRow key={datum.label} {...datum} />
                      ))}
                      <Table.Row>
                        <Table.Cell colSpan="2">
                          <Modal trigger={<Button size="mini" content="Details" />} style={{ overflowX: 'scroll' }}>
                            <Modal.Header>
                              Job Details for {this.props.job.type}
                            </Modal.Header>
                            <Modal.Content>
                              <pre>
                                {this.props.job.statusDetail}
                              </pre>
                            </Modal.Content>
                          </Modal>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  )}
                </Segment>
                <Card.Group>
                  {!_.isEmpty(this.props.job) && TASK_KEYS.map((k) => (
                    this.props.job[k] && <TaskCard key={k} type={k} task={this.props.job[k]} />
                  ))}
                </Card.Group>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PermissionPageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  job: state.jobDetail.job,
  unauthorized: state.jobDetail.unauthorized,
});

export default connect(mapStateToProps, { fetchJob })(withRouter(JobDetail));
