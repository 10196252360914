import { JOBS_DETAIL_FETCH_COMPLETED, JOBS_DETAIL_FETCH_FAILED } from './constants';

const INITIAL_STATE = {
  job: {},
  unauthorized: false,
};

export default function jobDetail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JOBS_DETAIL_FETCH_FAILED:
      return { ...state, unauthorized: true };
    case JOBS_DETAIL_FETCH_COMPLETED:
      return { ...state, job: action.payload };
    default:
      return state;
  }
}
