import _ from 'lodash';
import { OPERATOR } from 'constantsBase';
import { Messages } from 'utils/copilotAPI';
import {
  SELECT_ALL,
  SELECT_NONE,
  TOGGLE_MODAL,
  MESSAGES_SELECTED,
  DELETE_MESSAGES_COMPLETED,
  DELETE_MESSAGES_FAILED,
  CLEAR_RESULT_MESSAGE,
  CLEAR_SELECTED_MESSAGES,
  MESSAGES_FETCH_COMPLETED,
  MESSAGES_FETCH_FAILED,
} from './constants';

type FilterType = {
  or?: Array<{ [key: string]: { [key: string]: string } }>,
  isDeleted: boolean,
  createdBy: { [key: string]: number },
  updatedBy: { [key: string]: number } };

export const getMessages = async (limit = 10, skip = 0, searchStr = '', dispatch) => {
  const filter: FilterType = {
    isDeleted: false,
    createdBy: { [OPERATOR.NOT]: 0 },
    updatedBy: { [OPERATOR.NOT]: 0 },
  };

  if (!_.isEmpty(searchStr)) {
    filter.or = [
      { subject: { [OPERATOR.CONTAINS]: searchStr } },
      { id: { [OPERATOR.CONTAINS]: searchStr } },
    ];
  }

  try {
    const [messagesCount, messages] = await Promise.all([
      Messages.count({ where: filter }),
      Messages.get({
        where: filter,
        limit,
        skip,
        sort: 'startDate desc',
        isDeleted: false,
        createdBy: { [OPERATOR.NOT]: 0 },
        updatedBy: { [OPERATOR.NOT]: 0 },
      }),
    ]);
    dispatch({
      type: MESSAGES_FETCH_COMPLETED,
      payload: {
        messages: messages.data,
        messagesCount: messagesCount.data.count,
      },
    });
  } catch (error) {
    dispatch({ type: MESSAGES_FETCH_FAILED, error });
  }
};

export function selectAll() {
  return {
    type: SELECT_ALL,
  };
}

export function selectNone() {
  return {
    type: SELECT_NONE,
  };
}

export function toggleModal() {
  return {
    type: TOGGLE_MODAL,
  };
}

export function selectMessages(id) {
  return {
    type: MESSAGES_SELECTED,
    payload: id,
  };
}

export function deleteMessagesCompleted(deleted) {
  return {
    type: DELETE_MESSAGES_COMPLETED,
    payload: deleted,
  };
}

export function deleteMessagesFailed() {
  return {
    type: DELETE_MESSAGES_FAILED,
  };
}

export function clearStatusMsg() {
  return {
    type: CLEAR_RESULT_MESSAGE,
  };
}
export function clearSelectedMessages() {
  return {
    type: CLEAR_SELECTED_MESSAGES,
  };
}

export const deleteMessages = async (messages, limit, skip, search, dispatch) => {
  try {
    const records = _.map(messages, (s) => ({ ..._.pick(s, ['isDeleted', 'id']), isDeleted: true }));
    await Messages.bulkUpdate(records);
    await getMessages(limit, skip, search, dispatch);
    dispatch(deleteMessagesCompleted(records));
  } catch (error) {
    dispatch(deleteMessagesFailed());
    // TODO: we still need to figure out what to do when exception occurs
  }
};
