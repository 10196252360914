import _ from 'lodash';
import { Routes, Route, Outlet } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { connect } from 'react-redux';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { WppTab, WppTabs, WppTypography } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { Permission } from 'utils/featureFlags';
import DateRangePicker from 'components/DateRangePicker';
import JobsHealthTable from './components/JobsHealthTable';
import SFRHealthTable from './components/SFRHealthTable';
import DagRunsHealthTable from './components/DagRunsHealthTable';
import { getSummaryData } from './actions';
import { Job, StrategyFlightRun, DagRun } from './types';
import { HEALTH_CARE_STYLES } from './style';

const { tabStyle } = HEALTH_CARE_STYLES;

type Props = {
  getSummaryData: Function
  startDate: Moment
  endDate: Moment
  jobs?: Array<Job>
  strategyFlightRuns?: Array<StrategyFlightRun>
  dagRuns?: Array<DagRun>
};

export type TabType = {
  label: string
  to: string
  props: any
};

const TabView = ({ tabs }: { tabs: Record<string, TabType> }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>(_.get(_.values(tabs)[0], 'label', ''));

  useEffect(() => {
    const selectedTab = _.find(_.values(tabs) as Array<TabType>, (tab: TabType) => matchPath(tab.to, location.pathname)) as TabType;
    if (selectedTab) {
      setCurrentTab(selectedTab.label);
    }
  }, [location.pathname, tabs]);

  const handleTabClick = (tab: TabType) => {
    setCurrentTab(tab.label);
    navigate(tab.to);
  };

  return (
    <>
      {tabs && (
        <WppTabs value={currentTab}>
          {_.values(tabs).map((tab: TabType) => (
            <WppTab
              key={tab.label}
              value={tab.label}
              style={tabStyle}
              onClick={() => handleTabClick(tab)}
            >
              <WppTypography type="s-strong">{tab.label}</WppTypography>
            </WppTab>
          ))}
        </WppTabs>
      )}
      <Routes>
        <Route path="/jobs" element={<JobsHealthTable {...tabs.jobs.props} />} />
        <Route path="/sfr" element={<SFRHealthTable {...tabs.sfr.props} />} />
        <Route path="/dags" element={<DagRunsHealthTable {...tabs.dags.props} />} />
      </Routes>
    </>
  );
};

const getTabs = (props: Props) => ({
  jobs: {
    label: 'Jobs',
    to: '/health/jobs',
    props: {
      jobs: props.jobs,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
  sfr: {
    label: 'Strategy Flight Runs',
    to: '/health/sfr',
    props: {
      strategyFlightRuns: props.strategyFlightRuns,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
  dags: {
    label: 'DAGs',
    to: '/health/dags',
    props: {
      dagRuns: props.dagRuns,
      startDate: props.startDate,
      endDate: props.endDate,
    },
  },
});

const CopilotHealth = (props: Props) => {
  const { startDate, endDate, getSummaryData: fetchSummaryData } = props;

  useEffect(() => {
    fetchSummaryData(startDate, endDate);
  }, [startDate, endDate, fetchSummaryData]);

  return (
    <>
      <WppPermissionPageTemplate
        name="Copilot Health"
        permissions={[Permission.manageJobs]}
      >
        <DateRangePicker
          onDateRangeSubmit={fetchSummaryData}
          hasApplyButton
          enableFutureDateSelection
        />
      </WppPermissionPageTemplate>
      <WppPermissionPageTemplate permissions={[Permission.manageJobs]}>
        <TabView tabs={getTabs(props)} />
      </WppPermissionPageTemplate>
      <Outlet />
    </>
  );
};

function mapStateToProps(state) {
  const {
    jobs, strategyFlightRuns, dagRuns, startDate, endDate,
  } = state.copilotHealth;
  return {
    jobs,
    strategyFlightRuns,
    dagRuns,
    startDate,
    endDate,
  };
}

export default connect(mapStateToProps, { getSummaryData })(CopilotHealth);
