import _ from 'lodash';
import {
  COLUMNS,
  DEFAULT_SORT,
  FILTER_PATCH,
  FILTER_SET,
  FILTER_RESET,
  FETCH_MEMBERS,
  FETCH_JOBS,
  RANGE_SET,
  SORT_SET,
  STARTING_ELEMENTS_PER_PAGE,
  JOB_TYPE_OPTIONS,
  JOB_STATUS_OPTIONS,
} from './constants';

import { SortDirection } from './types';

const toggleSortDirection = (currentSort, clickedColumn): SortDirection => {
  if (
    currentSort.column === clickedColumn
    && currentSort.direction === 'descending'
  ) {
    return 'ascending';
  }
  return 'descending';
};

export const INITIAL_STATE = {
  // API responses.
  jobs: null,
  members: null,
  jobsCount: 0,

  // Options.
  jobTypeOptions: JOB_TYPE_OPTIONS,
  jobStatusOptions: JOB_STATUS_OPTIONS,
  memberOptions: [],

  filter: {
    router: {},
    startDate: null,
    endDate: null,
    type: [],
    member: [],
    status: [],
  },

  // Pagination
  range: {
    limit: STARTING_ELEMENTS_PER_PAGE,
    skip: 0,
  },

  sort: DEFAULT_SORT,

  // Data flight state.
  loadingMembers: false,
  loadingJobs: true,
};

export default function jobsListing(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MEMBERS.STARTED:
      return {
        ...state,
        loadingMembers: true,
      };

    case FETCH_MEMBERS.COMPLETED:
      return {
        ...state,
        members: action.payload,
        loadingMembers: false,
        memberOptions: _.map(
          action.payload,
          (member) => ({
            ...member,
            value: member.id,
          }),
        ),
      };

    case FETCH_MEMBERS.FAILED:
      return {
        ...state,
        memberFetchError: action.payload,
        loadingMembers: false,
      };

    case FETCH_JOBS.FAILED:
      return {
        ...state,
        jobFetchError: action.error,
        loadingJobs: false,
      };

    case FETCH_JOBS.COMPLETED:
      return {
        ...state,
        jobs: action.payload.jobs,
        jobsCount: action.payload.jobsCount,
        loadingJobs: false,
      };

    case FILTER_RESET:
      return { ...state, ..._.pick(INITIAL_STATE, ['sort', 'range', 'filter']) };

    case FILTER_SET:
      return {
        ...state,
        filter: _.get(action.payload, 'filter', {}),
        range: _.get(action.payload, 'range', INITIAL_STATE.range),
      };

    case FILTER_PATCH:
      return {
        ...state,
        filter: {
          ...state.filter,
          ..._.omit(action.payload, 'history'),
        },
      };

    case SORT_SET: {
      const { columnName } = action.payload;
      const clickedColumn = _.find(COLUMNS, (column) => (columnName === column.value));
      if (!clickedColumn.sortable) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        sort: {
          column: columnName,
          direction: toggleSortDirection(state.sort, columnName),
        },
      };
    }

    case RANGE_SET:
      return {
        ...state,
        range: { limit: action.payload.limit, skip: action.payload.skip },
      };

    default:
      return state;
  }
}
