/*
 *
 * CopilotHealth reducer
 *
 */

import moment from 'moment';
import { GET_SUMMARY_DATA_COMPLETED, GET_SUMMARY_DATA } from './constants';

const INITIAL_STATE = {
  jobs: [],
  strategyFlightRuns: [],
  dagRuns: [],
  startDate: moment().startOf('day').subtract(1, 'days'),
  endDate: moment().endOf('day'),
};

function copilotHealthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SUMMARY_DATA: {
      const { startDate, endDate } = action.payload;
      return { ...state, startDate, endDate };
    }
    case GET_SUMMARY_DATA_COMPLETED: {
      const { jobs, strategyFlightRuns, dagRuns } = action.payload;
      return {
        ...state, jobs, strategyFlightRuns, dagRuns,
      };
    }
    default:
      return state;
  }
}

export default copilotHealthReducer;
