import _ from 'lodash';
import qs from 'qs';
import React, { Dispatch, SyntheticEvent } from 'react';
import { Table, Checkbox } from 'buildingBlocks';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import MessageDetails from './MessageDetails';
import { tableHeader, messageTable } from '../style';
import { Message, SelectedMessages } from '../types';
import { messagesListingTableColumns } from '../constants';
import { deleteMessages } from '../actions';

type MessageTableProps = {
  messages: Array<Message>
  selectedMessages: SelectedMessages
  deleteEnabledMessages: SelectedMessages
  modalOpen: boolean
  deleting: boolean
  handleSelectAll: Function
  onMessageSelected: (e: SyntheticEvent, message: Message) => void
  toggleModal: (...args: Array<any>) => void
  dispatch: Dispatch<any>
};

const MessageTable = ({
  messages,
  selectedMessages,
  deleteEnabledMessages,
  modalOpen,
  deleting,
  handleSelectAll,
  onMessageSelected,
  toggleModal,
  dispatch,
}: MessageTableProps) => {
  const checked = !_.isEmpty(selectedMessages) && _.size(selectedMessages) === _.size(deleteEnabledMessages);
  const handleDeleteMessages = () => {
    const { limit, skip, search } = qs.parse(location.search.replace(/^\?/, ''));
    deleteMessages(selectedMessages, limit, skip, search, dispatch);
  };
  return (
    <Table style={messageTable}>
      <Table.Header>
        <Table.Row>
          {messages.length
            ? (
              <Table.HeaderCell>
                <Checkbox
                  checked={checked}
                  onClick={(_e, d: { checked: any }) => handleSelectAll(d)}
                />
              </Table.HeaderCell>
            )
            : null}
          { _.map(messagesListingTableColumns, (column) => (
            <Table.HeaderCell key={column.value} style={tableHeader}>
              {column.text}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          _.map(messages, (message) => (
            <MessageDetails
              key={message.id}
              message={message}
              onSelected={(e) => onMessageSelected(e, message)}
              selected={_.has(selectedMessages, message.id)}
            />
          ))
        }
      </Table.Body>
      <DeleteConfirmationModal
        itemsToDelete={selectedMessages}
        onCancel={toggleModal}
        open={modalOpen}
        onDelete={handleDeleteMessages}
        deleting={deleting}
        itemName="subject"
        modalName="Delete Messages"
      />
    </Table>
  );
};

export default MessageTable;
