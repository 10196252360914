import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { WPP } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const strategyTableHeader: { [key: string]: CSSProperties | any } = {
  timeIcon: {
    paddingRight: SPACING[8],
  },
  goalDropDown: {
    width: '120px',
  },
  objectContainer: {
    background: WPP.grey300,
  },
  objectDivStyle: {
    marginLeft: '-16px',
  },
  actionCellStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  viewabilityMargin: {
    marginTop: SPACING[16],
  },
  strategyTitleMarginBottom: {
    marginBottom: SPACING[16],
  },
  modalHeading: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  actionButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: SPACING[8],
  },
};
