import React, { useRef } from 'react';
import _ from 'lodash';
import { WppIconMore, WppIconTrash, WppListItem, WppPopover } from 'buildingBlocks';
import { ActionTypes } from '../constants';
import { DEMO_SITES_STYLE } from '../styles';
import { LinksPopover } from './LinksPopover';

const {
  actionButtonDiv,
  hyperLinkStyles,
  popOverContent,
  moreItems,
} = DEMO_SITES_STYLE;

type MoreActionsPopoverProps = {
  domain: string
  handleDelete: (id: number) => void
  id?: number
};

export const MoreActionsPopover = ({ domain, id, handleDelete }: MoreActionsPopoverProps) => {
  const ref = useRef<HTMLWppPopoverElement>(null);
  const handleSiteDelete = () => {
    if (id) {
      handleDelete(id);
    }
  };

  return (
    <WppPopover
      config={{ placement: 'left-end' }}
      closable={false}
      ref={ref}
    >
      <WppIconMore
        slot="trigger-element"
        color="var(--wpp-grey-color-700)"
        style={hyperLinkStyles}
      />
      <div style={popOverContent}>
        <LinksPopover domain={domain} />
        <WppListItem
          key={ActionTypes.delete}
          style={moreItems}
          onWppChangeListItem={handleSiteDelete}
        >
          <p slot="label" style={_.omit(actionButtonDiv, 'justifyContent')}>
            <WppIconTrash color="var(--wpp-grey-color-700)" />
            <span>{_.capitalize(ActionTypes.delete)}</span>
          </p>
        </WppListItem>
      </div>
    </WppPopover>
  );
};
