/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import moment from 'moment';
import { Location, useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import KeyBinding from 'react-keybinding-component';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import qs from 'qs';
import env from 'env';
import { GlobalState } from 'reducers';
import { Sidebar } from 'buildingBlocks';
import NavBar from 'components/Nav';
import Chatbot from 'components/Chatbot';
import Maintenance from 'containers/Maintenance';
import { WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { loadTheme, changeTheme } from 'containers/UserSettings/actions';
import Xmas from 'easterEggs/Xmas2018';
import { InnerRoutes } from 'routes';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { usePrevious } from 'utils/hooks/usePrevious';
import { Auth } from 'utils/copilotAPI';
import { SIMPLE_DATE_FORMAT } from 'utils/dateTime';
import KEY_MAP, { KeyboardEvent } from 'utils/keymap';
import { User } from 'utils/types';
import AdBlockerModal from './components/AdBlockerModal';
import BannerComponent from './components/BannerComponent';
import mainContainer from './style';
import Login from '../Login';
import { getSession } from '../Login/actions';
import 'react-toastify/dist/ReactToastify.css';
import './overrideToast.css';

type IO = {
  socket: {
    on: Function,
    get: Function,
    off: Function,
  }
};
declare global {
  interface Window {
    previousLocation: Location;
    io: IO;
    pendo: any;
  }
}

// Order of precedence for roles -> admin, QA Global (Read Only), Developer API, Read & Write, Read Only
// even if a user has multiple roles, we assign only one, based on the above precedence
// Not comparing with IDs as they might be different for different environments
const getUserRole = (user: User) => {
  const roles = _.uniq(_.map(user.roles, 'name'));
  if (_.includes(roles, 'admin')) {
    return 'admin';
  }
  if (_.includes(roles, 'QA Global (Read Only)')) {
    return 'QA Global (Read Only)';
  }
  if (_.includes(roles, 'Developer API')) {
    return 'Developer API';
  }
  if (_.includes(roles, 'Read & Write')) {
    return 'Read & Write';
  }
  if (_.includes(roles, 'Read Only')) {
    return 'Read Only';
  }
  return '';
};

const initializePendo = (user: User) => {
  window.pendo.initialize({
    visitor: {
      id: user.id,
      env: env.ENV,
      role: getUserRole(user),
    },
  });
};

export const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const prevPathname = usePrevious(location.pathname);

  const {
    user,
    isAuthenticated,
    isAuthenticatedWithoutUser,
    pendingAuth,
    notificationsActionsUnreadCount,
  } = useSelector<GlobalState>((state) => state.login) as any;
  const isHomePage = _.isEqual(location.pathname, '/') || _.isEqual(location.pathname, '/strategies');
  const numTimesShownStored = localStorage.getItem('numTimesShownToday');
  const numTimesShownToday = _.isNil(numTimesShownStored) ? 1 : Number(numTimesShownStored);

  const { adblockDetected } = useSelector<GlobalState>((state) => state.app) as any;
  const [showAdBlockerModal, setShowAdBlockerModal] = useState<boolean>(false);
  const [maintenance, setMaintenance] = useState<boolean>(null);
  const lastDateStored = localStorage.getItem('lastDateStored');

  // if not previously set or is next day - reset date
  if (_.isNil(lastDateStored) || _.isNil(numTimesShownStored) || moment().diff(lastDateStored, 'd') >= 1) {
    localStorage.setItem('lastDateStored', moment().utc().format(SIMPLE_DATE_FORMAT).toString());
    localStorage.setItem('numTimesShownToday', '1');
  }

  const prevUser = usePrevious(user);
  const dispatch = useDispatch();

  const updateNumTimesShown = () => {
    const newCount = numTimesShownToday + 1;
    localStorage.setItem('numTimesShownToday', newCount.toString());
  };

  const handleBannerClose = () => {
    updateNumTimesShown();
    toast.dismiss();
  };

  const handleCompleteActionsClick = () => {
    handleBannerClose();
    const alreadyOnNotificationsPage = window.location.pathname === '/notifications';
    // only redirect if the user isn't on notifications page
    if (!alreadyOnNotificationsPage) {
      navigate('/notifications');
    }
  };

  const runEggs = (e: KeyboardEvent) => {
    if (e.ctrlKey) {
      if (e.shiftKey) {
        switch (e.keyCode) {
          case KEY_MAP.KEY_1:
            changeTheme();
            break;
          case KEY_MAP.KEY_2:
            if (!window.location.href.includes('/strategies/wizard')) {
              navigate(`/strategies/wizard/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`);
            }
            break;
          default:
            break;
        }
      } else if (e.keyCode === KEY_MAP.KEY_BACKSLASH) {
        changeTheme();
      } else if (e.keyCode === KEY_MAP.KEY_H) {
        if (window.location.hash !== '#support') {
          window.location.replace(`${window.location.href}#support`);
        }
      }
    }
  };

  const isOnStrategyWizard = (pathname: string) => _.includes(pathname, '/strategies/wizard/');

  const decrementStrategyWizardTabCount = () => {
    const strategyWizardTabCount = _.toNumber(localStorage.getItem('numOfStrategyWizardTabs'));
    if (strategyWizardTabCount > 1) {
      localStorage.setItem('numOfStrategyWizardTabs', _.toString(strategyWizardTabCount - 1));
    } else {
      localStorage.removeItem('numOfStrategyWizardTabs');
    }
  };

  useEffect(() => {
    const strategyWizardTabCount = _.toNumber(localStorage.getItem('numOfStrategyWizardTabs'));
    const isStrategyWizardPage = isOnStrategyWizard(location.pathname);
    const wasStrategyWizardPage = isOnStrategyWizard(prevPathname);

    // Increment numOfStrategyWizardTabs when navigating to the Strategy Wizard page
    if (isStrategyWizardPage && !wasStrategyWizardPage) {
      localStorage.setItem('numOfStrategyWizardTabs', !strategyWizardTabCount ? '1' : _.toString(strategyWizardTabCount + 1));
    }

    // Decrement numOfStrategyWizardTabs when navigating away from the Strategy Wizard page
    if (!isStrategyWizardPage && wasStrategyWizardPage) {
      decrementStrategyWizardTabCount();
    }
  }, [location.key, prevPathname]);

  useMount(() => {
    Auth.getMaintenanceStatus().then((status: boolean) => {
      setMaintenance(status);
    });
    dispatch(getSession({ navigate, location }));

    const handleBeforeUnload = () => {
      // only decrementStrategyWizardTabCount when on strategy wizard page
      // using window.location.pathname because location ends up '/' sometimes when closing
      if (isOnStrategyWizard(window.location.pathname)) {
        decrementStrategyWizardTabCount();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
  });

  useEffect(() => {
    if (!_.isEqual(showAdBlockerModal, adblockDetected)) {
      setShowAdBlockerModal(adblockDetected);
    }
  }, [adblockDetected]);

  useEffect(() => {
    window.previousLocation = location;
    if (!_.isEqual(_.get(user, 'id'), _.get(prevUser, 'id'))) {
      dispatch(loadTheme());
    }
  }, [user, location, prevUser]);

  if (_.isNull(maintenance)) {
    return null;
  }

  if (maintenance) {
    return <Maintenance />;
  }

  if (isAuthenticated || isAuthenticatedWithoutUser) {
    initializePendo(user);
    return (
      <div style={mainContainer}>
        <Sidebar.Pushable style={{ overflow: 'unset' }}>
          <Sidebar.Pusher style={{ overflow: 'unset' }}>
            <NavBar
              user={user}
            />
            <BannerComponent
              showBanner={notificationsActionsUnreadCount && numTimesShownToday <= 3 && isHomePage}
              handleClose={handleBannerClose}
              handleCompleteActionsClick={handleCompleteActionsClick}
            />
            <AdBlockerModal
              showAdBlockerModal={showAdBlockerModal}
              setShowAdBlockerModal={setShowAdBlockerModal}
            />
            <Chatbot />
            <InnerRoutes isAuthenticatedWithoutUser={isAuthenticatedWithoutUser} user={user} />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <Xmas changeTheme={changeTheme} />
        <KeyBinding
          onKey={runEggs}
          type="keydown"
        />
      </div>
    );
  }
  if (pendingAuth) {
    return null;
  }
  const query = qs.parse(location.search.replace(/^\?/, ''));
  return <Login code={query.code} />;
};

export default App;
