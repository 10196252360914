import {
  INIT,
  FETCH_MEMBERS,
  FILTER_PATCH,
  FILTER_RESET,
  FILTER_SET,
  RANGE_SET,
  SORT_SET,
} from './constants';

export function getMembers(id = []) {
  return {
    type: FETCH_MEMBERS.STARTED,
    payload: { id },
  };
}

export function resetFilter(router) {
  return {
    type: FILTER_RESET,
    payload: { router },
  };
}

export function updateFilter(name, patch, router) {
  return {
    type: FILTER_PATCH,
    payload: {
      [name]: patch,
      router,
    },
  };
}

export function initJobs(user, jobTypeOptions, jobStatusOptions, memberOptions, router) {
  return {
    type: INIT,
    payload: {
      user, jobTypeOptions, jobStatusOptions, memberOptions, router,
    },
  };
}

export function setFilter(filter, router) {
  return {
    type: FILTER_SET,
    payload: { filter, router },
  };
}

export function setRange(range, router) {
  return {
    type: RANGE_SET,
    payload: {
      limit: range.limit,
      skip: range.start,
      router,
    },
  };
}

export function setSort(columnName, router) {
  return {
    type: SORT_SET,
    payload: { columnName, router },
  };
}
