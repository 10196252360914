import _ from 'lodash';
import React from 'react';
import { WppCheckbox } from 'buildingBlocks';
import { useBulkCreateAttachModalContext } from 'containers/BulkCreateStrategyWizard/steps/AttachFlights/contexts/BulkCreateAttachModalProvider';
import { strategyTableColumns } from 'containers/StrategiesList/style';
import { Flight } from 'utils/types';

const { selectCheckbox } = strategyTableColumns;

type FlightCandidateCheckboxProps = {
  flight: Flight
};

export const FlightCandidateCheckbox = (props: FlightCandidateCheckboxProps) => {
  const { flight } = props;
  const { sessionAttachFlights } = useBulkCreateAttachModalContext();
  const checked = _.some(sessionAttachFlights, ['id', flight.id]);

  return (
    <div style={selectCheckbox}>
      <WppCheckbox
        checked={checked}
        className={checked ? 'visible-checkbox' : 'wpp-checkbox'}
      />
    </div>
  );
};
