import React from 'react';
import { Link } from 'react-router-dom';
import { WppButton } from 'buildingBlocks';
import { WizardSteps } from 'containers/StrategyWizard/constants';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { STRATEGY_WIZARD_BUTTON_TAG } from 'cssTagConstants';
import { COPILOT_LAYOUT } from 'globalStyles';
import { createLinkWithQS } from 'utils/functionHelpers';
import { BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS } from 'containers/BulkCreateStrategyWizard/constants';

type ConfirmButtonProps = {
  disabled: boolean
};

const { SPACING } = COPILOT_LAYOUT;

const ConfirmButton = ({ disabled }: ConfirmButtonProps) => {
  const backBtnStepId = BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id;

  return (
    <div style={{ ...strategyWizardStyles.navButtonContainerStyle, gap: SPACING[12] }}>
      <Link to={createLinkWithQS(`${BULK_CREATE_WIZARD_BASE}${backBtnStepId}`)}>
        <WppButton
          variant="secondary"
          id={STRATEGY_WIZARD_BUTTON_TAG.backButtonForNewStrategy}
        >
          Back
        </WppButton>
      </Link>
      <WppButton
        type="submit"
        id={STRATEGY_WIZARD_BUTTON_TAG.updateStrategy}
        disabled={disabled}
        style={{ pointerEvents: disabled ? 'none' : 'all' }}
      >
        Create Strategy
      </WppButton>
    </div>
  );
};

export default ConfirmButton;
