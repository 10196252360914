import {
  FETCH_STRATEGY_FLIGHT_RUNS,
  DASHBOARD_FETCH_LOG,
  DASHBOARD_SELECT_FLIGHT,
  DASHBOARD_SELECT_TASK,
  DASHBOARD_SELECT_RESULT,
  DASHBOARD_DOWNLOAD_RESULT,
} from './constants';

/**
 * Fetch the strategy, flights, and runs on component load.
 */
export const fetchStrategyFlightRuns = (strategyId, flightId, runId) => ({
  type: FETCH_STRATEGY_FLIGHT_RUNS,
  payload: { strategyId, flightId, runId },
});

/**
 * When user selects a flight.
 */
export const selectFlight = (flightId) => ({
  type: DASHBOARD_SELECT_FLIGHT,
  payload: { flightId },
});

/**
 * When user selects a task.
 */
export const selectTask = (task) => ({
  type: DASHBOARD_SELECT_TASK,
  payload: { task },
});

/**
 * When user selects an S3 task output path.
 */
export const selectResult = (run, task, result, index, download = false) => ({
  type: DASHBOARD_SELECT_RESULT,
  payload: {
    run, task, result, index, download,
  },
});

/**
 * When user clicks on download button to download an S3 file.
 */
export const downloadResult = (run, task, result) => ({
  type: DASHBOARD_DOWNLOAD_RESULT,
  payload: { run, task, result },
});

/**
 * When user request log output for a task.
 */
export const fetchLog = (run, task) => ({
  type: DASHBOARD_FETCH_LOG,
  payload: { run, task },
});
