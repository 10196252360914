// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-cell {
  display: flex;
  height: 100% !important;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 7px;
}

.ag-center-cols-container {
  width: 100% !important;
}

.ag-theme-wpp.flight-candidates-table .ag-header {
  display: none !important;
  border-bottom: none !important;
}

.dark {
  background-color: var(--wpp-grey-color-800) !important;
  -webkit-filter: unset !important;
  filter: unset !important;
}

a {
  color: var(--wpp-primary-color-500);
}

.toolTipContent a {
  color: var(--wpp-gray-color-000);
  text-decoration: underline;
}
.toolTipContent a:hover {
  text-decoration: none;
  color: var(--wpp-gray-color-000) !important;
}

.already-attached-table-row .wpp-table-body-cell.row-selection {
  display: flex;
  width: 175px;
  padding: 4px !important;
  border-bottom: none !important;
}
.already-attached-table-row .wpp-table-body-cell .flight-name-div {
  display: flex;
  align-items: center;
  gap: 4px;
}
.already-attached-table-row .wpp-table-body-cell.flight-name, .already-attached-table-row .wpp-table-body-cell.strategy-name {
  width: 407px;
}

.wpp-progress-indicator.candidateValidationProgressBar.wpp-progress-indicator.wrapper {
  --pi-width: 1000px !important;
}
.wpp-progress-indicator.candidateValidationProgressBar.wpp-progress-indicator.wrapper .title {
  margin-bottom: 40px;
}
.wpp-progress-indicator.candidateValidationProgressBar.wpp-progress-indicator.wrapper .input {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/containers/BulkCreateStrategyWizard/steps/AttachFlights/components/AttachFlightsModal/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAGE;EACE,wBAAA;EACA,8BAAA;AAAJ;;AAIA;EACE,sDAAA;EACA,gCAAA;EACA,wBAAA;AADF;;AAIA;EACE,mCAAA;AADF;;AAIA;EACE,gCAAA;EACA,0BAAA;AADF;AAGE;EACE,qBAAA;EACA,2CAAA;AADJ;;AAOI;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,8BAAA;AAJN;AAOI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AALN;AAQI;EAEE,YAAA;AAPN;;AAYA;EACE,6BAAA;AATF;AAWE;EACE,mBAAA;AATJ;AAYE;EACE,mBAAA;AAVJ","sourcesContent":[".ag-cell {\n  display: flex;\n  height: 100% !important;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 7px;\n}\n\n.ag-center-cols-container {\n  width: 100% !important;\n}\n\n.ag-theme-wpp.flight-candidates-table {\n  .ag-header {\n    display: none !important;\n    border-bottom: none !important;\n  }\n}\n\n.dark {\n  background-color: var(--wpp-grey-color-800) !important;\n  -webkit-filter: unset !important;\n  filter: unset !important;\n}\n\na {\n  color: var(--wpp-primary-color-500);\n}\n\n.toolTipContent a {\n  color: var(--wpp-gray-color-000);\n  text-decoration: underline;\n\n  &:hover {\n    text-decoration: none;\n    color: var(--wpp-gray-color-000) !important;\n  }\n}\n\n.already-attached-table-row {\n  .wpp-table-body-cell {\n    &.row-selection {\n      display: flex;\n      width: 175px;\n      padding: 4px !important;\n      border-bottom: none !important;\n    }\n\n    .flight-name-div {\n      display: flex;\n      align-items: center;\n      gap: 4px;\n    }\n\n    &.flight-name,\n    &.strategy-name {\n      width: 407px;\n    }\n  }\n}\n\n.wpp-progress-indicator.candidateValidationProgressBar.wpp-progress-indicator.wrapper {\n  --pi-width: 1000px !important;\n\n  .title {\n    margin-bottom: 40px;\n  }\n\n  .input {\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
