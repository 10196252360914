import _ from 'lodash';
import { SemanticCOLORS } from 'buildingBlocks';
import { Permission } from 'utils/featureFlags';
import { JobTypeOption, JobStatusOption, TimeZoneOption, TimeZoneValue } from './types';

const PREFIX = 'JOBS';

// Pagination
export const STARTING_ELEMENTS_PER_PAGE = 10;
export const ELEMENTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

// Actions
export const FETCH_MEMBERS = {
  STARTED: `${PREFIX}:FETCH_MEMBERS_STARTED`,
  SUCCEEDED: `${PREFIX}:FETCH_MEMBERS_SUCCEEDED`,
  COMPLETED: `${PREFIX}:FETCH_MEMBERS_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_MEMBERS_FAILED`,
};

export const FETCH_JOBS = {
  SUCCEEDED: `${PREFIX}:FETCH_JOBS_SUCCEEDED`,
  COMPLETED: `${PREFIX}:FETCH_JOBS_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_JOBS_FAILED`,
};

export const FETCH_JOB_STATUSES = {
  STARTED: `${PREFIX}:FETCH_JOB_STATUSES_STARTED`,
  SUCCEEDED: `${PREFIX}:FETCH_JOB_STATUSES_SUCCEEDED`,
  COMPLETED: `${PREFIX}:FETCH_JOB_STATUSES_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_JOB_STATUSES_FAILED`,
};

export const FETCH_JOB_TYPES = {
  STARTED: `${PREFIX}:FETCH_JOB_TYPES_STARTED`,
  SUCCEEDED: `${PREFIX}:FETCH_JOB_TYPES_SUCCEEDED`,
  COMPLETED: `${PREFIX}:FETCH_JOB_TYPES_COMPLETED`,
  FAILED: `${PREFIX}:FETCH_JOB_TYPES_FAILED`,
};

export const FILTER_RESET = `${PREFIX}:FILTER_RESET`;
export const FILTER_PATCH = `${PREFIX}:FILTER_PATCH`;
export const FILTER_SET = `${PREFIX}:FILTER_SET`;
export const RANGE_SET = `${PREFIX}:RANGE_SET`;
export const SORT_SET = `${PREFIX}:SORT_SET`;
export const INIT = `${PREFIX}:INIT`;

// Options
export const JOB_TYPE_OPTIONS: Array<JobTypeOption> = [
  {
    text: 'Algo Detachment',
    value: 'algo_detachment',
  },
  {
    text: 'AMZN Report Analytics',
    value: 'amzn_report_analytics',
  },
  {
    text: 'AMZN Report Daily ETL',
    value: 'amzn_report_daily_etl',
  },
  {
    text: 'AMZN Report Download',
    value: 'amzn_report_dl',
  },
  {
    text: 'APN Impression Value Analytics',
    value: 'apn_impression_value_analytics',
  },
  {
    text: 'APN Standard Feed Analytics',
    value: 'apn_standard_feed_analytics',
  },
  {
    text: 'APN Standard Feed Daily ETL',
    value: 'apn_standard_feed_daily_etl',
  },
  {
    text: 'APN Video Feed Analytics',
    value: 'apn_video_feed_analytics',
  },
  {
    text: 'APN Video Feed Daily ETL',
    value: 'apn_video_feed_daily_etl',
  },
  {
    text: 'APN Video Report Analytics',
    value: 'apn_video_report_analytics',
  },
  {
    text: 'APN View Duration Analytics',
    value: 'apn_view_duration_analytics',
  },
  {
    text: 'Database Clean Up',
    value: 'db_model_clean_up',
  },
  {
    text: 'DBM Feature Report Download',
    value: 'dbm_feature_report_dl',
  },
  {
    text: 'DBM General Feature Geo Report Daily ETL',
    value: 'dbm_general_feature_geo_report_daily_etl',
  },
  {
    text: 'DBM General Feature Report Daily ETL',
    value: 'dbm_general_feature_report_daily_etl',
  },
  {
    text: 'DBM Reach Report Analytics',
    value: 'dbm_reach_report_analytics',
  },
  {
    text: 'DBM Report Analytics',
    value: 'dbm_report_analytics',
  },
  {
    text: 'DBM Trueview Feature Device Report ETL',
    value: 'dbm_trueview_feature_device_report_etl',
  },
  {
    text: 'DBM Trueview Feature Geo Report ETL',
    value: 'dbm_trueview_feature_geo_report_etl',
  },
  {
    text: 'DBM Trueview Feature Report Daily ETL',
    value: 'dbm_trueview_feature_report_daily_etl',
  },
  {
    text: 'DBM Trueview Report Analytics',
    value: 'dbm_trueview_report_analytics',
  },
  {
    text: 'Flight Attachment',
    value: 'flight_attachment',
  },
  {
    text: 'Flight Detachment',
    value: 'flight_detachment',
  },
  {
    text: 'LLD',
    value: 'lld',
  },
  {
    text: 'Parquet Conversion',
    value: 'parquet_conversion',
  },
  {
    text: 'Populate Hourly Spend Statistics',
    value: 'populate_hourly_spend_statistics',
  },
  {
    text: 'Sync',
    value: 'sync',
  },
  {
    text: 'TTD Feature Report Analytics Daily ETL',
    value: 'ttd_feature_report_analytics_daily_etl',
  },
  {
    text: 'TTD Feature Report Analytics Download',
    value: 'ttd_feature_report_analytics_dl',
  },
  {
    text: 'TTD Impression Feed Analytics',
    value: 'ttd_impression_feed_analytics',
  },
  {
    text: 'TTD Impression Feed Daily ETL',
    value: 'ttd_impression_feed_daily_etl',
  },
  {
    text: 'TTD Impression Value Analytics',
    value: 'ttd_impression_value_analytics',
  },
  {
    text: 'TTD Report Analytics',
    value: 'ttd_report_analytics',
  },
  {
    text: 'TTD Video Events Feed Analytics',
    value: 'ttd_video_events_feed_analytics',
  },
  {
    text: 'TTD Video Events Feed Daily ETL',
    value: 'ttd_video_events_feed_daily_etl',
  },
  {
    text: 'WMT Feature Report Analytics Daily ETL',
    value: 'wmt_feature_report_analytics_daily_etl',
  },
  {
    text: 'WMT Feature Report Analytics Download',
    value: 'wmt_feature_report_analytics_dl',
  },
  {
    text: 'WMT Impression Feed Analytics',
    value: 'wmt_impression_feed_analytics',
  },
  {
    text: 'WMT Impression Feed Daily ETL',
    value: 'wmt_impression_feed_daily_etl',
  },
  {
    text: 'WMT Impression Value Analytics',
    value: 'wmt_impression_value_analytics',
  },
  {
    text: 'WMT Report Analytics',
    value: 'wmt_report_analytics',
  },
  {
    text: 'WMT Video Events Feed Analytics',
    value: 'wmt_video_events_feed_analytics',
  },
  {
    text: 'WMT Video Events Feed Daily ETL',
    value: 'wmt_video_events_feed_daily_etl',
  },
];

export const JOB_STATUS_OPTIONS: Array<JobStatusOption> = [
  { text: 'New', value: 'NEW' },
  { text: 'Running', value: 'RUNNING' },
  { text: 'Warnings', value: 'WARNINGS' },
  { text: 'Success', value: 'SUCCESS' },
  { text: 'Failed', value: 'FAILED' },
];

export const TIME_ZONE_OPTIONS: Array<TimeZoneOption> = [
  { text: 'My Local', value: TimeZoneValue.myLocal },
  { text: 'UTC', value: TimeZoneValue.utc },
];

export const JOB_STATUS_OPTIONS_OBJ = _.keyBy(JOB_STATUS_OPTIONS, 'value');

export const FILTER = {
  JOB_TYPE: {
    placeholder: 'Type',
    name: 'type',
    id: 'type',
  },
  STATUS: {
    placeholder: 'Status',
    name: 'status',
    id: 'status',
  },
  MEMBER: {
    placeholder: 'Member',
    name: 'member',
    id: 'member',
  },
};

export const COLUMNS = [
  { text: 'Job Id', value: 'id', sortable: true },
  { text: 'Member', value: 'member', sortable: false },
  { text: 'Created', value: 'createdAt', sortable: true },
  { text: 'Updated', value: 'updatedAt', sortable: true },
  { text: 'Status', value: 'status', sortable: false },
];

export const PAGE_NAME = 'Jobs';

export const REQUIRED_PERMISSIONS = [Permission.manageJobs];

/**
 * Default "where" query to send to sails API for Jobs.
 */
export const DEFAULT_WHERE = {
  // All types.
  type: [],
  // All job statues.
  status: [],
  // All members.
  member: [],
  createdAt: null,
};

export const DEFAULT_SORT = {
  column: 'updatedAt',
  direction: 'descending',
};

export const DEFAULT_JOB_POPULATE = 'member';
export const DEFAULT_JOB_STATUS_COLOR = 'green';
export const DEFAULT_MEMBER_POPULATE = 'dsp';

export const JOB_STATUS_COLOR_MAP: { [key: string]: SemanticCOLORS } = {
  NEW: 'blue',
  RUNNING: 'grey',
  WARNINGS: 'yellow',
  SUCCESS: 'green',
  FAILED: 'red',
};
