import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Modal } from 'buildingBlocks';
import { AD_BLOCKER_STYLES } from './style';
import { updateAdblockStatus } from '../actions';

type AdBlockerModalProps = {
  showAdBlockerModal: boolean
  setShowAdBlockerModal: (x: any) => void
};
const AdBlockerModal = (props: AdBlockerModalProps) => {
  const { showAdBlockerModal, setShowAdBlockerModal } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updateAdblockStatus(false));
    setShowAdBlockerModal(false);
  };

  return (
    <Modal
      open={showAdBlockerModal}
      size="small"
      dimmer="blurring"
    >
      <Icon
        style={AD_BLOCKER_STYLES.closeIcon}
        name="times"
        onClick={handleClose}
      />
      <Modal.Content>
        <div style={AD_BLOCKER_STYLES.contentContainer}>
          We&apos;ve detected that you are using an ad blocker.
          Some features of this application may not work properly.
          Please disable it and&nbsp;
          <a
            style={AD_BLOCKER_STYLES.adBlockerLink}
            role="button"
            tabIndex={0}
            onClick={() => window.location.reload()}
          >
            refresh your browser
          </a>.
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdBlockerModal;
