import { GOAL_TYPES } from 'constantsBase';

export const CONTAINER_NAME = 'Strategies Information';

export enum ActionTypes {
  edit = 'edit',
  delete = 'delete',
}

export const standardGoalsForBulkCreate = [
  GOAL_TYPES.cpc,
  GOAL_TYPES.cpcv,
  GOAL_TYPES.cpm,
  GOAL_TYPES.ctr,
  GOAL_TYPES.completionRate,
  GOAL_TYPES.vcpcv,
  GOAL_TYPES.vcpm,
  GOAL_TYPES.vcrYoutube,
  GOAL_TYPES.cpvYoutube,
];
