import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY, COPILOT_COLORS } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { HEADING, BODY } = COPILOT_TYPOGRAPHY;
const { NEUTRALS, YELLOWS } = COPILOT_COLORS.NEW_DESIGN_SYSTEM;

export const BRAND_PAGE_STYLES: { [key: string]: CSSProperties } = {
  brandNameContainer: {
    marginBottom: SPACING[32],
    gap: SPACING[8],
    display: 'grid',
  },
  selectionOptionsListContainer: {
    display: 'grid',
    alignItems: 'center',
    gap: SPACING[4],
  },
  selectionOptionsListHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  brandsPageHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButtonsContainer: {
    display: 'flex',
    gap: SPACING[12],
    justifyContent: 'flex-end',
  },
  brandsTable: {
    borderRadius: 4,
  },
  brandsTableHeader: {
    ...HEADING.H6,
    backgroundColor: NEUTRALS.N50_ALABASTER,
  },
  brandsTableCells: {
    padding: '13px 16px',
  },
  checkBoxCell: {
    padding: '13px 8px 13px 24px',
  },
  labelMargin: {
    marginTop: SPACING[4],
  },
  noAccessMessageContainer: {
    display: 'flex',
    padding: '9px 16px',
    color: YELLOWS.Y700_METAL,
    borderRadius: 4,
    border: `1px solid ${YELLOWS.Y700_METAL}`,
    background: YELLOWS.Y50_DAWN,
  },
  noAccessIcon: {
    border: `1px solid ${YELLOWS.Y700_METAL}`,
    marginRight: SPACING[8],
  },
  helpCenterText: {
    ...BODY.SMALL,
    float: 'right',
    marginTop: SPACING[16],
  },
};
