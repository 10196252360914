import { CSSProperties } from 'react';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const MAINTENANCE_STYLES: { [key: string]: CSSProperties } = {
  mainContainer: {
    margin: 'auto',
    marginTop: 192,
    textAlign: 'center',
  },
  imageContainer: {
    margin: 'auto',
    borderRadius: '50%',
    backgroundColor: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B600_INDIGO,
    height: 258,
    width: 258,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  maintenanceImg: {
    marginBottom: COPILOT_LAYOUT.SPACING[16],
    marginLeft: COPILOT_LAYOUT.SPACING[8],
  },
  textContainer: {
    marginTop: COPILOT_LAYOUT.SPACING[32],
  },
  message: {
    ...COPILOT_TYPOGRAPHY.BODY.LARGE,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY,
  },
};

export default MAINTENANCE_STYLES;
