import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { WppGrid } from 'buildingBlocks';
import PageLoading from 'components/PageLoading';
import PageLoadError from 'components/PageLoadError';
import PermissionWrapper from 'components/PermissionWrapper';
import PageForbidden from 'containers/403';
import PageNotFound from 'containers/404';
import { GlobalState } from 'reducers';
import { Permission } from 'utils/featureFlags';
import { User } from 'utils/types';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { BRAND_NOT_FOUND_ERROR } from './constants';
import BrandDetailsForm from './components/BrandDetailsForm';
import { useBrandDetailsFormFetcher } from './utils';

const BrandDetails: React.FC = (): React.ReactElement => {
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const params = useParams();
  const brandId = params.id;
  const brandDetailsFormState = useBrandDetailsFormFetcher(brandId, user);
  const errorMessage = brandId ? `Error while loading brand [${brandId}]` : 'Error initializing brand form';
  const loadingMessage = brandId ? `Loading data for brand [${brandId}]` : 'Initializing brand form';
  const pageNotFoundButton = { url: '/brands', content: 'Return to Brands Listings' };

  const renderComponent = () => {
    const kind = brandDetailsFormState.kind;
    switch (kind) {
      case PossibleStates.hasData:
        return (
          <BrandDetailsForm
            brandId={brandId}
            defaultValues={_.get(brandDetailsFormState, 'data.defaultValues')}
            validAdvertisers={_.get(brandDetailsFormState, 'data.validAdvertisers')}
          />
        );
      case PossibleStates.error:
        return ((_.get(brandDetailsFormState, 'errorObject.message') === BRAND_NOT_FOUND_ERROR)
          ? <PageNotFound button={pageNotFoundButton} />
          : <PageLoadError error={errorMessage} />);
      case PossibleStates.initial:
      case PossibleStates.loading:
      default:
        return <PageLoading pageName="Brand">{loadingMessage}</PageLoading>;
    }
  };

  return (
    <PermissionWrapper
      user={user}
      permissions={Permission.manageBrands}
      unauthorizedComponent={<PageForbidden message="You are not permitted to view or manage brands." />}
    >
      <WppGrid container>
        <WppGrid item all={24}>
          {renderComponent()}
        </WppGrid>
      </WppGrid>
    </PermissionWrapper>
  );
};

export default BrandDetails;
