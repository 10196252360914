import { AnyAction } from 'redux';
import { Effect } from 'redux-saga';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { Jobs, ViewStrategyFlightRun, AFDagRun } from 'utils/copilotAPI';
import { OPERATOR } from 'constantsBase';
import {
  GET_SUMMARY_DATA, JOB_STATUSES, STRATEGY_FLIGHT_RUN_STATUSES, DAG_STATES,
} from './constants';
import { getSummaryDataCompleted } from './actions';
import { getIsoDateTime, processGroupedCountData, filterDagRuns, processSfrGroupedCountData } from './utils';
import { Job, DagRun, StrategyFlightRun } from './types';

const GROUPED_COUNT_TABLES = {
  jobs: {
    api: Jobs,
    columns: ['type', 'status'],
    dateField: 'createdAt',
  },
  strategyFlightRuns: {
    api: ViewStrategyFlightRun,
    columns: ['strategyType', 'algorithmType', 'status'],
    dateField: 'createdAt',
  },
  dagRuns: {
    api: AFDagRun,
    columns: ['dag_id', 'state'],
    dateField: 'execution_date',
  },
};

function getApiCalls(dateRange) {
  const apiCalls = _.map(GROUPED_COUNT_TABLES, (table, key) => {
    const where = { [table.dateField]: dateRange };
    return [key, call(table.api.groupedCount, { columns: table.columns, where })];
  });
  return _.fromPairs(apiCalls);
}

type APIResponseType = {
  jobs: { data: Array<Job> },
  strategyFlightRuns: { data: Array<StrategyFlightRun> },
  dagRuns: { data: Array<DagRun> },
};

export function* getSummaryData(action: AnyAction) {
  const { startDate, endDate } = action.payload;
  const dateRange = {
    [OPERATOR.GREATER_OR_EQUAL]: getIsoDateTime(startDate),
    [OPERATOR.LESS_OR_EQUAL]: getIsoDateTime(endDate),
  };

  try {
    const { jobs, strategyFlightRuns, dagRuns } : APIResponseType = yield all(getApiCalls(dateRange));
    const payload = {
      jobs: processGroupedCountData(jobs.data, 'type', JOB_STATUSES, 'status'),
      strategyFlightRuns: processSfrGroupedCountData(
        strategyFlightRuns.data,
        STRATEGY_FLIGHT_RUN_STATUSES,
      ),
      // @ts-ignore - hard
      dagRuns: filterDagRuns(processGroupedCountData(dagRuns.data, 'dag_id', DAG_STATES, 'state')),
    };
    yield put(getSummaryDataCompleted(payload));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* copilotHealthSagas(): Iterable<Effect> {
  yield all([
    takeLatest(GET_SUMMARY_DATA, getSummaryData),
  ]);
}

// All sagas to be loaded
export default copilotHealthSagas;
