import React from 'react';
import { Link } from 'react-router-dom';
import { WppButton, WppGrid, WppIconPlus, WppTypography } from 'buildingBlocks';
import { brandsHelpCenterLink } from '../constants';
import { BRAND_PAGE_STYLES } from '../styles';

const { brandsPageHeaderContainer } = BRAND_PAGE_STYLES;

type BrandsPageHeaderProps = {
  isAdminOrQAGlobal: boolean
};

const BrandsPageHeader: React.FC<BrandsPageHeaderProps> = ({ isAdminOrQAGlobal }: BrandsPageHeaderProps): React.ReactElement => (
  <WppGrid container fullWidth>
    <WppGrid item all={24} style={brandsPageHeaderContainer}>
      <WppTypography tag="h1" type="3xl-heading">
        Brands
      </WppTypography>
      {isAdminOrQAGlobal && (
        <Link to="/brands/create">
          <WppButton>
            <WppIconPlus slot="icon-start" /> New Brand
          </WppButton>
        </Link>
      )}
    </WppGrid>
    <WppGrid item all={8}>
      <WppTypography tag="p" type="s-body">
        Enable teams to easily create strategies by assigning a Member
        Advertiser to a Brand in Copilot.&nbsp;
        <a href={brandsHelpCenterLink} target="_blank" rel="noopener noreferrer">Read more</a>.
      </WppTypography>
    </WppGrid>
  </WppGrid>
);

export default BrandsPageHeader;
