import { Member } from 'utils/types';

export const MAX_EXTERNAL_IDS_ALLOWED = 10;

export type FlightSyncForm = {
  member: Member
  externalIds: Array<string>
};

export const initialValues = {
  member: null,
  externalIds: [],
};
