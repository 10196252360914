import { CHECK_FOR_AD_BLOCKER, UPDATE_AD_BLOCK_STATUS } from './constants';

export const checkForAdBlocker = () => ({
  type: CHECK_FOR_AD_BLOCKER,
});

export const updateAdblockStatus = (status: boolean) => ({
  type: UPDATE_AD_BLOCK_STATUS,
  payload: status,
});
