import _ from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { NO_REDIRECT } from 'containers/StrategyWizard/constants';
import { WizardSteps, BULK_CREATE_WIZARD_BASE, BULK_CREATE_WIZARD_STEPS, BULK_CREATE_ATTACH_FLIGHTS_LINK } from '../constants';
import { BulkCreateWizardFormValues } from '../types';

type Props = {
  step: number
  wizardFormValues: BulkCreateWizardFormValues
  children: JSX.Element
};

// handle redirection logic for the bulk create strategy wizard. Prevent skipping steps or going back to the first two steps
export const getRedirectLink = (
  step: number,
  wizardFormValues: BulkCreateWizardFormValues,
  moduleFormValues: StrategyConfigurationStep,
) => {
  const { attachFlightsStep: { member } } = wizardFormValues;

  const basicSetupCompleted = !_.isEmpty(member);
  const strategyConfigCompleted = !_.isEmpty(moduleFormValues);

  if (!step || _.isNaN(step) || (step > BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id)) {
    return `${BULK_CREATE_WIZARD_BASE}/${BULK_CREATE_WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`;
  }

  switch (step) {
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.goalSelectionStep].id:
      if (!basicSetupCompleted) {
        return BULK_CREATE_ATTACH_FLIGHTS_LINK;
      }
      break;
    case BULK_CREATE_WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id:
      if (!basicSetupCompleted || (!strategyConfigCompleted)) {
        return BULK_CREATE_ATTACH_FLIGHTS_LINK;
      }
      break;
    default:
      return NO_REDIRECT;
  }
  return NO_REDIRECT;
};

const BulkCreateStrategyWizardRedirector = ({ step, children, wizardFormValues }: Props) => {
  const { strategyConfigurationStep } = wizardFormValues;
  const to = getRedirectLink(step, wizardFormValues, strategyConfigurationStep);
  return to === NO_REDIRECT ? children : <Navigate to={to} />;
};

export default BulkCreateStrategyWizardRedirector;
