import React, { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { WppButton, WppDivider, WppGrid, WppIconInfo, WppInlineMessage, WppListItem, WppSelect, WppSideModal, WppTypography, ValidatedCustomWppInput } from 'buildingBlocks';
import { SelectChangeEventDetail } from 'utils/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { useAsyncEffect } from 'utils/functionHelpers';
import { getCurrencies } from '../utils';
import { demoGoalTypes, initStrategy } from '../constants';
import { CurrencyT, DemositeStrategy } from '../types';
import { DEMO_SITES_STYLE } from '../styles';

type Props = {
  isOpen: boolean
  handleOpenModal: () => void
  handleCloseModal: () => void
  setSelectedStratIndex: Dispatch<SetStateAction<number>>
  selectedStratIndex?: number
};

const { noMargin, containerMargins, lineItemRow } = DEMO_SITES_STYLE;

export const AddStrategy = React.memo((props: Props) => {
  const { isOpen, handleOpenModal, handleCloseModal, selectedStratIndex, setSelectedStratIndex } = props;
  const { control, setValue, trigger, clearErrors, formState: { errors } } = useFormContext();
  const formStrategies = useWatch({ name: 'strategies' });
  const [strategyIndex, setStratIndex] = useState<number>();
  const [originalStrategies, setOriginalStrategies] = useState<Array<DemositeStrategy>>([]);
  const [currencies, setCurrencies] = useState<Array<CurrencyT>>();

  useAsyncEffect(async () => {
    clearErrors();
    const currenciesRes = await getCurrencies();
    setCurrencies(currenciesRes);
  }, []);

  useMount(() => {
    if (isOpen) {
      // Save the current strategies when the modal opens
      setOriginalStrategies(_.cloneDeep(formStrategies));
    }
    if (_.isNumber(selectedStratIndex)) {
      setStratIndex(selectedStratIndex);
    } else {
      const tempStrategies = [...formStrategies];
      tempStrategies.push(initStrategy);
      setValue('strategies', tempStrategies);
      setStratIndex(_.size(tempStrategies) - 1);
    }
  });

  const defaultStrategy = formStrategies[strategyIndex];
  const strategyLineItems = _.get(defaultStrategy, 'lineItems');
  const strategyErr = _.get(errors, `strategies[${strategyIndex}]`);

  const handleStrategySave = async () => {
    const isValid: boolean = await trigger('strategies');
    if (isValid) {
      handleCloseModal();
      setSelectedStratIndex(null);
      clearErrors('strategies');
    }
  };

  const handleBackClick = () => {
    handleCloseModal();
    setValue('strategies', originalStrategies);
    setSelectedStratIndex(null);
    clearErrors('strategies');
  };

  return (
    <WppSideModal
      open={isOpen}
      onWppSideModalClose={handleBackClick}
      onWppSideModalOpen={handleOpenModal}
      disableOutsideClick
      size="l"
    >
      {defaultStrategy
        && (
          <>
            <h2 slot="header" className="demoSiteheaderStyles">Add Strategy</h2>
            <WppGrid container all={3} fullWidth columnSpacing={4} style={containerMargins} slot="body">
              <WppGrid item fullWidth all={24}>
                <Controller
                  name={`strategies[${strategyIndex}].strategyName`}
                  control={control}
                  defaultValue={defaultStrategy?.strategyName || ''}
                  render={(properties) => (
                    <ValidatedCustomWppInput
                      type="text"
                      title="Strategy Name"
                      onWppChange={properties.field.onChange}
                      {..._.omit(properties, 'formState')}
                      className="strategyName"
                      skipIsDirtyCheck
                    />
                  )}
                />
              </WppGrid>
              <WppGrid item fullWidth all={24}>
                <Controller
                  name={`strategies[${strategyIndex}].brand`}
                  control={control}
                  defaultValue={defaultStrategy?.brand}
                  render={(properties) => (
                    <ValidatedCustomWppInput
                      type="text"
                      title="Brand"
                      onWppChange={properties.field.onChange}
                      {..._.omit(properties, 'formState')}
                      className="brand"
                      skipIsDirtyCheck
                    />
                  )}
                />
              </WppGrid>
              <WppGrid container all={24} style={noMargin} fullWidth>
                <WppGrid item all={12}>
                  <Controller
                    name={`strategies[${strategyIndex}].member`}
                    control={control}
                    defaultValue={defaultStrategy?.member}
                    render={(properties) => (
                      <ValidatedCustomWppInput
                        type="text"
                        title="Member"
                        onWppChange={properties.field.onChange}
                        {..._.omit(properties, 'formState')}
                        className="member"
                        skipIsDirtyCheck
                      />
                    )}
                  />
                </WppGrid>
                <WppGrid item all={12}>
                  <Controller
                    name={`strategies[${strategyIndex}].advertiser`}
                    control={control}
                    defaultValue={defaultStrategy?.advertiser}
                    render={(properties) => (
                      <ValidatedCustomWppInput
                        type="text"
                        title="Advertiser"
                        onWppChange={properties.field.onChange}
                        {..._.omit(properties, 'formState')}
                        className="advertiser"
                        skipIsDirtyCheck
                      />
                    )}
                  />
                </WppGrid>
              </WppGrid>
              <WppGrid container all={24} style={noMargin} fullWidth>
                <WppGrid item all={12}>
                  <Controller
                    name={`strategies[${strategyIndex}].goalType`}
                    control={control}
                    render={({ field }) => (
                      <WppSelect
                        id="goalType"
                        {...field}
                        labelConfig={{ text: 'Goal Type' }}
                        disabled
                        value={demoGoalTypes[0]}
                        onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                          field.onChange(event.detail.value);
                        }}
                      >
                        {_.map(demoGoalTypes, (goalType) => (
                          <WppListItem key={goalType.value} value={goalType}>
                            <p slot="label">{goalType.displayName}</p>
                          </WppListItem>
                        ))}
                      </WppSelect>
                    )}
                  />
                  <WppTypography tag="h5" type="xs-body">
                    Only VCR is supported at the moment.
                  </WppTypography>
                </WppGrid>
                <WppGrid item all={12}>
                  <Controller
                    name={`strategies[${strategyIndex}].currency`}
                    control={control}
                    defaultValue={defaultStrategy?.currency}
                    render={({ field }) => (
                      <WppSelect
                        id="currency"
                        required
                        {...field}
                        labelConfig={{ text: 'Currency' }}
                        onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                          field.onChange(event.detail.value);
                        }}
                        value={defaultStrategy?.currency}
                        loading={_.isEmpty(currencies)}
                      >
                        {_.map(currencies, (curVal) => (
                          <WppListItem key={curVal.id} value={curVal}>
                            <p slot="label">{curVal.code}</p>
                          </WppListItem>
                        ))}
                      </WppSelect>
                    )}
                  />
                  {_.get(strategyErr, 'currency.message')
                    && (
                      <WppInlineMessage
                        size="s"
                        message={_.get(strategyErr, 'currency.message')}
                        type="error"
                      />
                    )}
                </WppGrid>
              </WppGrid>
              <WppGrid item fullWidth all={24}>
                <Controller
                  name={`strategies[${strategyIndex}].attachedFlight`}
                  control={control}
                  defaultValue={defaultStrategy?.attachedFlight}
                  render={(properties) => (
                    <ValidatedCustomWppInput
                      type="text"
                      title="Attached Flight"
                      onWppChange={properties.field.onChange}
                      {..._.omit(properties, 'formState')}
                      className="attachedFlight"
                      skipIsDirtyCheck
                    />
                  )}
                />
              </WppGrid>
              <WppTypography tag="span" className="demoSiteInfoStyle">
                <WppIconInfo /> Line Item names have been prefilled for your convenience. Feel free to edit.
              </WppTypography>
              <WppTypography tag="span" type="s-midi">
                Line Items
              </WppTypography>
              <WppGrid container all={3} fullWidth columnSpacing={4}>
                <WppGrid item all={24}>
                  <WppDivider />
                </WppGrid>
                <WppGrid container fullWidth style={lineItemRow} all={24}>
                  {_.map(strategyLineItems, (lineItem, index) => (
                    <WppGrid item all={24} key={index}>
                      <Controller
                        name={`strategies[${strategyIndex}].lineItems[${index}]`}
                        control={control}
                        defaultValue={lineItem}
                        render={(properties) => (
                          <ValidatedCustomWppInput
                            type="text"
                            {..._.omit(properties, 'formState')}
                            onWppChange={properties.field.onChange}
                            value={lineItem}
                            skipIsDirtyCheck
                          />
                        )}
                      />
                    </WppGrid>
                  ))}
                </WppGrid>
              </WppGrid>
            </WppGrid>
            <div className="demoSiteButtons" slot="actions">
              <WppButton onClick={handleBackClick} variant="secondary">
                Back
              </WppButton>
              <WppButton onClick={handleStrategySave} variant="primary" type="button">
                Save
              </WppButton>
            </div>
          </>
        )}
    </WppSideModal>
  );
});
