import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { WPP } = COPILOT_COLORS;
type CSSStyleObject = { [key: string]: CSSProperties };

export const tableStyles: CSSStyleObject = {
  div: {
    width: '100%',
    marginTop: SPACING[20],
  },
  container: {
    width: '100%',
    height: '500px',
  },
};

export const candidateLoaderStyles: CSSStyleObject = {
  loadingGrid: {
    minHeight: '200px',
  },
};

export const validationProgressStyles: CSSStyleObject = {
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  progressBar: {
    width: '100%',
    marginBottom: SPACING[8],
  },
  text: {
    marginTop: SPACING[8],
    textAlign: 'center',
  },
  inlineMessage: {
    wordBreak: 'break-word',
  },
};

export const optimizationTypeBanner: CSSStyleObject = {
  imageContainer: {
    display: 'block',
    margin: 'auto',
  },
  optTypeContainer: {
    boxShadow: 'none',
    backgroundColor: WPP.grey300,
    borderRadius: '10',
    height: '150px',
  },
  subHeading: {
    paddingLeft: '17px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '-10px',
  },
};

export const candidateContainer: CSSStyleObject = {
  grid: {
    margin: 0,
    paddingTop: SPACING[12],
  },
  selectionContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    paddingTop: SPACING[8],
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 0,
  },
};

export const toggles: CSSStyleObject = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const alreadyAttachedTableStyles: CSSStyleObject = {
  searchIncludeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: SPACING[24],
  },
  objectNameHeader: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '550px',
  },
  strategyNameHeader: {
    width: '150px',
  },
  selectionModeHeader: {
    width: '10px',
  },
  strategyText: {
    marginRight: '6px',
  },
  tableSelectionContainer: {
    padding: '16px 0',
  },
  filterLabelStyle: {
    textAlign: 'right',
  },
};

export const attachedFlightsTableStyles: CSSStyleObject = {
  flightName: {
    paddingLeft: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dspHeader: {
    width: '12%',
  },
};
