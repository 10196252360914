/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, ObjectMultiDropdown } from 'buildingBlocks';
import { SELECT_ALL_OPTION } from 'constantsBase';
import { TOOLTIP_DESCRIPTIONS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import SubHeader from './SubHeader';
import { MessageAlgoType, Option } from '../types';

type AlgorithmTypeSelectionProps = {
  options: Array<MessageAlgoType>
  error: string
};

const AlgorithmTypeSelection = ({ options, error }: AlgorithmTypeSelectionProps) => {
  const { control } = useFormContext();
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const loading = !_.size(options) && _.isNil(error);

  const hasSelectAll = (selected) => _.includes(selected, SELECT_ALL_OPTION);

  const handleSearchChange = (algoOptions: Array<MessageAlgoType>, searchQuery: string) => {
    const search = _.toLower(searchQuery);
    return _.filter(algoOptions, (option: Option) => _.includes(_.lowerCase(option.text), search));
  };

  return (
    <Grid.Row columns={4}>
      <SubHeader
        headerContent="Algorithm Type"
        toolTipContent={_.get(TOOLTIP_DESCRIPTIONS, 'algoType')}
      />
      <Grid.Column>
        <Controller
          name="algoTypes"
          control={control}
          render={(props) => (
            // @ts-ignore ObjectMultiDropdown
            <ObjectMultiDropdown
              name="algoTypes"
              placeholder="Select The Algorithm Type"
              value={props.field.value}
              options={options}
              loading={loading}
              keyFn={(algorithmType: MessageAlgoType) => algorithmType.name}
              onClick={() => setSelectedAll(true)}
              onChange={(algos) => {
                if (hasSelectAll(algos)) {
                  props.field.onChange([{ ...SELECT_ALL_OPTION, ids: _.compact(_.map(options, 'id')) }]);
                  setSelectedAll(false);
                } else {
                  props.field.onChange(algos);
                }
              }}
              search={{
                searchType: 'local',
                onSearchChange: handleSearchChange,
              }}
              selectionLimit={hasSelectAll(props.field.value) ? 1 : null}
              onBlur={() => setSelectedAll(false)}
              open={selectedAll}
              fluid
              selection
              scrolling
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default AlgorithmTypeSelection;
