import _ from 'lodash';
import React, { createContext, useContext, useReducer } from 'react';
import { GoalSuccessEvent } from 'constantsBase';
import { useBulkCreateStrategyWizardContext } from 'containers/BulkCreateStrategyWizard/contexts/BulkCreateStrategyWizardProvider';
import { getDspByMemberOrFlights } from 'containers/StrategyWizard/utils';
import { getParentObjectDisplayName } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { updateSuccessEventFilter } from 'containers/StrategyWizard/steps/GoalSelection/contexts/actions';
import { goalSectionReducer } from 'containers/StrategyWizard/steps/GoalSelection/contexts/reducers';
import { GoalSectionProviderProps } from 'containers/StrategyWizard/steps/GoalSelection/contexts/types';
import { INITIAL_STATE } from 'containers/StrategyWizard/steps/GoalSelection/contexts/constants';

const GoalSectionContext = createContext(null);

const BulkCreateGoalSectionProvider = ({ children }: GoalSectionProviderProps) => {
  const [state, dispatch] = useReducer(goalSectionReducer, INITIAL_STATE);
  const { wizardFormValues } = useBulkCreateStrategyWizardContext();
  const { optimizationLevel, member, attachedFlights } = _.get(wizardFormValues, 'attachFlightsStep');
  const dsp = getDspByMemberOrFlights(attachedFlights, member);
  const actions = {
    setGoalSuccessEventFilter: (successEvent: GoalSuccessEvent | 'all') => dispatch(updateSuccessEventFilter(successEvent)),
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValues = {
    ...state,
    ...actions,
    wizardFormValues,
    parentObjectTypeDisplayName: getParentObjectDisplayName(attachedFlights, optimizationLevel),
    dsp,
  };

  return (
    <GoalSectionContext.Provider value={providerValues}>
      {children}
    </GoalSectionContext.Provider>
  );
};

export const useBulkCreateGoalSectionContext = () => {
  const context = useContext(GoalSectionContext);
  if (!context) {
    throw new Error('useBulkCreateGoalSectionContext must be used within a GoalSectionProvider');
  }
  return context;
};

export default BulkCreateGoalSectionProvider;
