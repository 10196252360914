import React from 'react';
import { ActionTypes } from '../constants';
import { HyperLinksPopover } from './HyperLinksPopover';
import { MoreActionsPopover } from './MoreActionsPopover';
import { DEMO_SITES_STYLE } from '../styles';

const { popoverStyle } = DEMO_SITES_STYLE;

type Props = {
  domain: string
  actionType: ActionTypes
  id?: number
  handleDelete?: (id: number) => void
};

export const ButtonActions = ({ domain, actionType, id, handleDelete }: Props) => (
  <div style={popoverStyle}>
    {actionType === ActionTypes.hyperLinks && (
      <HyperLinksPopover domain={domain} />
    )}
    {actionType === ActionTypes.more && (
      <MoreActionsPopover
        domain={domain}
        handleDelete={handleDelete}
        id={id}
      />
    )}
  </div>
);
