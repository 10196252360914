const PREFIX = 'AUTH_TOKEN';

export const GET_AUTH_HEADERS = `${PREFIX}:GET_AUTH_HEADERS`;
export const GET_AUTH_HEADERS_COMPLETED = `${PREFIX}:GET_AUTH_HEADERS_COMPLETED`;
export const GET_AUTH_HEADERS_FAILED = `${PREFIX}:GET_AUTH_HEADERS_FAILED`;
export const LOADING_STATES = {
  loading: 'loading',
  notLoading: 'notLoading',
  error: 'error',
};
export const EXPIRE_TEXT = 'Your auth token will expire in 1 hour.';
export const FAILURE_TEXT = 'There was a problem generating the headers.';

export default GET_AUTH_HEADERS;
