import _ from 'lodash';
import { pluralizer } from 'utils/formattingUtils';
import { SUBMIT_FORM_COMPLETED, SUBMIT_FORM_FAILED } from 'containers/MessageCreator/constants';
import {
  MESSAGES_FETCH_COMPLETED,
  SELECT_ALL,
  SELECT_NONE,
  TOGGLE_MODAL,
  MESSAGES_SELECTED,
  CLEAR_RESULT_MESSAGE,
  DELETE_MESSAGES,
  DELETE_MESSAGES_COMPLETED,
  DELETE_MESSAGES_FAILED,
  CLEAR_SELECTED_MESSAGES,
} from './constants';
import { Message, SelectedMessages, StatusMessage } from './types';

export type State = {
  messages: Array<Message>
  messagesCount: number
  selectedMessages: SelectedMessages
  deleteEnabledMessages: SelectedMessages
  deleting: boolean
  statusMessage: StatusMessage
  loading: boolean
  modalOpen: boolean
};

export const INITIAL_STATE: State = {
  messages: [],
  messagesCount: 100,
  selectedMessages: {},
  deleteEnabledMessages: {},
  deleting: false,
  statusMessage: null,
  loading: true,
  modalOpen: false,
};

const messagePluralizer = pluralizer('Message', 'Messages');

export default function usersListing(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MESSAGES_FETCH_COMPLETED:
      return {
        ...state,
        messages: action.payload.messages,
        messagesCount: action.payload.messagesCount,
        loading: false,
      };

    case CLEAR_RESULT_MESSAGE:
      return { ...state, statusMessage: null };

    case SELECT_ALL: {
      const deleteEnabledMessages = _.keyBy(state.messages, 'id');
      return {
        ...state,
        selectedMessages: deleteEnabledMessages,
        deleteEnabledMessages,
      };
    }
    case SELECT_NONE:
      return {
        ...state,
        selectedMessages: {},
      };

    case DELETE_MESSAGES:
      return { ...state, deleting: true };

    case DELETE_MESSAGES_COMPLETED:
      return {
        ...state,
        modalOpen: false,
        deleting: false,
        selectedMessages: {},
        statusMessage: {
          header: 'Message Deletion Successful!',
          body: `Deleted ${_.size(action.payload)} ${messagePluralizer(_.size(action.payload))}`,
          isFailure: false,
        },
      };

    case DELETE_MESSAGES_FAILED:
      return {
        ...state,
        modalOpen: false,
        deleting: false,
        statusMessage: {
          header: 'Message Deletion failed!',
          body: 'There was a problem in the deletion process.',
          isFailure: true,
        },
      };
    case TOGGLE_MODAL:
      return { ...state, modalOpen: !state.modalOpen };

    case CLEAR_SELECTED_MESSAGES:
      return { ...state, selectedMessages: {} };

    case MESSAGES_SELECTED:
      return {
        ...state,
        selectedMessages: _.has(state.selectedMessages, action.payload.id)
          ? _.omit(state.selectedMessages, action.payload.id)
          : { ...state.selectedMessages, [action.payload.id]: action.payload },
      };
    case SUBMIT_FORM_COMPLETED:
      return {
        ...state,
        statusMessage: {
          header: 'Message saved!',
          body: `Message ${action.payload.subject} saved successfully`,
          isFailure: false,
        },
      };
    case SUBMIT_FORM_FAILED:
      return {
        ...state,
        statusMessage: {
          header: 'Error!',
          body: `Message ${action.payload.subject} (${action.payload.id || 'new'}) failed to save.`,
          isFailure: true,
        },
      };
    default:
      return state;
  }
}
