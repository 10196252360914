/* eslint-disable @typescript-eslint/no-shadow */
import React, { forwardRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, List } from 'buildingBlocks';
import { DATE_FORMAT_JS_DATE } from 'utils/dateTime';
import { CustomDatePickerProps } from 'utils/types';
import { datePickerStyles } from '../style';

const { calendarIconStyle, colorSetting, customInputContainer, postOptions } = datePickerStyles;

const postChoices = ['Immediately', 'One hour from now'];

type PostOptionsProps = {
  postChoices: Array<string>
  postAction: Function
};

export const PostOptions = (props: PostOptionsProps) => (
  <List style={postOptions}>
    {props.postChoices.map((postOption) => (
      <List.Item key={postOption} style={{ padding: '0' }}>
        <Button
          type="button"
          style={{ width: '100%' }}
          onClick={() => props.postAction(postOption)}
          content={postOption}
        />
      </List.Item>
    ))}
  </List>
);

type CalendarProps = {
  onClick?: () => void
  onChange?: (e) => void
  value?: string
  placeholder?: string
  disabled?: boolean
};

const CustomInput = forwardRef<any, CalendarProps>((props, ref) => {
  const { onClick, onChange, disabled, value, placeholder, ...otherProps } = props;
  return (
    <div style={{ ...customInputContainer, ...colorSetting }}>
      <input
        type="text"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
        value={value}
        style={{ backgroundColor: 'transparent', border: 0, width: '160px' }}
        disabled={disabled}
        {...otherProps}
      />
      <Button style={{ ...calendarIconStyle, ...colorSetting }} icon="calendar alternate outline" onClick={onClick} />
    </div>
  );
});

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {
    placeholder, showPostOptions,
    startDate, minDate, maxDate,
    hasMinDate, hasMaxDate, changeTime,
    selected, onChange, closeOnSelect,
  } = props;

  const calcMinDate = (hasMinDate, minDate, showPostOptions, startDate) => {
    if (hasMinDate) {
      return minDate;
    }
    return !showPostOptions ? moment(startDate) : moment();
  };

  const handleChange = (date) => {
    onChange(date);
  };

  const handlePostOptions = (isSelected) => {
    if (isSelected === 'Immediately') {
      const date = moment();
      onChange(date);
      changeTime(date);
    } else if (isSelected === 'One hour from now') {
      const date = moment().add(1, 'hours');
      onChange(date);
      changeTime(date);
    } else {
      const date = moment().add(1, 'days');
      onChange(date);
    }
  };

  const checkInputDate = moment(selected, [DATE_FORMAT_JS_DATE]).startOf('day');
  const checkBeforeStartDate = moment(startDate, [DATE_FORMAT_JS_DATE]).startOf('day');

  return (
    <div>
      <DatePicker
        placeholderText={placeholder}
        dateFormat={DATE_FORMAT_JS_DATE}
        selected={(selected && (!checkInputDate.isBefore(checkBeforeStartDate))) ? moment(selected).toDate() : null}
        onChange={handleChange}
        minDate={calcMinDate(hasMinDate, minDate, showPostOptions, startDate).toDate()}
        maxDate={hasMaxDate ? maxDate.toDate() : undefined}
        showPostOptions={showPostOptions}
        shouldCloseOnSelect={closeOnSelect}
        popperPlacement="top"
        customInput={<CustomInput />}
      >
        {showPostOptions && (<PostOptions postChoices={postChoices} postAction={handlePostOptions} />)}
      </DatePicker>
    </div>
  );
};

export default CustomDatePicker;
